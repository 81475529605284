import React, { Component } from "react";
import { Layout, Col, Row, Typography, List, message, Card, Statistic } from "antd";
import '../../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'

const { Content } = Layout;
const { Text } = Typography
const axios = require('axios')

/**
 * @export
 * @class ComprasList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class ComprasList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            compras: [],
            ordenes: [],
            page: 1,
            limit: 10,
            total: 0,
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
    }

    /**
     * @memberof FacturasList
     * @method componentDidUpdate 
     * @description Cargamos la informacion inicial
     */
    componentDidUpdate(prevProps) {
        if(this.props.orden_id && this.props.orden_id !== prevProps.orden_id){
            this.getOrdenes(1)
        }
    }

    /**
    * @memberof IngresosList
    * @method   getOrdenes
    * @description  Retorna los pagos realizados a la orden
    **/
    getOrdenes = (page = this.state.page) => {
        this.setState({ loading: true })
        axios.get('/customer/ordenes_detalles/list', {
            params: {
                orden_id: this.props.orden_id,
                page: page
            }
        }).then(response => {
            this.setState({
                ordenes: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,

            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obetner los pagos')
        }).finally(() => this.setState({ loading: false }))
    }



    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.ordenes}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: this.state.limit,
                                    total: this.state.total,
                                    hideOnSinglePage: false,
                                    position: 'bottom',
                                    className: "flex-left",
                                    onChange: this.getOrdenes
                                }}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <div className={`badge-card tipo-1`}>
                                            </div>
                                            <Row className="width-100">
                                                <Col span={10} className="flex-left">
                                                    <Text strong>
                                                        Marca: {item.producto_id?.marca ? item.producto_id?.marca : '-'}
                                                        {item.producto_id?.modelo != undefined ? <>Modelo: {item.producto_id?.modelo} <br /></> : null}
                                                    </Text>
                                                </Col>
                                                <Col span={5} className="flex-column">
                                                    <Text className="text-gray"> Cantidad</Text>
                                                    <Text> {item.cantidad}</Text>
                                                </Col>
                                                <Col span={5} className="flex-column">
                                                    <Text className="text-gray"> Cantidad Recibida</Text>
                                                    <Text> {item.cantidad_recibida}</Text>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />

                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}