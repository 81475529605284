import React, { Component } from "react";
import { Button, Row, Radio, Space, Col, Modal, Typography, Form, Input, InputNumber, Select, Spin, Upload, Avatar, message } from 'antd';
import { PlusOutlined, UploadOutlined, LoadingOutlined, } from '@ant-design/icons';
import AddOnMoneda from '../../../Widgets/Inputs/AddOnMoneda';


const { Title, Paragraph, Text } = Typography;
const { Option } = Select
const axios = require('axios').default;


/**
 *
 *
 * @class ModalRFQ
 * @extends {Component}
 */
class ModalRFQ extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			productos: [],
			producto: undefined,
			imagenes: [],
			files_to_delete: [],
		}
	}

	modalRFQ = React.createRef();

	componentDidMount() {
		axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
		this.getProductos()
		if (this.props.rfq_detalle_id != undefined) {
			this.getRFQDetalle()
		}
	}


	/**
	*
	* @memberof ModalRFQ
	*
	* @method getProductos
	* @description Obtiene una lista de productos.
	*/
	getProductos = (search) => {
		axios.get('/productos', {
			params: {
				search,
				limit: 20
			}
		}).then(response => {
			this.setState({ productos: response.data.data.data })

		}).catch(error => {
			console.log("error", error);
			message.error('Error al cargar los productos')
		})
	}

	/**
	 *
	 * @memberof ModalRFQ
	 *
	 * @method getRFQDetalle
	 * @description Obtiene la informacion de un rfqDetalle  para su edicion
	 */
	getRFQDetalle = () => {
		axios.get('/rfq/orden/get', {
			params: {
				_id: this.props.rfq_detalle_id
			}
		}).then(response => {
			let rfq = response.data.data

			let unidades = {
				'Pieza': 'H87',
				'Elemento': 'EA',
				'Kilogramo': 'KGM',
				'Metro': 'MTR',
				'Kit': 'KT',
				'Conjunto': 'SET',
				'Litro': 'LTR',
				'Caja': 'XBX',
				'Unidad': 'xun',
				'Gramo': 'GRM',
			}

			this.setState({ imagenes: rfq.imagenes })

			this.modalRFQ?.current?.setFieldsValue({
				...rfq,
				um: unidades[rfq.um],
				imagenes: rfq.imagenes.map((image, index) => ({
					uid: index,
					name: image,
					filename: image,
					status: 'done',
					url: process.env.REACT_APP_WEB_SERVICE + '/upload/th_' + image
				})),
				archivos: rfq.archivos.map(({ name, filename }, index) => ({
					uid: index,
					name,
					filename,
					status: 'done',
					url: process.env.REACT_APP_WEB_SERVICE + '/upload/' + filename
				})),
			})

		}).catch(error => {
			console.log("error", error);
			message.error('Error al obtener la información')
		})
	}

	/**
	 *
	 * @memberof ModalRFQ
	 *
	 * @method onChangeProducto
	 * @description Obtiene la informacion del producto selccionado y rellenar la informacion del formulario
	 */
	onChangeProducto = (producto_id) => {

		if (producto_id) {
			this.setState({ loading: true })
			axios.get('/producto/get', {
				params: {
					id: producto_id
				}
			}).then(response => {
				let producto = response.data.data

				this.setState({ producto })

				this.modalRFQ.current?.setFieldsValue({
					marca: producto.marca,
					descripcion: producto.descripcion,
					um: producto.unidad,
					precio_ref: producto.costo,

				})


			}).catch(error => {
				console.log("error", error);
				message.error('Error al obtener la información del producto')
			}).finally(() => this.setState({ loading: false }))
		} else {
			this.setState({ producto: undefined })
		}

	}

	/**
	 *
	 * @memberof ModalRFQ
	 *
	 * @method addRFQ
	 * @description Añade un RFQ detalle a la orden
	 */
	addRFQ = (values) => {

		axios.post('/rfq/orden/add', {
			...values,
			producto: this.state.producto,
			orden_id: this.props.orden_id
		}).then(response => {
			message.success('RFQ Creado con exito')
			this.props.onClose(true)

		}).catch(error => {
			console.log("error", error);
			message.error('Error al crear el RFQ')
		})

	}


	/**
	*
	* @memberof ModalRFQ
	*
	* @method updateRFQ
	* @description Actualza un RFQ detalle a la orden
	*/
	updateRFQ = (values) => {

		axios.post('/rfq/orden/update', {
			...values,
			rfq_detalle_id: this.props.rfq_detalle_id
		}).then(response => {
			message.success('RFQ actualizado')
			if (this.state.files_to_delete.length > 0) {
				for (const file of this.state.files_to_delete) {
					this.removeFile(file)
				}
			}
			this.props.onClose(true)
		}).catch(error => {
			console.log("error", error);
			message.error('Error al actualzar el RFQ')
		})

	}


	/**
	 *
	 * @memberof ModalRFQ
	 *
	 * @method onFinish
	 * @description Se ejecuta al hacer submit al formulario
	 */
	onFinish = (values) => {
		values.imagenes = values.imagenes?.map(e => e.filename)
		console.log("values", values);
		if (this.props.rfq_detalle_id) {
			this.updateRFQ(values)
		} else {
			this.addRFQ(values)
		}
	}


	/**
	 *
	 * @memberof ModalRFQ
	 *
	 * @method removeFile
	 * @description Elimina un archivo del WebService.
	 *
	 * @param images (string)
	 * Recibe el nombre de la imagen.
	 */
	removeFile = (image) => {
		axios.post("/upload/delete", {
			filename: image
		})
			.then(res => {
				console.log("imagen removida con exito", res);
			})
			.catch(res => {
				console.log("imagen no se puedo remover", res);
			})
	};

	/**
	 *
	 * @memberof ModalRFQ
	 *
	 * @method normFile
	 * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
	 *
	 * @param images (string)
	 * Recibe el nombre de la imagen.
	 */
	normFile = (e) => {
		const { file, fileList } = e;
		for (let x = 0; x < fileList.length; x++) {
			if (fileList[x].response) {
				fileList[x].filename = fileList[x].response.filename
				fileList[x].name = fileList[x].response.name
			}
		}

		/*if (file.status === "removed")
			this.removeFile((file?.response?.filename) ? file.response.filename : file.filename);*/
		return e.fileList;
	};

	/**
	 *
	 * @memberof ModalRFQ
	 *
	 * @method deleteimage
	 * @description Quita el archivo del state de comprobantes para ponerlos en files_to_delete, asi cuando el usuario guarde, se eliminan, y no se borren automaticamente
	 */
	deleteImage = (file) => {
		console.log("file", file);
		let imagenes = this.state.imagenes
		let index = imagenes.findIndex(img => img === file.filename)
		if (index != -1) {
			let to_delete = imagenes[index]
			imagenes.splice(index, 1)
			this.state.files_to_delete.push(to_delete)
		}
	}


	render() {


		return (
			<Form
				layout="vertical"
				ref={this.modalRFQ}
				onFinish={this.onFinish}
				initialValues={{
					prioridad: 1,
					cantidad: 1,
				}}

			>
				<Spin spinning={this.state.loading}>
					<Row justify="center">
						<Col xs={22}>
							<Form.Item
								label="Imagenes"
								name="imagenes"
								getValueFromEvent={this.normFile}
								valuePropName="fileList"
							>
								<Upload
									action={axios.defaults.baseURL + "/upload/add"}
									multiple={true}
									listType="picture-card"
									accept="image/*"
									onRemove={this.deleteImage}
								>
									<div>
										<PlusOutlined />
										<div style={{ marginTop: 8 }}>Subir Archivo</div>
									</div>
								</Upload>
							</Form.Item>
						</Col>
					</Row>

					{this.props.rfq_detalle_id === undefined ? <Row justify="center">
						<Col xs={22}>
							<Form.Item
								label={<Text style={{ color: '#000' }}>Productos <small>(opcional)</small></Text>}
								name="producto_id"
							>
								<Select
									showSearch
									onSearch={this.getProductos}
									filterOption={false}
									onChange={this.onChangeProducto}
									allowClear={true}
								>
									{this.state.productos.map(producto => {

										return <Option
											key={producto._id}
											value={producto._id}
											title={producto.np_fabricante}>
											<div>
												<Avatar
													style={{ display: "inline-block", marginRight: 4 }}
													shape="square" src={(Array.isArray(producto.imagenes) && producto.imagenes.length > 0) ? (axios.defaults.baseURL + '/upload/th_' + producto.imagenes[0].filename) : undefined} />
												<Text strong>{producto.np_fabricante}</Text> &nbsp; <Text>{producto.np_nexus}</Text> &nbsp; <Text>{producto.descripcion}</Text>
											</div>
										</Option>
									})}
								</Select>
							</Form.Item>
						</Col>
					</Row> : null}
					<Row justify="center">
						<Col xs={{ span: 22 }} md={{ span: 10 }}>
							<Form.Item
								className="agregar-producto-col-input"
								label="Marca"
								name="marca"
							>
								<Input placeholder="Ingresa la marca" maxLength={100} />
							</Form.Item>
						</Col>
						<Col xs={{ span: 0 }} md={{ span: 2 }}></Col>
						<Col xs={{ span: 22 }} md={{ span: 10 }}>
							<Form.Item
								className="agregar-producto-col-input"
								label="Modelo"
								name="modelo"
							>
								<Input placeholder="Ingresa el modelo" />
							</Form.Item>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={{ span: 22 }}>
							<Form.Item
								className="agregar-producto-col-input"
								label="Descripción"
								name="descripcion"

							>
								<Input.TextArea row={5} maxLength={2000} />
							</Form.Item>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={{ span: 22 }}>
							<Form.Item

								label="Unidad de Medida"
								name="um"

							>
								<Select laceholder="Unidad">
									<Option value={'H87'}>Pieza</Option>
									<Option value={'EA'}>Elemento</Option>
									<Option value={'KGM'}>Kilogramo</Option>
									<Option value={'MTR'}>Metro</Option>
									<Option value={'KT'}>Kit</Option>
									<Option value={'SET'}>Conjunto</Option>
									<Option value={'LTR'}>Litro</Option>
									<Option value={'XBX'}>Caja</Option>
									<Option value={'xun'}>Unidad</Option>
									<Option value={'GRM'}>Gramo</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={{ span: 22 }}>
							<Form.Item

								label="Cantidad"
								name="cantidad"
								rules={[
									{
										required: true,
										message: "Ingresa la cantidad, por favor",
										pattern: new RegExp(/[0-9]+/)
									}
								]}
							>
								<InputNumber />
							</Form.Item>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={{ span: 22 }}>
							<Form.Item

								label="Precio Unitario de Referencia"
								name="precio_ref"
							>
								<InputNumber
									formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
									parser={value => value.replace(/\$\s?|(,*)/g, '')}
									className="width-100"
									controls={false}
									addonAfter={(
										<AddOnMoneda />
									)}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={22}>
							<Form.Item
								label="La prioridad 1 nos indica aquello que es más relevante para tu cotización"
								style={{ marginBottom: 0 }}
								name="prioridad"
								rules={[
									{
										required: true,
										message: "Ingresa la prioridad, por favor",
									}
								]}
							>
								<Select>
									<Option value={1}>Precio</Option>
									<Option value={2}>Tiempo de Entrega</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={22}>
							<Form.Item
								label="Documentos del Producto"
								name="archivos"
								getValueFromEvent={this.normFile}
								valuePropName="fileList"
							>
								<Upload
									accept='.doc,.docx,.odt,.pdf,.xls,.xlsx,.ods,.ppt,.pptx,.csv'
									action={axios.defaults.baseURL + "/upload/add"}
									multiple={true}
								>
									<Button>
										Subir Archivo
									</Button>
								</Upload>
							</Form.Item>
						</Col>
					</Row>
					<Row justify="center">
						<Col xs={22} className="center mt-2">
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
								>
									Guardar
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Spin>
			</Form>
		)
	}
}



export default function (props) {
	const { visible = false, onClose = () => { } } = props

	return <Modal
		visible={visible}
		onCancel={onClose}
		title={null}
		footer={null}
		closable={true}
		destroyOnClose={true}
		zIndex={1000}
	>
		<div className="center">
			<Title level={3}> {props.title} RFQ </Title>
		</div>
		<ModalRFQ {...props} />
	</Modal>

}