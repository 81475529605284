import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, InputNumber, Select, Checkbox } from 'antd';

import { User } from '../../../../Hooks/Logged';
import { SocketContext } from '../../../../Hooks/Socket';

const { Title, Text } = Typography;
const { Option } = Select

const axios = require('axios').default;


/**
 *
 *
 * @class ModalSolicitudProductos
 * @extends {Component}
 */
class ModalSolicitudProductos extends Component {

    static contextType = User

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            return: false,
            disabled: false,
            premium: false,
            almacenes: [],
            almacen_origen: {},
            almacen_disponible: {}
        }
    }

    ModalSolicitudProductos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.clear()
        this.getProductos()
        if(this.props.solicitud_id){
            this.getSolicitud()
        }

        
    }

    /**
     * @memberOf ModalSolicitudProductos
     * @method getSolicitud
     * @description Obtiene la información de la solicitud
     */
    getSolicitud = () => {
        this.setState({loading: true})
        axios.get('/solicitudes/get',{
            params:{
                solicitud_id: this.props.solicitud_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.ModalSolicitudProductos.current.setFieldsValue({
                producto_id:{
                    value: response.data.producto_id._id,
                    key: response.data.producto_id._id,
                    label: response.data.producto_id.np_fabricante,
                },
                almacen_id:{
                    value: response.data.almacen_id._id,
                    key: response.data.almacen_id._id,
                    label: response.data.almacen_id.direccion,
                },

                almacen_producto_id: response.data.almacen_producto_emisor_id._id,
                preguntar: true,
                cantidad: response.data.cantidad,
            })
            this.setState({
                cliente_id: response.data.cliente_solicitante_id._id,
                cliente_emisor_id: response.data.cliente_emisor_id._id
            })
        }).then(()=> {
                this.getAlmacenesDestino()
                this.getAlmacenesDisponibles()
        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener la Solicitud')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @memberOf ModalSolicitudProductos
     * @method getAlmacenesDisponibles
     * @description Obtiene la lista almacenes de otros clientes, con el mismo producto seleccionado
     */
    getAlmacenesDisponibles = (clear) => {
        
        let producto_id = this.ModalSolicitudProductos.current.getFieldValue('producto_id')

        axios.get('/almacenes/productos/solicitud', {
            params: {
               cliente_id: this.state.cliente_id,
               producto_id: producto_id?.value,
               cliente_emisor_id: this.state.cliente_emisor_id
            }
        }).then(({ data }) => {
            this.setState({
                almacenes_disponibles: data
            })
            if(clear){
                this.ModalSolicitudProductos.current.setFieldsValue({
                    almacen_producto_id: null
                })
            }
            
        })
        .catch(error => {
            console.log(error)
        })
    }

    /**
     * @memberOf ModalSolicitudProductos
     * @method getAlmacenesDetino
     * @description Obtiene la lista de almacenes del cliente y que tengan el producto seleccionado
     * 
     */
    getAlmacenesDestino = (clear) => {
        let producto_id = this.ModalSolicitudProductos.current.getFieldValue('producto_id')
        axios.get('/almacenes/productos/solicitud/destino',{
            params:{
                cliente_id: this.state.cliente_id,
                producto_id: producto_id?.value,
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
               almacenes: response.data
            })
            if(clear){
                this.ModalSolicitudProductos.current.setFieldsValue({
                    almacen_id: null
                })
            }

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los almacenes del cliente')
        })
    }


    /**
     * @memberOf ModalSolicitudProductos
     * @method getProductos
     * @description Obtiene la lista de productos que tiene el cliente, tiene integrado el arreglo de almacenes donde esta agregado
     * 
     */
    getProductos = (search) => {
        axios.get('/vmi/list', {
            params: {
                productos: true,
                cliente_id: this.state.cliente_id,
                search
            }
        }).then(({ data }) => {
            this.setState({
                productos: data.data.itemsList
            })

        })
        .catch(error => {
            message.error("No se obtuvieron los Almacenes")
            console.log(error)
        })
    }

    

    /**
     * @memberOf ModalSolicitudProductos
     * @method onFinish
     * @description Se ejecuta al hacer submit en el formulario
     * 
     */
    onFinish = (values) => {
        const user = this.context
        values.producto_id = values.producto_id.value;
        values.almacen_id = values.almacen_id.value;
        this.updateSolicitud(values)
    }

    /**
     * @memberOf ModalSolicitudProductos
     * @method updateSolicitud
     * @description Crea una nueva solicitud hacia otro cliente
     * 
     */
    updateSolicitud = (values) => {

        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/solicitudes/update', {
                cliente_id: this.state.cliente_id,
                solicitud_id: this.props.solicitud_id,
                ...values
            })
                .then(success => {
                    message.success('Solicitud Actualizada!')
                    const socket = this.props.socket
    
                    /*if(socket.connected) {
            
                        let notificacion = {
                            evento: `${user.nombre} te mandó una solicitud de productos`,
                            detalle: `${user.nombre} solicita productos un almacen a tu cargo en ${this.state.almacen_disponible?.almacen?.direccion}`,
                            usuarios_destino: [this.state.almacen_disponible?.inventario_id?.cliente_id?.responsable_id],
                            usuario_id: user._id
                        }
                        socket.emit('nueva_notificacion', notificacion)
                    }*/
                    
                    this.props.onClose()
                })
                .catch(error => {
                    message.error('Error al actualizar Solicitud!')
                    console.log(error)
                })
                .finally(() => {
                    this.setState({loading: false})
                })
        })
        

    }


    render() {
        const disabled = this.state.disabled
        const user = this.context
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                    ref={this.ModalSolicitudProductos}
                >
                    <Row className="mt-1">
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Producto"
                                }]}
                            >
                                <Select
                                    disabled={disabled}
                                    showSearch
                                    filterOption={false}
                                    onSearch={this.getProductos}
                                    labelInValue
                                    onChange={(value) => {
                                        this.getAlmacenesDisponibles(true)
                                        this.getAlmacenesDestino(true)
                                    }}
                                >
                                    {
                                        this.state.productos?.map(item => (
                                            <Option key={item.producto_id._id} value={item.producto_id._id}>{item.producto_id.np_fabricante}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese una cantidad"
                                }]}
                            >
                                <InputNumber
                                    placeholder="Cantidad"
                                    className="width-100"
                                    min={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                labelInValue
                                label="Almacen Destino"
                                name="almacen_id"
                                multiple={false}
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la disponibilidad"
                                }]}
                            >
                                <Select
                                    disabled={disabled}
                                >
                                    {
                                        this.state.almacenes?.map(({ _id, almacen_id }) => {
                                            return <Option key={almacen_id._id} value={almacen_id._id}>{almacen_id.direccion}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Disponibilidad"
                                name="almacen_producto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la disponibilidad"
                                }]}
                            >
                                <Select
                                    onChange={(value) => {
                                        this.setState({
                                            almacen_disponible: this.state.almacenes_disponibles?.find(almacen => almacen._id === value)
                                        })
                                    }}
                                >
                                    {
                                        this.state.almacenes_disponibles?.map(({ _id, almacen_id, inventario_id, inventario_producto_id, cantidad }) => (
                                            <Option value={_id}>
                                                <div className="flex-between">
                                                    <Text className="text-purple">{inventario_id?.cliente_id?.nombre}</Text>
                                                    <Text>{cantidad} {inventario_producto_id?.producto_id?.unidad.toUnidades()}</Text>
                                                </div>
                                                <div>
                                                    <Text className="text-gray">{almacen_id?.direccion}</Text>
                                                </div>
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {user._id.toString() === this.props.responsable._id.toString() ? <Col xs={24} lg={24}>
                            <Form.Item
                                label="Estatus"
                                name="estatus"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el estatus"
                                }]}
                            >
                                <Select
                                    placeholder="Respuesta"
                                >
                                    <Option value={1}>Aceptar</Option>
                                    <Option value={2}>Rechazar</Option>
                                </Select>
                            </Form.Item>
                        </Col> : null}
                        {user.tipo <= 1 ? <Col xs={24} lg={24}>
                            <Form.Item name="preguntar" valuePropName="checked" wrapperCol={{span: 24 }}>
                                <Checkbox>¿Desea preguntarle al encargado del cliente si acepta o no el traspaso?</Checkbox>
                            </Form.Item>
                        </Col>: null}
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Solicitar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    const socket = React.useContext(SocketContext)

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Solicitud de Productos </Title>
        </div>
        <ModalSolicitudProductos {...props} socket = {socket} />
    </Modal>

}