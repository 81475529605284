

import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, Upload } from 'antd';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';

const axios = require('axios').default;
const { Option } = Select;
const { Title } = Typography;


/**
 *
 *
 * @class FormUsuarios
 * @extends {React.Component}
 * @description Formulario de usuarios
 */
class FormUsuarios extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };

    static contextType = Logged;
    formModaluser = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loadingImage: false,
            form: {},

            id: null,
            image: null,
            hasPermision: true,

        }
    }


    /**:4
     * @methodOf ModalUsuarios
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.id !== undefined) {
            this.getUsuario(this.props.id)
        }
    }


    /**
     *
     * @methodOf ModalUsuarios
     *
     * @method getUsuario
     * @description Obtiene el usuario
     * @param id ObjectId
     *
     * */
    getUsuario = (id) => {

        this.setState({loading: true})
        axios.get("/usuarios/get", { 
            params: { id } 
        }).then(async ({ data }) => {
            const avatar = data.data.avatar;
            if (data.data.avatar !== undefined && data.data.avatar !== null && data.data.avatar !== "") {
                this.setState({
                    image: {
                        url: axios.defaults.baseURL + '/upload/' + avatar,
                        name: avatar
                    }
                })
                data.data.avatar = [{
                    uid: -1,
                    name: data.data.avatar,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + data.data.avatar,
                    response: { filename: data.data.avatar }
                }];

            } else data.data.avatar = []

            if (data.data.tipo != 4 && data.data.roles) {
                let permisos = [];
                
                for (var [key, value] of Object.entries(data.data.roles)) {
                    if(value === true){
                        permisos.push(key)
                    }
                }


                data.data.permisos = permisos;
            }
            this.setState({
                form: data.data,
                hasPermision: (data.data.tipo != 4),
                hide: data.data.tipo === 1 || data.data.tipo === 4 ? false : true
            })
            await this.formModaluser.current.resetFields();
        })
        .catch(res => {
            message.error('No se encontro el usuario');
            console.log("Usuario no obtenido", res);
        }).finally(()=>this.setState({loading: false}))
    }


    /**
     * @methodOf  Usuarios
     * @method saveUsuario
     *
     * @description Guardao actualiza el usuario, segun si elID está definido.
     *
     * */
    saveUsuario = values => {

        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            if (this.state.image)
                values.avatar = this.state.image.name;
            else
                values.avatar = null;
            if (values._id) {
    
                axios.put('/usuarios/update', { ...values, id: values._id, cliente_id: this.props.cliente_id })
                    .then(() => {
                        message.success("¡Se ha guardado correctamente el usuario!")
                        this.props.accept();
                    })
                    .catch((e) => {
                        message.error('No se pudo actualizar el usuario');
                        console.log('e', e)
                    })
                    .finally(() => this.setState({
                        loading: false,
                        image: undefined
                    }))
            } else {
                axios.post('/usuarios/add', { ...values, id: values._id, cliente_id: this.props.cliente_id })
                    .then(() => {
                        message.success("¡Se ha guardado correctamente el usuario!")
                        this.props.accept();
                    }).catch((e) => {
                        message.error('No se pudo crear el usuario')
                        console.log('e', e)
                    })
                    .finally(() => this.setState({
                        loading: false,
                        image: undefined
                    }))
            }
        })
    }

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param image (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param e (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })
        if (file.status === "done") {
            if (this.state.image)
                this.removeFile(this.state.image.name)

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })

            if (this.state.form._id)
                axios.put('/usuarios/update', { avatar: e.file.response.filename, id: this.state.form._id })
                    .finally(() => {
                        this.props.update()
                    });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name)


        return e && e.fileList;
    };


    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        this.state.form.avatar = [];
        this.state.image = undefined;
        this.formModaluser.current.resetFields();
        if (this.state.form._id)
            axios.put('/usuarios/update', { avatar: 0, id: this.state.form._id })
                .finally(() => {
                    this.props.update();
                    this.setState({ loadingImage: false })
                })
        else {
            this.props.update();
            this.setState({ loadingImage: false })
        }
    };


    /**
     *
     * @memberof ModalUsuarios
     *
     * @method onChangeTipoUsuario
     * @description Despliega o elimina los permisos dependiendo del tipo de usuario
     */
    onChangeTipoUsuario = (value) => {
        if(value === 1 || value === 4){
            this.setState({hide: false})
        }else
            this.setState({hide: true})
    }

    render() {
        const { normFile, formModaluser, saveUsuario, deleteImage } = this;
        const { form, image, loading, loadingImage } = this.state;
        const option = [
            {
                name: "Finanzas",
                value: "finanzas"
            },
            {
                name: "Facturas",
                value: "facturas"
            },{
                name: "Clientes",
                value: "clientes"
            },
            {
                name: "Usuarios",
                value: "usuarios"
            },
            {
                name: "Proveedores",
                value: "proveedores"
            },
            {
                name: "Contabilidad",
                value: "contabilidad"
            },
            {
                name: "Cuentas",
                value: "cuentas"
            },
            {
                name: "Areas",
                value: "areas"
            },
            {
                name: "Productos",
                value: "productos"
            },
            {
                name: "Inventarios",
                value: "inventarios"
            },
            {
                name: "Clasificadores",
                value: "clasificadores"
            },
            {
                name: "Razones Sociales",
                value: "razones_sociales"
            },
            {
                name: "Project Manager",
                value: "project_manager"
            },
            {
                name: "Orden Productos Cotizacion",
                value: "orden_productos_cotizacion"
            },
            {
                name: "Matriz Costos",
                value: "orden_matriz_costos"
            },
            {
                name: "Orden Ingresos",
                value: "orden_ingresos"
            },
            {
                name: "Orden Egresos",
                value: "orden_egresos"
            },
            {
                name: "Orden Facturas",
                value: "orden_facturas"
            },
            {
                name: "Orden Compras",
                value: "orden_compras"
            },
            {
                name: "Orden Logistica",
                value: "orden_logisticas"
            },
            {
                name: "VMI",
                value: "vmi"
            },
            {
                name: "VMI Ingresos",
                value: "vmi_ingresos"
            },
            {
                name: "VMI Ventas",
                value: "vmi_ventas"
            },
            {
                name: "VMI Solicitudes",
                value: "vmi_solicitudes"
            },
            {
                name: "KPIS",
                value: "KPIS"
            },

        ];
        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.id ? "Editar Usuario" : "Nuevo Usuario"}</Title>
                <Form layout="vertical" ref={formModaluser} name="formulario-transacciones" onFinish={saveUsuario} initialValues={form} >
                    <Form.Item name="_id" noStyle >
                        <Input type="hidden" />
                    </Form.Item>
                    <Row justify="center">
                        <Form.Item name="avatar" getValueFromEvent={normFile} valuePropName="fileList"
                            help={image ? <Button className="btn-upload-delete" shape="circle" danger icon={<DeleteOutlined />} onClick={deleteImage} /> : null}
                        >
                            <Upload listType="picture-card" className="avatar-uploader" showUploadList={false} action={axios.defaults.baseURL + "/upload/add"} accept="image/*" >

                                {(loadingImage) ? <div>
                                    {(this.state.loading || loadingImage) ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div>Subir </div>
                                </div> : ((image) ?
                                    <img src={image.url} alt="avatar" style={{ width: '100%' }} />
                                    :
                                    <div>
                                        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div>Subir </div>
                                    </div>)}
                            </Upload>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item label="Nombre" name="nombre" rules={[{ required: true, message: "Por favor, ingrese el nombre" }]} >
                                <Input placeholder="Nombre"></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item label="Apellido" name="apellido" rules={[{ required: true, message: "Por favor, ingrese apellido" }]} >
                                <Input placeholder="Apellido"></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item label="Posición" name="posicion" rules={[{ required: true, message: "Por favor, ingrese posición" }]}>
                                <Input placeholder="Posición"></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item label="Correo Electrónico" name="email" 
                                rules={[
                                    { required: true, message: "Por favor, ingrese correo electrónico" },
                                    { type: 'email', message: 'Correo incorrecto' }
                                ]} >
                                <Input placeholder="Correo Electrónico"></Input>
                            </Form.Item>
                        </Col>

                        <Col xs={24} >
                            <Form.Item label="Tipo de Usuario" name="tipo" rules={[{ required: true, message: "Por favor, ingrese el tipo de Usuario" }]} >
                                <Select onSelect={this.onChangeTipoUsuario}>
                                    <Option key="1" value={1}>Dueño</Option>
                                    <Option key="2" value={2}>Administrador</Option>
                                    <Option key="3" value={3}>Usuario</Option>
                                    <Option key="4" value={4}>Cliente</Option>
                                </Select>
                            </Form.Item>
                        </Col>


                    </Row>

                    <Row>
                        {this.state.hide ? <Col xs={24}>
                            <Form.Item label="Permisos" name="permisos"  >
                                <Select mode="multiple" placeholder="Permisos">
                                    {option.map((option, index) => (
                                        <Option key={index} value={option.value}>
                                            {option.name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>: null }
                    </Row>

                    <Row justify="center" align="middle" >
                        <Col span={24} className="flex-column">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={loading || loadingImage}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuarios
 *@description 
 */
export default function (props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.hideModal}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormUsuarios {...props} />
        </Modal>
    )
}