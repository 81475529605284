import React, { Component } from 'react';
import { Button, Divider, Spin, Table, PageHeader, Layout, message, Row, Col, Card, Typography, Space, Tag, Tabs } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";

//Componentes
import { IconArrowBack } from '../../Widgets/Iconos';
// import ModalTransaccionesDetalle from './ModalTransaccionesDetalle';
import Facturas from './DetalleClienteSecciones/Facturas';

import Transacciones from './DetalleClienteSecciones/Transacciones';
import { StarFilled } from '@ant-design/icons'

// this.state.cliente_id

const { Content } = Layout;
const { Text, Title } = Typography;

const { TabPane } = Tabs;

const moment = require('moment');
moment.locale('es');

/**
 * @export
 * @class DetalleClientes
 * @extends {Component}
 * @description Vista de Detalle Clientes
 */
export default class DetalleClientes extends Component {

    volver = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,

            cliente: [],
            cliente_id: undefined,
        }
    }

    componentDidMount() {
        var ruta = window.location.pathname;
        var cliente_id = ruta.split("/")[((ruta.split("/").length) - 1)]

        if (cliente_id !== "rubros") {
            this.state.cliente_id = cliente_id;
            this.setState({
                cliente_id: cliente_id
            })
        }


        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCliente();
    }



    /**
     * @memberof DetalleClientes
     * @method getCliente
     * @description Obtiene un cliente de la DB
     */
    getCliente = () => {

        this.setState({ loading: true })

        axios.get('/clientes/get', {
            params: {
                id: this.state.cliente_id
            }
        })
            .then(response => {
                this.setState({ cliente: response.data.data, })
            })
            .catch(error => {
                message.error('Error al traer cliente')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }


    render() {

        console.log('v', this.props.match.params)

        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={(this.state.cliente != []) ?
                            (
                                <div>
                                    <Button ghost onClick={this.volver} style={{ border: "none" }}>
                                        <IconArrowBack />
                                    </Button>

                                    <Text level={4} className="text-white ant-page-header-heading-title ml-1" > Información de {this.state.cliente.razon_social} {this.state.cliente.apellido} </Text>
                                </div>
                            )
                            : "Cliente"}
                    />

                    <Content className="admin-content content-bg pd-1">

                        <Row>
                            <Col span={24}>
                                <Card bordered >
                                    <Row>
                                        <Title level={4}>Información del Cliente {this.state.cliente?.premium ? <Button size="small" type="dashed" style={{ marginLeft: "4px" }}>
                                            <Link to={`/admin/manager-inventarios/vendedor/${this.state.cliente?._id}`}>Ir a VMI</Link>
                                        </Button> : null} </Title>
                                    </Row>
                                    <Row>
                                        {(this.state.cliente?.nombre || this.state.cliente?.apellido) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Nombre</label>
                                                <p className="pInfo"><strong>
                                                    {this.state.cliente?.nombre} {this.state.cliente?.apellido}

                                                    {this.state.cliente?.premium ?
                                                        <Tag className="ml-1" color="gold" icon={<StarFilled />}>Premium</Tag>
                                                        : null}
                                                </strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.email) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Correo Electrónico</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.email}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.razon_social) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Razón Social</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.razon_social}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.telefono) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Número Telefónico</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.telefono}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.rfc) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Registro Federal de Contribuyentes</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.rfc}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.departamento) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Registro Federal de Contribuyentes</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.departamento}</strong> </p>
                                            </Col>
                                            : null}

                                        {(this.state.cliente?.direccion) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Dirección</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.direccion} {this.state.cliente?.codigo_postal}</strong> </p>
                                            </Col>
                                            : null}
                                        {(this.state.cliente?.regimen_fiscal) ?
                                            <Col xs={24} lg={12} xl={6}>
                                                <label className="lblTitulo">Régimen Fiscal</label>
                                                <p className="pInfo"><strong>{this.state.cliente?.regimen_fiscal}</strong> </p>
                                            </Col>
                                            : null}


                                    </Row>
                                    <Row>
                                        <Divider />
                                        <Title level={4}>Información de Pagos</Title>
                                    </Row>
                                    <Row>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Total Vendido</label>
                                            <p className="pInfo"><strong>$ {this.state.cliente?.total_vendido ? this.state.cliente?.total_vendido.toMoney(true) : (0).toMoney(true)}</strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Total Pagado</label>
                                            <p className="pInfo"><strong>$ {this.state.cliente?.total_pagado ? this.state.cliente?.total_pagado.toMoney(true) : (0).toMoney(true)}</strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Saldo por Pagar</label>
                                            <p className="pInfo"><strong>$ {this.state.cliente?.saldo_pendiente ? this.state.cliente?.saldo_pendiente.toMoney(true) : (0).toMoney(true)}</strong> </p>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6}>
                                            <label className="lblTitulo">Total Facturado</label>
                                            <p className="pInfo"><strong>$ {this.state.cliente?.total_facturado ? this.state.cliente?.total_facturado.toMoney(true) : (0).toMoney(true)}</strong> </p>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row className='mt-1'>
                            {console.log('this.props.match.params.id', this.props.match.params.id)}
                            <Tabs type="card" style={{ width: '100%' }}>
                                <TabPane tab="Transacciones" key="transacciones" >
                                    <Transacciones
                                        cliente_id={this.props.match.params.id}
                                    />
                                </TabPane>
                                <TabPane tab="Facturas" key="facturas">
                                    <Facturas
                                        cliente_id={this.props.match.params.id}
                                        cliente={this.state?.cliente}
                                    />
                                </TabPane>
                            </Tabs>

                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}