import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Clasificadores from '../components/Admin/Clasificadores/Clasificadores'

/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCuentas(props) {
    return (
        <Switch>
            <Route exact path="/admin/clasificadores" render={() => <Clasificadores {...props} />} />
        </Switch>
    )
}

export default RouterCuentas;
