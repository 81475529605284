import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Cotizador from '../../components/Customer/Cotizador/Cotizador'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCotizador(props) {

    return (
        <Switch>
            <Route exact path="/customer/cotizador" render={() => <Cotizador {...props} />} />
        </Switch>
    )
}

export default RouterCotizador;
