import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Statistic, Spin, } from 'antd';
//componentes

const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalTransaccionesDetalle
 * @extends {Component}
 */
class ModalFacturasDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            factura: {},
            transaccion: null,
            razon_social: null,
            proveedor: null
        }

    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getFactura()
    }


    /**
    * @memberof ModalTran
    * @method getFactura
    * @description Obtiene la informacion de la transacciones para el modal de detalle. Actualiza el state
    * con informacion obtenida.
    */
    getFactura = () => {
        this.setState({ loading: true })
        console.log(this.props.factura_id)
        axios.get('/customer/facturas/get', {
            params: { id: this.props.factura_id },
        })
            .then((factura) => {
                const data = factura.data.data;
                this.setState({factura: data})
            })
            .catch(error => {
                console.log('error', error.response)
            })
            .finally(() => {
                this.setState({ loading: false })
            })


    }

    render() {
        const { factura } = this.state;

        return (
            <Form layout="vertical"   >
                <Title level={3} className="text-center">Detalle Transacción </Title>
                <Spin spinning={this.state.loading}>
                    <Row justify="center" style={{ marginTop: "1rem" }} gutter={[16,16]}>
                        <Col xs={24} lg={12}>
                            <Form.Item label="Concepto" >
                                <Text type="secondary" >{factura.concepto}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                            <Form.Item label="Fecha" >
                                <Text type="secondary" >{moment(factura.fecha).format('LL')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label="UUID" >
                                <Text type="secondary" >{factura.uuid}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                            <Form.Item label="Proveedor" >
                                <Text type="secondary" >{factura.proveedor_id?.nombre_comercial ?  factura.proveedor_id?.nombre_comercial : 'N/A'}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 12 }}>
                            <Form.Item label="Razon Social" >
                                <Text type="secondary" >{factura.razon_social_id?.razon_social ? factura.razon_social_id?.razon_social : 'N/A'}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8 }}>
                            <Form.Item label="Monto en Pesos" >
                                <Statistic
                                    value={factura.monto}
                                    valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF00' }}
                                    prefix={'$'}
                                    suffix={'MXN'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8 }}>
                            <Form.Item label="Tipo de Cambio" >
                                <Statistic
                                    value={factura.tipo_cambio}
                                    valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF00' }}
                                    prefix={'$'}
                                    suffix={'MXN'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8 }}>
                            <Form.Item label="Monto en Dolares" >
                                <Statistic
                                    value={factura.monto_dolar}
                                    valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF00' }}
                                    prefix={'$'}
                                    suffix={'USD'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <ModalFacturasDetalle {...props} />
    </Modal>

}