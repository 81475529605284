import React, { Component } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Upload, Modal, Row, Spin, Typography } from 'antd';
import { EditableProTable } from '@ant-design/pro-table';

const axios = require('axios').default;
const { Title, Text } = Typography;

class ModalProofDelivery extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recepcion_entrega: {}
        }
    }

    formModalProofDelivery = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
        this.getRecepcionEntrega()
    }

    /**
     *
     *
     * @memberof ModalProofDelivery
     * @method getRecepcionEntrega
     * @description Obtiene los datos de una recepcion/entrega
     */
    getRecepcionEntrega = () => {

        this.setState({loading: true})

        axios.get('/ordenesCompra/recepcion', {
            params: {recepcion_entrega_id: this.props.recepcion_entrega_id}
        })
            .then(({data}) => {
                const recepcion_entrega = data.data

                if(recepcion_entrega.archivos){
                    const archivos = recepcion_entrega.archivos?.map((archivo, index) => ({
                        uid: index,
                        name: archivo,
                        status: 'done',
                        url: `${axios.defaults.baseURL}/voucher/${archivo}`,
                      }))
                    
                    this.formModalProofDelivery.current?.setFieldsValue({
                        archivos
                    })
                }
                
                this.setState({
                    recepcion_entrega,
                    loading: false
                })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al obtener la recepción')
            })
    }

    /**
     *
     * @memberof ModalProofDelivery
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param filename (string)
     * Recibe el nombre del archivo.
     */
    removeFile = (filename) => {
        axios.post("/voucher/delete", {
            filename
        })
            .then(res => {
                console.log("Documento removido con exito", res);
            })
            .catch(res => {
                console.log("Documento no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof ModalProofDelivery
     *
     * @method normFile
     * @description Declaramos las imagenes
     *
     */
    normFile = (e) => {
        const { file, fileList } = e;

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status == "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/voucher/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status == "removed")
            this.removeFile((file.response?.filename !== undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };

    /**
     *
     *
     * @param {*} values Objecto con los datos del formulario
     * @memberof ModalProofDelivery
     * @method onFinish
     * @description Manejar los datos del formulario y actualizar datos
     */
    onFinish = (values) => {

        const v = {...values}

        if(Array.isArray(v.archivos) && v.archivos?.length > 0) {
            v.archivos = v.archivos?.map(archivo =>  archivo.name) 
        }else{
            v.archivos = []
        }

        const payload = {
            id: this.props.recepcion_entrega_id,
            ...v
        }

        axios.post('/ordenesCompras/recepciones/files', payload)
            .then(success => {
                message.success(`${this.props.recepcion ? "Recepción" : "Entrega"} Creada`)
                this.props.onSuccess()
            })
            .catch(error => {
                message.error(`Error al crear ${this.props.recepcion ? "Recepción" : "Entrega"}`)
                console.log(error)
            })
    } 

    render() {
        const { loading } = this.state;

        return (
            <Form
                onFinish={this.onFinish}
                ref={this.formModalProofDelivery}
                layout="vertical"
            >
                <Spin spinning={loading}>
                    <Row justify="center">
                        <Col xs={24}>
                            <Title level={3} className="text-center">Editar Comprobantes</Title>
                        </Col>
                        
                        <Col xs={16}>
                            <Form.Item
                                label="Archivos"
                                name="archivos"
                                getValueFromEvent={this.normFile}
                                
                                valuePropName="fileList"
                            >
                                <Upload
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    multiple={true}
                                    className="upload-large"
                                >
                                    <Button>
                                        <UploadOutlined /> Subir Archivo
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={24}></Col>
                        <Col xs={4}>
                            <Form.Item>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}


/**
 * @description Según el modal
 */
 export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <ModalProofDelivery {...props} />
        </Modal>
    )
}
