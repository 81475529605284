import React, { useEffect } from 'react'
import { Route, Switch } from "react-router-dom";
import { Layout } from 'antd';


// componentes
import Landing from "../components/Public/LandingPage";
import ThemeEdit from '../components/ThemeEdit/index';
import Login from '../components/Auth/Login';
import Register from '../components/Auth/Register';
import UpdatePassword from '../components/Auth/UpdatePassword';
import Marketplace from '../components/Public/Cotizador/Marketplace';
import CatalogoViewer from '../components/Public/CatalogoViewer/CatalogoViewer';
import Support from '../components/Public/Support';

import { PublicNavbar } from '../components/Header/Header'
import Productos from '../components/Public/Productos';
import Terminos from '../components/Public/Terminos';
import Cookies from 'universal-cookie';
import { SetCarrito } from '../Hooks/Carrito';

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function PublicRoutes({ setUser }) {

    const [proveedor_id, setProveedorId] = React.useState(null)
    const setCarrito = React.useContext(SetCarrito)

    useEffect(() => {
        const cookies = new Cookies()
        let carrito = cookies.get('carrito')
        if(carrito){
            setCarrito(carrito)
        }
    }, [])

    return (
        <Layout>
            <PublicNavbar proveedor={proveedor_id} />
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/productos" component={Productos} />
                <Route exact path="/terminos" component={Terminos} />
                <Route exact path="/marketplace" component={Marketplace} />
                <Route exact path="/login" type={2} component={Login} />
                <Route exact path="/register" type={1} component={Register} />

                <Route path="/register/email-:email/token-:token" render={props => <UpdatePassword {...props} />} />
                <Route path="/recovery/email-:email/token-:token" component={UpdatePassword} />


                <Route path="/catalogo/:proveedor_url/:catalogo_url" render={props => <CatalogoViewer setProveedorId={setProveedorId} {...props} />} />
                
                <Route path="/theme" component={ThemeEdit} />
                <Route path="/soporte" component={Support} />
            </Switch>
        </Layout>

    )
}

export default PublicRoutes;
