import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, DatePicker,Upload, Input, InputNumber, Select, message, Spin, Cascader } from 'antd';
import {UploadOutlined} from "@ant-design/icons";
import CustomAvatar from "../../../Widgets/Avatar/Avatar";


const { Title, Text } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalTransaccionesIngreso
 * @extends {Component}
 */
class ModalTransaccionesIngreso extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            areas: [],
            rubros: [],
            cuentas: [],
            clasificadores: [],
            razones: [],
            tipo_cambio: 0,
            cuentaSeleccionada: '',
            disabled: false

        }
    }

    ModalTransaccionesIngreso = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getCuentas();
        this.getClasificadores();
        this.getRazones();

        if (this.props.transaccion_id !== undefined || this.props.transaccion_id != null) {
            this.getTransaccion();
        } else {
            this.getAreas();
            this.getTipoCambio();
            this.ModalTransaccionesIngreso.current?.setFieldsValue({
                tipo: 1
            })
        }
    }




    /**
     * @memberof ModalTransaccionesIngreso
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    onFinish = (values) => {

        const orden = this.props.orden;

        values.orden_id = orden._id;
        values.cliente_id = orden.cliente_id._id;

        values.clasificadores = Array.isArray(values.clasificadores) ? values.clasificadores.map(clasificador => {
            let obj = JSON.parse(clasificador)

            return obj._id
        }) : []

        if (Array.isArray(values.area_rubro) && values.area_rubro.length === 2) {
            values.area_id = values.area_rubro[0]
            values.rubro_id = values.area_rubro[1]
        }

        // if (Array.isArray(values.cliente_proyecto) && values.cliente_proyecto.length === 2) {
        //     values.cliente_id = values.cliente_proyecto[0]
        //     values.proyecto_id = values.cliente_proyecto[1]
        // }

        if (this.props.transaccion_id) {
            this.updateTransaccion(values)
        } else {
            this.addTransaccion(values)
        }
    }

    /**
     * @memberof ModalTransaccionesIngreso
     * @method getTransaccion
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    getTransaccion = () => {
        this.setState({ loading: true })

        axios.post('/transacciones/get', {
            id: this.props.transaccion_id,
        }).then(async response => {
            let transaccion = response.data.data
            await this.getAreas()
            let areas = this.state.areas

            if (transaccion.area_id?._id)

                await axios.get('/rubros/list', {
                    params: {
                        paginate: false,
                        area_id: transaccion.area_id?._id
                    }
                }).then(response => {

                    let index = areas.findIndex(area => area.value.toString() === transaccion.area_id?._id.toString())

                    if (index !== -1 && response.data.data.length > 0) {
                        areas[index].children = response.data.data.map(rubro => ({ label: rubro.nombre, value: rubro._id }))
                        this.setState({ areas })
                    } else {
                        message.error('Rubros no encontrados')
                    }
                })

            let comprobantes = []
            if(transaccion.comprobantes?.length > 0){
                comprobantes = transaccion.comprobantes.map((comp,index) => ({
                    uid: index,
                    name: comp,
                    status: 'done',
                    url: `${axios.defaults.baseURL}/voucher/${comp}`
                }))
            }


            this.ModalTransaccionesIngreso.current?.setFieldsValue({
                concepto: transaccion.concepto,
                fecha: moment(transaccion.fecha),
                descripcion: transaccion.descripcion,
                clasificadores: transaccion.clasificadores?.map(clasificador => `{"_id":"${clasificador._id}", "color":"${clasificador.color}"}`),
                cuenta_id: transaccion.cuenta_id?._id,
                tipo: transaccion.tipo,
                razon_social_id: transaccion?.razon_social_id?._id,
                tipo_cambio: transaccion.tipo_cambio,
                monto_pesos: transaccion.monto_pesos,
                monto_dolar: transaccion.monto_dolar,
                // area_rubro: (transaccion.area_id?._id) ? [transaccion.area_id?._id, transaccion.rubro_id?._id] : undefined,
                cuenta_destino_id: transaccion.cuenta_destino_id?._id,
                comprobantes: comprobantes
            })


            if (transaccion.tipo === 3) {
                this.setState({
                    cuentaSeleccionada: transaccion.cuenta_id._id,
                    tipoTransaccion: transaccion.tipo,
                })
            }


        }).catch(error => {
            console.log(error)
            message.error('Error al traer la transaccion')
        }).finally(() => {

            this.setState({ loading: false, disabled: true })
        })
    }

    /**
     * @memberof ModalTransaccionesIngreso
     * @method getClasificadores
     * @description Trae las cuentas 
     * 
     */
    getClasificadores = (search) => {
        axios.get('/clasificadores/list', {
            params: {
                page: 1,
                limit: 100,
                search
            }
        }).then(response => {
            this.setState({
                clasificadores: response.data.data.itemsList,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }


    /**
     * @memberof ModalTransaccionesIngreso
     * @method clasificadorRender
     * @description renderiza los clasificadores del select con su respectivo color
     * 
     */
    clasificadorRender = (props, other) => {

        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = event => {
            event.preventDefault();
            event.stopPropagation();
        };

        let obj = JSON.parse(value)

        return (
            <clasificador
                color={obj.color}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{ marginRight: 3 }}
            >
                {label}
            </clasificador>
        );

    }

    /**
    * @memberof ModalTransaccionesIngreso
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    getCuentas = (page = 1) => {
        axios.get('/cuentas/list', {
            params: {
                page: page,
                limit: 100
            }
        }).then(response => {
            this.setState({
                cuentas: response.data.data.itemsList,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        })
    }



    /**
    * @memberof ModalTransaccionesIngreso
    * @method getTipoCambio
    * @description Trae el ulitmo tipo de cambio utilizado 
    * 
    */
    getTipoCambio = () => {
        axios.get('/tipo-cambio/get').then(response => {
            this.ModalTransaccionesIngreso.current?.setFieldsValue({
                tipo_cambio: response.data.data[0].valor.toMoney()
            })

        }).catch(error => {
            console.log(error);
            message.error('Error al traer tipo de cambio')
        })
    }

    /**
    * @memberof ModalTransaccionesIngreso
    * @method getAreas
    * @description Trae las areasm las pone en el formato adecuado para el cascader
    * 
    */
    getAreas = (search) => {
        return axios.get('/areas/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {
            this.setState({
                areas: response.data.data.map(area => ({
                    value: area._id, label: area.nombre, isLeaf: false
                }))
            })
        }).catch(error => {
            console.log(error)
        })
    }

    /**
     * @memberof ModalTransaccionesIngreso
     * @method loadRubros
     * @description Trae los rubros asiganos a una area y los formatea para el cascader
     * 
     */
    loadRubros = selectedOptions => {
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;

        let areas = this.state.areas

        axios.get('/rubros/list', {
            params: {
                paginate: false,
                area_id: targetOption.value
            }
        }).then(response => {
            targetOption.loading = false;
            let index = areas.findIndex(area => area.value.toString() === targetOption.value.toString())

            if (index !== -1 && response.data.data.length > 0) {
                areas[index].children = response.data.data.map(rubro => ({ label: rubro.nombre, value: rubro._id }))
                this.setState({ areas: [...areas]  })
            } else {
                message.error('Rubros no encontrados')
            }


        }).catch(error => {
            console.log(error)
            message.error('Error al cargar los rubros')
        })

    };


    /**
    * @memberof ModalTransaccionesIngreso
    * @method getRazones
    * @description Trae los negocios
    * 
    */
    getRazones = (search) => {
        axios.get('/razones-sociales', {
            params: {
                search,
                limit: 20
            }
        }).then(response => {
            this.setState({ razones: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Negocios')
        })
    }
    

      /**
     *
     * @memberof ModalTransaccion
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
       normFile = (e) => {
        const { file, fileList } = e;


        console.log('file', file);
        console.log('fileList', fileList);

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status == "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status == "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };


    /**
     * @memberof ModalTransaccionesIngreso
     * @method addTransaccion
     * @description Trae las cuentas 
     */
    addTransaccion = (values) => {
        console.log('values', values)
        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/transacciones/add', {
                ...values,
                monto_pesos: values.monto_pesos.toMoney(),
                monto_dolar: values.monto_dolar.toMoney(),
                tipo_cambio: values.tipo_cambio.toMoney(),
            }).then(response => {
                message.success('Transacción creada')
                this.props.onClose()
            }).catch(error => {
                console.log(error)
                message.error('Error al traer la información')
            }).finally(() => this.setState({ loading: false }))
        })
        
    }

    /**
    * @memberof ModalTransaccionesIngreso
    * @method updateTransaccion
    * @description Trae las cuentas
    */
    updateTransaccion = (values) => {
        this.setState({ loading: true })
        axios.post('/transacciones/update', {
            ...values,
            monto_pesos: values.monto_pesos.toMoney(),
            monto_dolar: values.monto_dolar.toMoney(),
            tipo_cambio: values.tipo_cambio.toMoney(),
            id: this.props.transaccion_id
        }).then(response => {
            message.success('Transacción Actualizada')
            this.props.onClose()
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @memberof ModalTransaccionesIngreso
     * @method onChangePesos
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares 
     * 
     */
    onChangePesos = (value) => {
        let tipo_cambio = this.ModalTransaccionesIngreso.current?.getFieldValue('tipo_cambio')
        this.ModalTransaccionesIngreso.current?.setFieldsValue({
            monto_dolar: parseFloat(value / tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalTransaccionesIngreso
     * @method onChangeDolar
     * @description Hace la conversion de dolares a pesos para actualizar el campo de pesos 
     * 
     */
    onChangeDolar = (value) => {
        let tipo_cambio = this.ModalTransaccionesIngreso.current?.getFieldValue('tipo_cambio')
        this.ModalTransaccionesIngreso.current?.setFieldsValue({
            monto_pesos: parseFloat(value * tipo_cambio).toMoney()
        })
    }

    /**
     * @memberof ModalTransaccionesIngreso
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        let monto_pesos = this.ModalTransaccionesIngreso.current?.getFieldValue('monto_pesos')
        this.ModalTransaccionesIngreso.current?.setFieldsValue({
            monto_dolar: parseFloat(monto_pesos / value).toMoney()
        })
    }

    //? Hay otras cosas que deba quitar?
    render() {
        const { disabled } = this.state;
        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalTransaccionesIngreso}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio
                }}

            >
                <Spin spinning={this.state.loading}>

                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Concepto"
                                name="concepto"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el concepto"
                                }]}
                            >
                                <Input placeholder="Concepto"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Fecha"
                                name="fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la fecha"
                                }]}
                            >
                                <DatePicker className="width-100" disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Descripción de la Transacción"
                                name="descripcion"
                            >
                                <Input.TextArea placeholder="Descripción"></Input.TextArea>
                            </Form.Item>
                        </Col>
                       
                        <Col xs={24}>
                            <Form.Item
                                label="Área / Rubro"
                                name="area_rubro"
                            >
                                <Cascader
                                    placeholder="Seleccione el Area y Rubro"
                                    options={this.state.areas}
                                    loadData={this.loadRubros}
                                    changeOnSelect
                                    disabled={disabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Clasificadores"
                                name="clasificadores"
                            >
                                <Select
                                    mode="multiple"
                                    showArrow
                                    className="witdh-100"
                                    clasificadorRender={this.clasificadorRender}
                                    disabled={disabled}
                                >
                                    {this.state.clasificadores.map(clasificador => {
                                        return <Option value={`{"_id":"${clasificador._id}", "color":"${clasificador.color}"}`} po={45}>{clasificador.nombre}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Cuenta"
                                name="cuenta_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <Select
                                    onChange={value => {
                                        this.setState({ cuentaSeleccionada: value })
                                        let cuenta_detino = this.ModalTransaccionesIngreso.current?.getFieldValue('cuenta_destino_id')
                                        if (cuenta_detino === value)
                                            this.ModalTransaccionesIngreso.current?.setFieldsValue({
                                                cuenta_destino_id: null
                                            })
                                    }}
                                    placeholder="Seleccione cuenta"
                                >
                                    {this.state.cuentas.map(function ({ _id, nombre, logo, color }, index) {
                                        return <Option style={{ margin: '2px 0 2px 0' }} value={_id}>
                                            <CustomAvatar
                                                image={logo}
                                                name={nombre}
                                                color={color}
                                                size="small"
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                            />
                                            {nombre}
                                        </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Tipo"
                                name="tipo"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el tipo"
                                }]}
                            >
                                <Select
                                    onChange={value => {
                                        this.setState({ tipoTransaccion: value })
                                    }}
                                    disabled
                                    placeholder="Seleccione tipo">
                                    <Option value={1}>Ingreso</Option>
                                    <Option value={2}>Egreso</Option>
                                    <Option value={3}>Traspaso</Option>
                                    <Option value={4}>Nulo <Text type="secondary" size="small">(No afecta saldos)</Text></Option>
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>

                    {this.state.tipoTransaccion === 3 ? <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Cuenta"
                                name="cuenta_destino_id"

                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la cuenta"
                                }]}
                            >
                                <Select placeholder="Seleccione cuenta">
                                    {this.state.cuentas.map(({ _id, nombre, logo, color }, index) => {

                                        if (this.state.cuentaSeleccionada.toString() === _id.toString())
                                            return null
                                        else
                                            return <Option style={{ margin: '2px 0 2px 0' }} value={_id}>
                                                <CustomAvatar
                                                    image={logo}
                                                    name={nombre}
                                                    color={color}
                                                    size="small"
                                                    style={{
                                                        marginRight: '5px'
                                                    }}
                                                />
                                                {nombre}
                                            </Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row> : null}

                    <Row>
                        <Col xs={24} lg={6} >
                            <Form.Item
                                label="Tipo Cambio"
                                name="tipo_cambio"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={1} defaultValue={0} className="width-100" onChange={this.onChangeTipo} disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8, push: 1 }} >
                            <Form.Item
                                label="Monto (Pesos)"
                                name="monto_pesos"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={0.01} defaultValue={0}  decimalSeparator="," className="width-100 " onChange={this.onChangePesos} disabled={disabled} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8, push: 2 }} >
                            <Form.Item
                                label="Monto (Dolar)"
                                name="monto_dolar"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={0.01} defaultValue={0}  decimalSeparator="," className="width-100 " onChange={this.onChangeDolar} disabled={disabled} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="colDocsComprobantes">
                            <Form.Item
                                label="Comprobantes"
                                name="comprobantes"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/voucher/add"}
                                    multiple={true}
                                >
                                    <Button>
                                        <UploadOutlined /> Subir Archivo
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, transaccion_id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3} className="text-center">{transaccion_id != null ? "Editar " : "Nueva "} Transacción</Title>
        </div>
        <ModalTransaccionesIngreso {...props} />
    </Modal>

}