import React, { Component } from 'react';
import { Layout, Button, Col, Row, message, Spin, Typography, } from 'antd'
import axios from 'axios'

const moment = require('moment');
const { Text, Title } = Typography;
const { Content } = Layout;


/**
 * @export
 * @class Cotizaciones
 * @extends {Component}
 */
export default class Cotizaciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orden_id: this.props.match.params.orden_id,
            data: [],
            loading: false,
            cliente: {},
            orden: {},
        }
    }

    /**
     * @memberof Cotizaciones
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }



    render() {
        const { cliente, orden } = this.state
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24} className="mt-05">
                            <Title level={3}>Producción</Title>
                        </Col>
                        <Col span={24} className="center">
                            <Title level={2} className="text-gray">Tu orden se encuentra en Producción</Title>
                        </Col>
                        <Col span={24} className="center">
                            <img src="/images/produccion.svg" alt="En Produccion" style={{width: "100%"}}/>
                        </Col>
                    </Row>
                </Spin>
            </>

        )
    }
}