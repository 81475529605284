
import React from 'react'
import { Spin, Layout, Col, Row, PageHeader, Typography, Button, List, Card, message, Tag, } from "antd";
import { MoneyCollectOutlined, ArrowDownOutlined, ArrowUpOutlined, ArrowRightOutlined } from "@ant-design/icons";
import ReactECharts from "echarts-for-react";

import { Link } from "react-router-dom"
import '../../../Styles/Modules/Compras/Compras.css';



const axios = require('axios')

const { Content } = Layout;
const { Title, Text, } = Typography;


/**
 *
 *
 * @export
 * @class Compras
 * @extends {React.Component}
 */
export default class Compras extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            loading_cambio: false,
            tipo_cambio_actual: 0,
            historial: [],
            page: 1,
            modal_visible: false,


            ordenes_compras_realizadas: 0,
            ordenes_compras_sin_realizar: 0,
            ordenes_compras: [],
            ordenes_compras_cliente: [],

        }
    }



    /**
     *
     *
     * @memberof Dashboard
     * @description Carga los datos a la vista
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getDashboardApp();

    }

    /**
     *
     *
     * @memberof Compras
     * @description Obtenemos la información general de las ordenes de compra para el dashboard
     */
    getDashboardApp = () => {
            this.setState({ loading: true })
        axios.get('/ordenesCompra/dashboard')
            .then(({ data }) => {
                this.setState(data.data)
            })
            .catch(({ data }) => {
                message.error('Error al obtener la información')
            }).finally(() => this.setState({ loading: false }))

    }

    /**
     *
     *
     * @memberof Dashboard
     * @description Carga mas elementos al historial
     */
    onLoadMore = () => {
        let current = this.state.page;
        current = current + 1;
        this.getHistorialCambio(current);

    };


    /**
     *
     *
     * @memberof Dashboard
     * @description Abre o cierra el modal de historial
     */
    modalToogle = () => {
        let toogle = this.state.modal_visible;
        this.setState({ modal_visible: !toogle });
    }

    /**
     *
     *
     * @memberof Compras
     * @description Se renderiza el ICONO de la compra
     */
    renderIcon = (index, valor) => {
        console.log(valor, index)
        let next = index + 1;

        if (next < this.state.historial.length) {
            console.log('?', valor.toFixed(2) > this.state.historial[next].valor.toFixed(2))
            if (valor.toFixed(2) < this.state.historial[next].valor.toFixed(2)) {
                return <ArrowDownOutlined style={{ color: '#24CA49' }} />
            }
            else
                return <ArrowUpOutlined style={{ color: '#FD4438' }} />
        }
        return <MoneyCollectOutlined />

    }
    /**
     *
     *
     * @memberof AccountPurchases
     * @method renderEstatus
     * @description Obtiene las ordenes de compra de la orden correspondiente
     */
    renderEstatus = (estatus) => {
        let estatus_label = {
            0: <Tag color="#facc15">Creada</Tag>,
            1: <Tag color="#1a91ff">Aceptada</Tag>,
            2: <Tag color="#1d4ed8">Confirmada</Tag>,
            3: <Tag color="#facc15">Incompleta</Tag>,
            4: <Tag color="#bef264">Completa</Tag>,
            5: <Tag color="#eab308">En entrega</Tag>,
            6: <Tag color="#eab308">Entregada</Tag>,
            7: <Tag color="#22c55e">Completa</Tag>,
            8: <Tag color="#15803d">Enviada</Tag>
        }
        return (estatus !== null || estatus !== undefined) ? estatus_label[estatus] : 'N/A'
    }


    render() {



        return (
            <>
                <Spin spinning={this.state.loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 18, order: 1 }} xl={{ span: 12, order: 1 }} lg={{ span: 12, order: 1 }} md={{ span: 24, order: 1 }} sm={{ span: 24, order: 1 }} xs={{ span: 24, order: 1 }}  >
                                    <span className="ant-page-header-heading-title">
                                        Dashboard
                                    </span>
                                </Col>
                            </Row>
                        }
                    />
                    <Content className=" content-bg" style={{ padding: "16px 16px" }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={18}>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} md={12}>
                                        <Card className="card-saldo">
                                            <Card.Meta
                                                className="card-saldo-main-meta"
                                                title="Compras Realizadas"
                                                description={<><Text style={{ marginRight: '0.5rem' }}>$ {
                                                    this.state.ordenes_compras_realizadas.toMoney(true)
                                                }</Text><small>MXN</small></>} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} md={12}>
                                        <Card className="card-saldo">
                                            <Card.Meta
                                                className="card-saldo-main-meta"
                                                title="Compras por Realizar"
                                                description={<><Text style={{ marginRight: '0.5rem' }}>$ {this.state.ordenes_compras_sin_realizar.toMoney(true)}</Text><small>MXN</small></>} />
                                        </Card>
                                    </Col>
                                    <Col xs={24} md={24}>
                                        <Card className="card-saldo">
                                            <Card.Meta
                                                className="card-saldo-main-meta"
                                                title="Compras por Cliente" />

                                            <ReactECharts
                                                option={{
                                                    xAxis: {
                                                        type: 'category',
                                                        data: this.state.ordenes_compras_cliente?.map(({ cliente }) => cliente?.razon_social || cliente?.nombre || "Sin Nombre")
                                                    },
                                                    yAxis: {
                                                        type: 'value'
                                                    },
                                                    series: [
                                                        {
                                                            data: this.state.ordenes_compras_cliente?.map(({ cantidad }) => cantidad),
                                                            type: 'bar',
                                                            showBackground: true,
                                                            backgroundStyle: {
                                                                color: 'rgba(180, 180, 180, 0.2)'
                                                            }
                                                        }
                                                    ]
                                                }}
                                                notMerge={true}
                                                lazyUpdate={true}
                                                style={{ height: '535px' }} />
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24} md={6}>
                                <Card className="card-saldo list" >
                                    <div className="card-saldo-container" >
                                        <Card.Meta
                                            className="card-saldo-main-meta"
                                            title={<>
                                                Ordenes de Compra <Link to="/admin/compras/ordenes">
                                                    <Button size='small' type='primary' icon={<ArrowRightOutlined style={{ color: "white" }} />} style={{ float: 'right', position: 'relative', top: '6px', }} />
                                                </Link>
                                            </>}>

                                        </Card.Meta>
                                        <List
                                            itemLayout="horizontal"
                                            dataSource={this.state.ordenes_compras}
                                            loading={this.state.loading}
                                            renderItem={item => (
                                                <List.Item>
                                                    <Row className='width-100'>
                                                        <Col className="" span={14}>
                                                            <List.Item.Meta
                                                                title={item.folio}
                                                                description={item.monto_total ? `$ ${item?.monto_total.toMoney(true)} MXN` : "$ 0 MXN"}
                                                            />
                                                        </Col>
                                                        <Col className="center " span={10}>
                                                            {this.renderEstatus(item?.estatus)}
                                                        </Col>
                                                    </Row>
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Spin>
            </>
        )
    }
}