import React, { Component, } from "react";
import {
    AutoComplete,
    Input,
    message,
    Typography,
    Avatar,
    List
} from 'antd';


import PropTypes from "prop-types";


const axios = require('axios').default;
const { Text } = Typography;


/**
 *
 *
 * @export
 * @class FormProducto
 * @extends {Component}
 */
export default class FormProducto extends Component {

    /**
     *
     *
     * @static
     * @memberof FormProducto
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.state = {
            data: [],

            page: 1,
            limit: 10,
            
            total: 0,
            pages: 0,
            proveedor_id: this.props.proveedor_id,
            producto: this.props.producto_id
        }
    }



    componentDidMount() {
        
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        
        this.getProductos()
        if (this.state.producto !== undefined && this.state.producto !== null)
            this.onSelect(this.state.producto.sku, { producto: this.state.producto })
    }

    componentDidUpdate(prevProps){
        if(this.props.proveedor_id !== prevProps.proveedor_id){
            this.getProductos()
            console.log('Cambios de proveedor')
        }
    }

    /**
     *
     *
     * @param {*} [{
     * 
     *         page = this.state.page,
     *         limit = this.state.limit,
     *         search = this.state.search
     * 
     *     }=this.state]
     * @memberof FormProducto
     * 
     * @method getProductos
     * @description Obtenemos los productos
     * 
     */
    getProductos = ({
        page = this.state.page,
        limit = this.state.limit,
        search = this.state.search
    } = this.state) => {

        this.setState(state => {
            state.page = page;
            state.limit = limit;
            return state;
        })
            
        axios.get('/productos', {
            params: {
                proveedor_id: this.props.proveedor_id,
                page,
                limit,
                search
            }
        })
            .then(response => {

                console.log()

                this.setState(state => {
                    state.data = response.data.data.data
                    state.total = response.data.data.total
                    state.pages = response.data.data.pages
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
                console.log(error)
            })
    }

    /**
     *
     *
     * @param {*} [{
     * 
     *         page = this.state.page,
     *         limit = this.state.limit,
     *         search = this.state.search
     * 
     *     }=this.state]
     * @memberof FormProducto
     * 
     * @method onPopupScrollProductos
     * @description Al scrollear, obtenemos los productos
     * 
     */
    onPopupScrollProductos = event => {
        const { page, pages, } = this.state.productos
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        ) {
            console.log('true')
            this.getProductos({ page: page + 1 })
        }
    }
    sayHi(){this.getProductos()}

    /**
     *
     *
     * @param {*} value
     * @param {*} { producto }
     * @memberof FormProducto
     * @method onSelect
     * @description Declaramos un producto en el staty y cambiamos el valor
     */
    onSelect = (value, { producto }) => {

        this.setState({ producto }, () => this.props.onChange(value))

        if (typeof this.props.setPrecio === "function" )
            this.props.setPrecio(producto)
    }


    /**
     *
     *
     * @param {*} value
     * @param {*} { producto }
     * @memberof FormProducto
     * @method onChange
     * @description Si hay un cambio, declaramos el producto como nulo y cambiamos el valor 
     */
    onChange = (value) => this.setState({ producto: null }, () => this.props.onChange(value))

    render() {
        return (
            <AutoComplete
                options={this.state.data.map(producto => ({
                    key: producto.sku,
                    value: producto.sku,
                    producto,
                    label: <List.Item.Meta
                        avatar={<Avatar
                            shape="square"
                            size="large"
                            src={(Array.isArray(producto.imagenes) && producto.imagenes.length > 0) ? (axios.defaults.baseURL + '/upload/' + producto.imagenes[0].filename) : undefined}
                        />}
                        title={<>
                            <Text strong>{producto.marca}</Text>
                            &nbsp;
                            <Text>{producto.np_nexus}</Text><br />
                        </>}
                        description={<>
                            {producto.sku} <br />
                            {producto.descripcion}
                        </>}
                    />
                }))}
                onSearch={search => this.getProductos({ search })}
                allowClear={false}

                className="width-100"
                {...this.props}
                onSelect={this.onSelect}
                onChange={this.onChange}
            >
                <Input {...this.props.inputProps} placeholder="Producto" enterButton={false} allowClear={false} />
            </AutoComplete>
        )
    }
}
