import React, { Component } from 'react';
import { Col, Row, Spin, Typography, List, Button, message} from 'antd'
import axios from 'axios'

import { PaperClipOutlined, FlagFilled } from "@ant-design/icons"
import CustomAvatar from "../../../Widgets/Avatar/Avatar";
import moment from 'moment';

const { Title, Text } = Typography;


/**
 * @export
 * @class Cotizaciones
 * @extends {Component}
 */
export default class Cotizaciones extends Component {

    constructor(props) {
        super(props)
        this.state = {
            orden_id: this.props.match.params.orden_id,
            data: [],
            loading: false,
            cliente: {},
            orden: {},
            ordenes_compra: []
        }
    }

    /**
     * @memberof Cotizaciones
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenesCompra()
    }

    /**
     * 
     * @method getOrdenesCompra
     * @memberof OrdenEmbarque
     * @description Obtener las ordenes de compra de la orden
     */
     getOrdenesCompra = () => {
        axios.get('/ordenesCompra/cliente-list', {
            params: {
                orden_id: this.props.match?.params?.orden_id,
                embarque: true
            }
        })
            .then(({ data }) => {
                const ordenes_compra = data.data.itemsList

                this.setState({
                    ordenes_compra
                })
            })
            .catch(error => {
                message.error("Error al obtener Ordenes de Compras")
                console.log(error)
            })
    }

    render() {
        const { cliente } = this.state
        const { orden } = this.props
        const { agente_compras_id } = orden
        return (
            <>
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col span={24} className="mt-05">
                            <Title level={3}>Orden de Embarque</Title>
                        </Col>
                        <Col span={24} className="center">
                            <Title level={2} className="text-gray">Tu orden se encuentra en Transito</Title>
                        </Col>
                        <Col span={24}>
                            {
                                (Array.isArray(this.state.ordenes_compra) && this.state.ordenes_compra.length > 0) ? (
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={this.state.ordenes_compra}
                                        header={
                                            <Row justify="space-between">
                                                <Col span={18} md={24} lg={14} xxl={{ span: 18 }}><Title level={3}>Orden de Embarque </Title></Col>
                                                <Col span={5} md={20} lg={7} xxl={{ span: 5 }} >
                                                    <Button type="primary" disabled={this.state.orden?.logistica_estatus !== 2} onClick={this.finishOrden} size="large" className="btn-purple" block>Orden Completa</Button>
                                                </Col>
                                            </Row>
                                        }
                                        renderItem={({ _id, folio, descripcion, estatus, archivos = [], createdAt }) => (
                                            <List.Item className="order-list-item bg-white">
                                                <Row className="width-100" style={{ padding: "0.5rem 1rem 0.5rem 1rem" }} align="middle" justify="start" >
                                                    <Col span={24}  >
                                                        <Row className="width-100" align="middle" justify="start" >
                                                            <Col span={24}>
                                                                <Row className="width-100" align="middle" justify="start" >
                                                                    <Col md={{ span: 24, order: 1 }} >
                                                                        <Text ellipsis strong className="tag-card-pink"> {folio} </Text>
                                                                    </Col>
                                                                    <Col md={{ span: 8, order: 2 }} xl={{ span: 8, order: 2 }} xxl={{ span: 8, order: 2 }} >
                                                                        <Text ellipsis className="text-gray">{descripcion}</Text>
                                                                    </Col>
                                                                    <Col md={{ span: 16, order: 3 }} xl={{ span: 16, order: 3 }} xxl={{ span: 16, order: 3 }} >
                                                                        <Row>
                                                                            {
                                                                                archivos.map(archivo => (
                                                                                    <Col >
                                                                                        <a href={`${axios.defaults.baseURL}/upload/${archivo}`} download target="_blank">
                                                                                            <Button
                                                                                                type="link"
                                                                                                target="_blank"
                                                                                                icon={<PaperClipOutlined />}
                                                                                            >
                                                                                                {archivo}
                                                                                            </Button>
                                                                                        </a>
                                                                                    </Col>
                                                                                ))
                                                                            }
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col span={24} style={{ marginTop: "1rem" }}>
                                                        <Row align="middle" justify="start" >
                                                            <Col span={4} >
                                                                <Text ellipsis className="text-gray"><FlagFilled /> <span>{moment(createdAt).format('L')}</span></Text>
                                                            </Col>
                                                            {/* <Col span={4} >
                                                                <Text ellipsis className="text-gray">20 días</Text>
                                                            </Col> */}
                                                            <Col span={4} >
                                                                <Text ellipsis className="text-gray">
                                                                    <CustomAvatar
                                                                        image={agente_compras_id?.avatar}
                                                                        name={`${agente_compras_id?.nombre || "N"} ${agente_compras_id?.apellido || "A"}`}
                                                                    />
                                                                    &nbsp;&nbsp;&nbsp;{`${agente_compras_id?.nombre || "No"} ${agente_compras_id?.apellido || "Asignado"}`}
                                                                </Text>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </List.Item>
                                        )}
                    
                                    />
                                ) : (
                                    <img src="/images/transito.svg" alt="En Produccion" style={{width: "100%"}} />
                                )
                            }
                        </Col>
                    </Row>
                </Spin>
            </>

        )
    }
}