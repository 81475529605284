import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Card, Typography, List, message, Tooltip, Modal } from "antd";

//componentes
import { IconDetails, IconEdit, IconSAT } from '../../../Widgets/Iconos';


import {
    FloatingMenu,
    MainButton,
    ChildButton,
} from 'react-floating-button-menu';

import { DollarOutlined, BankFilled, PlusOutlined, CloseOutlined, EyeOutlined, EditOutlined, FilePdfOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';

import CustomAvatar from "../../../Widgets/Avatar/Avatar";

// import { FloatingButton, Item } from "react-floating-button";
import DrawerTimbradoOrden from "../../Finanzas/Facturas/DrawerTimbrado";
//modales

import ModalTransaccionesDetalle from "./ModalTransaccionesDetalle";
import ModalTransaccionesIngreso from "./ModalTransaccionesIngreso";

const { Content } = Layout;
const { Text } = Typography
const axios = require('axios')
const moment = require('moment')


/**
 * @export
 * @class IngresosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class IngresosList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            ingresos: [],
            page: 1,
            limit: 10,
            total: 0,

            modalTransacVisible: false,
            transaccion_id: undefined,

            filtroSearch: '',
            searching: true,


            isOpen: false,
            drawerTimbrarComprobanteVisible: false
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTransacciones()
    }



    componentDidUpdate = (prevProps) => {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getTransacciones();
            this.state.searching = false;
        }

        //si cambia la orden, actualiza las transacciones
        if (this.props.orden_id && this.props.orden_id !== prevProps.orden_id) {
            this.getTransacciones(1)
        }
    }

    /**
    * @memberof AccountPurchases
    * @method getTransacciones
    * @description Obtiene las transacciones hechas por el cliente de la orden correspondiente
    */
    getTransacciones = (page = this.state.page) => {

        let search = this.props.search;
        const orden_id = this.props.orden_id;

        if (!orden_id) return;

        this.setState({ loading: true })

        axios.post('/transacciones/list', {
            id: orden_id,
            tipo: 1, //ingreso
            page: page,
            search
        })
            .then(({ data }) => {

                this.setState({
                    ingresos: data.data.itemsList,
                    total: data.data.itemCount,
                    page: data.data.currentPage,

                    searching: true,
                    filtroSearch: search
                })
            })
            .catch(err => console.log(err))
            .finally(() => this.setState({ loading: false }))
    }


    /**
    *
    *
    * @memberof AccountPurchases
    * @method createReferencia
    * @description Crea la referecia de pago de una orden VMI
    */
    createReferencia = () => {
        axios.post('/ordenes/update', {
            id: this.props.orden_id,
            referencia: true
        }).then(response => {
            message.success('Referencia Creada')
            this.props.updateOrden()
        }).catch(error => {
            message.error('Error al crear la referencia')
        })
    }


    render() {
        return (
            <>
                <Content>
                    {this.props.orden?.referencia && this.state.ingresos?.length === 0 ? <Card className="card-list">
                        <Row className="width-100">
                            <Col xs={24} className="">
                                <Text strong>Referencia de Pago: {this.props.orden?.referencia}</Text>
                            </Col>
                        </Row>
                    </Card> : null}
                    <List
                        className="component-list"
                        dataSource={this.state.ingresos}
                        pagination={{
                            current: this.state.page,
                            pageSize: this.state.limit,
                            total: this.state.total,
                            hideOnSinglePage: false,
                            position: 'bottom',
                            className: "flex-left",
                            onChange: this.getTransacciones,
                        }}
                        loading={this.state.loading}
                        renderItem={item => (
                            <List.Item>
                                <Card className="card-list">
                                    <div className={`badge-card tipo-${item.tipo}`}>  </div>
                                    <Row className="width-100">
                                        <Col xs={2} className="center">
                                            <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                        </Col>
                                        <Col xs={6} className="center">
                                            <Text className="text-gray-dark">{item.concepto}</Text>
                                        </Col>
                                        <Col xs={2} className="center">
                                            <Text className="text-gray-dark">{item.orden_id?.folio}</Text>
                                        </Col>
                                        <Col xs={2} className="center">
                                            <CustomAvatar name={[item.cliente_id?.nombre, item.cliente_id?.apellido]} />
                                        </Col>
                                        <Col xs={3} className="center">
                                            <Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
                                        </Col>
                                        <Col xs={5} className="center">
                                            <span style={{ color: (item.tipo === 1 ? "#10A902" : "#FF0000"), fontWeight: "700", fontSize: "16px", fontFamily: "Poppins" }} > $ {item.monto.toMoney(true)} MXN</span>
                                        </Col>
                                        <Col>
                                            <Space size={4}>
                                                <Tooltip title="Comprobante de Pago SAT">
                                                    <Button
                                                        //disabled={true}
                                                        size="small"
                                                        icon={<IconSAT style={{ width: 15, height: 15, top: 3, position: "relative" }} color="white" />}
                                                        type="primary"
                                                        onClick={() => this.setState({ drawerTimbrarComprobanteVisible: true, transaccion_id: item._id })}
                                                        style={{
                                                            backgroundColor: "#00529E" + (item.comprobante_id ? "80" : ""),
                                                            borderColor: "#00529E" + (item.comprobante_id ? "80" : ""),
                                                        }}
                                                    >
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title="Ver Comprobante">
                                                    <Button
                                                        target="_blank"
                                                        // download={true}
                                                        href={`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${item.comprobante_id}&Authorization=${sessionStorage.getItem("token")}&dummy=dummy`}
                                                        disabled={!item.comprobante_id}
                                                        size="small"
                                                        icon={<FilePdfOutlined style={{ color: "white" }} />}
                                                        type="primary"

                                                        style={{
                                                            backgroundColor: "#00529E" + (!item.comprobante_id ? "80" : ""),
                                                            borderColor: "#00529E" + (!item.comprobante_id ? "80" : ""),
                                                        }}
                                                    >
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip title={<>
                                                    Imprimir XML
                                                </>}>
                                                    <Button
                                                        disabled={!item.comprobante_id}
                                                        size="small"
                                                        icon={<DownloadOutlined style={{ color: "white" }} />}
                                                        type="primary"
                                                        href={`${axios.defaults.baseURL}/facturas/cfdi/xml?factura_id=${item.comprobante_id}&Authorization=${sessionStorage.getItem("token")}&dummy=dummy`}
                                                        style={{
                                                            backgroundColor: "#00529E" + (!item.comprobante_id ? "80" : ""),
                                                            borderColor: "#00529E" + (!item.comprobante_id ? "80" : ""),
                                                        }}
                                                    >
                                                    </Button>
                                                </Tooltip>
                                                <Button
                                                    size="small"
                                                    icon={<EyeOutlined style={{ color: "white" }} />}
                                                    type="primary"
                                                    title="Ver Detalles"
                                                    onClick={() => this.setState({ transaccion_id: item._id, modalTransacDetalleVisible: true })}
                                                    style={{
                                                        backgroundColor: "#ADFF45",
                                                        borderColor: "#ADFF45"
                                                    }}
                                                >
                                                </Button>
                                                <Button
                                                    size="small"
                                                    icon={<EditOutlined style={{ color: "white" }} />}
                                                    type="primary"
                                                    title="Editar"
                                                    onClick={() => this.setState({ modalTransacVisible: true, transaccion_id: item._id })}
                                                    style={{
                                                        backgroundColor: "#456EFF",
                                                        borderColor: "#456EFF"
                                                    }}
                                                >
                                                </Button>
                                                <Button
                                                    size="small"
                                                    icon={<DeleteOutlined style={{ color: "white" }} />}
                                                    type="primary"
                                                    title="Editar"
                                                    onClick={() => Modal.confirm({
                                                        title: "¿Deseas eliminar la transacción",
                                                        onOk: () => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                            message.success('Transaccion Eliminada')
                                                            this.getTransacciones()
                                                        }).catch((error) => {
                                                            message.error('Transacción NO Eliminada')
                                                            this.getTransacciones();
                                                        })
                                                    })}
                                                    danger
                                                >
                                                </Button>


                                            </Space>
                                        </Col>
                                    </Row>
                                </Card>
                            </List.Item>
                        )}
                    />

                </Content>
                <FloatingMenu
                    slideSpeed={500}
                    direction="left"
                    spacing={8}
                    isOpen={this.state.isOpen}
                    style={{ position: "fixed", bottom: "1em", right: "1em" }}

                >
                    <MainButton
                        iconResting={<PlusOutlined style={{ fontSize: 20, color: "white" }} nativeColor="white" />}
                        iconActive={<CloseOutlined style={{ fontSize: 20, color: "white" }} nativeColor="white" />}
                        background="#2d348c"
                        onClick={() => this.setState({ isOpen: !this.state.isOpen })}
                        size={56}
                    />
                    <ChildButton
                        icon={
                            <Tooltip placement="top" title="Crear Nueva Transaccion">
                                <DollarOutlined style={{ fontSize: 20, color: "white" }} nativeColor="white" />
                            </Tooltip>
                        }
                        background="#2d348c"
                        size={40}
                        onClick={() => this.setState({
                            modalTransacVisible: true,
                            isOpen: false
                        })}
                    />
                    <ChildButton
                        // icon={<BankFilled style={{ fontSize: 20, color: "white" }} nativeColor="white" />}
                        icon={
                            <Tooltip placement="top" title="Crea la referencía">
                                <BankFilled style={{ fontSize: 20, color: "white" }} nativeColor="white" />
                            </Tooltip>
                        }
                        background={this.props.orden?.referencia ? "#2C65A3" : "#2d348c"}
                        size={40}
                        onClick={this.props.orden?.referencia ? null : this.createReferencia}
                    />
                </FloatingMenu>

                <ModalTransaccionesDetalle
                    visible={this.state.modalTransacDetalleVisible}
                    onClose={() => {
                        this.setState({ modalTransacDetalleVisible: false, transaccion_id: undefined });
                        this.getTransacciones(this.state.page);
                    }}
                    id={this.state.transaccion_id}
                />
                <ModalTransaccionesIngreso
                    visible={this.state.modalTransacVisible}
                    transaccion_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modalTransacVisible: false, transaccion_id: undefined })
                        this.getTransacciones();
                    }}
                    orden={this.props.orden}
                />
                <DrawerTimbradoOrden
                    visible={this.state.drawerTimbrarComprobanteVisible}
                    transaccion_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ drawerTimbrarComprobanteVisible: false, modalTransacVisible: false, transaccion_id: undefined })
                        this.getTransacciones();
                    }}
                    onSuccess={() => {
                        this.setState({ drawerTimbrarComprobanteVisible: false, modalTransacVisible: false, transaccion_id: undefined })
                        this.getTransacciones();
                    }}
                    orden_id={this.props.orden?._id}
                />
            </>
        )
    }
}