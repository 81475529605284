import React from 'react'
import { Route, Switch } from "react-router-dom";
import KPIS from '../components/Admin/KPIs/KPIs';


/**
 *
 *
 * @export
 * @function RouterKPIs
 */
function RouterKPIs( props) {
    return (
        <Switch>
            <Route exact path="/admin/kpis" render={() => <KPIS {...props} />} />
        </Switch>
    )
}

export default RouterKPIs;
