import React, { Component } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Upload, Modal, Row, Spin, Typography, Select, Input, } from 'antd';

const axios = require('axios').default;
const moment = require('moment')
const { Title, Text } = Typography;
const { Option } = Select;


/**
 *
 *
 * @export
 * @class ModalMotivos
 * @extends {Component}
 */
class ModalMotivos extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false   
        }
    }

   


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        
    }

   

    render() {
        const { loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Motivos para recotizar</Title>
                <Row justify="center" className="mt-3">
                    {this.props.motivos.map(motivo=><>
                            <Col span={7}>
                                <Text strong className="mb-05">Fecha</Text>
                                <p className="text-gray"> {moment(motivo?.fecha).format('DD-MM-YYYY HH:MM')}</p>
                            </Col>
                            <Col span={15}>
                                <Text strong className="mb-05">Motivo:</Text>
                                <p className="text-gray">{motivo?.motivos}</p>
                            </Col>
                        </>)
                    }
                </Row>
                <Row>
                    <Col span={24} className="center mt-2">
                        <Button type="primary" onClick={()=>this.props.onCancel()}>Cerrar</Button>
                    </Col>
                </Row>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props;
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <ModalMotivos {...props} />
        </Modal>
    )
}
