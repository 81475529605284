import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Compras from '../components/Admin/Compras/Compras'
import ListaCompras from '../components/Admin/Compras/ListaCompras'

import ReportesCompras from '../components/Admin/ReporteOrdenesCompras/ReporteOrdenesCompras'

// import DetalleClientes from '../components/Admin/Clientes/Detall
// import Usuarios from '../components/Admin/Usuarios/Usuarios';



/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
 export default function RouterCompras(props) {

    return (
        <Switch>
            <Route exact path="/admin/compras" render={() => <Compras {...props} />} />
            <Route exact path="/admin/compras/ordenes" render={() => <ListaCompras {...props} />} />
            <Route exact path="/admin/compras/ordenes/:orden_compra_id" render={(routeProps) => <ListaCompras {...props} {...routeProps}/>} />
            <Route exact path="/admin/compras/reportes" render={() => <ReportesCompras {...props} />} />

            {/* <Route exact path="/admin/clientes/informacion/:id" render={() => <DetalleClientes  {...props} />} />
            <Route exact path="/admin/clientes/usuarios/:id" render={(props) => <Usuarios  {...props} />} /> */}
        </Switch>
    )
}

