import React, { Component } from 'react';
import { Button, Popconfirm, Spin, Row, Card, PageHeader, Layout, message, Col, List, Typography, Space, Tooltip } from 'antd'
import axios from 'axios';
import { Link } from "react-router-dom";
import { BiStoreAlt } from 'react-icons/bi'
import { BsBox } from 'react-icons/bs'



//Componentes
import { IconDelete, IconEdit } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalInventarios from "./ModalInventarios"

const { Content } = Layout;
const { Text } = Typography;

/**
 * @export
 * @class Inventarios
 * @extends {Component}
 * @description Vista de Inventarios
 */
export default class Inventarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            inventarios: [],
            inventario_id: undefined,

            page: 1,
            total: 0,

            filtroSearch: '',
            searching: true,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getInventarios()
    }


    componentDidUpdate() {
		const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getInventarios();
            this.state.searching = false;
        }
	}



    /**
     * @memberof ModalAreas
     * @method getInventarios
     * @description Obtiene una area de la DB
     */
    getInventarios = (page) => {
        let search = this.props.search;

        this.setState({ loading: true })
        axios.get('/inventarios/list', {
            params: { 
				page: page, 
                paginate: true,
				search
			} 

        }).then(response => {
            console.log(response.data.data)
            this.setState({
                inventarios: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
                searching: true,
                filtroSearch: search

            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información.')
        }).finally(() => this.setState({ loading: false }))
    }


    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Inventarios"
                    />

                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list scroll"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Inventarios" }}
                            dataSource={this.state.inventarios}
                            pagination={{
                                onChange: this.getInventarios,
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                position: ['bottomLeft'],
                            }}
                            loading={this.state.loading}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col span={10} className="center">
                                    <Text>Cliente</Text>
                                </Col>
                                <Col span={10} className="center">
                                    <Text>Descripción</Text>
                                </Col>
                            </Row>}

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            <Col lg={10} className="center">
                                                <Text>{item.cliente?.razon_social ||item.cliente?.nombre} </Text>
                                            </Col>
                                            <Col span={10} className="center">
                                                <Text>{item.descripcion}</Text>
                                            </Col>
                                            <Col span={4} className="center">
                                                <Space>
                                                    <Link to={`/admin/inventarios/${item._id}/almacenes`}>
                                                        <Tooltip title="Almacenes">
                                                            <Button
                                                                type="primary"
                                                                icon={<BiStoreAlt style={{ position: 'relative', top: 2 }} />}
                                                            />
                                                        </Tooltip>
                                                    </Link>
                                                    <Link to={`/admin/inventarios/${item._id}/productos`}>
                                                        <Tooltip title="Productos">
                                                            <Button
                                                                type="primary"
                                                                icon={<BsBox style={{ position: 'relative', top: 2 }} />}
                                                            />
                                                        </Tooltip>
                                                    </Link>
                                                    <Tooltip title="Editar">
                                                        <Button

                                                            type="primary"
                                                            icon={<IconEdit />}
                                                            title="Editar"
                                                            onClick={() => { this.setState({ modalVisible: true, inventario_id: item._id }) }}
                                                        />
                                                    </Tooltip>

                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="¿Deseas eliminar este Inventario?"
                                                        onConfirm={() => axios.post('/inventarios/delete',
                                                            { id: item._id }).then(() => {
                                                                this.getInventarios()
                                                                message.success('Inventario eliminado')
                                                            }).catch(error => console.log(error))}
                                                        okText="Si"
                                                        cancelText="No"
                                                    >
                                                        <Tooltip title="Eliminar">
                                                            <Button
                                                                type="primary"
                                                                icon={<IconDelete />}
                                                                title="Eliminar"
                                                                danger
                                                            />
                                                        </Tooltip>

                                                    </Popconfirm>
                                                </Space>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />

                    <ModalInventarios
                        visible={this.state.modalVisible}
                        inventario_id={this.state.inventario_id}
                        onClose={() => {
                            this.setState({ modalVisible: false, inventario_id: undefined })
                            this.getInventarios()
                        }}
                    />

                </Spin>
            </>
        )
    }
}