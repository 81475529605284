import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Popconfirm, Typography, List, message, Card, Statistic, Tag } from "antd";
import { IconDelete, IconEdit, IconDetails } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { EyeOutlined, EditFilled, CloseOutlined, WarningOutlined, CheckCircleOutlined, FilePdfOutlined } from "@ant-design/icons";
import ModalXML from "../Syncfy/ModalXML";
import ModalFacturas from "../Finanzas/Facturas/ModalFacturas";

import '../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'

const { Content } = Layout;
const { Text } = Typography
const axios = require('axios')
const moment = require('moment')

var tabActiva = "";

/**
 * @export
 * @class IngresosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class FacturasList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            ingresos: [],
            transaccion_id: undefined,
            modalIngresosDetalleVisible: false,
            modalIngresosVisible: false,

            page: 1,
            limit: 10,
            total: 0,


            filtroSearch: '',
            searching: true,


        }
    }

    componentDidMount = () => {
        this.getFacturas()
    }



    componentDidUpdate = () => {


        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getFacturas();
            this.state.searching = false;
        }


        if (this.props.actualizarTabla !== tabActiva) {
            tabActiva = this.props.actualizarTabla;
        }
        if (tabActiva === "Ingresos" && this.state.actualizarTabla === true) {

            this.state.actualizarTabla = false;
            this.setState({ actualizarTabla: false })
            this.getFacturas();
        }
        else if (tabActiva !== "Ingresos" && !(this.state.actualizarTabla)) {
            this.state.actualizarTabla = true;
            this.setState({ actualizarTabla: true })
        }


    }

    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    renderTipo = (value) => {
        switch (value) {
            case 1:
                return <Tag color="#87d068">Ingreso</Tag>
            case 0:
                return "Sin cambios"
            case -1:
                return <Tag color="#f50">Ingreso</Tag>
        }
    }

    renderViews = (item) => {


        return <Space>
            <Button
                type="primary"
                icon={<EditFilled />}
                title="Editar"
                onClick={() => { this.setState({ modalFacturasVisible: true, factura_id: item._id }) }}
            />
            <Button
                type="primary"
                icon={<EyeOutlined style={{ color: "#fff" }} />}
                onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                title="Ver XML"
                disabled={!item.factura_xml}
            >
            </Button>
            <Button
                type="primary"
                icon={<FilePdfOutlined style={{ color: "currentColor" }} />}
                onClick={() => window.open(`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`, '_blank')}
                title="Ver PDF"
                disabled={!(item.tipo_facturacion === 1)}
            />
        </Space>
    }

    renderEstatus = (item) => {

        switch (item.estatus) {
            case 0:
                return <Tag color="#87d068" icon={<CloseOutlined style={{ color: "currentcolor", fontSize: "14px" }} />}>Factura Cancelada</Tag>
            case 1:
                // Si la fecha de vencimiento 
                if (this.props.cliente?.premium && moment().isAfter(moment(item.fecha_vigencia)))
                    return <Tag color="#ff4d4f" icon={<CloseOutlined style={{ color: "currentcolor" }} />}>Vencida</Tag>
                else
                    return <Tag color="#faad14" icon={<WarningOutlined style={{ color: "currentcolor" }} />}>Pendiente</Tag>

            case 2:
                return <Tag color="#52c41a" icon={<CheckCircleOutlined style={{ color: "currentcolor" }} />}>Liquidada</Tag>
        }
    }

    getFacturas = () => {

        let search = this.props.search
        const { clienteId } = this.props

        console.log('search', search);

        axios.get('/facturas/list', {
            params: {
                page: this.state.page,
                limit: this.state.limit,
                tipo: 1, // Solo Ingresos
                cliente_id: clienteId,
                vmi: true,
                search
            }
        })
            .then(({ data }) => {
                console.log('data.data.itemsList', data.data);
                this.setState({
                    facturas: data.data.itemsList,
                    searching: true,
                    filtroSearch: search,
                    total: data.data.paginator.itemCount,
                    // limit: data.data.paginator.itemCount,

                })
            })
            .catch(error => {
                message.error("No se obtuvieron las Transacciones")
                console.log(error)
            })
    }


    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                className="component-list lista-vmi"
                                locale={{ emptyText: "Sin Facturas" }}
                                itemLayout="horizontal"
                                dataSource={this.state.facturas}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: this.state.limit,
                                    total: this.state.total,
                                    hideOnSinglePage: false,
                                    position: 'bottom',
                                    className: "flex-left",
                                    onChange: (page, limit) => this.setState({page, limit}, () => this.getFacturas())
                                }}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row className="width-100 ">
                                                <Col lg={2} className="center">
                                                    <Text ellipsis>{this.renderTipo(item.tipo)}</Text>
                                                </Col>
                                                <Col lg={6} span={8} className="center">
                                                    <p style={{ width: '100%' }}>
                                                        <strong>{item.concepto}</strong><br />
                                                        <small>{item?.receptor?.rfc}</small>
                                                    </p>
                                                </Col>
                                                <Col lg={4} className="center">
                                                    <Text ellipsis strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col lg={3} className="center">
                                                    <Text ellipsis>$ {item.monto ? item.monto.toMoney(true) : '0.00'} <small>{item.moneda}</small></Text>
                                                </Col>
                                                <Col lg={3} className="center">
                                                    <Text ellipsis>$ {item.pagado ? item.pagado.toMoney(true) : '0.00'} <small>{item.moneda}</small></Text>
                                                </Col>
                                                <Col lg={3} className="center">
                                                    {this.renderEstatus(item)}
                                                </Col>
                                                <Col lg={1} className="center">

                                                    {this.renderViews(item)}

                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />

                        </Col>
                    </Row>
                    {/* <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalIngresosVisible: true })} /> */}
                </Content>
                <ModalXML
                    visible={this.state.ModalXMLVisible}
                    onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml_storage: undefined, xml: undefined })}
                    factura_xml_storage={this.state.factura_xml_storage}
                />

                <ModalFacturas
                    visible={this.state.modalFacturasVisible}
                    onClose={() => {
                        this.setState({ modalFacturasVisible: false, factura_id: undefined })
                        this.getFacturas()
                    }}
                    factura_id={this.state.factura_id}
                />
            </>
        )
    }
}