import { useState } from 'react';

import { Form, Upload, } from 'antd';
import { PlusOutlined } from "@ant-design/icons";

const axios = require('axios').default;

export default function UploadFotoProducto(props) {

    let [image, setImage] = useState(null);
    let [dataFile, setDataFile] = useState(null);

    /**
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    const removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            });
    };

    /**
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    const normFile = (e) => {
        const { file, fileList } = e;
        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
                fileList[x].name = fileList[x].response.name
            }

        }
        if (file.status === "removed")
            removeFile((file?.response?.filename) ? file.response.filename : file.filename);
        return e.fileList;
    };

    return (
        <Form.Item
            name="imagen"
            getValueFromEvent={normFile}
            valuePropName="fileList"
        >
            <Upload
                action={axios.defaults.baseURL + "/upload/add"}
                multiple={true}
                listType="picture-card"
            >
                <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Subir</div>
                </div>
            </Upload>
        </Form.Item>
    )
}