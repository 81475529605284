

import React, { Component } from "react";
import { Layout, PageHeader, Button, Col, Row, Typography, message, Space, Popconfirm, Checkbox } from "antd";

import { CardEntrega, CardLegalInfo, CardProjectInfo, CardUtilidad } from "../../Widgets/Cards";
import { IconArrowBack } from '../../Widgets/Iconos';

import StepTracker from "../ProjectManager/Secciones/StepTracker";
import TableMatrizCostos from "./Matriz/TableMatrizCostos";

import { User } from '../../../Hooks/Logged';
import { SocketContext } from '../../../Hooks/Socket';

import ModalMotivos from "../ProjectManager/ModalMotivos";

const { Content } = Layout;
const { Title } = Typography

const axios = require('axios');

/**
 *
 *
 * @export
 * @class MatrizCostos
 * @extends {Component}
 * @description Vista de Matriz de Costos
 */
class MatrizCostos extends Component {

    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            orden_id: this.props.match.params.orden_id,
            productos: [],
            orden: {
                cliente_id: {},
                responsable_id: {},
            },
            margen: 0,
            perdidas: [],

            motivos: [],
            modalMotivos: false,
            check: true,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrden()
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id){
            this.setState({orden_id: this.props.match.params.orden_id},()=>{
                this.getOrden();
            })
        }
    }

    stepTracker = React.createRef();

    /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method getOrden
     * @description obetenmos la informacion de la orden
     */
    getOrden = () => {
        this.setState({ loading: true })
        axios.get('/project-manager/orden', {
            params: {
                id: this.state.orden_id,
            }
        }).then(response => {

            let ingresos = response.data.data.margen?.ingresos ? response.data.data.margen.ingresos : 0
            let costos = response.data.data.margen?.costos ? response.data.data.margen?.costos : 0

            let margen = (ingresos - costos) / ingresos * 100



            this.setState({
                orden: response.data.data,
                percentage: response.data.data.percentage ? response.data.data.percentage : 0,
                productos: response.data.data.orden_detalles,
                margen: margen.toMoney(),
                check: response.data.data.totales,
            })
        })
            .catch(error => {
                console.log(error)
                message.error('Error al cargar la informacion')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method getPDF
     * @description Generamos el PDF de la contizacion
     */
    getPDF = () => {
        let urlPDF = new URL(axios.defaults.baseURL + '/project-manager/matriz/pdf');
        urlPDF.searchParams.append('Authorization', sessionStorage.getItem('token'))
        urlPDF.searchParams.append('orden_id', this.state.orden._id)
        urlPDF.searchParams.append('totales', this.state.check)
        return urlPDF.href
    }

    /**
    *
    *
    * @memberof FormOrdenes
    * 
    * @method sendPDF
    * @description Enviamos la cotizacion al cliente
    */
    sendPDF = () => {
        if(this.state.loading) return

        this.setState({ loading_enviar: true }, () => {
            
            return axios.get('/project-manager/matriz/pdf', {
                params: {
                    orden_id: this.state.orden_id,
                    agente_compras_id: this.state.orden.agente_compras_id?._id,
                    enviar: true
                }
            }).then(response => {
                message.success('Cotización Enviada')
    
                const socket = this.props.socket
                const user = this.props.user
                const { agente_compras_id, usuario_id } = this.state.orden

                const destinos = []

                if(user?._id){
                    destinos.push(user._id)
                }

                if(agente_compras_id?._id !== user._id && agente_compras_id !== undefined){
                    destinos.push(agente_compras_id._id)
                }

                if(usuario_id){
                    destinos.push(usuario_id)
                }
    
                if(socket?.connected){
                    console.log('Socket corriendo')
                    let notificacion = {
                        evento: `Se ha enviado la cotización al cliente`,
                        detalle: `El mensaje con la cotización se ha enviado al cliente y una copia se te ha enviado por correo`,
                        usuarios_destino: destinos,
                        usuario_id: user._id
                    }
                    socket.emit('nueva_notificacion', notificacion)
                }
    
                this.getOrden()
                this.stepTracker?.current?.getOrden()
                this.setState({
                    cotizado: true,
                    orden_id: this.props.match.params.orden_id
                })
            }).catch(error => {
                console.log(error)
                message.error('Error al enviar la cotizacion')
            }).finally(() => {
                this.setState({ loading_enviar: false })
            })
        })
        
    }

    onChangeStep = current => {
        this.setState({ current });
    };

    /**
     *
     *
     * @memberof MatrizCostos
     * @description 
     */
    onChangeProveedor = (value) => {
    }

    toogle = () => {
        // this.props.toogle;
    }

    back = () => {
        this.props.history.goBack();
    }


    /**
     * @memberof FormOrdenes
     * 
     * @method renderStatusOrden
     * @description Declaramos el render status orden.
     */
    renderStatusOrden = () => {

        if (this.state.orden.cotizacion?.status) {
            switch (this.state.orden.cotizacion?.status) {
                //Si es 3, se envió (está en espera)
                case 1: return "";
                //Si es 4, aceptada
                case 2: return "color-waiting";
                //Si es 5, rechazada
                case 3: return "color-accepted"

                case 4: return "color-rejected"
            }
        }
        return ""
    }

    /**
     * @memberof FormOrdenes
     * 
     * @method crearListaCompras
     * @description Crear lista de compras a partir de orden
     */
    crearListaCompras = () => {

        if(this.state.loading) return

        this.setState({ loading: true }, () => {

            axios.post('/ordenesCompra/addListaCompras', {
                orden_id: this.state.orden_id,
    
            }).then(response => {
                this.props.history.push(`/admin/project-manager/cuenta-ventas/${this.state.orden_id}`)
            })
                .catch(error => {
                    console.log(error)
                    message.error('Error al cargar la informacion')
                })
                .finally(() => this.setState({ loading: false }))
        })



    }

    render() {

        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={
                        <Row className=" width-100 " justify="space-between" align="middle">
                            <Col span={2} xxl={1}>
                                <Button ghost onClick={this.back} className="no-border">
                                    <IconArrowBack />
                                </Button>
                            </Col>

                            <Col span={22} xxl={23}>
                                <span className="ant-page-header-heading-title" >
                                    Cadena de Suministro
                                </span>
                            </Col>
                        </Row>
                    }
                    
                />
                <Content className="content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} lg={8} xl={6}>
                            <StepTracker orden_id={this.state.orden_id} ref={this.stepTracker} cotizado={this.state.cotizado} />
                        </Col>
                        <Col lg={16} xl={18} >
                            <Row gutter={[16, 16]}>
                                <Col span={8} xl={8} lg={24} xs={24} >
                                    <CardProjectInfo
                                        cliente={this.state.orden?.cliente_id}
                                        orden={this.state.orden}
                                        responsable={this.state.orden?.agente_compras_id}
                                        progress={this.state.percentage}
                                    />
                                </Col>
                                <Col span={8} xl={8} lg={12} xs={24}>
                                    <Row gutter={[0, 16]}>
                                        <Col span={24}>
                                            <CardUtilidad margen={this.state.margen} />
                                        </Col>
                                        <Col span={24}>
                                            <CardLegalInfo orden_id={this.state.orden_id} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8} xl={8} lg={12} xs={24}>


                                    <CardEntrega
                                        direccion={this.state.orden?.cliente_id?.direccion}
                                        motivos={this.state.orden?.cotizacion?.motivos}
                                        orden_id={this.state.orden?._id}
                                        fecha_entrega={this.state.orden?.fecha_entrega}
                                        update={this.getOrden}
                                        onClick={() => { this.setState({ modalMotivos: true, motivos: this.state.orden?.cotizacion?.motivos }) }}
                                    />


                                </Col>
                            </Row>
                            <Row gutter={[16, 0]}>
                                <Row justify="space-between " className="width-100 pd-1">
                                    <Title level={3}>
                                        Matriz de Costos
                                        <Checkbox 
                                            className="ml-1" 
                                            checked={this.state.check}
                                            onClick={(e)=>{
                                                this.setState({check: !this.state.check},()=>{
                                                    axios.post('/ordenes/update',{
                                                        id: this.state.orden._id,
                                                        totales: this.state.check
                                                    })

                                                })
                                            }}
                                        >Adjuntar Totales</Checkbox>
                                    </Title>
                                    
                                    <Space style={{ float: "right" }} wrap>
                                        {
                                            /*ORDEN VMI*/
                                        (this.state.orden?.tipo_orden === 2) ? (
                                            <>
                                                {
                                                    this.state.margen < 0 || this.state.perdidas > 0 ? (
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title="Tienes pérdidas en tu cotizacion, ¿Quieres continuar?"
                                                            onConfirm={() => {
                                                                this.crearListaCompras();
                                                            }}
                                                            okText="Confirmar Compra"
                                                            cancelText="Cancelar"
                                                        >
                                                            <Button
                                                                type="primary"
                                                                size="large"
                                                                className="btn-quote"
                                                            >
                                                                Confirmar Compra
                                                            </Button> 
                                                        </Popconfirm>
                                                    ) : (
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            className="btn-quote"
                                                            onClick={() => {
                                                                this.crearListaCompras();
                                                            }}
                                                        >
                                                            Confirmar Compra
                                                        </Button> 
                                                    )
                                                }
                                            </>
                                        ) : (
                                            <>
                                                {this.state.margen < 0 ? (
                                                    <Popconfirm
                                                        placement="topRight"
                                                        title="Tienes pérdidas en tu cotización, ¿Quieres continuar?"
                                                        onConfirm={this.sendPDF}
                                                        okText="Enviar Cotización"
                                                        cancelText="Cancelar"
                                                    >
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            className={"btn-quote " + this.renderStatusOrden()}
                                                            loading={this.state.loading_enviar}
                                                        >
                                                            Enviar Cotización Final
                                                        </Button>
                                                    </Popconfirm>
                                                    ) : (
                                                        <Button
                                                            type="primary"
                                                            size="large"
                                                            className={"btn-quote " + this.renderStatusOrden()}
                                                            onClick={this.sendPDF}
                                                            loading={this.state.loading_enviar}
                                                        >
                                                            Enviar Cotización Final
                                                        </Button>
                                                    )
                                                }
                                                <a href={this.getPDF()} download target="_blank">
                                                    <Button
                                                        type="primary"
                                                        size="large"
                                                        className="btn-quote"
                                                        onClick={() => this.getPDF()}
                                                    >
                                                        Revisar Cotización Final
                                                    </Button>
                                                </a>
                                            </>
                                        )
                                        }
                                    </Space>

                                </Row>
                                <Col span={24}>
                                    <TableMatrizCostos
                                        productos={this.state.productos}
                                        perdidas={this.state.perdidas}
                                        setMargen={(margen) => this.setState({ margen: margen.toMoney() })}
                                        setPerdidas={(perdidas) => this.setState({ perdidas })}
                                        orden={this.state.orden}
                                        tipo_orden={this.state.orden?.tipo_orden}
                                        getOrden={this.getOrden}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>

                <ModalMotivos
                    visible={this.state.modalMotivos}
                    onCancel={() => this.setState({ modalMotivos: false })}
                    motivos={this.state.motivos}
                />
            </>
        )
    }
}

export default function(props){

    const user = React.useContext(User)
    const socket = React.useContext(SocketContext)

    const onNotificaciones =  ({notificaciones, count}) => {
        let cliente_notificacion = notificaciones.data.find(notificacion => /cliente/i.test(notificacion.evento))
        if(count > 0 && cliente_notificacion){
            console.log(notificaciones, count)
            socket.emit('leidos', [cliente_notificacion._id])
            window.location.reload()
        }
    }

    React.useEffect(() => { 

        socket.on('notificaciones', onNotificaciones)

        return () => {
            socket.off('notificaciones', onNotificaciones)
        }
    }, [socket])

    return(<MatrizCostos {...props} user={user} socket={socket}/>)
}