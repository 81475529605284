import React, { Component, } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Select, } from 'antd';
import PropTypes from "prop-types";


const axios = require('axios').default;
const { Title, } = Typography;
const { Option } = Select;





/**
 *
 *
 * @export
 * @class FormOrdenes
 * @extends {Component}
 */
class FormOrdenes extends Component {


    /**
     *
     *
     * @static
     * @memberof FormOrdenes
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            clientes: [],
            usuarios: [],


            inventario_producto_edit: undefined,
            productos: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
            }




        }
    }

    formOrden = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getClientes();
        this.getUsuarios();

        if (this.props.orden_id) {
            this.getOrden()
        }
    }

    /**
    *
    * @memberof FormOrdenes
    * 
    * @method getClientes
    * @description Guardamos el producto
    */
    getClientes = (search) => {
        return axios.get('/clientes/list', {
            params: {
                search,
                limit: 200
            }
        }).then(response => {
            this.setState({ clientes: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los clientes')
        })
    }

    /**
    * @function getUsuarios
    * @description Obtenemos los usuarios del sistema para agregarlos a Syncfy
    * */
    getUsuarios = async (search) => {
        await axios.get("/usuarios", {
            params: {
                search: search,
                paginate: false
            },
        })
            .then(({ data }) => {
                console.log('data', data)
                this.setState({ usuarios: data.data })
            })
            .catch(e => console.log('er', e))
    }

    /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method getOrden
     * @description Guardamos el producto
     */
    getOrden = () => {
        this.setState({ loading: true })
        axios.get('/ordenes/get', {
            params: {
                id: this.props.orden_id,
            }
        }).then(response => {
            console.log('orden', response.data.data)
            this.formOrden.current.setFieldsValue({
                folio: response.data.data.folio,
                cliente_id: response.data.data.cliente_id?._id,
                agente_compras_id: response.data.data.agente_compras_id?._id,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar la informacion')
        }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @function onFinish
    * @description Metodo que se ajecuta al dar enter al formulario
    * */
    onFinish = async (values) => {
        if (this.props.orden_id) {
            this.updateOrden(values)
        } else {
            this.addOrden(values)
        }
    }


    /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method addOrden
     * @description Guardamos el producto
     */
    addOrden = (values) => {
        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/ordenes/add', {
                ...values,
            })
                .then(() => {
                    message.success("¡Se ha guardado correctamente la Orden!")
                    this.props.onCancel();
                })
                .catch((e) => {
                    message.error('No se pudo guardar la Orden')
                })
                .finally(() => this.setState({
                    loading: false,
                }))
        })
        
    }

    /**
     *
     *
     * @memberof FormOrdenes
     * 
     * @method updateOrden
     * @description Actualizamos el producto.
     */
    updateOrden = (values) => {
        this.setState({ loading: true })
        axios.post('/ordenes/update', {
            ...values,
            id: this.props.orden_id
        })
            .then(() => {
                message.success("¡Se ha actualizado correctamente la orden!")
                this.props.onCancel();
            })
            .catch((e) => {
                console.log(e)
                message.error('No se actualizo la Orden')
            })
            .finally(() => this.setState({
                loading: false,
            }))
    }





    selectProducto = React.createRef()

    render() {
        const { formOrden, } = this;
        const { form, loading, } = this.state;



        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.almacen_producto_id ? "Editar Producto" : "Nueva Orden"}</Title>
                <Form
                    layout="vertical"
                    ref={formOrden}
                    initialValues={form}
                    onFinish={this.onFinish}
                >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Folio"
                                name="folio"
                            >
                                <Input placeholder="000001" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cliente "
                                name="cliente_id"
                            >
                                <Select
                                    showArrow
                                    className="witdh-100"
                                    onSearch={(value) => this.getClientes(value)}
                                    showSearch
                                    filterOption={false}
                                >
                                    {this.state.clientes.map(cliente => {
                                        return <Option value={cliente._id}  >{cliente.razon_social}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Agente de Compras"
                                name="agente_compras_id"
                            >
                                <Select
                                    showArrow
                                    className="witdh-100"
                                    onSearch={(value) => this.getUsuarios(value)}
                                    showSearch
                                    filterOption={false}
                                >
                                    {this.state.usuarios.map(usuario => {
                                        return <Option value={usuario._id}  >{usuario.nombre} {usuario.apellido}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormOrdenes {...props} />
        </Modal>
    )
}
