
import React, { Component } from "react";
import { Layout, PageHeader, message, Button,Spin, Col, Row, Tabs } from "antd";
import StepTracker from "./Secciones/StepTracker";
import { CardEntrega, CardLegalInfo, CardProjectInfo, CardUtilidad } from "../../Widgets/Cards";
import { IconArrowBack, } from '../../Widgets/Iconos';
import OrdenEmbarque from "./Logistica/OrdenEmbarque";
import EntregaMercancia from "./Logistica/EntregaMercancia";
import RecepcionMercancia from "./Logistica/RecepcionMercancia";
const { Content } = Layout;
const { TabPane } = Tabs;

const axios = require('axios').default;


/**
 *
 *
 * @export
 * @class Logistica
 * @extends {Component}
 */
export default class Logistica extends Component {

    back = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            tab: 1,
            orden: {},
            ordenes_compra: [],
            orden_compra_id: undefined,
            visible: false
        }
    }

    componentDidMount = () => {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrden();
        this.loadTab();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const orden_compra_id = (prevProps.match.params.orden_compra_id !== this.props.match.params.orden_compra_id)
        const orden_id = (prevProps.match.params.orden_id !== this.props.match.params.orden_id)
        const query = new URLSearchParams(this.props.location?.search)

        let tab = this.getTab({ query })
        const tabs = (prevState.tab !== tab)

        if (orden_compra_id || tabs || orden_id) {
            this.loadTab();
        }


        if(this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id){
            this.setState({orden_id: this.props.match.params.orden_id},()=>{
                this.getOrden();
            })
        }
    }

    loadTab = () => {
        const query = new URLSearchParams(this.props.location?.search)
        let tab = this.getTab({ query })
        this.setState({
            tab
        })
    }

    getTab = ({ query, key }) => {

        const tabs = ['embarque', 'recepcion', 'entrega']
        let tab

        if (query) tab = tabs.findIndex(tab => tab === query.get('tab')) + 1 || 1
        if (key) tab = tabs[key - 1]

        return tab
    }

    getOrden = () => {
        this.setState({ loading: true })
        axios.get('/project-manager/orden', {
            params: {
                id: this.props.match.params.orden_id
            }
        }).then(response => {

            let orden    = response.data.data
            let ingresos = orden.margen?.ingresos ? orden.margen.ingresos : 0
            let costos   = orden.margen?.costos ? orden.margen?.costos : 0
            let margen   = (ingresos - costos) / ingresos * 100
            let faltante = orden.orden_detalles.find(item => item.cantidad_faltante > 0)

            this.setState({
                orden,
                completo : !faltante && orden.tipo_orden === 2 ? true : false,
                productos: orden.orden_detalles,
                margen   : (typeof margen === "number") ? margen.toMoney() : margen
            })
        })
            .catch(error => {
                console.log(error)
                message.error('Error al cargar la información')
            })
            .finally(() => this.setState({ loading: false }))
    }

    render() {


        return (
            <>
            <Spin spinning={this.state.loading}>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={
                        <Row className=" width-100 " justify="space-between" align="middle">
                            <Col span={2}>
                                <Button ghost onClick={this.back} className="no-border">
                                    <IconArrowBack />
                                </Button>
                            </Col>
                            <Col span={22}>
                                <span className="ant-page-header-heading-title" >
                                    Cadena de Suministro
                                </span>
                            </Col>
                        </Row>
                    }
                />
                <Content className="content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} lg={8} xl={6}>
                            <StepTracker orden_id={this.props.match?.params?.orden_id} />
                        </Col>
                        <Col xs={24} md={24} lg={16} xl={18}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} xl={8}>
                                    <CardProjectInfo
                                        cliente={this.state.orden?.cliente_id}
                                        orden={this.state.orden}
                                        responsable={this.state.orden?.agente_compras_id}
                                        progress={(typeof this.state.percentage === "number") ? this.state.percentage : 0}
                                    />
                                </Col>
                                <Col xs={24} lg={12} xl={8}>
                                    <Row gutter={[0, 16]}>
                                        <Col span={24}>
                                            <CardUtilidad margen={this.state.margen} />
                                        </Col>
                                        <Col span={24}>
                                            <CardLegalInfo orden_id={this.state.orden?._id} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} lg={12} xl={8}>
                                    <CardEntrega 
                                        direccion={this.state.orden?.cliente_id?.direccion}
                                        orden_id={this.state.orden?._id}
                                        fecha_entrega={this.state.orden?.fecha_entrega}
                                        update={this.getOrden}
                                        />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "1.5rem" }}>
                                <Col span={24}>
                                    <Tabs
                                        activeKey={this.state.tab?.toString()}
                                        onChange={(activeKey) => {

                                            if (Number(activeKey) === 1 && this.props.match.params.orden_compra_id) {
                                                this.props.history.push(`/admin/project-manager/logistica/${this.props.match.params.orden_id}?tab=embarque`)
                                            } else {
                                                let tab = this.getTab({ key: activeKey })
                                                this.props.history.push(`${this.props.location?.pathname}?tab=${tab}`)
                                            }

                                            this.setState({ tab: activeKey })
                                        }}
                                        destroyInactiveTabPane={true}
                                        className="tabs-inventario width-100">
                                        <TabPane tab="Orden de Embarque" key="1">
                                            <OrdenEmbarque
                                                logistica_estatus={this.state.orden?.logistica_estatus}
                                                vmi={this.state.orden?.tipo_orden === 2}
                                                {...this.props}
                                            />
                                        </TabPane>
                                        {
                                            !this.state.completo ? (
                                                <TabPane tab="Recepción de Mercancia" key="2">
                                                    <RecepcionMercancia
                                                        logistica_estatus={this.state.orden?.logistica_estatus}
                                                        vmi={this.state.orden?.tipo_orden === 2}
                                                        {...this.props}
                                                    />
                                                </TabPane>
                                            ) : null
                                        }
                                        <TabPane tab="Entrega de Mercancia" key="3">
                                            <EntregaMercancia
                                                logistica_estatus={this.state.orden?.logistica_estatus}
                                                vmi={this.state.orden?.tipo_orden === 2}
                                                {...this.props}
                                            />
                                        </TabPane>
                                    </Tabs>
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                </Content >
                </Spin>
            </>
        )
    }
}