import React, { Component } from "react";
import { Button, Col, List, Row, Typography, message, Dropdown, Menu } from "antd";

import { IconCheck } from '../../../Widgets/Iconos';
import { ImPlus } from "react-icons/im";
import { EllipsisOutlined, PaperClipOutlined } from "@ant-design/icons";
import ModalDetalleOrdenCompra from './ModalDetalleOrdenCompra'

import ModalOrdenCompra from "./ModalOrdenCompra";
import ModalProofDelivery from "./ModalProofDelivery";

const { Title, Text } = Typography

const axios = require('axios')
const moment = require('moment')
/**
 *
 *
 * @export
 * @class EntregaMercancia
 * @extends {Component}
 */
export default class EntregaMercancia extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            orden_id: this.props.match.params.orden_id,
            orden_compra_id: this.props.match.params.orden_compra_id,
            entregas: [],
            modalDetalleVisible: false,
            orden_compras_entregas: 0,
            orden_entregas: 0
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getEntregas()
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id){
            this.setState({orden_id: this.props.match.params.orden_id},()=>{
                this.getEntregas();
            })
        }
    }


    /**
     *
     *
     * @memberof EntregaMercancia
     * @method getEntregas
     * @description Obtener la lista de entregas
     */
    getEntregas = () => {
        axios.get('/ordenesCompra/recepciones', {
            params: {
                orden_compra_id: this.state.orden_compra_id,
                existencias: this.state.orden_compra_id ? false : true,
                orden_id: this.state.orden_id,
                tipo: 1
            }
        }).then(({ data }) => {
            const entregas = data.data.data
            const ordenes = entregas[0]?.orden_detalles

            let completo = false
            let orden_compras_entregas = 0
            let orden_entregas = 0
            let productos = []

            if(entregas.length > 0){
                for(const entrega of entregas){
                    if(entrega.orden_compra_id){
                        orden_compras_entregas += entrega.cantidades
                    }
                    if(entrega.orden_id){
                        orden_entregas += entrega.cantidades
                    }
                }
            }

            console.log('Cantidades: ', orden_compras_entregas, orden_entregas)

            if (entregas.length > 0 && this.state.orden_compra_id && this.props.vmi && ordenes) {
                completo = true

                for (const orden of ordenes) {
                    if ((orden_compras_entregas) >= orden.cantidad_faltante) continue
                    completo = false
                }
            } else if (entregas.length > 0 && this.state.orden_compra_id && ordenes) {
                completo = true

                console.log("Corriendo en condicion 2")

                for (const orden of ordenes) {
                    if (orden.cantidad_entregada >= orden.cantidad) continue
                    completo = false
                }
            }
            
            if(entregas.length > 0 && this.props.vmi && !this.state.orden_compra_id){
                let orden = ordenes[0]
                if((orden_entregas)>= orden.cantidad - orden.cantidad_faltante) completo = true
            } else if (entregas.length > 0 && !this.state.orden_compra_id) {
                for (const entrega of entregas) {
                    for (const orden of entrega.orden_detalles) {
                        if (productos.find(producto => producto.id === orden.producto_id)) continue

                        productos.push({
                            id: orden.producto_id,
                            completo: (orden.cantidad_entregada === orden.cantidad)
                        })
                    }
                }

                completo = true

                for (const producto of productos) {
                    if (producto.completo) continue
                    completo = false
                }
            }

            if(entregas.length > 0){
                for(const entrega of entregas){
                    if(!entrega.firma || !entrega.archivos || entrega.archivos?.length <= 0){
                        completo = false
                        console.log('no completo')
                        break
                    }
                }
            }


            this.setState({
                entregas,
                completo,
                orden_compras_entregas,
                orden_entregas
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la orden compra')
        })
    }

    /**
     *
     *
     * @memberof EntregaMercancia
     * @method finishEntregas
     * @description Actualiza el estatus de la orden de embarque o de la orden en si para finalizar las entregas
     */
    finishEntrega = () => {

        if(this.props.vmi){ 
            this.props.history.push(`/admin/project-manager/logistica/${this.state.orden_id}?tab=embarque`)
            return
        }

        axios.post('/ordenesCompra/update', {
            id: this.state.orden_compra_id,
            estatus: 6
        })
            .then(success => {
                message.success('Recepcion completa')
                this.props.history.push(`/admin/project-manager/logistica/${this.state.orden_id}?tab=embarque`)
            })
            .catch(error => {
                message.error('Error al completar recepcion')
            })
    }

    render() {
        return (
            <>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.entregas}
                    header={
                        <Row gutter={[0, 8]}>
                            <Col span={18} md={24} lg={15} xxl={{ span: 18 }} ><Title level={3}>Entrega de Mercancia </Title></Col>
                            <Col span={1} md={4} lg={2} xxl={{ span: 1 }} >
                                <Col span={1} md={4} lg={2} xxl={{ span: 1 }} >
                                    {
                                        !this.state.completo && (this.state.orden_compra_id || this.props.vmi) ? (<Button className="btn-purple" onClick={() => { this.setState({ modalVisible: true }) }} icon={<ImPlus style={{ color: '#fff' }} />} />) : null
                                    }
                                </Col>
                            </Col>
                            <Col span={5} md={20} lg={7} xxl={{ span: 5 }} >
                                {
                                    this.state.orden_compra_id || this.props.vmi ? (
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="btn-purple"
                                            disabled={!this.state.completo || this.props.logistica_estatus > 1}
                                            block
                                            onClick={this.finishEntrega} >
                                            Orden Entregada
                                        </Button>
                                    ) : null
                                }
                            </Col>
                        </Row>
                    }
                    renderItem={(entrega) => {

                        const { _id, orden_detalles, archivos, firma, folio } = entrega
                        return (
                            <List.Item className="order-list-item bg-white">
                                <Row className="width-100 text-center" align="middle" justify="space-around" >
                                    <Col span={8} lg={{ span: 6, order: 1 }} md={{ span: 8, order: 1 }} >
                                        <Text ellipsis strong style={{width: 'auto', padding: "4px 1rem"}} className="tag-card-pink"> { folio } </Text>
                                    </Col>
                                    <Col span={6} lg={{ span: 6, order: 2 }} md={{ span: 12, order: 2 }}>
                                        <Text ellipsis className="text-gray">{orden_detalles ? moment(orden_detalles[-1]?.createdAt).format('L') : 'Sin Fecha'}</Text>
                                    </Col>
                                    <Col span={2} lg={{ span: 2, order: 3 }} md={{ span: 12, order: 4 }}>
                                        <Row>
                                            <Col span={24}><Text className="text-gray">Firma</Text></Col>
                                            <Col span={24}>{
                                                firma ? <IconCheck /> : <Text className="text-gray">Pendiente</Text>
                                            }</Col>
                                        </Row>
                                    </Col>
                                    <Col 
                                        span={6} 
                                        lg={{ span: 8, order: 4 }} 
                                        md={{ span: 12, order: 5 }}
                                        style={{
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => this.setState({modalProofDelivery: true, recepcion_entrega_id: orden_detalles[0]?.recepciones?.entrega_recepcion_id})}
                                    >
                                        <Row>
                                            <Col span={24}><Text className="text-gray">Proof of Delivery</Text></Col>
                                            <Col span={24}>
                                                {
                                                    archivos?.length > 0 ? 
                                                    archivos?.map(archivo => <Button onClick={() => this.setState({modalProofDelivery: true, recepcion_entrega_id: orden_detalles[0]?.recepciones?.entrega_recepcion_id})} type="link" icon={<PaperClipOutlined />} >
                                                            {archivo} 
                                                    </Button>) : <Button onClick={() => this.setState({modalProofDelivery: true, recepcion_entrega_id: orden_detalles[0]?.recepciones?.entrega_recepcion_id})} type="link" icon={<PaperClipOutlined />} > - </Button>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={2} lg={{ span: 2, order: 5 }} md={{ span: 2, order: 3 }}>
                                        <Dropdown overlay={
                                            <Menu>
                                                <Menu.Item onClick={() => this.setState({ modalDetalleVisible: true, recepcion_entrega_id: orden_detalles[0]?.recepciones?.entrega_recepcion_id })}>
                                                    Ver Detalle
                                                </Menu.Item>
                                            </Menu>
                                        } placement="bottomRight"
                                            trigger={['click']}
                                        >
                                            <Button
                                                icon={<EllipsisOutlined />}
                                                type="ghost"
                                                className="no-border"
                                            >
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </List.Item>
                        )
                    }
                    }
                />

                <ModalOrdenCompra
                    visible={this.state.modalVisible}
                    orden_id={this.state.orden_id}
                    orden_compra_id={this.state.orden_compra_id}
                    orden_compras_entregas = {this.state.orden_compras_entregas}
                    orden_entregas = {this.state.orden_entregas}
                    entrega
                    vmi = {this.props.vmi}
                    onSuccess={() => {
                        this.setState({
                            modalVisible: false
                        }, () => this.getEntregas())
                    }}
                    onCancel={() => {
                        this.setState({
                            modalVisible: false
                        })
                    }}
                />
                <ModalDetalleOrdenCompra
                    entrega
                    visible={this.state.modalDetalleVisible}
                    recepcion_entrega_id={this.state.recepcion_entrega_id}
                    onSuccess={() => this.getEntregas()}
                    onClose={() => this.setState({ modalDetalleVisible: false, entrega: undefined })}
                />
                <ModalProofDelivery
                    visible={this.state.modalProofDelivery}
                    orden_compras_entregas={this.state.orden_compras_entregas}
                    orden_entregas={this.state.orden_entregas}
                    recepcion_entrega_id={this.state.recepcion_entrega_id}
                    onSuccess={()=> {
                        this.getEntregas()
                        this.setState({modalProofDelivery: false, recepcion_entrega_id: undefined})
                    }}
                    onCancel={()=>this.setState({modalProofDelivery: false, recepcion_entrega_id: undefined})}
                />
            </>
        )
    }
}