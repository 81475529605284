import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography,  Form, Input, message, Spin } from 'antd';

const { Title } = Typography;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalAlmacen
 * @extends {Component}
 */
class ModalAlmacen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            clientes: [],
        }
    }

    ModalAlmacen = React.createRef();

    componentDidMount() {

        if(this.props.almacen_id){
            this.getAlmacen()
        }
    }

    /**
     * @memberof ModalAlmacen
     * @method getAlmacen
     * @description Obtiene un cliente de la DB
     */
    getAlmacen = () => {
        axios.get('/almacenes/get', {
            params: {
                id: this.props.almacen_id
            }
        }).then(response => {
            console.log(response.data.data)
            this.ModalAlmacen.current.setFieldsValue({
                direccion: response.data.data.direccion,
            })

        }).catch(error => {
            message.error('Error al traer almacen')
            console.log(error)
        })
    }


    /**
     * @memberof ModalAlmacen
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.almacen_id) {
            this.updateAlmacen(values)
        } else {
            this.addAlmacen(values)
        }
    }


    /**
     * @memberof ModalAlmacen
     * @method addAlmacen
     * @description Añade un cliente a la BD
     */
    addAlmacen = (values) => {
        console.log(values)
        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/almacenes/add', {
                inventario_id: this.props.inventario_id,
                ...values
            }).then(response => {
                message.success('Almacen Añadido')
                this.props.onSuccess()
                this.props.onClose()
            }).catch(error => {
                console.log(error)
                message.error('Error al crear el almacen.')
            }).finally(() => this.setState({ loading: false }))
        })
        
    }



    /**
     * @memberof ModalAlmacen
     * @method updateAlmacen
     * @description Actualiza la información de un cliente
     */
    updateAlmacen = (values) => {

        this.setState({ loading: true })
        axios.post('/almacenes/update', {
            id: this.props.almacen_id,
            ...values
        }).then(response => {

            message.success("Almacen Actualizado")
            this.props.onSuccess()
            this.props.onClose()

        }).catch(error => {
            message.error('Error al actualizar almacen')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    render() {


        return (
            <Form layout="vertical" ref={this.ModalAlmacen} onFinish={this.onFinish} >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Dirección"
                                name="direccion"
                            >
                                <Input.TextArea placeholder="Dirección"></Input.TextArea>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Almacenes </Title>
        </div>
        <ModalAlmacen {...props} />
    </Modal>

}