import React, { Component, useContext } from 'react'
import { Row, Col, Form, Input, Button, Card, Spin, Divider, Typography, Checkbox, Alert } from 'antd';
import { Redirect, Link } from "react-router-dom";


import Cookies from "universal-cookie";

import { User, SetUser } from '../../Hooks/Logged';
import '../../Styles/Global/auth.css';
import Recovery from './Recovery';

const axios = require("axios").default;
const { Text } = Typography



/**
 *
 *
 * @class Login
 * @extends {Component}
 * @description Vista
 */
class Login extends Component {

    script = React.createRef();

    redirectLink = "/admin/dashboard";


    /**
     *Creates an instance of Login.
     * @param {*} props
     * @memberof Login
     */
    constructor(props) {
        super(props);
        this.state = {
            log: false,
            loading: false,
            error: {
                success: null,
                message: null
            },
            modalLoginVisible: false,
            statusLoading: 0,
            type: this.props.type,
        }
    }




    /**
     * @memberof Login
     *
     * @method handleSubmit
     * @description  Envia los datos del formulario al Servidor
     *
     * @param values (array)
     * Contiene los campos del formulario para registrar al usuario
     *
     * @returns response (array)
     **/
    handleSubmit = (values) => {
        this.setState({ loading: true })
        axios.post('/login', {
            email: values.email,
            password: values.password
        })
            .then(({ data, headers }) => {
                const { setUser } = this.props;

                console.log('values.recuerdame', values)
                if (values.recuerdame === true) {
                    console.log('COOOKIES ')
                    const cookies = new Cookies()
                    cookies.set('token', headers.authorization, { path: '/', maxAge: 60 * 60 * 24 })
                } else {
                    console.log(' NO COOOKIES ')
                }

                sessionStorage.setItem('token', headers.authorization);
                axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
                setUser(data.user);
                if (data.user.tipo === 4) {
                    this.redirectTo('/customer/misPedidos');
                } else {
                    this.redirectTo('/admin/dashboard');
                }
                
                
            })
            .catch(({ response }) => {
                console.log('error', response)
                this.setState({ error: response?.data })
            })
            .finally(f => {
                this.setState({ loading: false });
            })
    };


    /**
     * @memberof Login
     *
     * @method renderRedirect
     * @description  Activa el redireccionamiento si el formulario se envio con exito
     *
     **/
    renderRedirect = () => {
        if (this.state.log)
            return <Redirect to={this.redirectLink} />
    };


    /**
     * @memberof Login
     *
     * @method redirectTo
     * @description  Redirecciona a cierto link.
     *
     **/
    redirectTo = (to) => {
        this.redirectLink = to;
        this.setState({ log: true });
    };

    showModal = () => {
        this.setState({
            modalLoginVisible: true
        })
    }

    hideModal = () => {
        this.setState({
            modalLoginVisible: false
        })
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                {this.renderRedirect()}
                <Row style={{ height: 'calc(100vh - 203.8px)', background: "url('images/bg-1.jpg')", backgroundSize: "cover", overflow: 'auto' }} align='middle' justify='center'>
                    <Col span={24} flex="auto">
                        <Row justify="center" gutter={[0, 0]} align="middle" >
                            <Col xs={{ span: 20, offset: 2, pull: 1 }} sm={{ span: 24, offset: 0, pull: 0 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 6 }} >
                                {this.state.error?.success == false ?
                                    <Alert
                                        message="¡Ha ocurrido un error!"
                                        description={this.state.error.message}
                                        type="error"
                                        closable
                                        className="pd-1 mb-1"
                                        onClose={() => this.setState({ error: { success: null, description: null } })}
                                    />
                                    : null}
                                <Card className='pd-2 login'>
                                    <h3 className="h3-title">Iniciar Sesión</h3>
                                    <Form onFinish={this.handleSubmit} layout={"vertical"} requiredMark={false}>
                                        <Form.Item name="email" label="Email"
                                            rules={[
                                                { required: true, message: 'Por favor ingresa tu correo' }
                                            ]}>
                                            <Input placeholder="E-mail" size="large" className="input-login" />
                                        </Form.Item>
                                        <Form.Item label="Contraseña" name="password" rules={[{ required: true, message: 'Por favor ingresa tu contraseña' }]}

                                            extra={
                                                <Form.Item name="recuerdame" valuePropName='checked' noStyle >
                                                    <Checkbox  style={{ marginTop: "12px" }}>Mantener la sesión iniciada</Checkbox>
                                                </Form.Item>
                                            }
                                        >
                                            <Input.Password placeholder="Contraseña" size="large" className="input-login" />
                                        </Form.Item>
                                        <a onClick={this.showModal}> Olvide mi contraseña </a>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" block size="large" className="button-login" >Iniciar sesión </Button>
                                        </Form.Item>
                                        <Divider />
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Recovery
                    visible={this.state.modalLoginVisible}
                    hideModal={this.hideModal}
                />
            </Spin>
        )
    }
}



export default function (props) {

    let user = useContext(User)
    const setUser = useContext(SetUser)

    return <Login {...props} user={user} setUser={setUser} />
}