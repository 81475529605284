import React, { Component, createRef } from "react";
import { Layout, Typography, Row, Col, Divider} from 'antd';
import { Link } from 'react-router-dom'
import '../../Styles/Global/terminos.scss'
import '../../Styles/Global/footer.scss'
import 'iseeyoutech-widget-web/dist/style.css'
import { Footer } from "antd/lib/layout/layout";

const { Content } = Layout
const { Title, Paragraph, Text } = Typography
/**
 *
 *
 * @export
 * @class Terminos
 * @extends {Component}
 * @description Pagina principal del sistema
 */ 
class Terminos extends Component {

    constructor(props){
        super(props)
        
    }

    render() {
        return (
            <Layout className="terminos-page">
                <Content className="terminos-main">
                    <Row gutter={[30, 30]}>
                        <Col xs={24} className="terminos-content">
                            <Title level={1}>Terminos y Condiciones</Title>
                            <Paragraph>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed iaculis sapien justo, in faucibus mi pulvinar in. Sed lobortis iaculis magna et pellentesque. Vivamus imperdiet mollis scelerisque. Nullam orci ligula, suscipit ut neque quis, bibendum gravida urna. Nullam vehicula lacus vitae tortor gravida commodo. Nam auctor vehicula turpis, eget sodales mauris pellentesque vel. Morbi diam diam, malesuada vitae justo ac, tincidunt feugiat tellus. Duis ac tellus aliquam, sagittis dolor quis, faucibus dolor.
                            </Paragraph>
                            <Paragraph>
                            Mauris sollicitudin magna tellus, id aliquam velit condimentum accumsan. Ut pharetra gravida justo sit amet molestie. Pellentesque elementum elementum neque, non interdum magna consectetur vel. Quisque pulvinar venenatis lacus sed vulputate. Duis volutpat, dui vitae tempor ornare, mi sem euismod felis, at commodo massa enim eget mi. Etiam ac consectetur ante. Vivamus nec dolor imperdiet, consectetur mi sit amet, posuere arcu. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam accumsan ante nec cursus imperdiet. Ut fermentum et enim id rutrum. In venenatis mi quis nulla suscipit, eget interdum dolor consectetur.
                            </Paragraph>
                            <Paragraph>
                            Suspendisse potenti. Vivamus turpis sapien, ultricies eget ultrices nec, bibendum ac augue. Phasellus eu risus lobortis, ultrices velit sit amet, viverra orci. Quisque ultricies et sapien nec accumsan. Donec cursus maximus arcu, egestas ultrices eros tempus sit amet. Aenean cursus molestie nisl a ultricies. Nulla in velit convallis, tempus dui eu, faucibus est.
                            </Paragraph>
                            <Paragraph>
                            Cras ut molestie nisi. Aenean quis velit finibus, vestibulum lorem eu, vehicula mauris. Fusce dui tellus, facilisis tincidunt justo ut, porttitor posuere felis. Donec pharetra urna vel hendrerit tempor. Duis sem neque, laoreet eget sagittis sit amet, sodales nec ante. Morbi sem quam, pellentesque quis urna in, vestibulum commodo orci. Pellentesque placerat id nibh sed porttitor. Morbi vel lorem ut odio venenatis placerat nec in dui. Curabitur bibendum quam eget lacus tempor faucibus. Aliquam at dictum turpis. Vivamus finibus, lorem vitae aliquet tempus, risus tortor faucibus felis, eu ultricies ante metus id nisi.
                            </Paragraph>
                        </Col>
                    </Row>
                </Content>
                <Footer
                    className="footer"
                >   
                    <Row className="footer-wrapper">
                        <Col xs={24} md={8} className="footer-about">
                            <img src="/images/nexus-footer.svg" className="footer-logo"/>
                            <Paragraph className="footer-descripcion">Traemos lo que necesitas de cualquier parte del mundo y nos encargamos de todos los detalles.</Paragraph>
                        </Col>
                        <Col xs={24} md={8} className="footer-directorio">
                            <Title level={3}>Directorio</Title>
                            <nav>
                                <Link to="/">Inicio</Link>
                                {/*<Link to="#">Preguntas Frecuentes</Link>*/}
                                <Link to="/terminos">Terminos y Condiciones</Link>
                                <Link to="/login">Iniciar Sesión</Link>
                            </nav>
                        </Col>
                        <Col xs={24} md={8} className="footer-sucursales">
                            <Title level={3}>Sucursales</Title>
                            <div className="footer-listado">
                                <Paragraph className="sucursal">
                                    <strong> Nexus Nova Aguascalientes </strong> <br/>
                                    San Emilion 304, 20310 Viñedos San Felipe, Ags. <br/>
                                    +524494253166
                                </Paragraph>
                                
                                <Paragraph className="sucursal">
                                    <strong> Nexus Nova Guadalajara </strong> <br/>
                                    Justicia 91, Colonia Esperanza, CP 44300 Guadalajara, Jalisco <br/>
                                    +524492041003
                                </Paragraph>
                                <Paragraph className="sucursal">
                                    <strong> Nexus Nova Guanajuato </strong> <br/>
                                    Boulevard Brisas de Sarandi #304 Interior manzana 4 bodega #146 Colonia brisas del Campestre CP 37669 León Guanajuato México <br/>
                                    +524792325305
                                </Paragraph>
                            </div>
                        </Col>
                        <Col span={24}><Divider/></Col>
                        <Col span={24}>
                            <Row justify={"space-between"} align={"middle"}>
                                <Text>@2023 Copyright ISEEYOUTECHNOLOGGIES</Text>
                                <Text>Nexus Nova Todos los derechos Reservados </Text>
                            </Row>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        )
    }

}

export default (props) => {

    return <Terminos {...props}/>
}