

import React, { useState, useEffect, } from "react";
import { Link } from "react-router-dom";
import { Steps, Card, message, List, Typography, Tabs } from "antd";
import '../../../../Styles/Modules/ProjectManager/StepTracker.css';

import { FaCheckCircle, FaTimesCircle, FaClock, FaRegCircle } from 'react-icons/fa';

import { LoadingOutlined } from '@ant-design/icons';
import ChatCustomer from "../../../Customer/MisPedidos/ChatCustomer";
import axios from "axios";
import socket from "../../../../Hooks/Socket";

const { Step } = Steps;
const { TabPane } = Tabs


/**
 *
 *
 * @const StepTracker
 * @extends {Component}
 * @description StepTracker del Project Manager
 */
const stepTracker = React.forwardRef((props, ref) => {

    let { orden_id, orden_compra_id } = props

    /** @type Number Step actual activo */
    const [loading, setLoading] = useState(false);

    /** @type {*} Información de la orden */
    const [orden, setOrden] = useState({});
    const [ordenDetalles, setOrdenDetalles] = useState({});



    /**
     *
     * @const StepTracker
     * @description Actualiza el StepTracker segun la orden cargada
     */
    useEffect(() => {
        getOrden()



        socket.on("actualización", () => getOrden())


    }, [orden_id])


    const getOrden = () => {
        setLoading(true)
        axios.get('/ordenes/get', {
            params: {
                id: orden_id,
                detalles: true
            }
        })
            .then(({ data }) => {
                setOrden(data.data)
                setOrdenDetalles(data.detalles)
            })
            .catch((error) => {
                console.log(error.request)
                message.error("No fue posible obtener la orden")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const renderStatus = (key = 1) => {

        const style = {
            fontSize: 25,
            position: 'relative',
            bottom: 3
        }

        if (typeof key === "boolean")
            return (key) ? <FaCheckCircle style={style} className="step-icon" /> : <FaRegCircle style={{ ...style, color: "#2C3E50" }} />//circulo sin relleno 

        switch (key) {
            case 0:
                //Non started
                return <FaRegCircle style={{ ...style, color: "#2C3E50" }} />//circulo sin relleno

            case 1:
                //Success
                return <FaCheckCircle style={style} className="step-icon" />//check

            case 2:
                //In progress
                return <FaClock style={{ ...style, color: "#FFC107" }} />//reloj


            case 3:
                //Error
                return <FaTimesCircle style={{ ...style, color: "#DC3545" }} />//X

            case 4:
                //LOADING
                return <LoadingOutlined style={style} spin />

        }
    }

    const getSteps = () => {
        let steps = []

        if (orden.tipo_orden === 1) {
            steps = [
                {
                    title: "Cotización",
                    steps: [
                        {
                            check: () => !!(orden.rfq_id),
                            task: 'Formulario de Cliente',
                        },
                        {
                            check: () => {
                                if (orden.cotizacion_id) return 1
                                if (orden.rfq_id && !orden.cotizacion_id) return 2
                                return 0
                            },
                            task: <Link to={`/admin/project-manager/supplychain/${orden_id}`}>Listado de Productos</Link>,
                        },
                        {
                            check: () => {
                                //Si está la orden matriz, evaluamos el status
                                if (orden.matriz_id)
                                    switch (orden.matriz_id.status) {
                                        //Si es 1, no se ha iniciado
                                        case 1: return 0;
                                        //Si es 2, se inició
                                        case 2: return 2;
                                        //Si es 3, se completo
                                        case 3: return 1;
                                    }
                                else if ((orden.cotizacion_id && !orden.matriz_id)) return 0
                                else return 0
                            },
                            task: <Link className={!((orden.matriz_id && orden.matriz_id.status != 1) || (orden.cotizacion_id && !orden.matriz_id)) ? "disabled-link" : null} to={`/admin/project-manager/matriz-costos/${orden_id}`}>Matriz de Costos</Link>,
                        },
                        {
                            //Si el estus es menor a 3, significa que no se enviado, entonces este paso no sucede
                            check: () => (orden.cotizacion_id?.status !== undefined && orden.cotizacion_id?.status >= 2),
                            task: <Link className={!(orden.cotizacion_id?.status !== undefined && orden.cotizacion_id?.status >= 2) ? "disabled-link" : null} to={`/admin/project-manager/matriz-costos/${orden_id}`}>Envio de Cotización Final</Link>,
                        },
                        {
                            check: () => {
                                if (orden.cotizacion_id?.status) {
                                    switch (orden.cotizacion_id?.status) {
                                        //Si es 3, se envió (está en espera)
                                        case 1: return 0;
                                        //Si es 4, aceptada
                                        case 2: return 2;
                                        //Si es 5, rechazada
                                        case 3: return 1;

                                        case 4: return 3;

                                        case 5: return 0;

                                    }
                                }
                                return 0
                            },
                            task: <Link
                                className={(orden.cotizacion_id?.status !== undefined && orden.cotizacion_id?.status >= 2) ? "disabled-link" : null}
                                to={`/admin/project-manager/matriz-costos/${orden_id}`}>{
                                    function () {
                                        switch (orden.cotizacion_id?.status) {
                                            //Si es 3, se envió (está en espera)
                                            case 1: return "Sin enviar";
                                            //Si es 4, aceptada
                                            case 2: return "En espera de respuesta";
                                            //Si es 5, rechazada
                                            case 3: return "Aceptada";

                                            case 4: return "Rechazada";

                                            case 5: return "Recotización";

                                            default: return " - "
                                        }
                                    }()
                                }</Link>,
                        }

                    ],
                    className: "step-color-blue",
                },
                {
                    title: "Compras",
                    steps: [
                        {
                            check: e => (orden.pago_recibido),
                            task: <Link className={!(orden.pago_recibido) ? "disabled-link" : null} to={`/admin/project-manager/cuenta-ventas/${orden_id}?tab=ingresos`} >Pago del Cliente</Link>,
                        },
                        {
                            check: e => (orden.pago_recibido) || orden.orden_compras,
                            task: <Link className={!orden.orden_compras ? "disabled-link" : null} to={`/admin/project-manager/cuenta-ventas/${orden_id}?tab=compras`}>Ordenes de Compra</Link>,
                        },
                        {
                            check: e => (orden.montos?.costo_total > 0) && orden.montos?.costo_total === orden.montos?.monto_pagado_total,
                            task: <Link className={true ? "disabled-link" : null} to={`/admin/project-manager/cuenta-ventas/${orden_id}`}>Pago de Ordenes de Compra</Link>,
                        },
                    ],
                    className: "step-color-yellow",
                },
                {
                    title: "Logística",
                    steps: [
                        {
                            check: e => {
                                if (orden.logistica_estatus > 2) return 1
                                if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                                return 0
                            },
                            task: <Link className={!orden.orden_compras ? "disabled-link" : null} to={`/admin/project-manager/logistica/${orden_id}?tab=embarque`}>Orden de Embarque</Link>,
                        },
                        {
                            check: e => {
                                if (orden.logistica_estatus >= 1) return 1
                                if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                                return 0
                            },
                            task: <Link className={!orden.orden_compras ? "disabled-link" : null} to={`/admin/project-manager/logistica/${orden_id}?tab=recepcion`}>Recepción de Mercancia</Link>,
                        },
                        {
                            check: e => {
                                if (orden.logistica_estatus >= 2) return 1
                                if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                                return 0
                            },
                            task: <Link className={!orden.orden_compras ? "disabled-link" : null} to={`/admin/project-manager/logistica/${orden_id}?tab=entrega`}>Entrega de Mercancia</Link>,
                        }
                    ],
                    className: "step-color-pink",
                },
                {
                    title: "Orden Completa",
                    className: "step-color-green",
                },
            ]
        }

        if (orden.tipo_orden === 2) {
            if (ordenDetalles.cantidad_faltante > 0) {
                steps.push({
                    title: "Cotización",
                    steps: [
                        {
                            check: () => 1,
                            task: <Link to={`/admin/project-manager/matriz-costos/${orden_id}`}>Matriz de Costos</Link>,
                        }
                    ],
                    className: "step-color-blue",
                })
            }

            const compras = [
                (ordenDetalles.cantidad_faltante > 0 ? {
                    check: e => orden.orden_compras,
                    task: <Link className={!orden.orden_compras ? "disabled-link" : null} to={`/admin/project-manager/cuenta-ventas/${orden_id}?tab=compras`}>Ordenes de Compra</Link>,
                } : null),
                {
                    check: e => ordenDetalles.cantidad_faltante <= 0 || (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total),
                    task: <Link to={`/admin/project-manager/cuenta-ventas/${orden_id}`}>Pago de Ordenes de Compra</Link>,
                }
            ]

            steps.push({
                title: "Compras",
                steps: compras.filter(step => step !== null),
                className: "step-color-yellow",
            })

            const logistica = [
                {
                    check: e => {
                        if (orden.logistica_estatus > 2) return 1
                        if (ordenDetalles.cantidad_faltante <= 0 || (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total)) return 2
                        return 0
                    },
                    task: <Link to={`/admin/project-manager/logistica/${orden_id}?tab=embarque`}>Orden de Embarque</Link>,
                },
                (ordenDetalles.cantidad_faltante > 0 ? {
                    check: e => {
                        if (orden.logistica_estatus >= 1) return 1
                        if (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total) return 2
                        return 0
                    },
                    task: <Link to={`/admin/project-manager/logistica/${orden_id}?tab=recepcion`}>Recepción de Mercancia</Link>,
                } : null),
                {
                    check: e => {
                        if (orden.logistica_estatus >= 2) return 1
                        if (ordenDetalles.cantidad_faltante <= 0 || (orden.montos?.costo_total > 0 && orden.montos?.costo_total === orden.montos?.monto_pagado_total)) return 2
                        return 0
                    },
                    task: <Link to={`/admin/project-manager/logistica/${orden_id}?tab=entrega`}>Entrega de Mercancia</Link>,
                }
            ]

            steps.push({
                title: "Logistica",
                steps: logistica.filter(step => step !== null),
                className: "step-color-pink",
            })

            steps.push({
                title: "Orden Completa",
                className: "step-color-green",
            })
        }

        return steps
    }

    const renderList = (list = []) => <List
        itemLayout="horizontal"
        dataSource={list}
        bordered={false}
        className="width-100 card-task-list list-border-none"
        locale={{ emptyText: ' ' }}
        renderItem={({ check, task }) => (
            <List.Item >
                <List.Item.Meta
                    className="card-task-list-meta"
                    avatar={renderStatus((loading === true) ? 4 : check())}
                    title={task}
                />
            </List.Item>
        )}
    />

    React.useImperativeHandle(ref, () => ({ getOrden }));

    return <Card className="card-shadow step-card" >
        <Tabs defaultActiveKey="1">
            <TabPane tab="Project Tracker" key="1">
                <Steps direction="vertical" className="step-tracker">
                    {getSteps().map(({ title, steps, className }, index) => <Step
                        key={'step-' + index}
                        title={title}
                        description={renderList(steps)}
                        className={className}
                    />)}
                </Steps>
            </TabPane>
            <TabPane tab="Chat" key="2">
                <ChatCustomer orden_id={orden._id} orden={orden} />
            </TabPane>
        </Tabs>

    </Card>
})


export default stepTracker