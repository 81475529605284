import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, DatePicker, InputNumber, Select, message, Spin, Checkbox } from 'antd';


const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;
const moment = require('moment');

class ModalClasificarTransacciones extends Component {


    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            areas: [],
            rubros: [],
            clientes: [],
            pageCliente: 1,
            cuentas: [],
            tags: [],
            ordenes: [],
            razones: [],
            ordenes_compra: [],

            proveedores: [],
            pageProveedor: 1,
            tipo_cambio: 0,
            cuentaSeleccionada: '',
            countSeleccionadas: 0,

            cliente_id: undefined,
            orden_id: undefined,
            orden_compra_id: undefined
        }
    }

    ModalTransacciones = React.createRef();

    /**
    * @memberof ModalClasificarTransacciones
    * @method   componentDidMount
    * @description  Al montar el componente:
    * Se inicializa header de axios
    **/
    componentDidMount() {

        this.getClientes();
        this.getOrdenes();
        this.getProveedores();
        this.getTipoCambio();
        this.setState({ countSeleccionadas: Object.keys(this.props.seleccionadas).length })

    }

    /**
    * @memberof ModalTransacciones
    * @method getOrdenes
    * @description Trae los negocios
    * 
    */
    getProveedores = (search, add) => {
        axios.get('/proveedores/list', {
            params: {
                search,
                page: this.state.pageProveedor
            }
        }).then(response => {
            console.log(response.data)
            let proveedores = response.data.data.itemsList

            if(add){
                proveedores = this.state.proveedores.concat(proveedores)
            }

            this.setState({ proveedores })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Negocios')
        })
    }

    /**
    * @memberof ModalTransacciones
    * @method getOrdenes
    * @description Trae los negocios
    * 
    */
    getOrdenes = (search, add) => {
        axios.get('/ordenes', {
            params: {
                search,
                limit: 20,
                cliente_id: this.state.cliente_id,
                page: this.state.pageOrdenes
            }
        }).then(response => {

            let ordenes = response.data.data.itemsList

            if(add){
                ordenes = this.state.ordenes.concat(ordenes)
            }

            this.setState({ ordenes })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Ordenes')
        })
    }

    /**
    * @memberof ModalTransacciones
    * @method getOrdenesCompra
    * @description Trae los negocios
    * 
    */
    getOrdenesCompra = (search) => {
        axios.get('/ordenesCompra/list', {
            params: {
                search,
                limit: 50,
                orden_id: this.state.orden_id
            }
        }).then(response => {
            this.setState({ ordenes_compra: response.data.data.itemsList })
        }).catch(error => {
            console.log(error.response)
            let msg = "Error al traer los Ordenes de Compra"
            if(error.response?.status === 403){
                msg = "No tienes permisos para Ordenes de Compra"
            }
            message.error(msg)
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method getClientes
     * @description Trae los negocios
     * 
     */
    getClientes = (search, add) => {
        return axios.get('/clientes/list', {
            params: {
                search,
                limit: 10,
                page: this.state.pageCliente,
                orden_id: this.state.orden_id,
            }
        }).then(response => {

            let clientes = response.data.data.itemsList

            if(add){
                clientes = this.state.clientes.concat(clientes);
            }

            this.setState({
                clientes: clientes
            })

            console.log(clientes)
            if(clientes.length === 1){
                this.ModalTransacciones.current?.setFieldsValue({
                    cliente_id: clientes[0]._id
                })
                this.setState({cliente_id: clientes[0]._id})
                this.getOrdenes()
            }

        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Clientes')
        })
    }


    /**
    * @memberof ModalTransacciones
    * @method getAreas
    * @description Trae las areasm las pone en el formato adecuado para el cascader
    * 
    */
    getAreas = (search) => {
        return axios.get('/areas/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {
            this.setState({
                areas: response.data.data.map(area => ({
                    value: area._id, label: area.nombre, isLeaf: false
                }))
            })
        }).catch(error => {
            console.log(error)
        })
    }

    /**
    * @memberof ModalTransacciones
    * @method getTipoCambio
    * @description Trae el ulitmo tipo de cambio utilizado 
    * 
    */
    getTipoCambio = () => {
        axios.get('/transacciones/tipo-cambio').then(response => {
            this.ModalTransacciones.current?.setFieldsValue({
                tipo_cambio: response.data.data.toMoney()
            })

        }).catch(error => {
            console.log(error);
            message.error('Error al traer tipo de cambio')
        })
    }

    /**
     * @memberof ModalTransacciones
     * @method onChangeTipo
     * @description Hace la conversion de pesos a dolares para actualizar el campo de dolares, usando el nuevo tipo de cambio
     */
    onChangeTipo = (value) => {
        this.setState({ tipo_cambio: value?.toMoney() })
    }

    /**
     * @memberof ModalTransacciones
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     * 
     */
    /**
     * @memberof ModalClasificarTransacciones
     * @method onFinish
     * @description Obtiene la informacion de los proyectos registradas en la base de datos
     *
     */
    onFinish = (values) => {
        if (this.state.loading === true) return
        this.state.loading = true


        this.setState({ loading: true })
        const { seleccionadas } = this.props;


        console.log('values', values)

        axios.post('/syncfy/facturas/add', {
            proveedor_id: values.proveedor_id,
            orden_id: values.orden_id,
            cliente_id: values.cliente_id,
            orden_compra_id: values.orden_compra_id,
            credito: values.credito,
            fecha_vigencia: values.fecha_vigencia,
            razon_social_id: this.props.razon_social_id,
            tipo_cambio: values.tipo_cambio.toMoney(),
            facturas: Object.values(seleccionadas)
        })
            .then(({ data }) => {
                this.setState({ loading: false })
                this.props.onClose()
                this.props.onClear()
            })
            .catch(() => {
                message.error("No fue posible guardar, por favor contacte a soporte.")
            }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof ModalClasificarTransacciones
    *
    * @method   onPopupScrollCliente
    * @description  Carga mas clientes al hacer scroll
    *
    **/
    onPopupScrollCliente = event => {
        if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)){
            this.setState({pageCliente: this.state.pageCliente + 1},()=>this.getClientes(null, true))
        }
    }

    /**
    * @memberof ModalClasificarTransacciones
    *
    * @method   onPopupScrollProveedores
    * @description  Carga mas proveedores al hacer scroll
    *
    **/
    onPopupScrollProveedores = event => {
        if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)){
            this.setState({pageProveedor: this.state.pageProveedor + 1},()=>this.getProveedores(null, true))
        }
    }

    /**
    * @memberof ModalClasificarTransacciones
    *
    * @method   onPopupScrollOrdenes
    * @description  Carga mas proveedores al hacer scroll
    *
    **/
    onPopupScrollOrdenes = event => {
        if ((event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight)){
            this.setState({pageOrdenes: this.state.pageOrdenes + 1},()=>this.getOrdenes(null, true))
        }
    }

    /**
    * @memberof ModalClasificarTransacciones
    *
    * @method   selectOptions
    * @description  Metodo que reciebe un array de elementos para devolver un array de opciones para un select,
    * ademas de agrear al arreglo el elemento a editar
    *
    **/
    selectOptions = (array, addElemnt) => {

        let arr = [];
        if (array) {
            array.forEach(function ({ _id, nombre, apellido,razon_social, folio }, index) {
                arr.push(<Option value={_id}>{nombre} {apellido} {razon_social} {folio}</Option>)
            })
        }

        if (array && addElemnt) {
            if (!array.some(element => element._id === addElemnt._id)) {
                arr.push(<Option value={addElemnt._id}> {addElemnt.nombre}{addElemnt.razon_social} {addElemnt.folio}</Option>)
            }
        }
        return arr
    }


    render() {


        return (
            <Form
                layout="vertical"
                name="form-materiales"
                ref={this.ModalTransacciones}
                onFinish={this.onFinish}
                initialValues={{
                    fecha: moment(),
                    tipo_cambio: this.state.tipo_cambio
                }}
            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cliente"
                                name="cliente_id"
                                rules={[{
                                    required: false,
                                    message: "Por favor, seleccione el Cliente"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione el Cliente"
                                    onSearch={(value) => {
                                        if(value.length > 0){
                                            this.setState({pageCliente: 1},()=>{
                                                this.getClientes(value)
                                            })
                                        } 
                                    }}
                                    onChange={(value) => {
                                        this.setState({cliente_id: value, pageOrdenes: 1}, () => {
                                            this.ModalTransacciones.current?.setFieldsValue({
                                                folio: null,
                                                orden_id: null,
                                                orden_compra_id: null
                                            })
                                            this.getOrdenes()
                                        })
                                    }}
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                    onPopupScroll={this.onPopupScrollCliente}
                                    onClear={()=>{
                                        this.ModalTransacciones.current?.setFieldsValue({
                                            cliente_id: undefined,
                                            orden_id: undefined,
                                            orden_compra_id: undefined
                                        })
                                        this.setState({
                                            pageCliente: 1,
                                            orden_id: undefined,
                                            pageOrdenes: 1
                                        },()=>{
                                            this.getClientes()
                                            this.getOrdenes()
                                        })
                                    }}
                                >
                                    {this.selectOptions(this.state.clientes)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Ordenes"
                                name="orden_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la Orden"
                                }]}
                            >
                                <Select
                                    //disabled={!this.state.cliente_id}
                                    placeholder="Seleccione la Orden"
                                    onSearch={(value) => {
                                        if(value.length > 0) this.setState({pageOrdenes: 1},()=>this.getOrdenes(value))
                                    }}
                                    onChange={(value) => {
                                        this.setState({orden_id: value}, () => {
                                            this.ModalTransacciones.current?.setFieldsValue({
                                                folio: null,
                                                orden_compra_id: null
                                            })
                                            this.getOrdenesCompra()

                                            this.setState({pageCliente: 1},()=>{
                                                this.getClientes()
                                            })
                                        })
                                    }}
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                    onClear={()=>{
                                        this.setState({pageOrdenes: 1},()=>this.getOrdenes())
                                    }}
                                >
                                    {this.state.ordenes.map(orden => {
                                        return <Option value={orden._id}>{orden.folio}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Ordenes de Compra"
                                name="orden_compra_id"
                            >
                                <Select
                                    disabled = {!this.state.orden_id}
                                    placeholder="Seleccione la Orden de Compra"
                                    onSearch={(value) => {
                                        if(value.length > 0) this.getOrdenesCompra(value)
                                    }}
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                >
                                    {this.state.ordenes_compra.map(orden => {
                                        return <Option value={orden._id}>{orden.folio}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[{
                                    required: false,
                                    message: "Por favor, seleccione el Proveedor"
                                }]}
                            >
                                <Select
                                    placeholder="Seleccione el Proveedor"
                                    onSearch={(value) => this.getProveedores(value)}
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                    onPopupScroll={this.onPopupScrollProveedores}
                                >
                                    {this.state.proveedores.map(negocio => {
                                        return <Option value={negocio._id}>{negocio.nombre_comercial}</Option>
                                    })}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                valuePropName="checked"
                                label="Linea de Credito"
                                name="credito"
                            >
                                <Checkbox onChange={e => this.setState({ uso_credito: e.target.checked })}></Checkbox>
                            </Form.Item>
                        </Col>
                        {!this.state.uso_credito ? <Col  xs={24} lg={12}>
                            <Form.Item
                            label="Fecha de Vencimiento"
                            name="fecha_vigencia" //<--------------------------------------- fecha_vigencia
                            rules={[{
                                required: true,
                                message: "Por favor, ingrese la fecha de monto"
                            }]}
                            style={{ width: '100%' }}
                        >
                            <DatePicker style={{ width: '100%' }} />
                        </Form.Item>
                        </Col> : null}
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Tipo Cambio"
                                name="tipo_cambio"

                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese monto"
                                }]}
                            >
                                <InputNumber min={1} defaultValue={0} className="width-100" onChange={this.onChangeTipo} />
                            </Form.Item>
                        </Col>
                    </Row>


                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>

        )
    }
}




export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>Clasificar Facturas</Title>
        </div>
        <ModalClasificarTransacciones {...props} />
    </Modal>

}



