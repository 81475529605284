import React, { Component } from "react";

import {
    Layout, Row, Col, PageHeader, Space, Button, List,
    Typography, Card, message, Spin, Popconfirm,
} from "antd";


//Componentes
import { IconEye, IconEdit, IconDelete, IconLink } from '../../Widgets/Iconos';

import { Link } from "react-router-dom"
import { CardRazonSocial } from "../../Widgets/Cards";

import ModalTransaccionesDetalle from "../Finanzas/Transacciones/ModalTransaccionesDetalle";
import ModalTransacciones from "../Finanzas/Transacciones/ModalTransacciones";
import ModalFacturas from "../Finanzas/Facturas/ModalFacturas";
import ModalAsociacionFacturasTransacciones from "./ModalAsociacionFacturasTransacciones";

const { Content } = Layout;
const { Title, Text } = Typography;
const axios = require('axios')
const moment = require('moment')


const tipo = { "0": 'Sin Cambios', "1": ' Ingreso', "-1": 'Egreso' }
const estatus_facturas = ['Factura Cancelada', 'Pendiente', 'Liquidad']

/**
 *
 *
 * @export
 * @class Contabilidad
 * @extends {Component}
 * @description Vista del listado de Contabilidad
 */
export default class Contabilidad extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            razones_sociales: [],
            facturas: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
                search: '',
                loading: false,
            },
            transacciones: {
                data: [],
                page: 1,
                limit: 10,
                total: 0,
                pages: 0,
                search: '',
                loading: false,
            },
            modal_trans_editar: false,
            modal_trans_detalle: false,

            modal_fact_detalle: false,
            modal_factura_editar: false,
            modal_factura: false,

            modal_link: false,



            transaccion_id: undefined,
            factura_id: undefined,


            filtroSearch: '',
            searching: true,
            showFiltro: {
            }
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        // this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getRazonesSociales();
        this.getFacturas();
        this.getTransaccionesSinFacturas();
    }

    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.state.searching = false;
        }
    }
    getRazonesSociales = () => {
        axios.get('/razones-sociales', {
            params: {
                paginate: false,
                amounts: true,
            }
        }).then(response => {
            console.log('response rz', response)
            this.setState({ razones_sociales: response.data.data })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Razones Sociales')
        })
    }

    /**
  *
  *
  * @memberof Transacciones
  * @description Obtiene todas todas las transacciones
  * @param page pagina
  * @param filters Filtrado de registros mediante el uso del form de filtros
  */
    getFacturas = (
        {
            page = this.state.facturas.page,
            limit = this.state.facturas.limit,
            search = this.state.facturas.search,

        } = this.state.facturas,
        { facturas } = this.state,
    ) => {
        facturas.loading = true;
        facturas.page = page;
        facturas.limit = limit;
        facturas.search = search;
        this.setState({ facturas })

        axios.get('/facturas/list', {
            params: {
                page,
                limit,
                search,
                sin_transacciones: true
            }

        })
            .then(res => {

                console.log('facturas sin transacciones data', res)
                let facturas = {
                    data: res.data.data.itemsList,
                    page: res.data.data.currentPage,
                    total: res.data.data.itemCount,
                    pages: res.data.data.pageCount,

                    filtroSearch: search,
                    searching: true,
                    loading: false
                }
                this.setState({
                    facturas
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            })
    }

    /**
   *
   *
   * @memberof Transacciones
   * @description Obtiene todas todas las transacciones
   * @param page pagina
   * @param filters Filtrado de registros mediante el uso del form de filtros
   */
    getTransaccionesSinFacturas = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
            search = this.state.transacciones.search,

        } = this.state.transacciones,
        { transacciones } = this.state,
    ) => {
        transacciones.loading = true;
        transacciones.page = page;
        transacciones.limit = limit;
        transacciones.search = search;
        this.setState({ transacciones })
        axios.post('/transacciones/list', {
            page,
            limit,
            search,
            sin_facturas: true

        })
            .then(res => {
                let transacciones = {
                    data: res.data.data.itemsList,
                    page: res.data.data.currentPage,
                    total: res.data.data.itemCount,
                    perPage: res.data.data.perPage,
                    pages: res.data.data.pageCount,
                    filtroSearch: search,
                    searching: true,
                    loading: false
                }
                this.setState({
                    transacciones
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            })
    }
    /**
     *
     *
     * @memberof Contabilidad
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
  


    reloadView = () => {
        this.getTransaccionesSinFacturas();
        this.getFacturas();
    }


    render() {
        return (
            <>
                <div className="bg-white">
                    <Spin spinning={false}>
                        <PageHeader
                            className="site-page-header custom-page-header"
                            title={
                                <Row className="width-100" justify="space-between" align="middle">
                                    <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                        <span className="ant-page-header-heading-title" >
                                            Contabilidad
                                        </span>
                                    </Col>

                                </Row>
                            }
                        />

                        <Content className="admin-content content-bg pd-1 ">
                            <Row>
                                <Col span={24}><Title className="subtitle-dashboard" level={2}>Razones Sociales</Title></Col>
                            </Row>
                            <Row gutter={[16, 16]} className="mb-1">
                                {
                                    this.state.razones_sociales.map((item) => {
                                        return <Col sm={24} md={24} lg={12} xl={8} xxl={6}>
                                            <Link to={`/admin/contabilidad/razon-social/${item._id}`}>
                                                <CardRazonSocial razon_social={item} transacciones={item.transacciones} facturas={item.facturas} />
                                            </Link>
                                        </Col>
                                    })
                                }
                            </Row>


                            {/* Facturas */}
                            <Row className="width-100 mt-50px " justify="space-between" align="middle" >
                                <Col span={24}><Title className="subtitle-dashboard" level={2}>Facturas sin Transacción </Title></Col>
                                <Col span={24}>
                                    <List
                                        loading={this.state.facturas.loading}
                                        className="component-list "
                                        size="large"
                                        itemLayout="horizontal"
                                        dataSource={this.state.facturas.data}
                                        renderItem={item => (
                                            <List.Item className="component-list-item">
                                                <Card className="card-list" bordered={false}>
                                                    <Row className="width-100" justify="space-around">
                                                        <Col xs={24} md={6} lg={2} xl={3} className="center item-info">
                                                            <Text ellipsis strong>{moment(item?.fecha).format('DD/MM/YYYY')}</Text>
                                                        </Col>
                                                        <Col xs={24} md={18} lg={4} xl={6} className="center item-info">
                                                            <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                                        </Col>
                                                        <Col xs={24} md={4} lg={3} xl={4} className="center item-info">
                                                            <span className="spn-estatus">
                                                                <Text ellipsis className="text-gray-dark">{estatus_facturas[item?.estatus]}</Text>
                                                            </span>
                                                        </Col>
                                                        <Col xs={24} md={4} lg={3} xl={4} className="center item-info">
                                                            <span className="spn-tipo">
                                                                <Text ellipsis className="text-gray-dark">{tipo[item?.tipo]}</Text>
                                                            </span>
                                                        </Col>
                                                        <Col xs={24} md={4} lg={3} xl={3} className="center item-info">
                                                            <Text ellipsis className="text-back text-monto"> $ {item?.monto}</Text>
                                                        </Col>
                                                        <Col xs={24} md={12} lg={3} xl={4} className="center item-info">
                                                            <Space direction="horizontal">
                                                                <Button
                                                                    className="btn-eye"
                                                                    icon={<IconEye />}
                                                                    type="primary"
                                                                    title="Ver Detalle"
                                                                    disabled={item.factura_xml}
                                                                >
                                                                </Button>

                                                                <Button
                                                                    className="btn-link"
                                                                    icon={<IconLink />}
                                                                    type="primary"
                                                                    title="Link"
                                                                    onClick={() => this.setState({ modal_link: true, factura_id: item._id })}

                                                                >
                                                                </Button>

                                                                <Button
                                                                    className="btn-editar"
                                                                    icon={<IconEdit />}
                                                                    type="primary"
                                                                    title="Editar"
                                                                    onClick={() => { this.setState({ modal_factura_editar: true, factura_id: item._id }) }}

                                                                >
                                                                </Button>
                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    title="¿Deseas eliminar esta factura?"
                                                                    onConfirm={() => { }}
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        type="primary"
                                                                        danger
                                                                        icon={<IconDelete />} title="Eliminar" />
                                                                </Popconfirm>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                        pagination={{
                                            onChange: (page, limit) => {
                                                this.getFacturas({ page, limit })
                                            },
                                            hideOnSinglePage: true,
                                            total: this.state.facturas.total,
                                        }}
                                    />
                                </Col>
                            </Row>



                            {/* Transacciones */}
                            <Row className="width-100 mt-50px " justify="space-between" align="middle">
                                <Col span={24}><Title className="subtitle-dashboard" level={2}>Transacción sin Facturas </Title></Col>
                                <Col span={24}>
                                    <List
                                        loading={this.state.transacciones.loading}
                                        className="component-list "
                                        size="large"
                                        itemLayout="horizontal"
                                        dataSource={this.state.transacciones.data}
                                        renderItem={item => (

                                            <List.Item className="component-list-item ">
                                                <Card className="card-list" bordered={false}>
                                                    <Row className="width-100" justify="space-around">
                                                        <Col xs={24} md={6} lg={2} xl={2} className="center item-info">
                                                            <Text ellipsis strong>{moment(item?.fecha).format('DD/MM/YYYY')}</Text>
                                                        </Col>
                                                        <Col xs={24} md={18} lg={4} xl={4} className="center item-info">
                                                            <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                                        </Col>
                                                        <Col xs={24} md={6} lg={3} xl={3} className="center item-info">
                                                            <Text ellipsis className="text-gray-dark">{(item?.area !== undefined) ? item?.area : 'Sin Area'}</Text>
                                                        </Col>
                                                        <Col xs={24} md={6} lg={3} xl={3} className="center item-info">
                                                            <Text ellipsis className="text-gray-dark">{(item?.rubro !== undefined) ? item?.rubro : 'Sin Rubro'}</Text>
                                                        </Col>

                                                        <Col xs={24} md={6} lg={4} xl={4} className="center item-info">
                                                            <Text ellipsis className="text-gray-dark">{item?.cuenta_id?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={24} md={16} lg={4} xl={4} className="center item-info">
                                                            <Text ellipsis className="text-back text-monto"> $ {item?.monto}</Text>
                                                        </Col>
                                                        <Col xs={24} md={8} lg={4} xl={4} className="center item-info">
                                                            <Space direction="horizontal">
                                                                <Button
                                                                    className="btn-eye"
                                                                    icon={<IconEye />}
                                                                    type="primary"
                                                                    title="Ver Detalle"
                                                                    onClick={() => this.setState({ modal_trans_detalle: true, transaccion_id: item._id })}

                                                                >
                                                                </Button>

                                                                <Button
                                                                    className="btn-link"
                                                                    icon={<IconLink />}
                                                                    type="primary"
                                                                    title="Link"
                                                                    onClick={() => this.setState({ modal_link: true, transaccion_id: item._id })}

                                                                >
                                                                </Button>

                                                                <Button
                                                                    className="btn-editar"

                                                                    icon={<IconEdit />}
                                                                    type="primary"
                                                                    title="Editar"
                                                                    onClick={() => this.setState({ modal_trans_editar: true, transaccion_id: item._id })}

                                                                >
                                                                </Button>
                                                                <Popconfirm
                                                                    placement="topRight"
                                                                    title="¿Deseas eliminar esta Transacción?"
                                                                    onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                                        message.success('Transaccion Eliminada')
                                                                        this.getFacturas()
                                                                    }).catch((error) => {
                                                                        message.error('Transacción NO Eliminada')
                                                                        this.getFacturas();
                                                                    })
                                                                    }
                                                                    okText="Si"
                                                                    cancelText="No"
                                                                >
                                                                    <Button
                                                                        type="primary"
                                                                        danger
                                                                        icon={<IconDelete />} title="Eliminar" />
                                                                </Popconfirm>
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </List.Item>
                                        )}
                                        pagination={{
                                            onChange: (page, limit) => {
                                                this.getTransaccionesSinFacturas({ page, limit })
                                            },
                                            hideOnSinglePage: true,
                                            total: this.state.transacciones.total,
                                        }}
                                    />
                                </Col>
                            </Row>

                            <ModalTransacciones
                                visible={this.state.modal_trans_editar}
                                transaccion_id={this.state.transaccion_id}
                                onClose={() => {
                                    this.setState({ modal_trans_editar: false, transaccion_id: undefined })
                                    this.getTransaccionesSinFacturas()
                                }}
                            />
                            <ModalFacturas
                                visible={this.state.modal_factura_editar}
                                onClose={() => {
                                    this.setState({ modal_factura_editar: false, factura_id: undefined })
                                    this.getFacturas()
                                }}
                                factura_id={this.state.factura_id}
                            />

                            <ModalTransaccionesDetalle
                                visible={this.state.modal_trans_detalle}
                                onClose={() => this.setState({
                                    modal_trans_detalle: false,
                                    transaccion_id: undefined
                                })}
                                id={this.state.transaccion_id}
                            />

                            <ModalAsociacionFacturasTransacciones
                                visible={this.state.modal_link}
                                onClose=

                                {() => this.setState({
                                    modal_link: false,
                                    transaccion_id: undefined,
                                    factura_id: undefined
                                }, this.reloadView())

                                }
                                factura_id={this.state.factura_id}
                                transaccion_id={this.state.transaccion_id}
                                razon_social_id={this.props.match.params.razon_social_id}

                            />
                            {/* <FloatingButton title="Nuevo registro" /> */}
                        </Content>
                    </Spin>

                </div>

            </>
        )
    }
}