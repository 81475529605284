import React, { Component, } from "react";
import { Button, Col, Form, message, Modal, Row, Spin, Typography, Select, Tooltip } from 'antd';

//componentes
import { FaMinus, FaSync } from 'react-icons/fa'
import { PlusOutlined, } from '@ant-design/icons'
import SyncWidget from "@paybook/sync-widget";

import CustomAvatar from '../../Widgets/Avatar/Avatar'
import { User } from '../../../Hooks/Logged'

//css
import "@paybook/sync-widget/dist/widget.css";
import "../../../Styles/Modules/Syncfy/ModalConfiguraciónSyncfy.css";

const axios = require('axios').default;
const { Title, } = Typography;
const { Option } = Select;

window.SyncWidget = SyncWidget

/**
 *
 *
 * @class NuevoUsuarioSyncfy
 * @extends {Component}
 * 
 * @description Modal para agregar un nuevo usuario a Syncfy
 */
class NuevoUsuarioSyncfy extends Component {

    static contextType = User

    state = {
        usuarios: {
            data: [],
            page: 1,
            limit: 50,
            pages: 0,
            search: null
        },

    }


    /**
     *
     *
     * @memberof NuevoUsuarioSyncfy
     * @description Se ejecuta al inicar
     */
    componentDidMount() {
        this.getUsuarios()
    }

    /**
    * @function getUsuarios
    * @description Obtenemos los usuarios del sistema para agregarlos a Syncfy
    * */
    getUsuarios = async ({ search = this.state.usuarios.search } = this.state.usuarios) => {
        await axios.get("/usuarios", {
            params: {
                search: (typeof search === "string" && search.length > 1) ? search : undefined,
                paginate: true
            },
        })
            .then(({ data }) => {
                console.log("data", data);
                this.setState({
                    usuarios: {
                        data: [...data.data],
                        //page: data.data.paginator.currentPage,
                        //limit: data.data.paginator.perPage,
                        //pages: data.data.paginator.pageCount
                    }
                })

                if (this.context?._id)
                    this.form.current.setFieldsValue({ usuario_id: this.context?._id })
            })
            .catch(e => console.log('er', e))
    }

    /**
     *
     *
     * @param {*} values
     * @memberof NuevoUsuarioSyncfy
     * 
     * @method onFinish
     * @description Agregamos el usuario a la lista de Usuarios de Syncfy
     */
    onFinish = (values) => axios.post('/syncfy/users', {
        ...values,
        cuenta_id: this.props.id
    })
        .then(() => {
            message.success("El Usuario ha sido agregado a Syncfy")
            this.props.onCancel()
        })
        .catch((error) => {
            console.error(error)
            message.success("El usuario no se puede agregar.")
        })

    form = React.createRef()

    render() {
        return <Form
            ref={this.form}
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={this.onFinish}
            autoComplete="off"
        >
            <Form.Item

                label="Usuarios del Sistema"
                extra="Debes seleccionar el usuario que quieres agregar a la lista de aceptados de Syncfy."
                name="usuario_id"
                rules={[
                    { required: true, message: 'Para poder proceder, debes ingresar el usuario' }
                ]}

            >
                <Select
                    showSearch
                    filterOption={false}
                    onSearch={search => this.getUsuarios({ search })}>
                    {this.state.usuarios.data?.map(function ({ _id, nombre, logo, color, apellido }, index) {
                        return <Option value={_id} style={{ margin: '2px 0 2px 0' }}>
                            <CustomAvatar
                                image={logo}
                                name={nombre}
                                color={color}
                                size="small"
                                style={{
                                    marginRight: '5px'
                                }}
                            />
                            {nombre} {apellido}
                        </Option>
                    })}
                </Select>

            </Form.Item>
        </Form>
    }
}


/**
 *
 *
 * @param {*} props
 * @return {*} 
 * 
 * @description Permite agregar un usuario del sistema ERP a Syncfy
 */
function ModalNuevoUsuario(props) {

    const { visible, onCancel, id } = props


    const formRef = React.createRef()

    /**
     *
     *
     * @param {*} values
     * @memberof NuevoUsuarioSyncfy
     * 
     * @method onFinish
     * @description Ejecutamos el onfinish del formulario
     */
    const onFinish = () => formRef.current.form.current.submit()

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title="Agregar un Usuario"
        destroyOnClose={true}
        onOk={onFinish}
        zIndex={1000}
    >
        <NuevoUsuarioSyncfy ref={formRef} {...props} />
    </Modal>
}


/**
 *
 * @method CuentaForm
 *
 * @param {*} props
 * @return {*} 
 * 
 * @description Este es el formulario de Syncfy. Asigna la credencial y el usuario a Syncfy.
 */
class CuentaForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            spinning: false,

            loading: false,
            syncfy_users_loading: false,
            syncfy_users: [],

            syncfy_credentials_loading: false,
            syncfy_credentials: [],

            syncfy_cuentas_bancarias_loading: false,
            syncfy_cuentas_bancarias: [],


            tipo: undefined
        }
    }

    /**
     *
     *
     * @memberof componentDidMount
     * @description Se ejecuta al inicar
     */
    componentDidMount() {
        this.setState({ id_site_organization_type: this.props.tipo ? ((this.props.tipo == 1) ? "56cf4f5b784806cf028b4568" : "56cf4f5b784806cf028b4569") : undefined })
        this.getUsuariosSyncfy()
        this.getAccount()
        window.form = this.formModalAccount
        // ={"56cf4f5b784806cf028b4569"}  

        // console.log('this', this)
    }


    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method startSession
     * @description Creamos una sesión para poder obtener las credenciales. Declaramos el token de synfy en la cabecera de axios, para que se envie siempre durante la solicitudes
     */
    startSession = async (id_user_syncfy, id_credential_syncfy) => {
        if (sessionStorage.getItem('syncfy')) {
            try {
                await this.deleteSession()
            } catch (error) {
                console.log('error', error)
            }
        }
        return axios.get('/syncfy/session', {
            params: { id_user_syncfy }
        })
            .then(({ data }) => {
                sessionStorage.setItem('syncfy', data.token)
                axios.defaults.headers.common['syncfy'] = data.token
                this.getCredencialesBySyncfyUser(id_user_syncfy)

                if (id_credential_syncfy)
                    this.getBankAccountingByCredentials(id_credential_syncfy)
            })
            .catch((response) => {
                console.log('error', response)
            })
            .finally(() => console.log('Sessión Nueva'))
    }

    /**
     *
     *
     * @param {*} value
     * @memberof CuentaForm
     * 
     * @description Obtenemos el tipo de institución de la cuenta.
     */
    getTipoDeInstitucion = (value) => (value === "Government") ? 2 : 1

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method deleteSession
     * @description Eliminamos una sesión.
     */
    deleteSession = () => {
        return axios.delete('/syncfy/session')
            .then(({ data }) => {
                sessionStorage.removeItem('syncfy')
            })
            .finally(() => console.log('Sessión Eliminada'))
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method getUsuariosSyncfy
     * @description Obtenemos los usuarios de Syncfy 
     */
    getUsuariosSyncfy = () => {

        this.setState({
            syncfy_users_loading: true,
        })

        axios.get('/syncfy/users')
            .then((response) => {
                this.setState({ syncfy_users: response.data.data })
                console.log('response', response.data)
            })
            .catch((response) => {
                console.log('error', response)
            })
            .finally(() => {
                this.setState({
                    syncfy_users_loading: false
                })
            })
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method getCredencialesBySyncfyUser
     * @description Obtenemos las credenciales del usuario seleccionado. Se verifica que primero haya una sesión. Si no la o no es valida, la crea.
     */
    getCredencialesBySyncfyUser = (usuario_id, credential_id) => {
        this.setState({

            syncfy_credentials_loading: true,
        })

        if (sessionStorage.getItem('syncfy'))
            axios.get('/syncfy/user/credentials', {
                params: {
                    id_site_organization_type: this.state.id_site_organization_type
                }
            })
                .then((response) => {
                    this.setState({ syncfy_credentials: response.data.data })

                    if (credential_id)
                        this.getBankAccountingByCredentials(credential_id)

                })
                .catch((response) => {
                    this.startSession(usuario_id, credential_id)
                })
                .finally(() => this.setState({ syncfy_credentials_loading: false, }))
        else this.startSession(usuario_id)
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method getBankAccountingByCredentials
     * @description Obtenemos la información de la cuenta, espcificamente dos campos, los de syncfy
     */
    getBankAccountingByCredentials = (id_credential, tipo) => {
        this.setState({ syncfy_cuentas_bancarias_loading: true, tipo })
        axios.get("/syncfy/user/credential/accounting", { params: { id_credential } })
            .then(({ data }) => {
                this.setState({ syncfy_cuentas_bancarias: data.data })
                // console.log('/syncfy/user/credential/accounting data', data)
            })
            .finally(() => this.setState({ syncfy_cuentas_bancarias_loading: false }))
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method getAccount
     * @description Obtenemos la información de la cuenta, espcificamente dos campos, los de syncfy
     */
    getAccount = (id = this.props.id) => {

        this.setState({ loading: true })
        console.log('{ params: { id } }', this.props)
        axios.get(((this.props.tipo == 1) ? "/cuentas/get" : "/razones-sociales/get"), { params: { id } })
            .then(({ data }) => {

                this.setState({ cuenta: data.data })
                this.formModalAccount.current.setFieldsValue(data.data)
                if (data.data.syncfy_usuario_id)
                    this.getCredencialesBySyncfyUser(data.data.syncfy_usuario_id, data.data.syncfy_credenciales_id)
            })
            .catch(res => {
                console.log("no obtenida", res);
                message.error('Error al obtener la información de la cuenta.')
            })
            .finally(() => this.setState({ loading: false }))
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method saveAccount
     * @description Guardamos los dos campos de la cuenta.
     */
    saveAccount = values => {
        values.tipo = this.state.tipo
        axios.post(((this.props.tipo == 1) ? '/cuentas/update' : '/razones-sociales/update'), { ...values, id: this.props.id, edit_syncfy: true })
            .then(() => {
                message.success("¡Se ha guardado correctamente la cuenta!")
                this.props.onCancel();
            })
            .catch((e) => {
                message.error(e.response.data.message)
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }


    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method deleteUser
     * @description Eliminamos un usuario de syncfy
     */
    deleteUser = ({ id_user, name }) => {
        Modal.confirm({
            title: `¿Deseas eliminar a ${name} de la lista de usuarios de syncfy ?`,
            content: "Al eliminar el usuario, no será posible usar las cuentas que se hayan creado.",

            okText: "Eliminar " + name,
            okCancel: "Cancelar",
            onOk: () => axios.delete('/syncfy/users', { params: { id_user } })
                .then(() => {
                    message.success("Se ha eliminado el usuario de Syncfy.");
                    this.getUsuariosSyncfy()
                })
                .catch((e) => { console.log('e', e) })
        })
    }


    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method deleteCredential
     * @description Eliminamos una credencial de Syncfy
     */
    deleteCredential = ({ id_credential, username }) => {
        Modal.confirm({
            title: `¿Deseas eliminar "${username}"" de la lista de credenciales ingresadas?`,
            content: "Al eliminar la credencial, no podras acceder a las transacciones de las cuentas.",

            okText: "Eliminar " + username,
            okCancel: "Cancelar",
            onOk: () => axios.delete('/syncfy/user/credentials', { params: { id_credential } })
                .then(() => {
                    message.success("Se ha eliminado el usuario de Syncfy.");
                    const { syncfy_usuario_id, } = this.formModalAccount.current.getFieldsValue()
                    this.getCredencialesBySyncfyUser(syncfy_usuario_id)
                })
                .catch((e) => { console.log('e', e) })
        })
    }

    /**	
    *	
    *	
    * @param {*} { id_credential, username }	
    * @memberof CuentaForm	
    * 	
    * @description Actualizamos las credenciales de cierto usuario sycnfy	
    */
    updateCredential = ({ id_credential, username }) => {
        Modal.confirm({
            title: `¿Deseas eliminar "${username}"" de la lista de credenciales ingresadas?`,
            content: "Al eliminar la credencial, no podras acceder a las transacciones de las cuentas.",

            okText: "Eliminar " + username,
            okCancel: "Cancelar",
            onOk: () => axios.delete('/syncfy/user/credentials', { params: { id_credential } })
                .then(() => {
                    message.success("Se ha eliminado el usuario de Syncfy.");
                    const { syncfy_usuario_id, } = this.formModalAccount.current.getFieldsValue()
                    this.getCredencialesBySyncfyUser(syncfy_usuario_id)
                })
                .catch((e) => { console.log('e', e) })
        })
    }


    actualizarCredenciales = (a) => {
        const { syncfy_usuario_id, } = this.formModalAccount.current.getFieldsValue()
        this.getCredencialesBySyncfyUser(syncfy_usuario_id)
    }

    /**
     *
     *
     * @param {*} id_user_syncfy
     * @memberof CuentaForm
     * 
     * @method renderWidget
     * @description Renderizamos el widget para declara las solicitudes.
     */
    renderWidget = (credential) => {
        console.log('1', this.state.id_site_organization_type,)
        if (sessionStorage.getItem('syncfy') && sessionStorage.getItem('syncfy') !== null) {
            if (!window.syncWidget)
                window.syncWidget = new SyncWidget({
                    // Set up the token you created in the Quickstart:	
                    token: sessionStorage.getItem('syncfy'),
                    config: {
                        // Set up the language to use:	
                        locale: 'es',
                        navigation: {
                            displayStatusInToast: true,
                            displaySiteOrganizationTypes: this.state.id_site_organization_type ? [this.state.id_site_organization_type,] : null
                        },
                        entrypoint: {
                            credential: credential ? credential : undefined,
                        }
                    }
                })
            else {
                window.syncWidget.setConfig({
                    navigation: {
                        displayStatusInToast: true,
                        displaySiteOrganizationTypes: this.state.id_site_organization_type ? [this.state.id_site_organization_type,] : null
                        // displaySiteOrganizationTypes: this.state.id_site_organization_type ? [this.state.id_site_organization_type,] : null
                    },
                    entrypoint: {
                        credential: credential ? credential : undefined,
                    }
                })
                window.syncWidget.setToken(sessionStorage.getItem('syncfy'))
            }
            console.log('2')
            window.syncWidget.$on("error", this.actualizarCredenciales);
            window.syncWidget.$on("success", this.actualizarCredenciales);
            console.log('4', window.syncWidget)
            if (!credential)
                window.syncWidget.open()
            return
        }
        return Modal.error({
            title: "Debe seleccionar un Usuario de Syncfy para poder generar las credenciales."
        })
    }


    formModalAccount = React.createRef()

    render() {
        const { formModalAccount, saveAccount } = this;
        const { spinning } = this.state;
        return (

            <Spin spinning={spinning}>

                <Form
                    layout="vertical"
                    ref={formModalAccount}

                    name="formulario-transacciones"
                    onFinish={saveAccount}

                    onValuesChange={(values, valuesAll) => {
                        if (valuesAll.syncfy_usuario_id == null || valuesAll.syncfy_usuario_id == undefined)
                            this.formModalAccount.current.setFieldsValue({
                                syncfy_credenciales_id: null,
                                syncfy_cuenta_bancaria_id: null
                            })
                    }}
                >
                    <img src="/images/syncfy-icon.webp" alt="Syncfy" style={{ margin: '40px auto 83px auto', display: 'block' }} />
                    <Row style={{ maxWidth: '400px', display: "block", margin: '0 auto' }}>
                        <Col span={24} >
                            <Row>
                                {(this.state.cuenta ?
                                    <Row style={{ width: '100%' }}>
                                        <Col span={12}>
                                            <Title level={5}><small style={{ position: 'absolute', left: 0, top: -15, fontWeight: 'lighter' }}>Cuenta:</small> {this.state.cuenta?.nombre ? this.state.cuenta?.nombre : this.state.cuenta?.razon_social}</Title>
                                        </Col>
                                        <Col span={12}>
                                            <Title level={5}><small style={{ position: 'absolute', left: 0, top: -15, fontWeight: 'lighter' }}>Titular:</small> {this.state.cuenta?.titular ? this.state.cuenta?.titular : this.state.cuenta?.rfc}</Title>
                                        </Col>
                                    </Row>
                                    : null)}
                                <Col span={21}>
                                    <Spin spinning={this.state.syncfy_users_loading || this.state.loading}>
                                        <Form.Item
                                            label="Usuario de Synfcy"
                                            name="syncfy_usuario_id"
                                        >
                                            <Select
                                                allowClear={true}
                                                className="syncfy-input"
                                                onSelect={(values) => {
                                                    sessionStorage.removeItem('syncfy')
                                                    this.getCredencialesBySyncfyUser(values)
                                                }}
                                            >



                                                {this.state.syncfy_users.map(({ id_user, name }) => <Option style={{ position: 'relative' }} value={id_user}>
                                                    {name}
                                                    <Button
                                                        className="button-syncfy button-delete-syncfy"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            this.deleteUser({ id_user, name })
                                                        }}
                                                        size="small" type="primary" shape="circle" danger icon={<FaMinus size='8px' className="icon" />} />
                                                </Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Spin>
                                </Col>
                                <Col span={3}>
                                    <Tooltip title="Agregar nuevo usuario de Synfy" >
                                        <Button
                                            onClick={() => this.setState({ modalUsuario: true })}
                                            style={{
                                                display: 'block',
                                                margin: '30px auto 0 auto',
                                                borderRadius: '6px'
                                            }} type="primary" icon={<PlusOutlined style={{ color: "white" }} />} />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} >
                            <Row>
                                <Col span={21}>
                                    <Spin spinning={this.state.syncfy_credentials_loading || this.state.loading}>
                                        <Form.Item
                                            label="Credenciales de Synfcy"
                                            name="syncfy_credenciales_id"
                                            rules={
                                                [
                                                    // {	
                                                    //     required: true,	
                                                    //     message: 'Debes indicar la credencial a acceder.',	
                                                    // }	
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            if (getFieldValue('syncfy_usuario_id') && !value) {
                                                                return Promise.reject(new Error('Debe de seleccionar la credencial a acceder.'));
                                                            }
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]
                                            }
                                        >
                                            <Select
                                                allowClear={true}
                                                onSelect={(value, { tipo }) => this.getBankAccountingByCredentials(value, this.getTipoDeInstitucion(tipo))}
                                            >
                                                {this.state.syncfy_credentials.map(({ id_credential, username, tipo }) => <Option
                                                    value={id_credential}
                                                    tipo={tipo}
                                                >
                                                    {username}

                                                    <Button
                                                        className="button-syncfy  button-delete-syncfy"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            this.deleteCredential({ id_credential, username })
                                                        }}
                                                        size="small" type="primary" shape="circle" danger icon={<FaMinus size='8px' className="icon" />} />

                                                    <Button
                                                        className="button-syncfy button-update-syncfy"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            event.preventDefault();
                                                            this.renderWidget(id_credential)
                                                        }}
                                                        size="small" type="primary" shape="circle" icon={<FaSync size='8px' className="icon" />} />


                                                </Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Spin>
                                </Col>
                                <Col span={3}>
                                    <Tooltip title="Agregar nuevas credenciales de Synfcy" >
                                        <Button
                                            onClick={() => this.renderWidget()}
                                            style={{
                                                display: 'block',
                                                margin: '30px auto 0 auto',
                                                borderRadius: '6px'
                                            }}
                                            type="primary" icon={<PlusOutlined style={{ color: "white" }} />} />
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>

                        <Col span={24} >
                            <Row>
                                <Col span={24}>
                                    <Spin spinning={this.state.syncfy_cuentas_bancarias_loading || this.state.loading}>
                                        <Form.Item
                                            label="Cuentas Bancarias / Fiscales asociadas a las credenciales de Synfcy"
                                            name="syncfy_cuenta_bancaria_id"
                                            rules={[
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (getFieldValue('syncfy_usuario_id') && !value) {
                                                            return Promise.reject(new Error('Debe de seleccionar la cuenta bancaria.'));
                                                        }
                                                        return Promise.resolve();
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Select allowClear={true}>>
                                                {this.state.syncfy_cuentas_bancarias.map(({ name, id_account, number }) => <Option value={id_account}>
                                                    <span>
                                                        <span>{name}</span>
                                                        <br />
                                                        <small>{number}</small>
                                                    </span>
                                                </Option>)}
                                            </Select>
                                        </Form.Item>
                                    </Spin>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" type="primary" className="btn-azul" loading={spinning}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <ModalNuevoUsuario
                    visible={this.state.modalUsuario}
                    id={this.props.id}
                    onCancel={() => {

                        this.getUsuariosSyncfy()
                        this.setState({ modalUsuario: false })
                    }}
                />
            </Spin>
        )
    }
}

export default function (props) {
    const { visible = false, onCancel = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onCancel}
        title={"Modal de Configuración de Syncfy"}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <CuentaForm {...props} />
    </Modal>

}