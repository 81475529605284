
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from '../components/Header/Header';
import { Layout, Button, Popconfirm } from "antd";
import { User, SetUser } from '../Hooks/Logged';

import Cliente_Sidebar from "../components/Sidebar/CustomerSidebar";


// ROUTERS
import RouterMisPedidos from "./Customer/RouterMisPedidos";
import RouterCotizador from "./Customer/RouterCotizador";
import RouterVMI from "./Customer/RouterVMI";

import '../Styles/Modules/Customer/customer.css';



const { Content } = Layout;


class CustomerRouter extends Component {

    static contextType = User;

    constructor(props) {
        super(props)
        this.state = {
            back: false,
            responsiveSidebar: false,

            showSearch: false,
            filterSearch: "",
            searching: false,
            collapsed: false
        };
    }

    SetFilterSearch(e) {
        this.setState({ filterSearch: e })
    }

    updateFilterSearch = (ShowFilter) => {
        if (ShowFilter === undefined) { ShowFilter = false }
        this.setState({
            showSearch: ShowFilter,
        })
    };


    showChat = () => {

    }

    render() {
        const { back } = this.state;
        const { updateFilterSearch } = this;
        return (
            <Layout className="layout" style={{ minHeight: '100vh' }}>

                <User.Consumer>
                    {value => { this.context = value }}
                </User.Consumer>

                <Navbar
                    user={this.context}
                    back={back}
                    onBack={this.onBack}
                    setUser={this.setUser}
                    showResponsiveSidebar={() => this.setState({ responsiveSidebar: !this.state.responsiveSidebar })}
                    showSearch={this.state.showSearch}
                    setFilterSearch={(e) => this.SetFilterSearch(e)}
                    filterSearchVal={this.state.filterSearch}
                    admin={true}
                />
                <Layout>
                    <Cliente_Sidebar
                        user={this.context}
                        back={back}
                        setUser={this.setUser}
                    />
                    <Content>
                        <Switch>
                            <Route path="/customer/misPedidos"
                                render={(props) => <RouterMisPedidos  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/customer/cotizador"
                                render={(props) => <RouterCotizador  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />

                            <Route path="/customer/VMI"
                                render={(props) => <RouterVMI  {...props} updateFilterSearch={updateFilterSearch} search={this.state.filterSearch} />}
                            />
                        </Switch>
                    </Content>
                </Layout>

            </Layout>
        )
    }
}

export default (props) => <CustomerRouter {...props} setUser={React.useContext(SetUser)} />