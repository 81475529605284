import React, { Component } from 'react';
import { Layout, PageHeader, Spin } from 'antd';

import Marketplace from '../../Public/Cotizador/Marketplace';
import axios from 'axios'
const { Content } = Layout;


/**
 *
 *
 * @export
 * @class Cotizador
 * @extends {Component}
 */
export default class Cotizador extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
    }

    /**
     * @memberof Cotizador
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }


    render() {
        const { } = this.state;
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Cotizador Spot"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Marketplace dashboard={true} login={true}/>
                    </Content>
                </Spin>
            </>

        )
    }
}