import React, { Component } from 'react';
import { Layout, Button, Col, Popconfirm, Row, message, Card, PageHeader, List, Spin, Typography, Space, Tooltip, Tag, Switch } from 'antd'
import { User } from "../../../Hooks/Logged";
import { Link } from 'react-router-dom';
import { BankOutlined, AuditOutlined } from "@ant-design/icons";


//componentes
import { IconDelete, IconEdit, IconBankError, IconSATSCR } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import axios from 'axios'
import FloatingButton from '../../Widgets/Floating Button/FloatingButton';
//modales
import ModalRazonSocial from './ModalRazonesSociales';
import ModalTaxPayer from './ModalTaxPayer';
import ModalConfiguraciónSyncfy from "../Syncfy/ModalConfiguraciónSyncfy"


const { Text } = Typography;
const { Content } = Layout;


/**
 *
 *
 * @export
 * @class RazonesSociales
 * @extends {Component}
 */
export default class RazonesSociales extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            id: null,

            loading: true,
            modal_visible: false,
            /* Paginado */
            itemCount: 0,
            perPage: 10,
            pageCount: 1,
            currentPage: 1,

            filtroSearch: "",
            searching: true,
        }
    }
    static contextType = User;


    /**
     *
     *
     * @memberof RazonesSociales
     * 
     * @method componentDidMount 
     * @description Cargamos la informacion de los Razones Sociales
     */

    componentDidMount() {
        this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getRS()
    }


    /**
     *
     *
     * @memberof RazonesSociales
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
    componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getRS();
            this.state.searching = false;
        }
    }


    /**
         *
         * @memberof RazonesSociales
         * @method getRS
         * @description Obtiene la informacion de los Razones Sociales y actualiza los valores locales de la lista.
         */
    getRS = async (page = this.state.currentPage) => {
        this.setState({ loading: true })
        let search = this.props.search;
        axios.get('/razones-sociales', {
            params: {
                page: page,
                limit: 10,
                search: search
            }
        })
            .then(res => {
                const data = res.data.data.itemsList
                this.setState({
                    data: data,
                    loading: false,

                    currentPage: res.data.data.paginator.currentPage,
                    itemCount: res.data.data.paginator.itemCount,
                    perPage: res.data.data.paginator.perPage,
                    pageCount: res.data.data.paginator.pageCount,

                    searching: true,
                    filtroSearch: search
                })

            })
            .catch(res => {
                console.log("error", res);
            });
    }


    /**
     * @methodOf  RazonesSociales
     * @function showModal
     *
     * @description Muestra el modal de la Razon Social
     *
     * */
    showModal = (id) => {
        this.setState({
            visible: true,
            id: id
        });
    };

    /**
     * @methodOf  RazonesSociales
     * @function hideModal
     *
     * @description Oculta el modal de la Razon Social
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };


    render() {
        const { getRS, showModal, hideModal } = this;
        const { visible, currentPage, itemCount, data, id } = this.state;
        const user = this.context;
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Razones Sociales"
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            className="component-list scroll"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Sin Razones Sociales " }}
                            dataSource={data}
                            pagination={{
                                current: currentPage,
                                pageSize: 10,
                                total: itemCount,
                                showSizeChanger: false,
                                position: 'bottom',
                                className: "flex-left"
                            }}
                            header={<Row className="header-list width-100 pr-1 pl-1" >
                                <Col md={{ span: 3 }} lg={{ span: 2 }} className="center">
                                    <Text strong>logo</Text>
                                </Col>

                                <Col md={{ span: 4 }} lg={{ span: 6 }} className="center">
                                    <Text strong>razón social</Text>
                                </Col>
                                <Col md={{ span: 6 }} lg={{ span: 6 }} className="center">
                                    <Text strong>descripción</Text>
                                </Col>

                                <Col md={{ span: 4 }} lg={{ span: 4 }} className="center">
                                    <Text strong>RFC</Text>
                                </Col>
                                <Col md={{ span: 2 }} lg={{ span: 2 }} className="center">
                                    <Text strong>Pagable</Text>
                                </Col>
                                <Col md={{ span: 4 }} lg={{ span: 4 }} className="center">
                                    <Text strong>Acciones</Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => (
                                <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <Row className="width-100 ">
                                            <Col md={{ span: 3 }} lg={{ span: 2 }} className="center">
                                                <CustomAvatar image={item.logo} name={item.razon_social} color={item.color} />
                                            </Col>
                                            <Col md={{ span: 4 }} lg={{ span: 6 }} className="center">

                                                {
                                                    (item.syncfy_credenciales_id && item.syncfy_cuenta_bancaria_id && item.syncfy_usuario_id) ?
                                                        <Link to={`/admin/razones-sociales/${item._id}`}>
                                                            <Button size="small" type="ghost" style={{ textDecoration: "underline" }}>{item.razon_social}</Button>
                                                        </Link>
                                                        :
                                                        <Text >{item.razon_social}</Text>
                                                }


                                            </Col>
                                            <Col md={{ span: 6 }} lg={{ span: 6 }} className="center">
                                                <Text >{item.descripcion}</Text>
                                            </Col>
                                            <Col md={{ span: 4 }} lg={{ span: 4 }} className="center">
                                                <Text >{item.rfc}</Text>
                                            </Col>
                                            <Col md={{ span: 2 }} lg={{ span: 2 }} className="center">
                                                <Switch checked={item.pagable} disabled={true} />
                                            </Col>
                                            <Col md={{ span: 4 }} lg={{ span: 4 }} className="center">
                                                {(user.tipo != 3) ?
                                                    <Space>
                                                        <Tooltip title={item.syncfy_taxpayer ? "Asignar el Certifcado y la Llave para Timbrar": "Actualizar el Certifcado y la Llave para Timbrar"}>
                                                            <Button
                                                                type={!!item.syncfy_taxpayer ? "primary" : undefined}
                                                                icon={<IconSATSCR width={22} color="currentColor" ballsColor={(!!item.syncfy_taxpayer) ? "currentColor" : undefined} style={{ position: "relative", top: 1 }} />}
                                                                onClick={() => this.setState({ razon_social_id: item._id, modalKeys: true })}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip title={(item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "Cuenta con enlace bancario" : "Cuenta sin enlace bancario"}>
                                                            <Button
                                                                onClick={() => this.setState({ syncfy_id: item._id, modalSyncfy: true })}
                                                                danger={!(item.syncfy_usuario_id && item.syncfy_credenciales_id)}
                                                                type="primary"
                                                                icon={
                                                                    (item.syncfy_usuario_id && item.syncfy_credenciales_id)
                                                                        ?
                                                                        <BankOutlined style={{ fontSize: 22, color: "white", position: "relative", bottom: 2 }} />
                                                                        :
                                                                        <IconBankError color="white" style={{ position: 'relative', bottom: 2 }} />
                                                                }
                                                                title="Eliminar"
                                                                style={{ backgroundColor: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "#1890ff" : undefined, border: (item.syncfy_usuario_id && item.syncfy_credenciales_id) ? "none" : undefined }}
                                                            />
                                                        </Tooltip>
                                                        <Button type="primary" icon={<IconEdit />} onClick={() => showModal(item._id)} />
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title="¿Deseas eliminar esta Razon Social?"
                                                            onConfirm={() => axios.delete('/razones-sociales/delete', { params: { id: item._id } }).then((response) => {
                                                                message.success(response.data.message)
                                                                this.getRS()
                                                            })
                                                                .catch((error) => {
                                                                    message.error(error.response.data.message);
                                                                    this.getRS();
                                                                })
                                                            }
                                                            okText="Si"
                                                            cancelText="No"
                                                        >
                                                            <Button type="primary" danger icon={<IconDelete />} title="Eliminar" />
                                                        </Popconfirm>
                                                    </Space>
                                                    : null}
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />

                        <FloatingButton title="Nuevo registro" onClick={() => showModal()} />
                    </Content>
                </Spin>
                <ModalRazonSocial
                    visible={visible}
                    hideModal={hideModal}
                    accept={() => {
                        hideModal();
                        getRS();
                    }}
                    update={() => getRS()}
                    id={id}

                >

                </ModalRazonSocial>

                <ModalConfiguraciónSyncfy
                    visible={this.state.modalSyncfy}
                    id={this.state.syncfy_id}
                    onCancel={() => {
                        this.setState({ modalSyncfy: false, id: null, cuenta_id: undefined })
                        this.getRS();
                    }}
                    tipo={2}
                />

                <ModalTaxPayer
                    visible={this.state.modalKeys}
                    razon_social_id={this.state.razon_social_id}
                    onCancel={() => {
                        this.setState({ modalKeys: false, razon_social_id: null, cuenta_id: undefined })
                        this.getRS();
                    }}
                />
            </>

        )
    }
}
