import React, { Component } from 'react';
import { Button, Popconfirm, Spin, Table, PageHeader, Breadcrumb, Layout, Space, message, Modal } from 'antd'
import { Link } from "react-router-dom";
import { IconDelete, IconEdit, IconArrowBack,IconMail } from '../../Widgets/Iconos';
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import Logged from "../../../Hooks/Logged";
import axios from 'axios';
//modal

import ModalUsuario from './ModalUsuarios';
import ModalUsuariosCliente from './ModalUsuariosCliente';
const { Content } = Layout;


/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
export default class Usuarios extends Component {
    static contextType = Logged;

    back = () => {
       this.props.history.goBack();
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {

            _id: undefined,
            loading: false,
            modal_visible: false,
            modal_cliente_visible: false,


            /* Paginado */
            page: 1,
            limit: 10,
            total: 0,
            data: [],
            filtroSearch: '',
            searching: true,

            cliente_id: undefined,
            cliente: []
        }
    }


    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        if(this.props.updateFilterSearch) this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        if (this.props.match.params.id != undefined) {
            this.setState({ cliente_id: this.props.match.params.id });
            this.getCliente();
        }


        this.getUsuarios();
    }

    /**
     * @memberof Usuarios
     *
     * @method   componentDidUpdate
     * @description Si se realiza una un busqueda por la barra, actuliza el contenido
     *
    **/
    componentDidUpdate(prevProps) {
        if (this.props.search !== prevProps.search) {
            this.setState({ search: this.props.search }, () => { this.getUsuarios() })
        }
    }


    /**
     * @methodOf  Usuarios
     * @method getUsuarios
     *
     * @description Obtiene la paginación de usuario
     *
     * */
    getUsuarios = (page = this.state.page) => {
        this.setState({ loading: true, });
        let search = this.props.search;


        axios.get("/usuarios", {
            headers: {
                Authorization: sessionStorage.getItem("token")
            },
            params: {
                page,
                cliente_id: this.props.match.params.id,
                search: search,
                searching: true,
            },
        })
            .then(({ data }) => {

                console.log('data', data)
                this.setState({
                    page: page,
                    total: data.data.itemCount,
                    data: data.data.itemsList,

                })

            })
            .catch(e => console.log('er', e))
            .finally(() => this.setState({ loading: false }))
    };

    /**
     * @memberof Usuarios
     * @method getCliente
     * @description Obtiene el cliente si hay un id
     */
    getCliente = () => {
        axios.get('/clientes/get', {
            params: {
                id: this.props.match.params.id
            }
        }).then(response => {
            this.setState({ cliente: response.data.data, })
        }).catch(error => {
            message.error('Error al traer cliente')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }

    /**
     * @methodOf  Usuarios
     * @method deleteUsuario
     *
     * @description Elimina un Usuario
     *
     * */
    deleteUsuario = (id) => {
        if(this.state.cliente_id){
            axios.post('/usuarios/clientes/update', {
                usuarios: [{value: id}],
                cliente_id: null
            })
                .then(() => this.getUsuarios()
                )
                .catch(error => {
                    console.log('error', error);
                })
        }else{
            axios.delete('/usuarios/delete', {
                params: { id: id }
            })
                .then(() => this.getUsuarios()
                )
                .catch(error => {
                    console.log('error', error);
                     message.error('No se pudo eliminar')
                })
        }

    }

    /**
     * @methodOf  Usuarios
     * @method sendEmail
     *
     * @description Envia un correo para recuperar la contraseña
     *
     * */
    sendEmail = (email) => {
        this.setState({loading: true})
        axios.put("/password/recovery", { email: email })
            .then(res => {
                Modal.success({
                    title: 'Restablecer contraseña.',
                    content: '¡El correo ha sido enviado!'
                })
            })
            .catch(res => {
                console.log(res)
                Modal.warning({
                    title: 'Error',
                    content: 'El correo no se ha podido enviar. Verifica que el correo sea correcto'
                });
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })

    }

    /**
     * @methodOf  Usuarios
     * @method showModal
     *
     * @description Muestra el modal del usuario
     *
     * */
    showModal = (id = undefined) => {
        this.setState({
            visible: true,
            id: id
        });
    };

    showModalCliente = () => {
        this.setState({
            modal_cliente_visible: true,
        });

    }

    /**
     * @methodOf  Usuarios
     * @method hideModal
     *
     * @description Oculta el modal del usuario
     *
     * */
    hideModal = () => {
        this.setState({
            visible: false,
            id: undefined
        });
    };

    /**
     * @methodOf  Usuarios
     * @method hideModal
     *
     * @description Oculta el modal del usuario
     *
     * */
    hideModalCliente = () => {
        this.setState({
            modal_cliente_visible: false,
        });
    };

    render() {

        const { getUsuarios, deleteUsuario, showModal, showModalCliente, hideModal, hideModalCliente, state, props, sendEmail } = this;
        const { visible, modal_cliente_visible, page, cliente_id, cliente, total, data, id, loading } = state;

        const user = this.context;

        return (
            <>
                <Spin spinning={loading}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title={(cliente_id != undefined) ?<div className="flex-left">
                            <Button ghost onClick={this.back} className="no-border" title="Volver a VMI">
                                <IconArrowBack />
                            </Button>
                            <Breadcrumb separator="de">
                                <Breadcrumb.Item>Usuarios</Breadcrumb.Item>
                                <Breadcrumb.Item>
                                    <Link className="text-gray" title="Ir a clientes" to={`/admin/clientes`}>
                                        {cliente.razon_social || cliente.nombre }                                    </Link>
                                </Breadcrumb.Item>
                            </Breadcrumb></div> : "Usuarios"}
                        extra={<span className="page-header-extra"> {total} Usuarios</span>}
                    >

                    </PageHeader>

                    <Content className="admin-content content-bg pd-1">
                        <Table
                            columns={[
                                {
                                    title: 'Logo',
                                    key: 'avatar',
                                    width: '80px',
                                    render: (text, { nombre, apellido, avatar }) => <CustomAvatar name={[nombre, apellido]} image={avatar} />,

                                },
                                {
                                    title: 'Nombre',
                                    dataIndex: 'nombre',
                                    render: (text, record) => (
                                        <h3>{record.nombre}</h3>
                                    ),
                                },
                                {
                                    title: 'Apellido',
                                    dataIndex: 'apellido',
                                    key: 'apellido',
                                },
                                {
                                    title: 'Posición',
                                    dataIndex: 'posicion',
                                    key: 'posicion',
                                },
                                {
                                    title: 'Email',
                                    dataIndex: 'email',
                                    key: 'email',
                                },
                                {
                                    title: 'Acciones',
                                    key: 'actions',
                                    fixed: 'right',
                                    width: '160px',
                                    render: (text, record) => (
                                        //(cliente_id == undefined) ?
                                            <Space size="small" direction="horizontal">

                                                <Popconfirm 
                                                    placement="topRight"
                                                    title="¿Deseas enviar un correo de recuperación de contraseña?" okText="Si" cancelText="No"
                                                    onConfirm={() => sendEmail(record.email)}
                                                >
                                                    <Button type="primary" title="Recuperar Contraseña" icon={<IconMail />} />
                                                </Popconfirm> 
                                                <Button type="primary" onClick={() => showModal(record._id)} title="Editar" icon={<IconEdit />} />
                                                {(user?.tipo == 1) ? <Popconfirm title="¿Deseas eliminar este usuario?" okText="Si" cancelText="No"
                                                    onConfirm={() => deleteUsuario(record._id)}
                                                >
                                                    <Button type="primary" danger title="Eliminar" icon={<IconDelete />} />
                                                </Popconfirm> : null}

                                            </Space>
                                            //: null

                                    ),
                                },
                            ]}
                            dataSource={data}
                            scroll={{ x: 1500 }}
                            pagination={{
                                onChange: getUsuarios,
                                current: page,
                                pageSize: 10,
                                total: total,
                                position: ['bottomLeft'],
                            }}
                            locale={{ emptyText: 'Sin Usuarios Disponibles' }}

                        />

                    </Content>
                    {
                        (user && user.tipo != 3) ?
                            (cliente_id != undefined || cliente_id != null) ?
                                <FloatingButton title="Nuevo Usuario" onClick={() => showModalCliente()} />
                                :
                                <FloatingButton title="Nuevo registro" onClick={() => showModal()} /> : null
                    }

                </Spin>
                <ModalUsuario
                    visible={visible}
                    hideModal={hideModal}
                    accept={() => {
                        hideModal();
                        getUsuarios();
                    }}
                    update={() => getUsuarios()}
                    id={id}
                >
                </ModalUsuario>

                <ModalUsuariosCliente
                    visible={modal_cliente_visible}
                    hideModal={hideModalCliente}
                    accept={() => {
                        hideModalCliente();
                        getUsuarios();
                    }}
                    update={() => getUsuarios()}
                    id={cliente_id}
                >
                </ModalUsuariosCliente>

            </>
        )
    }
}
