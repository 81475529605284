import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography,  Form, Input, message, Spin, Select } from 'antd';

import { SocketContext } from '../../../../Hooks/Socket'

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalAlmacen
 * @extends {Component}
 */
class ModalNotificar extends Component {

    static contextType = SocketContext

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            owners: []
        }
    }

    ModalNotificar = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOwners()
    }

    /**
     *
     *
     * @memberof ModalNotificar
     * @method getOwners
     * @description Obtiene el listado de dueños disponibles
     */
    getOwners = () => {
        axios.get('/usuarios', {
            params: {
                tipos: [1]
            }
        }).then(({data}) => {
            
            console.log(data.data)
            this.setState({
                owners: data.data.itemsList
            })

        }).catch(error => {
            message.error('Error al traer a los dueños')
            console.log(error)
        })
    }


    /**
     * @memberof ModalNotificar
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario, solicita enviar una notificacion a un dueño
     */
    onFinish = (values) => {

        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/ordenesCompra/notify', {
                orden_compra_id: this.props.orden_compra_id,
                admin_id: this.props.admin._id,
                path: this.props.path,
                ...values
            })
                .then(success => {
                    const socket = this.context
    
                    if(socket.connect){
                        let notificacion = {
                            evento: `${this.props.admin.nombre} te ha solicitado revisar una orden`,
                            detalle: `${this.props.admin.nombre} te ha solicitado revisar la orden ${this.props.folio} para su autorización`,
                            usuarios_destino: [values.owner_id],
                            usuario_id: this.props.admin._id
                        }
                        socket.emit('nueva_notificacion', notificacion)
                    }
    
                    message.success("Se le ha notificado al Dueño")
                })
                .catch(error => {
                    message.error('Error al notificar al Dueño')
                    console.log(error)
                })
                .finally(() => {
                    this.setState({loading: false}, () => this.props.onClose())
                })
        })
        

    }

    render() {


        return (
            <Form layout="vertical" ref={this.ModalNotificar} onFinish={this.onFinish} >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Dueños"
                                name="owner_id"
                            >
                                <Select>
                                    {
                                        this.state.owners?.map(owner => (
                                            <Option value={owner._id}>{
                                                owner.name && owner.apellido ? `${owner.name} ${owner.apellido}`
                                                : owner.name ? owner.name : owner.apellido 
                                            }</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Notificar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Notificar a Dueño </Title>
        </div>
        <ModalNotificar {...props} />
    </Modal>

}