import React, { Component } from "react";
import { Row, Col, Modal, Typography, Form, Statistic, Spin, } from 'antd';

const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalCompraDetalle
 * @extends {Component}
 */
class ModalCompraDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            factura: {},
            transaccion: null,
            razon_social: null,
            proveedor: null
        }

    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
    }

    render() {
        const { factura } = this.state;
        const { orden } = this.props;

        return (
            <Form layout="vertical"   >
                <Title level={3} className="text-center mb-3">Detalle Orden </Title>
                <Spin spinning={this.state.loading}>
                    <Row justify="center" className="mt-1" gutter={[16,16]}>
                        <Col xs={24} lg={11} className="">
                            <Form.Item label="Folio" >
                                <Text type="secondary" >{orden.folio}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item label="Fecha" >
                                <Text type="secondary" >{moment(factura.fecha).format('LL')}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item label="Producto">
                                <div className="flex-left-column">
                                    <Text type="secondary" >{orden.inventario_producto?.np_cliente}</Text>
                                    <small>{orden.orden_detalles?.producto_id?.modelo}</small>
                                </div>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11 }}>
                            <Form.Item label="Cantidad" >
                                <Text type="secondary" >{orden.orden_detalles?.cantidad}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 22 }}>
                            <Form.Item label="Almacén" >
                                <Text type="secondary" >{orden.almacen?.direccion}</Text>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 7 }}>
                            <Form.Item label="Subtotal" >
                                <Statistic
                                    value={orden.subtotal}
                                    valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF00' }}
                                    prefix={'$'}
                                    suffix={'MXN'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 8 }}>
                            <Form.Item label="Impuestos" >
                                <Statistic
                                    value={orden.impuestos}
                                    valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF00' }}
                                    prefix={'$'}
                                    suffix={'MXN'}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 7 }}>
                            <Form.Item label="Total" >
                                <Statistic
                                    value={orden.total}
                                    valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: '#00FF00' }}
                                    prefix={'$'}
                                    suffix={'MXN'}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <ModalCompraDetalle {...props} />
    </Modal>

}