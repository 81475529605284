import React, { Component } from 'react';
import {
    Layout,  Col, Row, message, Card, PageHeader,
    List, Spin, Typography, Radio,
} from 'antd'

import { CardInfoCustomerHeader } from '../../Widgets/Cards'


import {
    IconCustomHdTotal,
    IconCustomHdDnRango,
    IconCustomHdFrRango,
    IconCustomHdEntrega,
} from '../../Widgets/Iconos'
import axios from 'axios'

import ModalOrdenDetalle from './ModalOrdenDetalle'


const { Text, Title } = Typography;
const { Content } = Layout;

const estatus_ordenes = ['Aceptada', 'Confirmada ', 'Incompleta', 'Completa', 'En entrega', 'Entregada'];
const colores = ["#F67932", "#00A84D", "#003AD2", "#00A84D", "#E80000", "#8676FF"];

/**
 *
 *
 * @export
 * @class VMIDashboard
 * @extends {Component}
 */
export default class VMIDashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            // ordenes: [],
            ultimas: [],
            productos: {},

            data: [],
            dataPedidos: [],
            modalVisible: false,

            pedidos: 0,
            pagado: 0,
            saldo: 0,

            // page: 1,*
            // limit: 10,
            total: 0,


            ventas: {
                data: [],
                page: 1,
                limit: 10,

                pages: 0,
                total: 0,

                loading: false,
                search: null
            }

        }
    }

    /**
     * @memberof VMIDashboard
     * @method componentDidMount 
     * @description Cargamos la informacion inicial
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getData()
        this.getOrdenesVMI()
    }

    componentDidUpdate(prevProps){
        if(prevProps.search != this.props.search){
            this.getOrdenesVMI()
        }
    }

    /**
     * @memberof VMIDashboard
     * @method getData 
     * @description Cargamos la informacion inicial
     */
    getData = () => {
        axios.get('/customer/dashboard/vmi', {
        }).then(response => {
            this.setState({
                ultimas: response.data.ultimas,
                productos: response.data.productos
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la información')
        })
    }

    /**
     * @memberof VMIDashboard
     * @method getOrdenesVMI 
     * @description Obtiene un listado de ordenes VMI del cliente
     */
    getOrdenesVMI = (
        {
            page = this.state.ventas.page,
            limit = this.state.ventas.limit,
            tipo = this.state.ventas.tipo
        } = this.state.ventas,
        { ventas } = this.state
    ) => {

        this.setState({ loading: true })
        axios.get('/vmi/ventas', {
            params: {
                page,
                limit,
                search:this.props.search,
                tipo,
                cliente_only: true
            }
        })
            .then(({ data }) => {
                console.log('traje vmi ventas')
                this.setState(state => {
                    state.ventas.data = data.data.itemsList
                    state.ventas.pageCount = data.data.pageCount
                    state.ventas.total = data.data.itemCount
                    state.loading = false
                    // tipo
                    return state;
                })
                
            })
            .catch(err => {
                console.log('error al traer ventas',err)
                console.log(err)
            })
    }

    /**
     *
     *
     * @param {*} tipo
     * @param {number} [valor=0]
     * @returns
     * @memberof VMIDashboard
     */
    renderCard(tipo, valor = 0) {
        var bg_color = "white", titulo = "", texto = "Productos", clase = "text-white";

        switch (tipo) {
            case "sin_pagar":
                bg_color = "linear-gradient(89.69deg, #F39034 2.03%, #FF2727 99.73%)";
                titulo = "Sin Pagar"
                break;

            case "pnd_facturar":
                bg_color = "linear-gradient(270deg, #003AD2 0%, #0097EC 100%)";
                titulo = "Pendientes a Facturar"

                break;
            case "pagados":
                bg_color = "linear-gradient(270.23deg, #00A843 0.19%, #1FD071 99.8%)";
                titulo = "Pagados"

                break;
            case "perdidos":
                bg_color = "linear-gradient(269.97deg, #5900C9 0.02%, #9852F0 99.98%)";
                titulo = "Perdidos"
                break;


            case "total":
                clase = "";
                texto = "";
                titulo = "Total Productos"
                break;

            case "d_rango":
                clase = "";
                texto = "";
                titulo = "Dentro del Rango"

                break;
            case "f_rango":
                clase = "";
                texto = "";
                titulo = "Fuera del Rango"

                break;
            case "entrega":
                clase = "";
                texto = "";
                titulo = "Por Entregar"
                break;

            default:
                clase = "";
                texto = "";
                break;
        }


        return <Card style={{ borderRadius: "20px", background: bg_color }}>
            <Row>
                <Col span={4} >      {
                    (tipo === "total") ? <IconCustomHdTotal /> :
                        (tipo === "d_rango") ? <IconCustomHdDnRango /> :
                            (tipo === "f_rango") ? <IconCustomHdFrRango /> :
                                (tipo === "entrega") ? <IconCustomHdEntrega /> :
                                    null}
                </Col>
                <Col span={20} className="pl-1">
                    <Title style={{ marginTop: "10px" }} level={5} className={clase}> {titulo} </Title>
                </Col>
            </Row>
            <Row>
                <Col span={24} >
                    <Title style={{ margin: "0px" }} className={clase} >{valor}</Title>
                </Col>
            </Row>
        </Card>
    }

    /**
     *
     *
     * @param {*} tipo
     * @param {number} [valor=0]
     * @returns
     * @memberof VMIDashboard
     */
    renderEstatusLogistica = value => {
        console.log('v', value)

        switch (value) {
            case 0:
                return "Sin entregar"
                break;
            case 1:
                return "En entrega"
                break;
            case 2:
                return "En entrega"
                break;
            case 3:
                return "Entregado"
                break;


            default:
                break;
        }
    }

    /**
     *
     *
     * @param {*} tipo
     * @param {number} [valor=0]
     * @returns
     * @memberof VMIDashboard
     */
    renderEstatusPagado = value => {
        if (value) return "Pagado"
        return "Sin Pagar"

    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Vendor-Managed Inventory "
                    />
                    <Content className="admin-content content-bg pd-1">
                        <Row>
                            <Col xs={24} className='mb-1'>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} md={12} xl={6}>
                                        <CardInfoCustomerHeader
                                            bg_color={"linear-gradient(89.69deg, #F39034 2.03%, #FF2727 99.73%)"}
                                            titulo={"Sin Pagar"}
                                            texto={"Productos"}
                                            valor={this.state.pagado}
                                            icon="IconPdtCot"
                                        />

                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                        <CardInfoCustomerHeader
                                            bg_color={"linear-gradient(270deg, #003AD2 0%, #0097EC 100%)"}
                                            titulo={"Pendientes a Facturar"}
                                            texto={"Productos"}
                                            valor={this.state.pagado}
                                            icon="IconPdtAcp"
                                        />
                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                        <CardInfoCustomerHeader
                                            bg_color={"linear-gradient(270.23deg, #00A843 0.19%, #1FD071 99.8%)"}
                                            titulo={"Pagados"}
                                            texto={"Productos"}
                                            valor={this.state.pagado}
                                            icon="IconPrdAcp"
                                        />

                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                        <CardInfoCustomerHeader
                                            bg_color={"linear-gradient(269.97deg, #5900C9 0.02%, #9852F0 99.98%)"}
                                            titulo={"Perdidos"}
                                            texto={"Productos"}
                                            valor={this.state.pagado}
                                            icon="IconPrdPrc"
                                        />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={24} className='mb-1'>
                                <Row gutter={[16, 16]}>
                                    <Col xs={24} md={12} xl={6}>
                                        {this.renderCard("total", this.state.productos.total)}
                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                        {this.renderCard("d_rango", this.state.productos.dentro)}

                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                        {this.renderCard("f_rango", this.state.productos.fuera)}

                                    </Col>
                                    <Col xs={24} md={12} xl={6}>
                                        {this.renderCard("entrega")}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={24}>
                                <Row gutter={[16, 16]}>

                                    <Col xs={24} xl={18} >
                                        <Card className='pd-0' style={{ borderRadius: "20px" }} >
                                            <Row className='mb-1'>
                                                <Col xs={24} lg={6} >
                                                    <Title level={5} className='title-blue'>Ordenes VMI</Title>
                                                </Col>

                                                <Col xs={24} lg={18} className='right '>
                                                    <Radio.Group onChange={tipo => this.getOrdenesVMI({ tipo: tipo.target.value })} defaultValue="todas" buttonStyle="solid" className='right item-radio '>
                                                        <Radio.Button value="todas">Todas</Radio.Button>
                                                        <Radio.Button value="pedido">Pedido</Radio.Button>
                                                        <Radio.Button value="entregado">Entregado</Radio.Button>
                                                        <Radio.Button value="pagado">Pagado</Radio.Button>
                                                        <Radio.Button value="sin_pagar">Sin Pagar</Radio.Button>
                                                    </Radio.Group>
                                                </Col>
                                            </Row>

                                            <List
                                                loading={this.state.loading}
                                                className="component-list"
                                                itemLayout="horizontal"
                                                locale={{ emptyText: "Sin información para mostrar" }}
                                                dataSource={this.state.ventas.data}
                                                pagination={{
                                                    current: this.state.ventas.page,
                                                    pageSize: this.state.ventas.limit,
                                                    total: this.state.ventas.total,
                                                    position: 'bottom',
                                                    className: "flex-left",
                                                    onChange: (page, limit) => this.getOrdenesVMI({ page, limit })
                                                }}
                                                header={<Row className="header-list width-100" >
                                                    <Col xs={8} lg={3} className="center">
                                                        <Text strong>Folio</Text>
                                                    </Col>
                                                    <Col xs={8} lg={4} className="center">
                                                        <Text strong>Estatus de Entrega</Text>
                                                    </Col>
                                                    <Col xs={8} lg={3} className="center">
                                                        <Text strong>Estatus de Pago</Text>
                                                    </Col>
                                                    <Col xs={24} lg={4} className="center">
                                                        <Text strong>Producto</Text>
                                                    </Col>
                                                    <Col xs={24} lg={4} className="center">
                                                        <Text strong>Almacén</Text>
                                                    </Col>
                                                    <Col xs={12} lg={3} className="center">
                                                        <Text strong>Pagado</Text>
                                                    </Col>
                                                    <Col xs={12} lg={3} className="center">
                                                        <Text strong>Total</Text>
                                                    </Col>

                                                </Row>}
                                                
                                                renderItem={item => (
                                                    <List.Item className="component-list-item width-100 ">
                                                        <Row className=" hover width-100 font-12" style={{cursor: 'pointer'}} onClick={() => this.setState({ modalVisible: true, orden: item })}>
                                                            <Col xs={8} lg={3} className="center">
                                                                <Text className='item-orden-id'>{item.folio}</Text>
                                                            </Col>
                                                            <Col xs={8} lg={4} className="center">
                                                                <Text className='item-estatus'>{this.renderEstatusLogistica(item.logistica_estatus)}</Text>
                                                            </Col>
                                                            <Col xs={8} lg={3} className="center">
                                                                <Text className='item-estatus'>{this.renderEstatusPagado(item.pago_recibido)}</Text>
                                                            </Col>
                                                            <Col xs={24} lg={4} className="center">
                                                                <Text className='text-blue'>{item?.producto_id?.marca} {item?.producto_id?.np_cliente}</Text>
                                                            </Col>
                                                            <Col xs={24} lg={4} className="center">
                                                                <Text className='text-blue'>{item?.almacen_id?.direccion}</Text>
                                                            </Col>
                                                            <Col xs={12} lg={3} className="center">
                                                                <Text className='text-blue'>$ {item.pagado.toMoney(true)}</Text>
                                                            </Col>
                                                            <Col xs={12} lg={3} className="flex-right">
                                                                <Text className='text-blue'>$ {item.total.toMoney(true)}</Text>
                                                            </Col>
                                                        </Row>

                                                    </List.Item>
                                                )}
                                            />
                                        </Card>
                                    </Col>


                                    <Col xs={24} xl={6}>
                                        <Card style={{ borderRadius: "20px" }} >
                                            <Row style={{ width: "100%", borderBottom: "solid 1px #CCC" }} className='mb-2'>
                                                <Title level={5}> Ordenes de Compra</Title>
                                                <Col span={24} className='mb-1'>
                                                    <Text className='text-blue'>Pedidos </Text>
                                                    <Text className='right text-blue'>$ {this.state.pedidos}</Text>
                                                </Col>
                                                <Col span={24} className='mb-1'>
                                                    <Text className='text-blue'>Pagado </Text>
                                                    <Text className='right text-blue'>$ {this.state.pagado}</Text>
                                                </Col>
                                                <Col span={24} className='mb-1'>
                                                    <Text className='text-blue'>Saldo </Text>
                                                    <Text level={5} className='right text-blue ' strong style={{ borderTop: "solid 0.5px #CCC" }} >$ {this.state.saldo}</Text>
                                                </Col>

                                            </Row>

                                            <Row style={{ width: "100%", display: "inline-block" }}>
                                                <Title level={5}> Últimos Pedidos</Title>
                                                {this.state.ultimas.map(pedido => {
                                                    return <Row >
                                                        <Col span={2}> <span className='spn-status-order' style={{ borderColor: colores[pedido.status + 1] }} ></span></Col>
                                                        <Col span={14}>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <Text className='item-orden-id'>{pedido.folio}</Text>
                                                                </Col>
                                                                <Col span={24}>
                                                                    <Text className='text-blue'>{pedido.producto?.marca}</Text>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col span={8}> <Text className='right title-blue font-12' strong >$ {pedido.total.toMoney()} MXN</Text> </Col>
                                                    </Row>
                                                })}
                                            </Row>
                                        </Card>
                                    </Col>

                                </Row>
                            </Col>
                        </Row>



                    </Content>

                </Spin>

                <ModalOrdenDetalle
                    visible={this.state.modalVisible}
                    onClose={() => this.setState({ modalVisible: false, orden: undefined })}
                    orden={this.state.orden}
                />
            </>

        )
    }
}