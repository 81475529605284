import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import moment from 'moment'
import moment_es from 'moment/locale/es'


import moment_timezone from 'moment-timezone'

moment_timezone.tz.setDefault("America/Mexico_City");


// moment
moment.locale('es', moment_es);

function convertToMoney(string = false) {
  try {
    if (this) {
      var r = this.toString().replace(',', '').match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return (string === true) ? parseFloat(r).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : parseFloat(r)
    }
    else
      return 0.00
  } catch (error) {
    return 0.00
  }
}

Number.prototype.toMoney = convertToMoney

String.prototype.toMoney = convertToMoney


function convertToDolar(tipo_cambio = 19) {
  try {
    if (this) {
      let cambio = this / tipo_cambio;
      var r = cambio.toString().replace(',', '').match(/^-?\d+(?:\.\d{0,2})?/)[0]
      return parseFloat(r).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
    else
      return 0.00
  } catch (error) {
    return 0.00
  }
}

Number.prototype.toDolar = convertToDolar


function folioFormat(tipo_orden){
  let code = "0000000"
  let str = this.toString()
  let end = 7 - str.length
  let format
  if(tipo_orden === 2){
    format = `${process.env.VMI_SERIE}-${code.slice(0, end)}${str}`
  }else{
    format = `${process.env.SPOT_SERIE}-${code.slice(0, end)}${str}`
  }

  return format
}

Number.prototype.toFolioFormat = folioFormat

String.prototype.toFolioFormat = folioFormat

function toUnidades(){
  
  let unidades = {
      'H87': 'Piezas',
      'EA': 'Elementos',
      'KGM': 'Kilogramos',
      'MTR': 'Metros',
      'KT': 'Kits',
      'SET': 'Conjuntos',
      'LTR': 'Litros',
      'XBX': 'Cajas',
      'xun': 'Unidades',
      'GRM': 'Gramos',
  }

  return unidades[this]
}

String.prototype.toUnidades = toUnidades

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();