import React from 'react'
import { Route, Switch } from "react-router-dom";
import { Result } from 'antd';
import Usuarios from '../components/Admin/Usuarios/Usuarios';

// componentes

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterUsuarios( props) {
    return (
        <Switch>
            <Route exact path="/admin/usuarios" render={() => <Usuarios {...props} />} />
        </Switch>
    )
}

export default RouterUsuarios;
