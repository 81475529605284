import React, { useEffect, useState, } from "react";
import { Checkbox, List } from "antd";

import PropTypes from 'prop-types';

import './filtros.css'
import CustomAvatar from "../Avatar/Avatar";
const axios = require('axios')

/**
 *
 *
 * @param {*} props
 * @returns Component
 */
function FilterCuenta(props) {


    const { onChange, value } = props

    
    /** @type Array  @data */
    let [data, setData] = useState([])
    /** @type Boolean  @selected */
    let [selected, setSelected] = useState({})
    /** @type Boolean  @loading */
    let [loading, setLoading] = useState([])
    /** @type Integer  @page */
    let [page, setPage] = useState(1)



    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = sessionStorage.getItem('token');
        get()
    }, [])


    const triggerChange = (selected) => {
        onChange(selected);
        setSelected(selected)
    };

    /**
     *
     *
     * @param {*} currentPage
     * 
     * @method get
     * @description Obtenemos la lista de opciones.
     */
    const get = ({ currentPage = page, search = null } = {}) => {
        axios.get('/cuentas/list', {
            params: {
                page: currentPage,
            }
        }).then(response => {
            setData(response.data.data.itemsList)
        }).catch(error => {
            console.log(error)
        }).finally(() => setLoading(false))
    }


    /**
     *
     *
     * @param {*} _id
     * 
     * @method update
     * @description Cuando se actualiza un valor, se agrega al objeto de seleccionados
     */
    const update = (_id,nombre) => {
        if (selected[_id])
            delete selected[_id]
        else
            selected[_id] = nombre
        triggerChange({ ...selected });
    }


    return (

        <List
            size="small"
            dataSource={data}
            loading={loading}
            style={{ backgroundColor: '#fff' }}
            renderItem={({ _id, nombre, color, avatar, }) => <List.Item >
                <Checkbox
                    onChange={() => update(_id,nombre)}
                    style={{ margin: '0 5px', position: 'relative', top: '1px' }}
                    checked={selected[_id]}
                >
                    <CustomAvatar
                        name={nombre}
                        image={avatar}
                        color={color}
                    />
                    {nombre}
                </Checkbox>

            </List.Item>}
        />
    )
}

FilterCuenta.propTypes = {
    onChange: PropTypes.func,
    onChange: PropTypes.func,
};

export default FilterCuenta
