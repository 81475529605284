import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Spin, message } from 'antd';
import CustomAvatar from "../../../Widgets/Avatar/Avatar";

const { Title, Text } = Typography;
const axios = require('axios').default;
const moment = require('moment');



/**
 *
 *
 * @class ModalSolicitudDetalle
 * @extends {Component}
 */
class ModalSolicitudDetalle extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            solicitud: {},
        }

    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getSolicitud()
    }

    /**
    *
    * @memberof ModalSolicitudDetalle
    * @method getSolicitud
    * @description Obtiene la solicitud por medio del id en props y acualiza su estatus a visto
    */
    getSolicitud = () => {
        this.setState({ loading: true })
        axios.get('/solicitudes/get', {
            params:{
                solicitud_id: this.props.solicitud_id,
            
            }
        })
            .then(({data}) => {
                const solicitud = data
                console.log("solicitud", solicitud);
                this.setState({ solicitud });
            })
            .catch(error => {
                message.error('Error al obtener solicitud')
                console.log('error', error.response)
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    /**
     * 
     * @memberof ModalSolicitudDetalle
     * @method aceptarSolicitud
     * @description Actualiza los registros de los productos del almacen de origen y el disponible si el 
     * almacen disponibles cuenta con las existencias suficientes. Actualiza el estado a "ACEPTADO" al terminar.
     */
    aceptarSolicitud = () => {

        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            const {almacen_disponible, almacen_producto, cantidad} = this.state.solicitud
    
            if(almacen_disponible?.cantidad <= cantidad) {
                message.error('Existencias insuficientes')
                return
            }
    
            let peticiones = [
                axios.post('/almacenes/productos/update',{
                    id: almacen_producto?._id,
                    cantidad,
                    agregar_cantidad: true
                }),
                axios.post('/almacenes/productos/update',{
                    id: almacen_disponible?._id,
                    cantidad,
                    remover_cantidad: true
                })
            ]
    
            Promise.all(peticiones)
                .then((values) => {
                    message.success("Solicitud Procesada")
                    this.updateEstatus(3) // 3 ->  Solicitud aceptada
                    this.props.onClose()
                })
                .catch(error => {
                    message.error("Solicitud No Procesada")
                })
                .finally(() => {
                    this.setState({loading: false})
                })
        })
        

    }

    /**
     *
     *
     * @memberof ModalSolicitudDetalle
     * @method declinarSolicitud
     * @description Cambia el estado de la solicitud a "DECLINADO"
     */
    declinarSolicitud = () => {
        this.updateEstatus(4) // 4 -> Solicitud declinada
        this.props.onClose()
    }

    /**
     *
     *
     * @param {*} estatus Numero que representa el estatus de la solicitud
     * @memberof ModalSolicitudDetalle
     * @method updateEstatus
     * @description Actualizar el estatus del registro de la solicitud
     */
    updateEstatus = (estatus) => {
        axios.post('/solicitudes/update', {
            id: this.props.solicitud_id,
            estatus
        })
            .then(response => {
                message.success("Estatus de solicitud actualizada")
            })
            .catch(error => {
                message.error("Error al actualizar estatus")
            })
    }

    /**
     *
     *
     * @param {*} values Objeto con los datos del formulario
     * @memberof ModalSolicitudDetalle
     * @method onFinish
     * @description Se hace cargo de ejecutar la accion que se aplicara a la solicitud
     */
    onFinish = (values) => {
        switch(this.state.accion){
            case 'aceptar':
                this.aceptarSolicitud()
                break
            case 'declinar':
                this.declinarSolicitud()
                break
            default:
                break
        }
    }

    render() {
        const { updatedAt, cliente_solicitante_id, usuario_id, almacen_id, producto_id, cantidad, cliente_emisor_id, almacen_emisor } = this.state.solicitud;

        return (
            <Form layout="vertical" onFinish={this.props.onClose}  >
                <Title level={3} className="text-center">Detalle Solicitud </Title>
                <Spin spinning={false}>
                    <Row justify="center" gutter={[32, 0]}>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Fecha" >
                                <Text type="secondary" >{moment(updatedAt).format('LL')}</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Cliente Solicitante" >
                                <Text type="secondary" >{cliente_solicitante_id?.nombre} {cliente_solicitante_id?.apellidos}</Text>
                            </Form.Item>
                        </Col>
                        
                        <Col xs={24} lg={11}>
                            <Form.Item label="Responsable Solicitante" >
                                <Text type="secondary" > <CustomAvatar name={`${usuario_id?.nombre} ${usuario_id?.apellido}`} /> {`${usuario_id?.nombre} ${usuario_id?.apellido}`}</Text>
                            </Form.Item>
                        </Col>
                         <Col xs={24} lg={11}>
                            <Form.Item label="Almacén Solicitante" >
                                <Text type="secondary" >{almacen_id?.direccion}</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Producto" >
                                <Text type="secondary" >{producto_id?.np_fabricante}</Text>
                            </Form.Item>
                        </Col>

                       <Col xs={24} lg={11}>
                            <Form.Item label="Cantidad" >
                                <Text type="secondary" >{cantidad}</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Cliente Emisor" >
                                <Text type="secondary" >{cliente_emisor_id?.razon_social}</Text>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={11}>
                            <Form.Item label="Almacén Emisor" >
                                <Text type="secondary" >{almacen_emisor?.direccion}</Text>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Cerrar
                                </Button>
                            </Form.Item>
                        </Col>
                       
                        {/*
                            estatus !== 3 && this.props.tipo !== 1 ? (
                            <>
                            <Col xs={10}>
                                <Form.Item >
                                    <Button 
                                        type="primary" 
                                        htmlType="submit"
                                        className="width-100"
                                        disabled={(estatus === 4)}
                                        style={{
                                            backgroundColor: "#ED0303",
                                            borderColor: "#ED0303"
                                        }}
                                        onClick={()=> this.setState({accion: 'declinar'})}
                                    >
                                        Declinar
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col xs={10}>
                                <Form.Item >
                                    <Button 
                                        type="primary" 
                                        htmlType="submit"
                                        className="width-100"
                                        style={{
                                            backgroundColor: "#08CA27",
                                            borderColor: "#08CA27"
                                        }}
                                        loading={this.state.loading}
                                        onClick={()=> this.setState({accion: 'aceptar'})}
                                    >
                                        Aceptar
                                    </Button>
                                </Form.Item>
                            </Col>
                            </>
                            ) : null
                        */}
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <ModalSolicitudDetalle {...props} />
    </Modal>

}