import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, InputNumber, Radio } from 'antd';
import { IconCerrarModal } from './../../Widgets/Iconos'

import User from "../../../Hooks/Logged";




const { Title, Text } = Typography;
const axios = require('axios').default;


/**
 * @class ModalCompraProductos
 * @extends {Component}
 */
class ModalCompraProductos extends Component {

    static contextType = User;

    constructor(props) {
        super(props);
        this.state = {
            producto: this.props.producto,
            cntDisponible: this.props.cantidad_disp,
            np_cliente: this.props.np_cliente,
            loading: false,
            return: false,
            premium: false,
            cantidad: 0,
            subtotal: 0,
            iva: 0,
            total: 0,


            step: 0,

        }
    }

    ModalCompraProductos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

    }

    /**
     * @memberof ModalCompraProductos
     * @method onFinish
     * @description Crea una nueva orden de compra para clientes premium
     */
    onFinish = (values) => {
        if (this.state.loading) return
        this.setState({ loading: true }, () => {
            axios.post('/vmi/orden/add', {
                producto_id: this.props.producto?._id,
                almacen_id: this.props.almacen_id,
                cantidad_proceso: this.props.cantidad_proceso,
                ...values
            }).then(response => {
                message.success('Compra Creada')
                this.props.onClose()
            }).catch(error => {
                if (error.response.status === 401)
                    message.error(error.response?.data?.message)
                else
                    message.error('Error al crear la orden')
            }).finally(() => this.setState({ loading: false }))
        })

    }


    render() {

        return <>
            <Spin spinning={this.state.loading}>
                <div className="center">
                    <Title level={3}>  Compra de Productos </Title>
                </div>
                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                >
                    <Row className="mt-1 center mb-1">
                        <Col xs={24} lg={10}>
                            <Text className="text-gray">Producto</Text>
                            <Title className="text-gray" level={4} >{this.state.np_cliente}</Title>
                        </Col>
                        <Col xs={24} lg={10}>

                            <Text className="text-gray"> Cantidad Disponible</Text>
                            <Title className="text-gray" level={4} >{this.state.cntDisponible}</Title>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col xs={4} className="flex-left">
                            <Text className="text-gray">Subtotal</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Title className="text-gray" level={4} >{this.props.precio ? `$ ${(this.state.subtotal).toMoney(true)} MXN` : `$ - MXN`}</Title>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col xs={4} className="flex-left">
                            <Text className="text-gray">IVA 16%</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Title className="text-gray" level={4} >{this.props.precio ? `$ ${(this.state.iva).toMoney(true)} MXN` : `$ - MXN`}</Title>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col xs={4} className="flex-left">
                            <Text className="text-gray">Total</Text>
                        </Col>
                        <Col xs={12} className="flex-right">
                            <Title className="text-gray" level={4} >{this.props.precio ? `$ ${(this.state.total).toMoney(true)} MXN` : `$ - MXN`}</Title>
                        </Col>

                    </Row>

                    <Row className="mt-1 center">
                        <Col xs={20} lg={12} className="mt-3 mb-3">
                            <Text className="text-gray mb-1">Cantidad Requerida</Text>
                            <Form.Item
                                className="mt-2"
                                name="cantidad"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la cantidad requerida"
                                }]}
                            >
                                <InputNumber
                                    placeholder="0"
                                    className="width-100"
                                    min={0}
                                    max={10000000}
                                    onChange={(cantidad) => {
                                        let subtotal = this.props.precio * cantidad
                                        let iva = subtotal * 0.16
                                        let total = subtotal + iva

                                        this.setState({
                                            subtotal,
                                            iva,
                                            total,
                                        })
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Aceptar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </>

    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
        closeIcon={<IconCerrarModal />}
    >

        <ModalCompraProductos {...props} />
    </Modal>

}