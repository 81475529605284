import React, { Component, useState, useEffect } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Upload, Select, InputNumber, notification, Checkbox, Switch } from 'antd';


import PropTypes from "prop-types";
import { PlusOutlined } from "@ant-design/icons";
import ModalProductos from "../../../Styles/Modules/Productos/ModalProductos.scss";

const axios = require('axios').default;

const { Title, } = Typography;
const { Option } = Select;
/**
 *
 *
 * @export
 * @class FormProducto
 * @extends {Component}
 */
class FormProducto extends Component {


    /**
     *
     *
     * @static
     * @memberof FormProducto
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            proveedores: [],
            claves: {
                data: [],

                page: 1,
                limit: 20,

                total: 0,
                pages: 0,
                search: null,

            },

            errors: []
        }
    }

    formModalNegocios = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');


        console.log("this.props.id !== undefined", this.props.id);
        if (this.props.id && this.props.id !== null) {
            console.log("GET")
            this.get()
        }
        else {
            console.log("AS")
            this.getNumberPart()
        }


        if (this.props.productoValues)
            this.formModalNegocios.current.setFieldsValue(this.props.productoValues)


        this.getProveedores()
        this.getSATClaveProdServ()

    }



    getNumberPart = () => {

        axios.get('/productos/np-nexus')
            .then(({ data }) => {
                this.formModalNegocios.current.setFieldsValue({ np_nexus: data.data })
            })
            .catch(response => {
                console.log("DATRA", response)
            })


    }


    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method get
     * @description Obtenemos el producto
     */
    get = (id = this.props.id) => {
        this.setState({ loading: true })
        axios.get("/producto/get", {
            params: { id, clave_producto: true }
        })
            .then(async ({ data }) => {
                data = data.data

                if (data.clave_producto && data.clave_producto_descripcion)
                    data.clave_producto = {
                        label: <>
                            <strong>{data.clave_producto_descripcion} {data.clave_producto}</strong>
                            <small style={{ float: "right" }}>{data.clave_producto}</small>
                        </>,
                        value: data.clave_producto
                    }



                this.formModalNegocios.current.setFieldsValue({
                    ...data,
                    imagenes: data.imagenes.map((imagen, index) => ({
                        uid: -index,
                        name: imagen.name,
                        filename: imagen.filename,
                        status: 'done',
                        url: axios.defaults.baseURL + '/upload/th_' + imagen.filename
                    }))
                })
            })
            .catch(res => {
                message.error('No se pudo cargar el Producto')
                console.log("no obtenida", res);
            }).finally(() => this.setState({ loading: false }))
    }



    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method onFinish
     * @description Cuando se guarda el producto
     */
    onFinish = values => {
        values.impuestos = !values.impuestos;
        //console.log('values producto', values)
        //console.log("values.clave_producto", values.clave_producto)
        values.clave_producto = (values.clave_producto?.value) ? values.clave_producto?.value : values.clave_producto
        //console.log("values.clave_producto", values.clave_producto)

        // (this.props.id !== undefined) ? this.update : this.save

        return (this.props.id !== undefined) ? this.update(values) : this.save(values)
    }


    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method save
     * @description Guardamos el producto
     */
    save = (values) => {

        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            axios.post('/producto/add', { ...values, id: values._id })
                .then(({ data }) => {
                    message.success("¡Se ha guardado correctamente el producto!")
                    this.props.onCancel(data.data);
                })
                .catch((e) => {

                    if (Array.isArray(e.response.data.message) && e.response.data.message.length > 0) return notification.error({
                        message: `No fue posible guardar`,
                        description: <ul>{e.response.data.message.map(e => <li>{e}</li>)}</ul>,
                        placement: "top",

                        style: { margin: "0 auto" },
                        duration: 3
                    });
                    console.log('e', e.response)
                    message.error('No se pudo guardar el Producto')
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        })

    }



    /**
     *
     *
     * @memberof FormProducto
     * 
     * @method update
     * @description Actualizamos el producto.
     */
    update = (values) => {
        this.setState({ loading: true })
        axios.put('/producto/update', { ...values, id: this.props.id })
            .then(() => {
                message.success("¡Se ha guardado correctamente el producto!")
                this.props.onCancel();
            })
            .catch((e) => {
                if (Array.isArray(e.response.data.message) && e.response.data.message.length > 0) return notification.error({
                    message: `No fue posible guardar`,
                    description: <ul>{e.response.data.message.map(e => <li>{e}</li>)}</ul>,
                    placement: "top",

                    style: { margin: "0 auto" },
                    duration: 3
                });
                console.log('e', e.response)
                message.error('No se pudo guardar el Producto')
            })
            .finally(() => this.setState({
                loading: false,
                loadingImage: false,
                image: undefined
            }))
    }


    /**
    * @memberof ModalTransacciones
    * @method getProveedores
    * @description Trae los proveedores en lista
    */
    getProveedores = (search) => {
        axios.get('/proveedores/list', {
            params: {
                search,
                paginate: false
            }
        }).then(response => {
            this.setState({ proveedores: response.data.data })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Proveedores')
        })
    }


    /**
    * @memberof ModalTransacciones
    * @method getProveedores
    * @description Trae los proveedores en lista
    */
    getSATClaveProdServ = (
        {
            page = this.state.claves.page,
            limit = this.state.claves.limit,

            search = this.state.claves.search
        } = this.state.claves,
        {
            claves
        } = this.state,
    ) => {

        claves.page = page;
        claves.limit = limit
        claves.search = search
        this.setState({ claves })

        return axios.get('/SAT/claves-productos-servicios', {
            params: {
                page,
                limit,
                search
            }
        })
            .then(({ data }) => {
                const pagination = data.data
                claves.data = pagination.data;
                claves.total = pagination.total;
                claves.page = pagination.page;
                this.setState({ claves })
            })
            .catch(error => {
                console.log(error)
                message.error('Error al traer las Claves SAT')
            })
    }

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof ModalTransaccion
     *
     * @method normFile
     * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;
        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
                fileList[x].name = fileList[x].response.name
            }
        }
        if (file.status === "removed")
            this.removeFile((file?.response?.filename) ? file.response.filename : file.filename);
        return e.fileList;
    };


    render() {
        const { normFile, formModalNegocios, } = this;
        const { form, loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{this.props.id ? "Editar Producto" : "Nuevo Producto"}</Title>
                <Form layout="vertical" ref={formModalNegocios} initialValues={form} onFinish={this.onFinish} >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Número de Parte de Nexus"
                                name="np_nexus"
                                rules={[
                                    { required: true, message: "Ingrese el número de parte del fabricante" }
                                ]} >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Número de Parte de Fabrica"
                                name="np_fabricante"
                                rules={[
                                    { required: true, message: "Ingrese el número de parte del fabricante" }
                                ]} >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                                rules={[
                                    { required: true, message: "Ingrese la descripción" }
                                ]} >
                                <Input.TextArea placeholder="Descripción" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Marca"
                                name="marca"
                                rules={[
                                    { required: true, message: "Ingrese la marca" }
                                ]} >
                                <Input placeholder="Marca" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="SKU"
                                name="sku"
                                rules={[
                                    { required: true, message: "Ingrese el SKU" }
                                ]} >
                                <Input placeholder="SKU" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Costo"
                                name="costo"
                                rules={[
                                    { required: true, message: "Ingrese el costo" }
                                ]}>
                                <InputNumber 
                                    className="width-100 input-number-modal"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    placeholder="Costo"
                                    addonAfter={
                                        <Form.Item
                                            style={{ marginBottom: 0 }}
                                            name="moneda"
                                            rules={[
                                                { required: true, message: "Ingrese la moneda" }
                                            ]}
                                            noStyle
                                        >
                                            <Select style={{ minWidth: 80 }}>
                                                <Option value="MXN">MXN</Option>
                                                <Option value="USD">USD</Option>
                                            </Select>
                                        </Form.Item>
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[
                                    { required: true, message: "Ingrese el Proveedor" }
                                ]} >
                                <Select
                                    placeholder="Seleccione el Proveedor"
                                    showSearch
                                    onSearch={(value) => this.getProveedores(value)}
                                    filterOption={false}
                                >
                                    {this.state.proveedores.map(({ nombre, _id }) => <Option value={_id}>{nombre}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Clave Producto (SAT)"
                                name="clave_producto"

                                extra={<>
                                    Puede buscar el tipo de producto aquí o <a target={"_blank"} href="http://pys.sat.gob.mx/PyS/catPyS.aspx">en el SAT con este enlace</a>.
                                </>}
                                rules={[
                                    { required: true, message: "Ingrese la clave del producto en base al catalogo del SAT" }
                                ]}>
                                <Select
                                    size="large"
                                    showSearch
                                    onSearch={search => this.getSATClaveProdServ({ search })}
                                    placeholder="Buscar"
                                    filterOption={false}
                                    valuePropName="value"
                                    value
                                >
                                    {this.state.claves.data.map(({ clave, descripcion }) => <Option value={clave} key={clave}>
                                        <strong>{descripcion}</strong> <small style={{ float: "right" }}>{clave}</small>
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Unidad"
                                name="unidad"
                                rules={[
                                    { required: true, message: "Seleccione la unidad de medida del producto" }
                                ]} >
                                <Select laceholder="Unidad">
                                    <Option value={'H87'}>Pieza</Option>
                                    <Option value={'EA'}>Elemento</Option>
                                    <Option value={'KGM'}>Kilogramo</Option>
                                    <Option value={'MTR'}>Metro</Option>
                                    <Option value={'KT'}>Kit</Option>
                                    <Option value={'SET'}>Conjunto</Option>
                                    <Option value={'LTR'}>Litro</Option>
                                    <Option value={'XBX'}>Caja</Option>
                                    <Option value={'xun'}>Unidad</Option>
                                    <Option value={'GRM'}>Gramo</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="visible"
                                label="Visible"
                                valuePropName="checked"
                            >
                                <Switch/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                name="impuestos"
                                label=" "
                                valuePropName="checked"
                                
                            >
                                <Checkbox onChange={(e)=>console.log('cambio valor', e)}>Libre de impuestos</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Imagenes del Producto"
                                name="imagenes"
                                getValueFromEvent={normFile}
                                valuePropName="fileList"
                            >
                                <Upload
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    multiple={true}
                                    listType="picture-card"
                                >
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Subir</div>
                                    </div>
                                </Upload>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading} >
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */

export default React.forwardRef((props, ref) => {

    const { visible, onCancel } = props
    return (
        <Modal

            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormProducto ref={ref} {...props} />
        </Modal>
    )
});
// export default function (props) {

//


// }
