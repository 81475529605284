import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Tooltip, Row, Spin, Popover, Typography, Upload, Select, Switch } from 'antd';

import PropTypes from "prop-types";
import { LoadingOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import ColorPicker from '../../Widgets/ColorPicker';


const axios = require('axios').default;
const { Title, } = Typography;
const { Option } = Select


/**
 *
 *
 * @export
 * @class FormRazonSocial
 * @extends {Component}
 */
class FormRazonSocial extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => {
        },
        accept: () => {
        }
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            form: {},
            loadingImage: false
        }
    }

    formModalRS = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        if (this.props.id !== this.state.id && this.props.id !== undefined) {
            this.state.id = this.props.id
            this.getRazonSocial(this.props.id)
        }
    }

    /**
     * @methodOf FormRazonSocial
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se actualiza una propiedad o un
     *
     * */
    componentDidUpdate() {
        //Si la propiedad cambia y es diferente al estado, actualizamos el estado con el ID de la poppiedad y buscamos al usuario
        if (this.props.id !== this.state.id && this.props.id !== undefined && this.props.id !== null) {
            this.state.id = this.props.id
            this.getRazonSocial(this.props.id)
        }

        //Si la propiedad es diferente de la actual y el estado es diferente de nada y la propiedad es nada, reiniciamos el formulario.
        if (this.props.id !== this.state.id && this.state.id !== undefined && this.props.id === undefined) {
            this.state.id = undefined;
            this.state.form = {};
            this.state.image = undefined;
            this.formModalRS.current.resetFields();
        }
    }

    /**
     * @methodOf FormRazonSocials
     * @function getRazonSocial
     *
     * @description Obtiene la razón social
     *
     * */
    getRazonSocial = (id) => axios.get("/razones-sociales/get", {
        params: { id }
    })
        .then(async ({ data }) => {
            const logo = data.data.logo;
            if (data.data.logo !== undefined && data.data.logo !== null && data.data.logo !== "") {
                this.setState({
                    image: {
                        url: axios.defaults.baseURL + '/upload/' + logo,
                        name: logo
                    }
                })
                data.data.logo = [{
                    uid: -1,
                    name: data.data.logo,
                    status: 'done',
                    url: axios.defaults.baseURL + '/upload/' + data.data.logo,
                    response: { filename: data.data.logo }
                }];

            } else data.data.logo = []
            this.setState({
                form: data.data,
            })
            await this.formModalRS.current.resetFields();
        })
        .catch(res => {
            message.error('No se pudo cargar la Razón Social')
            console.log("no obtenida", res);
        })


    /**
     * @methodOf FormRazonSocials
     * @function saveRazonSocial
     *
     * @description Guarda la razón social
     *
     * */
    saveRazonSocial = values => {

        if (this.state.loading) return

        this.setState({ loading: true }, () => {
            if (this.state.image)
                values.logo = this.state.image.name;
            else
                values.logo = null;
            if (values._id) {

                axios.put('/razones-sociales/update', { ...values, id: values._id })
                    .then(() => {
                        message.success("¡Se ha guardado correctamente la razón social!")
                        this.props.accept();
                    })
                    .catch((e) => {
                        message.error('No se pudo guardar la razón social')
                        console.log('e', e)
                    })
                    .finally(() => this.setState({
                        loading: false,
                        loadingImage: false,
                        image: undefined
                    }))
            } else {
                axios.post('/razones-sociales/add', { ...values, id: values._id })
                    .then(() => {
                        message.success("¡Se ha guardado correctamente la razón social!")
                        this.props.accept();
                    })
                    .catch((e) => {
                        message.error('No se pudo guardar la razón social')
                        console.log('e', e)
                    })
                    .finally(() => this.setState({
                        loading: false,
                        loadingImage: false,
                        image: undefined
                    }))
            }
        })

    }



    /**
     *
     * @memberof ModalUsuarios
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof ModalUsuarios
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {

        const { file } = e;
        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "uploading")
            this.setState({ loadingImage: true })


        if (file.status === "done") {
            if (this.state.image)
                this.removeFile(this.state.image.name)

            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                },
                loadingImage: false
            })
            if (this.state.form._id)
                axios.put('/razones-sociales/update', { logo: e.file.response.filename, id: this.state.form._id })
                    .finally(() => {
                        this.props.update()
                    });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name)

        console.log('uploadeed razones sociales')
        return e && e.fileList;
    };


    /**
     *
     * @memberof FormRazonSocials
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);
        this.state.form.avatar = [];
        this.state.image = undefined;
        this.formModalRS.current.resetFields();
        if (this.state.form._id)
            axios.put('/razones-sociales/update', { logo: 0, id: this.state.form._id })
                .finally(() => {
                    this.props.update()
                    this.setState({ loadingImage: false })
                })
        else {
            this.props.update()
            this.setState({ loadingImage: false })
        }
    };

    render() {
        const { normFile, formModalRS, saveRazonSocial, deleteImage } = this;
        const { form, image, loading, id, loadingImage } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{id ? "Editar Razón Social" : "Nueva Razón Social"}</Title>
                <Form layout="vertical" ref={formModalRS} initialValues={form} onFinish={saveRazonSocial} >
                    <Form.Item name="_id" noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                    <Row align="center">
                        <Form.Item name="logo" align="center" getValueFromEvent={normFile} valuePropName="fileList"
                            help={image ? <Button className="btn-upload-delete" shape="circle" danger onClick={deleteImage}><DeleteOutlined /></Button> : null} >
                            <Upload
                                listType="picture-card"
                                className="avatar-uploader"
                                showUploadList={false}
                                action={axios.defaults.baseURL + "/upload/add"}
                                accept="image/*"
                            >
                                {(loadingImage) ? <div>
                                    {(this.state.loading || loadingImage) ? <LoadingOutlined /> : <PlusOutlined />}
                                    <div className="ant-upload-text">
                                        Subir Archivo
                                    </div>
                                </div> : ((image) ?
                                    <img src={image.url} alt="avatar" style={{ width: '100%' }} />
                                    :
                                    <div>
                                        {this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
                                        <div className="ant-upload-text">
                                            Subir Archivo
                                        </div>
                                    </div>)}
                            </Upload>
                        </Form.Item>
                    </Row>
                    <Row>
                        <Col xs={24} lg={11}>
                            <Tooltip title={<>
                                El nombre de la razón social debe ser igual al que se tiene registrado en el SAT para evitar discrepancias.<br />
                                Puede obtener el nombre oficial desde el siguiente enlace
                            </>}>
                                <Form.Item label="Razón Social" name="razon_social"
                                    rules={[{ required: true, message: "Por favor, ingrese la razón social" }]}
                                    extra={<Button type="link" className="m-0 p-0" target="_blank" href="https://portalsat.plataforma.sat.gob.mx/RecuperacionDeCertificados/">Enlace SAT</Button>}
                                >
                                    <Input placeholder="Razón Social"></Input>
                                </Form.Item>
                            </Tooltip>

                        </Col>

                        <Col xs={24} lg={{ span: 11, push: 1 }}>
                            <Form.Item label="Descripción" name="descripcion" rules={[{ required: true, message: "Por favor, ingrese descripción" }]} >
                                <Input placeholder="Descripción"></Input>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item label="RFC" name="rfc" rules={[{ required: true, message: "Por favor, ingrese el RFC" }]} >
                                <Input placeholder="RFC"></Input>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 1 }} >
                            <Form.Item label="Color" name="color" >
                                <ColorPicker
                                    colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Pagable"
                                name="pagable"
                                valuePropName="checked"
                            >
                                <Switch />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row className="ant-row-center">
                        <Col xs={24}>
                            <Form.Item
                                label="Regimen Fiscal"
                                name="regimen_fiscal"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Regimen"
                                }]} >
                                <Select
                                    showArrow
                                    className="witdh-100"
                                    placeholder="Seleccione un regimen"
                                >
                                    <Option value="601">General de Ley Personas Morales</Option>
                                    <Option value="603">Personas Morales con Fines no Lucrativos</Option>
                                    <Option value="605">Sueldos y Salarios e Ingresos Asimilados a Salarios</Option>
                                    <Option value="606">Arrendamiento</Option>
                                    <Option value="607">Régimen de Enajenación o Adquisición de Bienes</Option>
                                    <Option value="608">Demás ingresos</Option>
                                    <Option value="609">Consolidación</Option>
                                    <Option value="610">Residentes en el Extranjero sin Establecimiento Permanente en México</Option>
                                    <Option value="611">Ingresos por Dividendos (socios y accionistas)</Option>
                                    <Option value="612">Personas Físicas con Actividades Empresariales y Profesionales</Option>
                                    <Option value="616">Sin obligaciones fiscales</Option>
                                    <Option value="620">Sociedades Cooperativas de Producción que optan por diferir sus ingresos</Option>
                                    <Option value="621">Incorporación Fiscal</Option>
                                    <Option value="622">Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras</Option>
                                    <Option value="623">Opcional para Grupos de Sociedades</Option>
                                    <Option value="624">Coordinados</Option>
                                    <Option value="625">Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas</Option>
                                    <Option value="626">Régimen Simplificado de Confianza</Option>
                                    <Option value="629">De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales</Option>


                                </Select>
                            </Form.Item>

                        </Col>

                    </Row>

                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading || this.state.loadingImage}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalNegocios
 *@description 
 */
export default function (props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.hideModal}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormRazonSocial {...props} />
        </Modal>
    )
}
