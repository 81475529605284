import React from 'react'
import { Route, Switch } from "react-router-dom";

import {PrivateRoutes} from './Routes/Middleware/PrivateRoute';

import RouterPublic from "./Routes/PublicRoutes";
import AdminRoutes from "./Routes/AdminRoutes";
import CustomerRouter from "./Routes/CustomerRoutes";



/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function Routes() {
    return (
        <Switch>
            <PrivateRoutes path='/admin'>
                <Route component={AdminRoutes} />
            </PrivateRoutes>

            <PrivateRoutes path='/customer'>
                <Route component={CustomerRouter} />
            </PrivateRoutes>
            
            <Route path="/" component={RouterPublic} />

        </Switch>
    )
}

export default Routes;

