import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Auth } from "./AuthVerify";
/**
 *
 *
 * @const PrivateRoute
 * @description Restringe el acceso a las rutas privadas si no se ha iniciado sesion
 */
const PrivateRoutes = ({ component: Component, ...rest }) => {
    
    if(Auth())
        return (<Route 
            {...rest} 
            render={(props) => (
                <Component {...props}/> 
            )}
        />)
    else
        return (<Redirect to='/login'/>)
};

export {
    PrivateRoutes
}