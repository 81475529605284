import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, InputNumber, Select, Checkbox } from 'antd';

import { User } from '../../../Hooks/Logged';
import { SocketContext } from '../../../Hooks/Socket';

const { Title, Text } = Typography;
const { Option } = Select

const axios = require('axios').default;


/**
 *
 *
 * @class ModalSolicitudProductos
 * @extends {Component}
 */
class ModalSolicitudProductos extends Component {

    static contextType = User

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            return: false,
            disabled: false,
            premium: false,
            almacenes: [],
            almacen_origen: {},
            almacen_disponible: {}
        }
    }

    ModalSolicitudProductos = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.clear()


            this.getAlmacenesDisponibles()
            this.getProductos()
            this.ModalSolicitudProductos.current?.setFieldsValue({
                almacen_producto_id: this.props.almacen?.almacen_producto?.id,
                producto_id: this.props.almacen?.producto?.id
            })
    }



     /**
     * @memberOf ModalSolicitudProductos
     * @method getAlmacenesDisponibles
     * @description Obtiene la lista almacenes de otros clientes, con el mismo producto seleccionado
     */
    getAlmacenesDisponibles = () => {
        
        let producto_id = this.ModalSolicitudProductos.current.getFieldValue('producto_id')

        axios.get('/almacenes/productos/solicitud', {
            params: {
               cliente_id: this.props.cliente_id,
               producto_id: producto_id?.value,
            }
        }).then(({ data }) => {
            this.setState({
                almacenes_disponibles: data
            })
            this.ModalSolicitudProductos.current.setFieldsValue({
                almacen_producto_id: null
            })
            
        })
        .catch(error => {
            console.log(error)
        })
    }

    /**
     * @memberOf ModalSolicitudProductos
     * @method getAlmacenesDetino
     * @description Obtiene la lista de almacenes del cliente y que tengan el producto seleccionado
     * 
     */
    getAlmacenesDestino = (clear) => {
        let producto_id = this.ModalSolicitudProductos.current.getFieldValue('producto_id')
        axios.get('/almacenes/productos/solicitud/destino',{
            params:{
                cliente_id: this.props.cliente_id,
                producto_id: producto_id?.value,
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
               almacenes: response.data
            })
            if(clear){
                this.ModalSolicitudProductos.current.setFieldsValue({
                    almacen_id: null
                })
            }

        }).catch(error => {
            console.log("error", error);
            message.error('Error al obtener los almacenes del cliente')
        })
    }


    /**
     * @memberOf ModalSolicitudProductos
     * @method getProductos
     * @description Obtiene la lista de productos que tiene el cliente, tiene integrado el arreglo de almacenes donde esta agregado
     * 
     */
    getProductos = (search) => {
        axios.get('/vmi/list', {
            params: {
                productos: true,
                cliente_id: this.props.cliente_id,
                search
            }
        })
            .then(({ data }) => {
                console.log("data", data);

                this.setState({
                    productos: data.data.itemsList
                })

                if(this.props.producto && this.props.almacen && this.props.productosList){
                    this.ModalSolicitudProductos.current.setFieldsValue({
                        producto_id:{
                            value: this.props.producto.producto_id._id,
                            key: this.props.producto.producto_id._id,
                            label: this.props.producto.producto_id.np_fabricante,
                        },
                        almacen_id:{
                            value: this.props.almacen._id,
                            key: this.props.almacen._id,
                            label: this.props.almacen.direccion,
                        }
                    })
                }else if(this.props.producto && this.props.almacen && this.props.almacenesList){
                    this.ModalSolicitudProductos.current.setFieldsValue({
                        producto_id:{
                            value: this.props.producto._id,
                            key: this.props.producto._id,
                            label: this.props.producto.np_fabricante,
                        },
                        almacen_id:{
                            value: this.props.almacen._id,
                            key: this.props.almacen._id,
                            label: this.props.almacen.direccion,
                        }
                    })
                }

            })
            .then(()=>{
                this.getAlmacenesDisponibles()
                this.getAlmacenesDestino()
            })
            .catch(error => {
                message.error("No se obtuvieron los Almacenes")
                console.log(error)
            })
    }

    

    /**
     * @memberOf ModalSolicitudProductos
     * @method onFinish
     * @description Se ejecuta al hacer submit en el formulario
     * 
     */
    onFinish = (values) => {
        const user = this.context
        values.producto_id = values.producto_id.value;
        values.almacen_id = values.almacen_id.value;
        this.createSolicitud(values)
    }

    /**
     * @memberOf ModalSolicitudProductos
     * @method createSolicitud
     * @description Crea una nueva solicitud hacia otro cliente
     * 
     */
    createSolicitud = (values) => {

        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/solicitudes/add', {
                cliente_id: this.props.cliente_id,
                ...values
            })
                .then(success => {
                    message.success('Solicitud Creada!')
                    const socket = this.props.socket
    
                    /*if(socket.connected) {
            
                        let notificacion = {
                            evento: `${user.nombre} te mandó una solicitud de productos`,
                            detalle: `${user.nombre} solicita productos un almacen a tu cargo en ${this.state.almacen_disponible?.almacen?.direccion}`,
                            usuarios_destino: [this.state.almacen_disponible?.inventario_id?.cliente_id?.responsable_id],
                            usuario_id: user._id
                        }
                        socket.emit('nueva_notificacion', notificacion)
                    }*/
                    
                    this.props.onClose()
                })
                .catch(error => {
                    message.error('Error al Crear Solicitud!')
                    console.log(error)
                })
                .finally(() => {
                    this.setState({loading: false})
                })
        })
        

    }


    render() {
        const disabled = this.state.disabled
        const user = this.context
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                    ref={this.ModalSolicitudProductos}
                >
                    <Row className="mt-1">
                        <Col xs={24} lg={11}>
                            <Form.Item
                                label="Producto"
                                name="producto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione el Producto"
                                }]}
                            >
                                <Select
                                    disabled={disabled}
                                    showSearch
                                    filterOption={false}
                                    onSearch={this.getProductos}
                                    labelInValue
                                    onChange={(value) => {
                                        this.getAlmacenesDisponibles()
                                        this.getAlmacenesDestino(true)
                                    }}
                                >
                                    {
                                        this.state.productos?.map(item => (
                                            <Option key={item.producto_id._id} value={item.producto_id._id}>{item.producto_id.np_fabricante}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={{ span: 11, push: 2 }}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese una cantidad"
                                }]}
                            >
                                <InputNumber
                                    placeholder="Cantidad"
                                    className="width-100"
                                    min={1}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                            labelInValue
                                label="Almacen Destino"
                                name="almacen_id"
                                multiple={false}
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la disponibilidad"
                                }]}
                            >
                                <Select
                                    disabled={disabled}
                                    
                                >
                                    {
                                        this.state.almacenes?.map(({ _id, almacen_id }) => {
                                            return <Option key={almacen_id._id} value={almacen_id._id}>{almacen_id.direccion}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Disponibilidad"
                                name="almacen_producto_id"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione la disponibilidad"
                                }]}
                            >
                                <Select
                                    onChange={(value) => {
                                        this.setState({
                                            almacen_disponible: this.state.almacenes_disponibles?.find(almacen => almacen._id === value)
                                        })
                                    }}
                                >
                                    {
                                        this.state.almacenes_disponibles?.map(({ _id, almacen_id, inventario_id, inventario_producto_id, cantidad }) => (
                                            <Option value={_id}>
                                                <div className="flex-between">
                                                    <Text className="text-purple">{inventario_id?.cliente_id?.nombre}</Text>
                                                    <Text>{cantidad} {inventario_producto_id?.producto_id?.unidad.toUnidades()}</Text>
                                                </div>
                                                <div>
                                                    <Text className="text-gray">{almacen_id?.direccion}</Text>
                                                </div>
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        {user.tipo <= 1 ? <Col xs={24} lg={24}>
                            <Form.Item name="preguntar" valuePropName="checked" wrapperCol={{span: 24 }}>
                                <Checkbox>¿Desea preguntarle al encargado del cliente si acepta o no el traspaso?</Checkbox>
                            </Form.Item>
                        </Col>: null}
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Solicitar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    const socket = React.useContext(SocketContext)

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Solicitud de Productos </Title>
        </div>
        <ModalSolicitudProductos {...props} socket = {socket} />
    </Modal>

}