import React, { Component } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Upload, Modal, Row, Spin, Typography, Select, Input, } from 'antd';

const axios = require('axios').default;

const { Title } = Typography;


/**
 *
 *
 * @export
 * @class FormEmbarque
 * @extends {Component}
 */
class FormEmbarque extends Component {


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            loadingFile: false,
            ordenes_compras: [],
            archivos: null
        }
    }

    formEmbarque = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenEmbarque();
    }

    /**
     *
     * @method getOrdenEmbarque
     * @memberof FormEmbarque
     * @description Obtener la orden de compra para la edicion
     */
    getOrdenEmbarque = () => {
        axios.get('/ordenesCompra/get', {
            params: {id: this.props.ordenCompraId}
        })
            .then(({ data }) => {
                const archivos = data.data.archivos?.map((archivo, index) => ({
                    uid: (index + 1 ) * -1,
                    name: archivo,
                    status: "done",
                    url: axios.defaults.baseURL + "/upload/" + archivo
                }))

                data.data.archivos = archivos;

                this.setState({
                    archivos
                })

                this.formEmbarque.current?.setFieldsValue({...data.data})
            })
            .catch(error => {
                message.error('No se pudo cargar la Orden de Embarque')
                console.log("no obtenida", error);
            })
    }

    /**
     *
     * @method onFinish
     * @param {*} values Objecto con los valores del formulario
     * @memberof FormEmbarque
     * @description Guarda los valores del formulario en la orden de compra
     */
    onFinish = (values) => {
        const v = {...values}
        v.archivos = Array.isArray(v.archivos) ? v.archivos.map(({ filename }) => filename ) : []
        
        axios.post('/ordenesCompra/update', {
            ...v,
            id: this.props.ordenCompraId,

            logistica: true
        })
        .then(({data}) => {
            message.success('Se actualizo orden')
            this.props.onSuccess()
        })
        .catch(error => {
            message.error("Error al guardar la Logistica")
            console.log(error)
        })
    }

    /**
     *
     * @memberof ModalOrdenEmbarque
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
     removeFile = (image) => {
        this.setState({loadingFile: true})

        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
            .finally(() => {
                this.setState({loadingFile: false})
            })
    };

    /**
     *
     * @memberof ModalOrdenEmbarque
     *
     * @method normFile
     * @description Declaramos las imagenes en el row de la tabla
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
     normFile = (e) => {

        const { file, fileList } = e;       

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
                fileList[x].name = fileList[x].response.name
            }
        }
        
        if (file.status === "removed")
            this.removeFile((file?.response?.filename) ? file.response.filename : file.filename);
            
        return e.fileList;
    };

    render() {
        const { loading, } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Información de Embarque</Title>
                <Form layout="vertical" ref={this.formEmbarque} onFinish={this.onFinish}>
                    <Row gutter={[24, 16]}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[
                                    { required: true, message: "Ingrese el nombre" }
                                ]} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Descripción"
                                name="descripcion"
                                rules={[
                                    { required: true, message: "Ingrese una descripción" }
                                ]} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Archivos"
                                name="archivos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="orden-embarque-upload"
                            >
                                <Upload
                                    action={`${axios.defaults.baseURL}/upload/add`}
                                    multiple={true}
                                >
                                    <Button style={{width: "100%"}} icon={<UploadOutlined />}>Presione aquí para subir</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loadingFile}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormEmbarque {...props} />
        </Modal>
    )
}
