
import React, { Component } from "react";
import { Layout, PageHeader, Button, Col, Space, List, Row, Typography, message, Dropdown, Menu } from "antd";

import { EllipsisOutlined, FlagFilled, PaperClipOutlined } from "@ant-design/icons";
import { ImPlus } from "react-icons/im";

import { CardEntrega, CardLegalInfo, CardProjectInfo, CardTaskProgress, CardUtilidad } from "../../../Widgets/Cards";
//modales
import ModalDetalleOrdenCompra from './ModalDetalleOrdenCompra'

import StepTracker from "../../ProjectManager/Secciones/StepTracker";
import ModalOrdenCompra from "./ModalOrdenCompra";
import { IconArrowBack, IconCheck } from '../../../Widgets/Iconos';
import ModalProofDelivery from "./ModalProofDelivery";

const { Content } = Layout;
const { Title, Text } = Typography

const axios = require('axios').default
const moment = require('moment')
/**
 *
 *
 * @export
 * @class RecepcionMercancia
 * @extends {Component}
 */
export default class RecepcionMercancia extends Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            orden_id: this.props.match.params.orden_id,
            orden_compra_id: this.props.match.params.orden_compra_id,
            completo: false,
            recepciones: [],


            modalVisible: false,
            modalDetalleVisible: false

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getRecepciones();
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id){
            this.setState({orden_id: this.props.match.params.orden_id},()=>{
                this.getRecepciones();
            })
        }
    }

    /**
     *
     *
     * @memberof RecepcionMercancia
     * @method getRecepciones
     * @description Obtener la lista de recepciones
     */
    getRecepciones = () => {
        axios.get('/ordenesCompra/recepciones', {
            params: {
                orden_compra_id: this.state.orden_compra_id,
                orden_id: this.state.orden_id,
                tipo: 0
            }
        }).then(({ data }) => {

            console.log('data recepciones', data)
            const recepciones = data.data.data
            const ordenes = recepciones[0]?.orden_detalles

            let completo = false
            let productos = []

            // Busca negar que todos las cantidades de todos los tipos de productos esten completas
            if (recepciones.length > 0 && !this.state.orden_compra_id) {
                for (const recepcion of recepciones) {
                    for (const orden of recepcion.orden_detalles) {
                        if (productos.find(producto => producto.id === orden.producto_id)) continue

                        productos.push({
                            id: orden.producto_id,
                            completo: (orden.cantidad_recibida === orden.cantidad)
                        })
                    }
                }

                completo = true

                for (const producto of productos) {
                    if (producto.completo) continue
                    completo = false
                }
            }

            // Busca negar si se recibieron todos los productos
            if (recepciones.length > 0 && this.state.orden_compra_id && ordenes && this.props.vmi) {
                completo = true

                for (const orden of ordenes) {
                    if (orden.cantidad_recibida === orden.cantidad_faltante) continue
                    completo = false
                }
            } else if (recepciones.length > 0 && this.state.orden_compra_id && ordenes) {
                completo = true

                for (const orden of ordenes) {
                    if (orden.cantidad_recibida === orden.cantidad) continue
                    completo = false
                }
            }

            if (recepciones.length > 0) {
                for (const recepcion of recepciones) {
                    if (!recepcion.firma) {
                        completo = false
                        break
                    }
                }
            }

            this.setState({
                recepciones,
                completo
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener las recepciones')
        })
    }

    finishRecepcion = () => {
        axios.post('/ordenesCompra/update', {
            id: this.state.orden_compra_id,
            estatus: 4
        })
            .then(success => {
                message.success('Recepcion completa')
                this.props.history.push(`/admin/project-manager/logistica/${this.state.orden_id}?tab=embarque`)
            })
            .catch(error => {
                message.error('Error al completar recepcion')
            })
    }

    render() {
        console.log('state recepciones',this.state)
        return (
            <>
                <List
                    itemLayout="horizontal"
                    dataSource={this.state.recepciones}
                    header={
                        <Row gutter={[0, 8]}>
                            <Col span={18} md={24} lg={15} xxl={{ span: 18 }} ><Title level={3}>Recepción de Mercancia </Title></Col>
                            <Col span={1} md={4} lg={2} xxl={{ span: 1 }} >
                                {
                                    !this.state.completo && this.state.orden_compra_id ? (<Button className="btn-purple" onClick={() => { this.setState({ modalVisible: true }) }} icon={<ImPlus style={{ color: '#fff' }} />} />) : null
                                }
                            </Col>
                            <Col span={5} md={20} lg={7} xxl={{ span: 5 }} >
                                {
                                    this.state.orden_compra_id ? (
                                        <Button type="primary" size="large" className="btn-purple" disabled={!this.state.completo || this.props.logistica_estatus > 0} block onClick={this.finishRecepcion}>
                                            Orden Recibida
                                        </Button>
                                    ) : null
                                }
                            </Col>
                        </Row>
                    }
                    renderItem={(recepcion) => {

                        const { _id, orden_detalles, archivos, firma, folio } = recepcion
                        return (
                            <List.Item className="order-list-item bg-white">
                                <Row className="width-100 text-center" align="middle" justify="space-around" >
                                    <Col span={8} lg={{ span: 6, order: 1 }} md={{ span: 8, order: 1 }} >
                                        <Text ellipsis strong style={{ width: 'auto', padding: "4px 1rem" }} className="tag-card-pink">{folio}</Text>
                                    </Col>
                                    <Col span={6} lg={{ span: 6, order: 2 }} md={{ span: 12, order: 2 }}>
                                        <Text ellipsis className="text-gray">{orden_detalles ? moment(orden_detalles[-1]?.createdAt).format('L') : 'Sin Fecha'}</Text>
                                    </Col>
                                    <Col span={2} lg={{ span: 2, order: 3 }} md={{ span: 12, order: 4 }}>
                                        <Row>
                                            <Col span={24}><Text className="text-gray">Firma</Text></Col>
                                            <Col span={24}>{
                                                firma ? <IconCheck /> : <Text className="text-gray">Pendiente</Text>
                                            }</Col>
                                        </Row>
                                    </Col>
                                    <Col span={6} lg={{ span: 8, order: 4 }} md={{ span: 12, order: 5 }} >

                                    </Col>
                                    <Col span={2} lg={{ span: 2, order: 5 }} md={{ span: 2, order: 3 }}>
                                        <Dropdown overlay={
                                            <Menu>
                                                <Menu.Item onClick={() => this.setState({ modalDetalleVisible: true, recepcion_entrega_id: orden_detalles[0]?.recepciones?.entrega_recepcion_id })}>
                                                    Ver Detalle
                                                </Menu.Item>
                                            </Menu>
                                        } placement="bottomRight"
                                            trigger={['click']}
                                        >
                                            <Button
                                                icon={<EllipsisOutlined />}
                                                type="ghost"
                                                className="no-border"
                                            >
                                            </Button>
                                        </Dropdown>
                                    </Col>
                                </Row>
                            </List.Item>
                        )
                    }
                    } />
                <ModalOrdenCompra
                    visible={this.state.modalVisible}
                    orden_id={this.state.orden_id}
                    orden_compra_id={this.state.orden_compra_id}
                    recepcion
                    onSuccess={() => {
                        this.setState({
                            modalVisible: false
                        }, () => this.getRecepciones())
                    }}
                    onCancel={() => {
                        this.setState({
                            modalVisible: false
                        })
                    }}
                />
                <ModalDetalleOrdenCompra
                    recepcion
                    visible={this.state.modalDetalleVisible}
                    recepcion_entrega_id={this.state.recepcion_entrega_id}
                    onSuccess={() => this.getRecepciones()}
                    onClose={() => this.setState({ modalDetalleVisible: false, recepcion_entrega_id: undefined })}
                />

            </>
        )
    }
}