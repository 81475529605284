import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Chat from '../components/Admin/Chat/Chat'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterChat(props) {

    return (
        <Switch>
            <Route exact path="/admin/chat" render={() => <Chat {...props} />} />
        </Switch>
    )
}

export default RouterChat;
