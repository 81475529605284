import React from 'react'
import { Route, Switch } from "react-router-dom";


// componentes
import Areas from '../components/Admin/Areas/Areas'
import Rubros from '../components/Admin/Rubros/Rubros'


/**
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCuentas(props) {

    return (
        <Switch>
            <Route exact path="/admin/areas" render={() => <Areas {...props} />} />

            <Route exact path="/admin/areas/rubros/:id" render={() => <Rubros  {...props} />} />

        </Switch>
    )
}

export default RouterCuentas;
