import React, { Component } from "react";
import { Layout, Row, Col, PageHeader, Button, List, Typography, Card, message, Spin, Popconfirm, Statistic, Checkbox, Modal } from "antd";
import { IconDelete, IconCheckGreen } from '../../../Widgets/Iconos';
import { SyncOutlined } from '@ant-design/icons'
import FloatingButton from "../../../Widgets/Floating Button/FloatingButton";
import ModalTransaccionesSyncfy from './ModalTransaccionesSyncfy'

const { Content } = Layout;
const { Text } = Typography;
const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class TransaccionesSyncfy
 * @extends {Component}
 * @description Vista del listado de transacciones
 */
export default class TransaccionesSyncfy extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            transacciones: [],
            cuenta: undefined,
            seleccionadas: {},

            page: 1,
            limit: 20,
            total: 0,
        }
    }

    componentDidMount() {
        this.setState({ cuenta_id: this.props.match.params.cuenta_id })

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getCuenta();
    }

    /**
    *
    *
    * @memberof TransaccionesSyncfy
    * @description Obtiene todas todas las transacciones
    * @param values pagina
    */
    getCuenta = () => {
        this.setState({ loading: true })
        axios.get('/cuentas/get', {
            params: {
                id: this.props.match.params.cuenta_id
            }
        }).then(response => {
            this.setState({ cuenta: response.data.data }, () => {
                this.getToken()
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al cargar la cuenta')
            this.setState({ loading: false })
        })

    }

    /**
    *
    *
    * @memberof TransaccionesSyncfy
    * @description Obtiene todas todas las transacciones
    * @param values pagina
    */
    getToken = () => {
        axios.get('/syncfy/session', {
            params: {
                id_user_syncfy: this.state.cuenta?.syncfy_usuario_id,
            }
        }).then(response => {
            this.setState({ token: response.data.token }, () => {
                this.getTransacciones();
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener el token')
            this.setState({ loading: false })
        })

    }

    /**
    *
    *
    * @memberof TransaccionesSyncfy
    * @description Obtiene todas todas las transacciones
    * @param values pagina
    */
    getTransacciones = () => {
        this.setState({ loading: true })
        axios.get('/syncfy/transacciones', {
            params: {
                cuenta_id: this.props.match.params.cuenta_id,
                token: this.state.token,
                page: this.state.page,
                limit: this.state.limit,
            }
        }).then(response => {
            this.setState({
                transacciones: response.data.data.data,
                total: response.data.data.total,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener las transacciones')
            this.setState({ loading: false })
        }).finally(() => this.setState({ loading: false }))

    }

    /**
    *
    * @memberof TransaccionesSyncfy
    * 
    * @method clasificarTransacciones
    * @description Abre el modal si se seleccionaron transacciones
    */
    clasificarTransacciones = () => {

        const selecionadasLenght = Object.keys(this.state.seleccionadas).length

        if (selecionadasLenght === 0)
            return Modal.error({
                title: "Debe seleccionar algunas transacciones",
                content: "Para poder agregar transacciones "
            })

        this.setState({
            modalClasificarVisible: true
        })
    }






    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Transacciones no clasificadas"
                        extra={
                            <Button type="link" style={{ border: '1px solid white', borderRadius: 2 }} disabled={this.state.loadingRender} icon={<SyncOutlined spin={this.state.loadingRender} style={{ color: "white" }} />} onClick={() => this.getCuenta()} />
                        }
                    />
                    <Content className="admin-content content-bg pd-1">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            dataSource={this.state.transacciones}
                            header={<Row className="header-list width-100 pl-1 pr-1">

                                <Col span={2} className=" center">
                                    <Text strong></Text>
                                </Col>
                                <Col xs={12} className="center">
                                    <Text strong>Concepto</Text>
                                </Col>
                                <Col xs={5} className="center">
                                    <Text strong>Fecha</Text>
                                </Col>
                                <Col xs={4} className="center">
                                    <Text strong>Monto</Text>
                                </Col>
                                <Col xs={1} className="center">
                                    <Text strong></Text>
                                </Col>

                            </Row>
                            }

                            renderItem={item => {

                                const { amount, dt_transaction, description, transaccion, id_transaction, category, currency } = item;

                                return <List.Item className="component-list-item">
                                    <Card className="card-list">
                                        <label for={"list-item-" + id_transaction} className="width-100">


                                            <Row className="width-100">
                                                <Col span={2} className="center">
                                                    <Checkbox
                                                        id={"list-item-" + id_transaction}
                                                        className="radio-purple"
                                                        checked={(this.state.seleccionadas[id_transaction])}
                                                        onChange={value => {
                                                            this.setState(state => {
                                                                if (value.target.checked === true)
                                                                    state.seleccionadas[id_transaction] = item
                                                                else
                                                                    delete state.seleccionadas[id_transaction]
                                                                return state;
                                                            })
                                                        }}
                                                    ></Checkbox>
                                                </Col>
                                                <Col span={12} className="center">
                                                    <Text>{description}</Text>
                                                </Col>
                                                <Col span={5} className="center">
                                                    <Text strong>{moment.unix(dt_transaction).format('DD-MM-YYYY')}</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Statistic value={amount} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: (amount < 0) ? '#FF0000' : '#00FF19' }} prefix={'$'} suffix={currency} />
                                                </Col>
                                                <Col span={1} className="center">
                                                    {this.state.vista_guardadas ? <Popconfirm placement="topRight"
                                                        title="¿Eliminar de Guardados?" okText="Si, seguro" cancelText="No"
                                                        onConfirm={() => {
                                                            this.deleteGuardados(item._id)
                                                        }}>
                                                        <Button type="primary"
                                                            danger
                                                            className="ant-btn ant-btn-primary"
                                                            title="Eliminar">
                                                            <IconDelete />
                                                        </Button>
                                                    </Popconfirm> : null
                                                    }
                                                </Col>
                                            </Row>
                                        </label>
                                    </Card>
                                </List.Item>
                            }}

                            pagination={{
                                onChange: (page, limit) => this.setState(state => {
                                    state.page = page;
                                    state.limit = limit;
                                    return state;
                                }, () => this.getTransacciones()),

                                pageSizeOptions: [10, 20, 50, 100, 500],
                                showSizeChanger: true,

                                pageSize: this.state.limit,
                                total: this.state.total,
                                current: this.state.page,

                                hideOnSinglePage: false,
                                showQuickJumper: false,
                                className: "pagination-list",
                            }}

                        />

                        <FloatingButton title="Clasificar" icon={<IconCheckGreen />} onClick={() => this.clasificarTransacciones()} />
                        {/*<FloatingButton 
                            title="Guardar" 
                            icon={<IconSave/>} 
                            style={{right: '130px'}}
                        />*/}
                    </Content>
                </Spin>
                <ModalTransaccionesSyncfy
                    visible={this.state.modalClasificarVisible}
                    seleccionadas={this.state.seleccionadas}
                    onClose={() => {
                        this.setState({ modalClasificarVisible: false })
                        this.getTransacciones()
                    }}
                    clearSeleccionadas={() => this.setState({ seleccionadas: {} })}
                    cuenta_id={this.state.cuenta_id}
                />

            </>
        )
    }
}