import React from 'react'
import { Route, Switch } from "react-router-dom";


// COMPONENTES
import ProjectManager from '../components/Admin/ProjectManager/ProjectManager';
import ProductsForQuotation from "../components/Admin/ProjectManager/ProductsForQuotation/ProductsForQuotation";
import Ordenes from "../components/Admin/Ordenes/Ordenes"

import MatrizCostos from "../components/Admin/ProjectManager/MatrizCostos";
import AccountPurchases from '../components/Admin/ProjectManager/AccountPurchases';

//LOGISTICA
import RecepcionMercancia from '../components/Admin/ProjectManager/Logistica/RecepcionMercancia';
import OrdenEmbarque from '../components/Admin/ProjectManager/Logistica/OrdenEmbarque';
import EntregaMercancia from '../components/Admin/ProjectManager/Logistica/EntregaMercancia';
import ProofDelivery from '../components/Admin/ProjectManager/Logistica/ProofDelivery';
import Logistica from '../components/Admin/ProjectManager/Logistica';

 

function RouterProjectMananger({search}) {
    return (
        <Switch>
            {/* Lista de Project Manager */}
            {/* <Route exact path="/admin/project-manager/ordenes"
                render={(props) => <Ordenes  {...props} />}
            /> */}
            {/* Lista de Project Manager */}
            <Route exact path="/admin/project-manager"
                render={(props) => <ProjectManager  search={search} {...props}/>}
            />            
            {/* Lista de Selección de Proveedor */}
            <Route exact path="/admin/project-manager/supplychain/:orden_id"
                render={(props) => <ProductsForQuotation {...props} />}
            />
            
            {/* Lista de Selección de Matriz Costos */}
            <Route exact path="/admin/project-manager/matriz-costos/:orden_id"
                render={(props) => <MatrizCostos  {...props} />}
            />
            <Route exact path="/admin/project-manager/cuenta-ventas/:orden_id"
                render={(props) => <AccountPurchases  {...props} search={search}  />}
            />

            <Route exact path="/admin/project-manager/cuenta-ventas/:orden_id/:orden_compra_id"
                render={(props) => <AccountPurchases  {...props} search={search}  />}
            />

            {/* SECCION DE LOGISTICA */}
            <Route exact path="/admin/project-manager/logistica/:orden_id"
                render={(props) => <Logistica {...props} />}
            />
            <Route exact path="/admin/project-manager/logistica/:orden_id/:orden_compra_id"
                render={(props) => <Logistica {...props} />}
            />
        </Switch>
    )
}

export default RouterProjectMananger;
