import React, { Component, useContext } from 'react';
import axios from 'axios';
import ifIsImage from 'if-is-image';
import SocketIOFileClient from 'socketio-file-upload';
import { FaFilePdf, FaImage, FaFileExcel, FaFilePowerpoint, FaFileAlt, FaTimes } from 'react-icons/fa'
import { Card, Avatar, Badge, Button, Col, Row, Comment, Input, Typography, Tooltip, Modal, Upload, Progress,Image, Empty, Spin } from 'antd'

//componentes
import Logged from '../../../Hooks/Logged';
import CustomAvatar from '../../Widgets/Avatar/Avatar'
import { SocketContext } from '../../../Hooks/Socket';
import { IconAttach, IconSend } from '../../Widgets/Iconos'

//css
import './../../../Styles/Modules/Chat/mensajes.css';


const moment = require("moment");
const { Title, Text, Paragraph } = Typography;


function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
        type: originalFile.type,
        lastModified: originalFile.lastModified,
    });
}


/**
 *
 *
 * @class Mensajes
 * @extends {Component}
 * 
 * @description Componente de chat para hablar con parque esmeralda y vicerversa.
 */
class Mensajes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            usuarios: [],
            data: [],
            filesArray: [],
            filesDictionary: {},
            from: undefined, //id del usuario logeado
            cliente_selected: {},
            limit: 20,
            page: 1,
            update: true,
            loading: false

        }
    }



    static contextType = SocketContext;

    loading = false

    scrollToBottomOption = true

    /***
 *
 * @methodOf Mensajes
 * @function componentDidMount
 * 
 * @description Iniciamos el chat, si se agrega al usuario a la lista de sockets exitomsanete, se encadena los emits iniciales
 */
    componentDidMount() {
        document.getElementById("messages-body")?.addEventListener('scroll', this.handleScroll, true);

        this.context.emit("start", sessionStorage.getItem("token"))
        this.context.removeListener('successful');
        this.context.on('successful', this.IO_start)

        //metodos para subir archivos
        this.uploader = new SocketIOFileClient(this.context);
        this.uploader.addEventListener("start", this.startUpload);
        this.uploader.addEventListener("progress", this.progressUpload);
        this.uploader.addEventListener("complete", this.completeUpload);
    }

    /***
     *
     * @methodOf Mensajes
     * @function componentDidMount
     * 
     * @description Removemos el event listener del messages
     */
    componentWillUnmount() {
        document.getElementById("messages-body")?.removeEventListener('scroll', this.handleScroll);
        this.context.emit("header", sessionStorage.getItem("token"))
    }

    /***
     *
     * @methodOf Mensajes
     * @function IO_start
     * 
     * @description Inicio del socket, obtebemos los mensajes y si es admin obtebemos los usuarios
     */
    IO_start = ({ user_id }) => {

        this.setState({ from: user_id })
        //Eliminamos estos metodos para volverlos a declarar
        this.context.removeListener('get_messages');
        this.context.removeListener('mensaje_nuevo');

        this.context.on('get_messages', this.IO_get_messages)
        this.context.on('mensaje_nuevo', this.IO_mensaje_nuevo)

        //Si es administrador, obtenemos los usuarios, caso contrario es un usuario por lo que obteemos los mensajes normales
        this.context.removeListener('get_users');
        this.context.on('get_users', this.IO_get_users)
        this.setState({loading: true})
        this.context.emit('get_users')
    }

    /***
    *
    * @methodOf Mensajes
    * @function IO_get_users
    * 
    * @description Obtenemos los usuarios (ordenes) para ponerlos en las tarjetas. Solo funciona si eres Admin 
    */
    IO_get_users = (usuarios) => {

        const { page, limit, update } = this.state;

        //obtenemos los mensajes del primer usuario
        let orden_id = usuarios[0]?._id;
        
        if(update === false){
            this.setState({usuarios: usuarios, update: true, loading: false})
        }else{
            this.setState({
                usuarios: usuarios,
                cliente_selected: usuarios[0] ? usuarios[0] : {},
                orden_id: usuarios[0] ? usuarios[0]._id : null,
                data: [],
                loading: false,
            }, ()=>{
                this.context.emit('get_messages', { limit, orden_id: orden_id })
            })
        }
    }

    /**
     *
     * @methodOf Mensajes
     * @function setUserConversation
     * 
     * @description Ponemos el usuario seleccionado en el chat para hablar con el 
     * 
     * */
    setUserConversation = ({ orden_id }) => {

        const { page, limit } = this.state;
        this.scrollToBottomOption = true
        let usuarios = this.state.usuarios

        let index = this.state.usuarios.findIndex(e => e._id.toString() === orden_id.toString())
        if (index !== -1 && usuarios) {
            usuarios[index].last_msg.visto = true
        }

        this.setState({
            orden_id: orden_id,
            data: [],
            cliente_selected: this.state.usuarios.find(e => e._id.toString() === orden_id.toString()),
            usuarios: usuarios,
        },()=>{
            this.setState({loading: true})
            this.context.emit('get_messages', {limit, orden_id: orden_id })
        })
    }

    /**
     *
     * @methodOf Mensajes
     * @function IO_get_messages
     * 
     * @description Obtenemos los mensajes completos 
     */
    IO_get_messages = ({ mensajes }) => {


        let mensajesList = this.state.data

        mensajesList.unshift(...mensajes.list)

        this.loading = false
        this.setState(state => {
            state.data = mensajesList;
            state.page = mensajes.page;
            state.limit = mensajes.limit;
            state.loading = false;

            //Se hace scroll hasta abajo
            if (this.scrollToBottomOption === true) {
                setTimeout(this.scrollToBottom, 100)
            }

            return state;
        })
    }

    /**
     *
     * @methodOf Mensajes
     * @function IO_mensaje_nuevo
     * 
     * @description Cuando llegue un mensaje nuevo, se agrega a la lista de mensajes
     */
    IO_mensaje_nuevo = ({ mensaje, user_id }) => {

        let mensajes = this.state.data

        if (mensaje.orden_id === this.state.orden_id) {

            mensajes.push(mensaje)
            this.setState({ data: mensajes })

            this.context.emit('visto', {
                mensaje: mensaje
            })

            //Se hace scroll hasta abajo
            if (this.scrollToBottomOption === true) {
                setTimeout(this.scrollToBottom, 10)
            }
        }
        let index = this.state.usuarios.findIndex(e => e._id.toString() === mensaje.orden_id.toString())
        if (index !== -1) {
            let usuarios = this.state.usuarios
            usuarios[index].last_msg.remitente_id = mensaje.remitente_id._id
            usuarios[index].last_msg.mensaje = mensaje.mensaje
            usuarios[index].last_msg.visto = index !== 0 || this.state.cliente_selected._id !== mensaje.orden_id ? false : true

            this.setState({ usuarios: usuarios })
        } else {
            this.setState({ update: false }, () => {
                this.context.emit('get_users')
            })
        }

    }



    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar enter, enviamos el mensaje y limpiamos el input
     * 
     * */
    onKeyDownPress = (event) => {
        if (event.key === 'Enter' && ((event.target.value && event.target.value != null && event.target.value != "") || this.state.filesArray.length > 0)) {
            this.context.emit('new_message', {
                message: event.target.value,
                orden_id: this.state.orden_id,
                to: this.state.cliente_selected?.usuario_id?._id,
                files: this.state.filesArray.map(event => event.fileSend.name)
            })

            this.setState({ filesArray: [], filesDictionary: {}, value: '' })

            if (this.state.cliente_selected?._id != this.state.usuarios[0]._id) {
                this.context.emit('get_users')
            }
        }
    }


    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar enter, enviamos el mensaje y limpiamos el input
     * 
     * */
    onPress = (event) => {
        if ((event.target.value && event.target.value !== null && event.target.value !== "") || this.state.filesArray.length > 0) {
            this.context.emit('new_message', {
                message: event.target.value,
                orden_id: this.state.orden_id,
                to: this.state.cliente_selected?.usuario_id?._id,
                files: this.state.filesArray.map(event => event.fileSend.name)

            })
            this.setState({ filesArray: [], filesDictionary: {}, value: '' })
        }
    }


    /**
     *
     * @methodOf Mensajes
     * @function progressUpload
     * 
     * 
     * */
    progressUpload = (event) => {
        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = (parseInt(event.bytesLoaded) * 100) / parseInt(event.file.size);
        this.state.filesArray[index - 1].status = "progress";
        this.setState({ update: !this.state.update })
    }


    /**
     *
     * @methodOf Mensajes
     * @function completeUpload
     * 
     * @description 
     * 
     * */
    completeUpload = (event) => {
        let index = this.state.filesDictionary[event.file.name];
        this.state.filesArray[index - 1].progress = 100;
        this.state.filesArray[index - 1].status = "complete";
        this.state.filesArray[index - 1].fileSend = event.file;
        this.setState({ update: !this.state.update })
    }

    /**
     *
     * @methodOf Mensajes
     * @function startUpload
     * 
     * @description 
     * 
     * */
    startUpload = (event) => {
        let name = "";
        let fileSplit = event.file.name.split("_");
        for (let index = 0; index < (fileSplit.length - 1); index++) {
            name += ((index == 0) ? "" : '_') + fileSplit[index];
        }
        let extension = fileSplit[fileSplit.length - 1].split('.')[1];
        let index = this.state.filesArray.push({
            file: event.file,
            progress: 0,
            status: 'start',
            name,
            extension
        });

        this.state.filesDictionary[event.file.name] = index;
        this.setState({ update: !this.state.update })
    }

    /**
     *
     * @methodOf Mensajes
     * @function uploadFile
     * 
     * @description 
     * 
     * */
    uploadFile = ({ file }) => {
        let fileSplit = file.name.split(".");
        let rename = "";
        for (let index = 0; index < (fileSplit.length - 1); index++)
            rename += fileSplit[index]
        let fileName = rename + "_" + Math.random().toString(36).substring(2, 10) + "." + fileSplit[fileSplit.length - 1];
        this.uploader.submitFiles([renameFile(file, fileName)])
    }

    /**
     *
     * @methodOf Mensajes
     * @function scrollToBottom
     * 
     * @description Scrolleamos el chat al final
     * 
     * */
    scrollToBottom = () => {
        this.scrollToBottomOption = true;
        const messages_body = document.getElementById("messages-body");
        let overflow = messages_body.scrollHeight > messages_body.clientHeight
        if (messages_body !== null && messages_body !== undefined && messages_body !== null) {
            messages_body.scrollTop = messages_body.scrollHeight;
        }
    }


    /**
     *
     * @methodOf Mensajes
     * @function handleScroll
     * 
     * @description Se ejecuta cuando se scrollea el chat, cargamos mas mensajes antiguos.
     * 
     * */
    handleScroll = (event) => {
        let { scrollTop, scrollHeight, clientHeight } = document.getElementById("messages-body");
        let { page, limit, search } = this.state;
        let overflow = scrollHeight > clientHeight
        if (scrollTop === 0 && this.loading === false && overflow) {
            this.loading = true;
            this.scrollToBottomOption = false;
            page = page + 1
            this.context.emit('get_messages', { page, limit, orden_id: this.state.orden_id })

        }
    }

    /**
     *
     * @methodOf Mensajes
     * @function onKeyDownPress
     * 
     * @description Se ejecuta al dar enter, enviamos el mensaje y limpiamos el input
     * 
     * */
    deleteFile = (file) => {
        let index = this.state.filesDictionary[file];
        this.state.filesArray.splice(index, 1);
        this.context.emit("delete_file", file)
        this.setState({ update: !this.state.update })
    }


    messagesContentReference = React.createRef();



    render() {

        let { user } = this.props;
        let { from, cliente_selected } = this.state;

        return (
            <section className="pd-1 ">
                <Row gutter={[20,20]} className="witdh-100 m-0">
                    {this.state.usuarios.length  > 0 ? <Col xs={24} md={10} xl={8} xxl={6} style={{maxHeight: '1000px', overflowY: 'auto', paddingTop: '10px'}}>
                        <Spin spinning={this.state.loading}>
                        {this.state.usuarios.map(({ _id, nombre, usuario_id ,cliente_id, mensaje, count, folio, last_msg }) => {
                                return <div
                                    className={"cnt-card-mensaje " + ((cliente_selected._id == _id) ? " selected" : '')}
                                    onClick={e => this.setUserConversation({ orden_id: _id })}
                                >
                                    <Badge dot={last_msg?.remitente_id !== this.state.from && !last_msg?.visto} className="width-100" size="large">
                                        <Card
                                            className="card-msg "
                                            style={{ width: '100%' }}
                                            hoverable
                                        >
                                            <Comment
                                                author={<span>{cliente_id.razon_social} <br /> {usuario_id?.nombre} {usuario_id?.apellido} - {folio}</span>}
                                                avatar={<CustomAvatar name={[cliente_id?.nombre, cliente_id?.apellido]} size="large" />}
                                                content={
                                                    <>
                                                        <Text ellipsis className="m-0" style={{width:'65% '}}>{last_msg?.imagenes.length > 0 || last_msg?.archivos.length > 0 ? <i>Envio un Archivo</i> : last_msg?.mensaje}</Text>
                                                        <Text style={{width:'35% '}}>· {moment(last_msg.fecha).fromNow(true)}</Text>
                                                    </>
                                                }
                                            />
                                        </Card>
                                    </Badge>
                                </div>
                        })}
                        </Spin>
                    </Col> : <Col xs={24} md={10} xl={8} xxl={6} style={{paddingTop: '10rem'}}> 
                            <Empty
                                description={<span>No hay ordenes disponibles</span>}
                            />
                        </Col>}

                    {this.state.usuarios.length  > 0 ? <Col xs={24} xl={16} xxl={18} className="">
                        
                        <div className="cnt-Mensajes">
                            <Row className="cnt-Mensajes-header">
                                <Avatar src={"/images/Vector.png"} size="large" style={{ backgroundColor: '#fff' }} />
                                <Title level={2}>Nexus <small className="font-16">{cliente_selected?.cliente_id?.nombre} {cliente_selected?.cliente_id?.apellido} {cliente_selected?.folio}</small></Title>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="cnt-Mensajes-body"
                                        ref={messageContentReference => { this.messageContentReference = messageContentReference; }}
                                        id="messages-body">

                                            <Row className="card-msj">
                                                <Card className="cnt-msj" loading={this.state.loading}>
                                                    <Row>
                                                        {this.state.data.map(comment => {

                                                        const { owner, remitente_id, mensaje, fecha, primero, archivos, casa } = comment;

                                                        let autor = remitente_id.nombre + " " + remitente_id.apellido;

                                                        return <Comment
                                                            style={{ width: '100%' }}
                                                            className={(remitente_id._id === from) ? "owner" : null}
                                                            avatar={
                                                                <CustomAvatar
                                                                    name={[remitente_id.nombre, remitente_id.apellido]}
                                                                />
                                                            }
                                                            content={<div>
                                                                {mensaje} <br></br>
                                                                {archivos.map(filename => {
                                                                    let name = "";
                                                                    let fileSplit = filename.split("_");
                                                                    for (let index = 0; index < (fileSplit.length - 1); index++) {
                                                                        name += ((index == 0) ? "" : '_') + fileSplit[index];
                                                                    }
                                                                    let extension = fileSplit[fileSplit.length - 1].split('.')[1];


                                                                    let extensionImage = null;
                                                                    if (ifIsImage(filename)) {
                                                                        extensionImage = <FaImage style={{ marginRight: '0.5em' }} />
                                                                    }
                                                                    else if (extension == "pdf" || extension == "PDF") {
                                                                        extensionImage = <FaFilePdf style={{ marginRight: '0.5em' }} />
                                                                    }
                                                                    else {
                                                                        extensionImage = <FaFileAlt style={{ marginRight: '0.5em' }} />
                                                                    }


                                                                    if (ifIsImage(`${name}.${extension}`))
                                                                        return <a className="archivito">
                                                                            <Image src={axios.defaults.baseURL + '/upload/' + filename} style={{ maxWidth: '200px', margin: '1em' }} />
                                                                        </a>
                                                                    else
                                                                        return <>
                                                                            <br />
                                                                            <a target="_blank" download={true} href={axios.defaults.baseURL + '/upload/' + filename} className="archivito">
                                                                                {extensionImage}{`${name}.${extension}`}
                                                                            </a>
                                                                        </>
                                                                })}
                                                            </div>}
                                                            datetime={
                                                                <Tooltip title={moment(fecha).format('YYYY-MM-DD HH:mm:ss')}>
                                                                    <span>{moment(fecha).fromNow()}</span>
                                                                </Tooltip>
                                                            }
                                                        />





                                                    })}
                                                </Row>
                                            </Card>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row>

                                <Col span={24}>
                                    <div className={this.state.filesArray.length > 0 ? "filesList" : null}>

                                        {this.state.filesArray.map(file => {

                                            let extensionImage = null;
                                            if (ifIsImage(file.file.name)) {
                                                extensionImage = <img src={axios.defaults.baseURL + '/upload/' + file.file.name} style={{ maxWidth: '200px', margin: '1em' }} />
                                            }
                                            else if (file.extension == "pdf" || file.extension == "PDF") {
                                                extensionImage = <FaFilePdf />
                                            }
                                            else {
                                                extensionImage = <FaFileAlt />
                                            }

                                            let name = (file.name.length > 10) ? (file.name.slice(0, 10) + '... ') : file.name;
                                            return <div className="file-container">
                                                <div className="file">
                                                    {(file.status != "complete") ? <Progress percent={parseInt(file.progress)} size="small" style={{
                                                        position: 'absolute',
                                                        top: '36%',
                                                        left: '5px',
                                                        width: 'calc(100% - 10px)',
                                                    }} /> : extensionImage}
                                                    <Button onClick={() => this.deleteFile(file.file.name)} className="buttom-close"><FaTimes /></Button>
                                                </div>
                                                <span style={{
                                                    display: 'block',
                                                    margin: '0 auto',
                                                    textAlign: ' center',
                                                    left: 0,
                                                    color: '#7b7373',
                                                    fontSize: 12

                                                }}>{name + '.' + file.extension}</span>
                                            </div>
                                        })}
                                    </div>
                                    <div className="cnt-Mensajes-opciones">
                                        <Avatar
                                            style={{ paddingTop: "5px" }}
                                            className={(user.tipo === 1) ? "" : "avatarGlobal"}
                                            src={(user.tipo === 1) ? "/images/portadaPE.jpg" : ""}
                                        >
                                            {user.nombre}
                                        </Avatar>
                                        <Input
                                            ref={ref => this.inputRef = ref}
                                            onKeyDown={this.onKeyDownPress}
                                            onChange={(e) => this.setState({ value: e.target.value })}
                                            placeholder="Escribe tu mensaje aqui..."
                                            value={this.state.value}
                                        />

                                        <div className="cnt-msj-opciones">

                                            <Button title="Adjuntar archivo">
                                                <Upload
                                                    multiple={true}
                                                    showUploadList={false}
                                                    customRequest={this.uploadFile}

                                                >
                                                    <IconAttach />
                                                </Upload>
                                            </Button>
                                            <Button title="Enviar" onClick={() => this.onPress({ target: { value: this.state.value } })}>
                                                <IconSend />
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col> : <Col xs={24} xl={16} xxl={18}> <div className="cnt-Mensajes-body" style={{ paddingTop: '10rem' }}>
                        <Empty
                            description={<span>No hay mensajes disponibles</span>}
                        />
                    </div> </Col>}
                </Row>


            </section>
        );
    }
}

export default function (props) { return <Mensajes {...props} user={useContext(Logged)} /> }


