import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, DatePicker, InputNumber, Select } from 'antd';

import EditableTable from './EditableTable'

const axios = require('axios').default;
const { Title, } = Typography;
const { Option } = Select;
const moment = require('moment')


/**
 *
 *
 * @export
 * @class ModalTimbrado
 * @extends {Component}
 */
class ModalTimbrado extends Component {


    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            ordenes: [],
            razones: [],
            clientes: [],
            dataSource: [],
            newData: [],
            orden: {}

        }
    }

    formModalRS = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getOrdenes()
        this.getRazonesSociales()
        this.getClientes()
    }

    /**
    * @memberof ModalTimbrado
    * @method getOrdenes
    * @description Trae las ordenes
    * 
    */
    getOrdenes = (search) => {
        axios.get('/ordenes', {
            params: {
                search,
                timbrado: true,
                limit: 20
            }
        }).then(response => {
            this.setState({ ordenes: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Ordenes')
        })
    }

    /**
     *
     * @memberof RazonesSociales
     * @method getRazonesSociales
     * @description Obtiene la informacion de los Razones Sociales y actualiza los valores locales de la lista.
     */
    getRazonesSociales = async () => {
        axios.get('/razones-sociales', {
            params: {
                paginate: false,
            }
        })
            .then(res => {
                const data = res.data.data
                console.log("data", data);
                this.setState({ razones: data })
            })
            .catch(res => {
                console.log("error", res);
            });
    }

    /**
    *
    * @memberof FormOrdenes
    * 
    * @method getClientes
    * @description Guardamos el producto
    */
    getClientes = (search) => {
        return axios.get('/clientes/list', {
            params: {
                search,
                limit: 200
            }
        }).then(response => {
            this.setState({ clientes: response.data.data.itemsList })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los clientes')
        })
    }

    /**
    * @memberof ModalTimbrado
    * @method onChangeOrden
    * @description Trae las ordenes_detalles de una orden
    * 
    */
    onChangeOrden = (orden_id) => {
        axios.get('/syncfy/ordenes_detalles', {
            params: {
                orden_id
            }
        }).then(response => {
            console.log("response", response.data);
            this.setState({
                dataSource: response.data.data,
                newData: response.data.data,
                orden: response.data.orden,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Ordenes')
        })
    }

    /**
    * @memberof ModalTimbrado
    * @method onChangeDetalles
    * @description Cambaia el state con la info nueva
    * 
    */
    onChangeDetalles = (info) => {
        console.log("info", info);
        let index = this.state.newData.findIndex(n => n._id.toString() === info._id.toString())
        let data = this.state.newData
        if (index !== -1) {
            data[index] = info
        }

        this.setState({ newData: data })
    }



    /**
     *
     * @memberof ModalTimbrado
     *
     * @method onFinish
     * @description envia los datos para registrar el contribuyente en syncfy
     *
     */
    onFinish = (values) => {

        if (this.state.loading) return

        this.setState({ loading: true }, () => {

            let search = this.props.search;


            axios.post('/syncfy/timbrado', {
                ...values,
                info: this.state.newData
            })
                .then(response => {
                    message.success('Factura Timbrada')
                    this.props.onClose()
                })
                .catch(error => {

                    console.log("error.response?.data?.data", error.response?.data?.data);
                    if (Array.isArray(error.response?.data?.data)) {
                        Modal.error({
                            title: 'Error al timbrar la factura',
                            content: <ul>
                                {error.response.data.data.map(e => <li>{e}</li>)}
                            </ul>
                        })
                    } else {
                        message.error('Error al timbrar la facturas')
                    }
                })
                .finally(() => this.setState({ loading: false }))
        })


    }


    render() {
        const { normFile, formModalRS, onFinish, deleteImage } = this;
        const { form, image, loading, id, loadingImage } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Timbrar factura</Title>
                <Form
                    layout="vertical"
                    ref={formModalRS}
                    onFinish={onFinish}
                    initialValues={{
                        TipoDeComprobante: 'I',
                        Moneda: 'MXN',
                        Fecha: moment(),
                        Exportacion: '01'

                    }}
                >
                    <Row gutter={[16, 16]} justify="center" className="mt-2">
                        <Col xs={20} md={8}>

                            <Form.Item
                                name="Serie"
                                label="Serie"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la serie"
                                }]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={8}>
                            <Form.Item
                                name="Folio"
                                label="Folio"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el folio"
                                }]}
                            >
                                <Input />
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={8}>
                            <Form.Item
                                name="Fecha"
                                label="Fecha"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese la Fecha"
                                }]}
                            >
                                <DatePicker className="width-100" />
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={8}>
                            <Form.Item
                                name="TipoDeComprobante"
                                label="Tipo De Comprobante"

                            >
                                <Select defaultValue={'I'} disabled={true}>
                                    <Option value={'I'}>Ingreso</Option>
                                    <Option value={'E'}>Egreso</Option>
                                    <Option value={'T'}>Traslado</Option>
                                    <Option value={'N'}>Nómina</Option>
                                    <Option value={'P'}>Pago</Option>
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={8}>
                            <Form.Item
                                name="Exportacion"
                                label="Exportacion"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione una opcion"
                                }]}
                            >
                                <Select>
                                    <Option value={'01'}>No aplica</Option>
                                    <Option value={'02'}>Definitiva</Option>
                                    <Option value={'03'}>Temporal</Option>
                                </Select>
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={8}>
                            <Form.Item
                                name="LugarExpedicion"
                                label="Lugar Expedicion (C.P.)"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingreso el codigo postal"
                                }]}
                            >
                                <InputNumber className="width-100" />
                            </Form.Item>

                        </Col>

                        <Col xs={20} md={8}>
                            <Form.Item
                                name="Emisor"
                                label="Emisor"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione una opción"
                                }]}
                                tooltip="
                                El nombre de la razón social debe ser igual al que se tiene registrado en el SAT para evitar discrepancias.
                                Puede obtener el nombre oficial desde el siguiente enlace"
                            >
                                <Select
                                    placeholder="Seleccione la razón social"
                                    showSearch
                                    onSearch={this.getRazonesSociales}
                                    filterOption={false}
                                >

                                    {this.state.razones.map(rs => {
                                        return <Option value={rs._id}  >{rs.razon_social}</Option>
                                    })}
                                </Select>
                                <Button type="link" className="m-0 p-0" href="https://portalsat.plataforma.sat.gob.mx/RecuperacionDeCertificados/">Enlace SAT</Button>
                            </Form.Item>

                        </Col>
                        <Col xs={20} md={8}>
                            <Form.Item
                                name="Receptor"
                                label="Receptor"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione una opción"
                                }]}
                            >
                                <Select
                                    showArrow
                                    placeholder="Seleccione el cliente"
                                    className="witdh-100"
                                    onSearch={(value) => this.getClientes(value)}
                                    showSearch
                                    filterOption={false}
                                >
                                    {this.state.clientes.map(cliente => {
                                        return <Option value={cliente._id}  >{cliente.razon_social} {cliente.apellido}</Option>
                                    })}

                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={20} md={8}>
                            <Form.Item
                                name="orden_id"
                                label="Orden"
                                rules={[{
                                    required: true,
                                    message: "Por favor, seleccione una Orden"
                                }]}
                            >
                                <Select
                                    showArrow
                                    placeholder="Seleccione la orden"
                                    filterOption={false}
                                    onSearch={(value) => this.getOrdenes(value)}
                                    showSearch
                                    onChange={this.onChangeOrden}
                                >
                                    {
                                        this.state.ordenes.map(orden => {
                                            return <Option value={orden._id}>{orden.folio}</Option>
                                        })
                                    }

                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={24}>
                            <EditableTable
                                productos={this.state.dataSource}
                                onChange={this.onChangeDetalles}
                            />

                        </Col>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="Moneda"
                                label="Moneda"
                            >
                                <Select disabled={true} defaultValue={'MXN'}>
                                    <Option value={'MXN'}>MXN</Option>
                                    <Option value={'USD'}>USD</Option>
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="SubTotal"
                                label="SubTotal"
                            >
                                $ {this.state.orden.subtotal?.toMoney(true)}
                            </Form.Item>

                        </Col>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="Impuestos"
                                label="Impuestos (16 %)"
                            >
                                $ {this.state.orden.impuestos?.toMoney(true)}
                            </Form.Item>

                        </Col>
                        <Col xs={20} md={6}>
                            <Form.Item
                                name="Total"
                                label="Total"
                            >
                                $ {this.state.orden.total?.toMoney(true)}
                            </Form.Item>

                        </Col>
                        <Col xs={24} >
                            <Form.Item
                                label="Observaciones"
                                name="observaciones"
                                // formatter="(\d)(?=(\d\d\d)+(?!\d))"
                                rules={[{
                                    max: 1000,
                                    message: "Máximo de caracteres alcanzado (1000)"
                                }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalNegocios
 *@description 
 */
export default function (props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.onClose}
            title={null}
            footer={null}
            closable={true}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            width={800}
        >
            <ModalTimbrado {...props} />
        </Modal>
    )
}
