import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form, Input, message, Spin, Upload, InputNumber, Select, Radio } from 'antd';
import { PlusOutlined, UploadOutlined, LoadingOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;
const axios = require('axios').default;


class ModalProveedores extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accionModal: '',
            loading: false,
            datosConsultados: false,
            return: false,
            extranjero: false,
            tipo: 1,
            impuesto: true
        }
    }

    ModalProveedores = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        console.log('props', this.props)
        if (this.props.proveedor_id !== undefined) {
            this.getProveedor()
        }
    }


    /**
     * @memberof ModalProveedores
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        if (this.props.proveedor_id) {
            this.updateProveedor(values)
        } else {
            this.addProveedor(values)
        }
    }


    /**
     * @memberof ModalProveedores
     * @method addProveedor
     * @description Añade un proveedor a la BD
     */
    addProveedor = (values) => {
        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            let archivos = []
            let comentarios = []
    
            if (values.documentos && values.documentos.length > 0) {
                archivos = values.documentos.map(img => img.name)
            }
    
            if (values.comentarios !== undefined) comentarios = values.comentarios.filter(comentario => (comentario !== undefined && comentario.length >= 1));
    
            axios.post('/proveedores/add', {
                nombre: values.nombre,
                nombre_comercial: values.nombre_comercial,
                linea_credito: values.linea_credito,
                rfc: values.rfc,
                email: values.email,
                telefono: values.telefono,
                pais: values.pais,
                direccion: values.direccion,
                persona: values.persona,
                comentarios,
                plazo: values.plazo,
    
                tipo: values.tipo,
                impuesto: values.impuesto,
    
                documentos: archivos
            }).then(response => {
    
                if (response.data.success) {
                    message.success('Proveedor creado')
                    this.props.onClose()
                }
                else if (response.data.message !== undefined) {
                    message.error(response.data.message.title + "  " + response.data.message.content)
                }
                else {
                    message.error('Error al crear proveedor, verifique los datos ingresados.')
                }
            }).catch(error => {
                console.log(error)
                message.error('Error al crear proveedor.')
            })
            .finally(() => {
                this.setState({loading: false})
            })
        })
        
    }


    /**
     * @memberof ModalProveedores
     * @method getProveedor
     * @description Obtiene un proveedor de la DB
     */
    getProveedor = () => {

        this.setState({ loading: true })

        axios.get('/proveedores/get', {
            params: {
                id: this.props.proveedor_id
            }
        }).then(response => {

            let proveedor = response.data.data
            let comentarios = [];

            this.setState({
                proveedor: response.data.data,
            })

            let archivos = []
            if (proveedor.documentos && proveedor.documentos.length > 0) {
                archivos = proveedor.documentos.map((img, index) => {
                    return {
                        uid: index,
                        response: {
                            filename: img,
                            message: "File upload"
                        },
                        name: img,
                        url: axios.defaults.baseURL + "/upload/" + img,
                        status: 'done',
                    }
                })
            }

            if (proveedor.comentarios) {
                comentarios = proveedor.comentarios.map(comentario => comentario.entrada);
            }

            
            if (proveedor.tipo === 1)
                this.setState({ impuesto: false })
            //Actualiza los valores del form dentro del modal.
            this.ModalProveedores.current.setFieldsValue({
                nombre: proveedor.nombre,
                nombre_comercial: proveedor.nombre_comercial,
                linea_credito: proveedor.linea_credito,
                rfc: proveedor.rfc,
                url: proveedor.url,
                email: proveedor.email,
                telefono: proveedor.telefono,
                pais: proveedor.pais,
                direccion: proveedor.direccion,
                persona: proveedor.persona,
                plazo: proveedor.plazo,
                comentarios,

                tipo: proveedor.tipo,
                impuesto: proveedor.impuesto,
    
                documentos: archivos

            })


            console.log(proveedor.comentarios);

        }).catch(error => {
            message.error('Error al traer proveedor')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
     * @memberof ModalProveedores
     * @method updateProveedor
     * @description Actualiza la información de un proveedor
     */
    updateProveedor = (values) => {

        this.setState({ loading: true })

        let archivos = []
        let comentarios = []
        if (values.documentos && values.documentos.length > 0) {
            archivos = values.documentos.map(img => img.name)
        }

        if (values.comentarios !== undefined) comentarios = values.comentarios.filter(comentario => (comentario !== undefined && comentario.length >= 1));

        axios.post('/proveedores/update', {
            id: this.props.proveedor_id,
            nombre: values.nombre,
            url: values.url,
            nombre_comercial: values.nombre_comercial,
            linea_credito: values.linea_credito,
            rfc: values.rfc,
            email: values.email,
            telefono: values.telefono,
            pais: values.pais,
            direccion: values.direccion,
            persona: values.persona,
            plazo: values.plazo,
            tipo: values.tipo,
            impuesto: values.impuesto,
            comentarios,
            documentos: archivos
        }).then(response => {

            if (response.data.success) {
                message.success('Proveedor Actualizado')
                this.props.onClose()
            }
            else if (response.data.message !== undefined) {
                message.error(response.data.message.title + "  " + response.data.message.content)
            }
            else {
                message.error('Error al editar proveedor, verifique los datos ingresados.')
            }

        }).catch(error => {
            message.error('Error al actualizar proveedor')
            console.log(error)
        }).finally(() => this.setState({ loading: false }))
    }



    /**
  *
  * @memberof ModalProveedores
  *
  * @method removeFile
  * @description Elimina un archivo del WebService.
  *
  * @param images (string)
  * Recibe el nombre de la imagen.
  */
    deleteImage = () => {
        this.setState({ loadingImage: true })
        const { image } = this.state;
        this.removeFile(image.name);

        this.setState({ loadingImage: false })


    };


    /**
     *
     * @memberof ModalProveedores
     *
     * @method normFile
     * @description Se ejecuta cuando se actualiza el estado uploader. Si hay un archivo como "done", se actualiza como el nuevo archivo.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;
        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }


        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status === "done") {
            this.setState({
                image: {
                    url: axios.defaults.baseURL + '/upload/' + e.file.response.filename,
                    name: e.file.response.filename
                }
            });

        }
        if (file.status === "removed")
            this.removeFile((file.response.filename !== undefined) ? file.response.filename : file.name);
        return e && e.fileList;

    };

    /**
     *
     * @memberof ModalProveedores
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito");
                this.setState({ loadingImage: false,/* image: null */ })
            })
            .catch(res => {
                console.log("imagen no se puedo remover");
            })
    };

    render() {

      
        return (
            <Form
                layout="vertical"
                ref={this.ModalProveedores}
                onFinish={this.onFinish}

                onValuesChange={(values, x) => {
                    if (values.tipo){
                        this.ModalProveedores.current.setFieldsValue({ impuesto: undefined })
                        this.setState({ impuesto: (values.tipo === 2), extranjero: (values.tipo === 2)  })
                    }
                }}
                style={{ padding: '0 2em' }}
            // onValuesChange={(values,x) => this.ModalProveedores.current.setFieldsValue({ url: values.nombre ? values.nombre.toLowerCase().replace(/\s+/g, '-') : '' })}

            >
                <Spin spinning={this.state.loading}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre"
                                }]}
                            >
                                <Input placeholder="Nombre" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Tipo"
                                name="tipo"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el tipo"
                                }]}
                            >
                                <Radio.Group> 
                                    <Radio value={1}>Nacional</Radio>
                                    <Radio value={2}>Extranjero</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12} >
                            <Form.Item
                                label="Impuesto"
                                name="impuesto"
                                rules={[{
                                    required: !this.state.impuesto,
                                    message: "Por favor, ingrese el tipo de impuesto"
                                }]}
                            >
                                <Radio.Group disabled={this.state.impuesto} > 
                                    <Radio value={1}>I.V.A. Normal 16%</Radio>
                                    <Radio value={2}>I.V.A. Fronterizo 8%</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={15} >
                            <Form.Item
                                label="Nombre Comercial"
                                name="nombre_comercial"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el nombre comercial"
                                }]}
                            >
                                <Input placeholder="Nombre Comercial" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={9} >
                            <Form.Item
                                label="RFC"
                                name="rfc"
                                rules={[{
                                    required: !this.state.extranjero,
                                    message: "Por favor, ingrese el RFC"
                                },
                                    {
                                        pattern: /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
                                        message: 'RFC invalido'
                                    }
                                ]}
                            >
                                <Input placeholder="RFC" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{
                                    required: true,
                                    message: "Por favor, ingrese el email"
                                }]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Teléfono"
                                name="telefono"
                                rules={[{
                                    required: false,
                                    message: "Por favor, ingrese el teléfono"
                                }]}
                            >
                                <InputNumber placeholder="Teléfono" min={0} defaultValue={0} className="width-100" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={8} >
                            <Form.Item
                                label="País"
                                name="pais"
                                rules={[{
                                    required: false,
                                    message: "Por favor, ingrese el nombre del país"
                                }]}
                            >
                                <Select
                                    placeholder="Elige un País"
                                >
                                    <Select.Option value="MEX">MEX</Select.Option>
                                    <Select.Option value="USA">USA</Select.Option>
                                    <Select.Option value="CHN">CHN</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={16} >
                            <Form.Item
                                label="Dirección"
                                name="direccion"
                                rules={[{
                                    required: false,
                                    message: "Por favor, ingrese la dirección"
                                }]}
                            >
                                <Input placeholder="Dirección" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Persona"
                                name="persona"
                                rules={[{
                                    required: false,
                                    message: "Por favor, ingrese el nombre de la persona"
                                }]}
                            >
                                <Input placeholder="Persona" />
                            </Form.Item>
                        </Col>

                        <Col xs={10} lg={12} >
                            <Form.Item
                                label="Linea de Crédito"
                                name="linea_credito"
                                rules={[{
                                    required: false,
                                    message: "Por favor, ingrese la linea de crédito"
                                }]}
                            >
                                <InputNumber placeholder="Linea de Crédito" min={0} defaultValue={0} className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={10} lg={12} >
                            <Form.Item
                                label="Plazo del Crédito"
                                name="plazo"
                                rules={[{
                                    required: false,
                                    message: "Por favor, ingrese el plazo de crédito"
                                }]}
                            >
                                <InputNumber placeholder="Plazo de Crédito" min={0} defaultValue={0} className="width-100" />
                            </Form.Item>
                        </Col>


                        <Col xs={24} lg={24} >
                            <Form.Item
                                label="Documentos"
                                name="documentos"
                                getValueFromEvent={this.normFile}
                                valuePropName="fileList"
                                className="content-uploader"
                            >
                                <Upload
                                    className="avatar-uploader"
                                    action={axios.defaults.baseURL + "/upload/add"}
                                    multiple={true}
                                >
                                    <Button>
                                        <UploadOutlined /> Agregar archivos
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.List
                                name="comentarios"
                                rules={[]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                required={false}
                                                key={field.key}
                                                style={{ marginBottom: 12 }}
                                                {...(index === 0 ? { label: 'Comentarios' } : null)}
                                            >
                                                <Row align="middle">
                                                    <Col xs={21} lg={22}>
                                                        <Form.Item
                                                            {...field}
                                                            style={{ marginBottom: 0 }}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                        >
                                                            <Input.TextArea
                                                                placeholder="Ingresa un comentario"
                                                                rows={2}
                                                                style={{ width: "100%" }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col xs={{ span: 2, push: 1 }} lg={{ span: 1, push: 1 }}>
                                                        <CloseCircleOutlined
                                                            style={{ fontSize: "1.5rem" }}
                                                            onClick={() => remove(field.name)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Item>
                                        )
                                        )}
                                        <Button
                                            type="dashed"
                                            style={{ width: "100%", marginBottom: 24 }}
                                            icon={<PlusOutlined />}
                                            onClick={() => add()}
                                        >
                                            Agregar Comentario
                                        </Button>

                                    </>
                                )}
                            </Form.List>
                        </Col>
                    </Row>

                    <Row style={{ textAlign: "center" }}>
                        <Col span={24} >
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading || this.state.loadingImage}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    // width={600}
    >
        <div className="center">
            <Title level={3}>  Proveedor </Title>
        </div>
        <ModalProveedores {...props} />
    </Modal>

}