import React from 'react'
import { Route, Switch } from "react-router-dom";
import { Result } from 'antd';


// componentes
import Cuentas from '../components/Admin/Cuentas/Cuentas'
/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterCuentas(props) {
    return (
        <Switch>
            <Route exact path="/admin/cuentas" render={() => <Cuentas {...props} />} />
        </Switch>
    )
}

export default RouterCuentas;
