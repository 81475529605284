import React, { Component } from "react";
import { Layout, Button, Col, Row, Space, Popconfirm, Typography, List, message, Card, Statistic, Tooltip } from "antd";
import { IconDelete, IconEdit, IconDetails, IconSAT } from '../../Widgets/Iconos';
import CustomAvatar from "./../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import { FilePdfOutlined, DownloadOutlined } from '@ant-design/icons';
import ModalIngresos from './Ingresos/ModalIngresos';
import ModalIngresosDetalle from './Ingresos/ModalIngresosDetalle';
import DrawerTimbradoOrden from "../Finanzas/Facturas/DrawerTimbrado";
import '../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'

const { Content } = Layout;
const { Text } = Typography
const axios = require('axios')
const moment = require('moment')

var tabActiva = "";

/**
 * @export
 * @class IngresosList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class IngresosList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            transaccion_id: undefined,
            modalIngresosDetalleVisible: false,
            modalIngresosVisible: false,
            
            // ingresos: [],
            // page: 1,
            // limit: 10,
            // total: 0,
            ingresos: {
                data: [],
                page: 1,
                limit: 20, 


                total: 0,
                pages: 0
            },



            filtroSearch: '',
            searching: true,


        }
    }

    componentDidMount = () => {
        this.getTransacciones()
    }



    componentDidUpdate = () => {


        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getTransacciones();
            this.state.searching = false;
        }


        if (this.props.actualizarTabla !== tabActiva) {
            tabActiva = this.props.actualizarTabla;
        }
        if (tabActiva === "Ingresos" && this.state.actualizarTabla === true) {

            this.state.actualizarTabla = false;
            this.setState({ actualizarTabla: false })
            this.getTransacciones();
        }
        else if (tabActiva !== "Ingresos" && !(this.state.actualizarTabla)) {
            this.state.actualizarTabla = true;
            this.setState({ actualizarTabla: true })
        }


    }

    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    getTransacciones = (
        {
            page = this.state.ingresos.page,
            limit = this.state.ingresos.limit,

        } = this.state.ingresos
    ) => {

        let search = this.props.search;
        const { clienteId } = this.props

        this.setState()

        axios.post('/transacciones/list', {
            page,
            limit,
            tipo: 1, // Solo Ingresos
            cliente_id: clienteId,
            vmi: true,
            search
        })
            .then(({ data }) => {
                data = data.data
                // console.log('data', data);
                this.setState({
                    ingresos: {
                        ...this.state.ingresos,
                        data: data.itemsList,
                        total: data.itemCount,
                        pages: data.pageCount,
                    },
                    searching: true,
                    filtroSearch: search
                })
            })
            .catch(error => {
                message.error("No se obtuvieron las Transacciones")
                console.log(error)
            })
    }


    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                className="component-list lista-vmi"
                                locale={{ emptyText: "Sin Ingresos" }}
                                itemLayout="horizontal"
                                dataSource={this.state.ingresos.data}
                                pagination={{
                                    current: this.state.ingresos.page,
                                    pageSize: this.state.ingresos.limit,
                                    total: this.state.ingresos.total,
                                    // hideOnSinglePage: false,
                                    showSizeChanger: true, 
                                    position: 'bottom',
                                    className: "flex-left",
                                    onChange: (page, limit) => {
                                        this.getTransacciones({page, limit})
                                        // console.log('getTransacciones', page, limit);
                                    }
                                }}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <div className={`badge-card tipo-${item.tipo}`}>
                                            </div>
                                            <Row className="width-100 font-13">
                                                <Col span={2} className="center">
                                                    <Text strong>{moment(item.fecha).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col xs={7} className="center" >
                                                    <Text className="text-gray-dark" style={{ textAlign: "start", width: '100%' }} strong>{item.concepto?.length > 50 ? item.concepto?.slice(0, 50) + "..." : item.concepto}</Text>
                                                </Col>

                                                <Col xs={2} className="center">
                                                    <Text className="text-gray-dark">{item.orden_id?.folio}</Text>
                                                </Col>
                                                <Col xs={1} className="center">
                                                    <CustomAvatar name={[item.cliente_id?.nombre, item.cliente_id?.apellido]} />
                                                </Col>
                                                <Col xs={3} className=" center">
                                                    <Text className="text-gray-dark">{item.cuenta_id?.nombre}</Text>
                                                </Col>
                                                <Col xs={4} className="center">
                                                    <Text>{this.renderMonto(item.tipo, item.monto)} </Text>
                                                </Col>
                                                <Col xs={5} className="center">
                                                    <Space>
                                                        <Tooltip title="Comprobante de Pago SAT">
                                                            <Button
                                                                disabled={!!item.comprobante_id}
                                                                icon={<IconSAT style={{ width: 15, height: 15, top: 3, position: "relative" }} color="white" />}
                                                                type="primary"
                                                                onClick={() => this.setState({ drawerTimbrarComprobanteVisible: true, transaccion_id: item._id, orden_id: item.orden_id._id })}
                                                                style={{
                                                                    backgroundColor: "#00529E" + (item.comprobante_id ? "80" : ""),
                                                                    borderColor: "#00529E" + (item.comprobante_id ? "80" : ""),
                                                                }}
                                                            >
                                                            </Button>
                                                        </Tooltip>
                                                        <Button
                                                            icon={<IconDetails />}
                                                            type="primary"
                                                            style={{ backgroundColor: "#ADFF45", borderColor: "#ADFF45" }}
                                                            title="Ver Detalle"
                                                            onClick={() => this.setState({ modalIngresosDetalleVisible: true, transaccion_id: item._id })}>
                                                        </Button>
                                                        <Button
                                                            target="_blank"
                                                            // download={true}
                                                            href={`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${item.comprobante_id}&Authorization=${sessionStorage.getItem("token")}&dummy=dummy`}
                                                            disabled={!item.comprobante_id}
                                                            icon={<FilePdfOutlined style={{ color: "white" }} />}
                                                            type="primary"

                                                            style={{
                                                                backgroundColor: "#00529E" + (!item.comprobante_id ? "80" : ""),
                                                                borderColor: "#00529E" + (!item.comprobante_id ? "80" : ""),
                                                            }}
                                                        >
                                                             
                                                        </Button>
                                                        <Tooltip title={<>
                                                    Imprimir XML
                                                </>}>
                                                    <Button
                                                        disabled={!item.comprobante_id}
                                                        icon={<DownloadOutlined  style={{ color: "white" }}  />}
                                                        type="primary"
                                                        href={`${axios.defaults.baseURL}/facturas/cfdi/xml?factura_id=${item.comprobante_id}&Authorization=${sessionStorage.getItem("token")}&dummy=dummy`} 
                                                        style={{
                                                            backgroundColor: "#00529E" + (!item.comprobante_id ? "80" : ""),
                                                            borderColor: "#00529E" + (!item.comprobante_id ? "80" : ""),
                                                        }}
                                                    >
                                                    </Button>
                                                </Tooltip>
                                                        <Button
                                                            icon={<IconEdit />}
                                                            type="primary"
                                                            title="Editar"
                                                            onClick={() => this.setState({ modalIngresosVisible: true, transaccion_id: item._id })}>
                                                        </Button>
                                                        <Popconfirm
                                                            placement="topRight"
                                                            title="¿Deseas eliminar esta Transacción?"
                                                            onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                                message.success('Transacción Eliminada')
                                                                this.getTransacciones()
                                                            }).catch((error) => {
                                                                message.success('Transacción NO Eliminada')
                                                                this.getTransacciones();
                                                            })
                                                            }
                                                            okText="Si"
                                                            cancelText="No"
                                                        >
                                                            <Button type="primary" danger icon={<IconDelete />} title="Eliminar" />
                                                        </Popconfirm>
                                                    </Space>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />

                        </Col>
                    </Row>
                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalIngresosVisible: true })} />
                </Content>
                <ModalIngresos
                    visible={this.state.modalIngresosVisible}
                    transaccion_id={this.state.transaccion_id}
                    cliente_id={this.props.clienteId}
                    onClose={() => {
                        this.setState({ modalIngresosVisible: false, transaccion_id: undefined });
                        this.getTransacciones();
                    }}
                    orden={this.state.orden}
                />
                <ModalIngresosDetalle
                    visible={this.state.modalIngresosDetalleVisible}
                    transaccion_id={this.state.transaccion_id}
                    onClose={() => {
                        this.setState({ modalIngresosDetalleVisible: false, transaccion_id: undefined });
                    }}
                    orden={this.state.orden}
                />
                <DrawerTimbradoOrden
                    visible={this.state.drawerTimbrarComprobanteVisible}
                    transaccion_id={this.state.transaccion_id}
                    cliente_id={this.props.clienteId}
                    vmi={true}
                    onClose={() => {
                        this.setState({ drawerTimbrarComprobanteVisible: false, modalTransacVisible: false, transaccion_id: undefined })
                        this.getTransacciones();
                    }}
                    onSuccess={() => {
                        this.setState({ drawerTimbrarComprobanteVisible: false, modalTransacVisible: false, transaccion_id: undefined })
                        this.getTransacciones();
                    }}
                    orden_id={this.state.orden_id}
                />
            </>
        )
    }
}