import React, { Component, useState } from "react";
import {
    AutoComplete,
    Button,
    Input,
    message,
    Modal,
    Spin,
    Typography,
    Upload,
    Select,
    InputNumber,
    Avatar,
    List,
    Empty,
    Space
} from 'antd';

import PropTypes from "prop-types";
import { UploadOutlined } from "@ant-design/icons";

import { EditableProTable } from '@ant-design/pro-table';


const axios = require('axios').default;


const { Title, Text, Paragraph } = Typography;
const { Option } = Select;

/**
 *
 *
 * @export
 * @class AgregarProductos
 * @extends {Component}
 */
class AgregarProductos extends Component {


    /**
     *
     *
     * @static
     * @memberof AgregarProductos
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    state = {
        editableKeys: [],//.map((item) => item.id),
        dataSource: [],

        loading: false,
        proveedores: {
            data: [],

            page: 1,
            limit: 10,

            total: 0,
            pages: 0,

            loading: false
        },

        productos: {
            data: [],

            page: 1,
            limit: 10,

            total: 0,
            pages: 0,
        },

    }


    componentDidMount() {
        this.getOrdenDetallesSinProductos()
        this.getProductos()
    }


    /**
     *
     *
     * @memberof AgregarProductos
     * 
     * @method getOrdenDetallesSinProductos
     * @description Obtenemos todos los ordenes_detalles que no tienen un producto en fin
     */
    getOrdenDetallesSinProductos = () => {
        axios.get('/productos/orden/inexistentes', {
            params: {
                orden_id: this.props.orden_id
            }
        })
            .then(({ data }) => {

                this.setState({
                    editableKeys: data.data.map((item) => item.id),
                    dataSource: data.data,
                    available: false
                })
            })
            .catch(error => {
                console.log('error', error)
                message.error('Error al traer la información')
            })
    }


    /**
     *
     *
     * @memberof AgregarProductos
     * 
     * @method getProveedores
     * @description Obtenemos los proveedores para agregar
     */
    getProveedores = ({

        page = this.state.proveedores.page,
        limit = this.state.proveedores.limit,
        search = this.state.proveedores.search

    } = this.state.proveedores) => {
        this.setState(state => {
            state.loading = true
            state.proveedores.page = page;
            state.proveedores.limit = limit;
            return state;
        })
        axios.get('/proveedores/list', {
            params: {
                page,
                limit,
                search,
            }
        })
            .then(response => {
                console.log('response ', response)
                this.setState(state => {
                    if (page === 1)
                        state.proveedores.data = response.data.data.itemsList
                    else
                        state.proveedores.data = [...state.proveedores.data, ...response.data.data.itemsList]

                    state.proveedores.total = response.data.data.paginator.itemCount
                    state.proveedores.pages = response.data.data.paginator.pageCount

                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
            .finally(error => this.setState({
                loading: false
            }))
        // state.loading = false
    }

    /**
     *
     *
     * @memberof AgregarProductos
     * 
     * @method getProductos
     * @description Obtenemos los productos a obtener
     */
    getProductos = ({

        page = this.state.productos.page,
        limit = this.state.productos.limit,
        search = this.state.productos.search

    } = this.state.productos) => {
        this.setState(state => {
            state.productos.page = page;
            state.productos.limit = limit;
            return state;
        })
        return axios.get('/productos', { params: { page, limit, search } })
            .then(response => {

                this.setState(state => {
                    // state.productos.page = page;
                    // state.productos.limit = limit;
                    if (page === 1)
                        state.productos.data = response.data.data.data
                    else
                        state.productos.data = [...state.productos.data, ...response.data.data.data]

                    state.productos.total = response.data.data.total
                    state.productos.pages = response.data.data.pages
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
            .finally(error => this.setState({
                loading: false
            }))
    }

    /**
     *
     *
     * @memberof AgregarProductos
     * 
     * @method onPopupScrollProductos
     * @description Al scrollear, obtenemos los productos
     */
    onPopupScrollProductos = event => {
        const { page, pages, } = this.state.productos
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        ) {
            console.log('true')
            this.getProductos({ page: page + 1 })
        }

    }


    /**
     *
     * @memberof AgregarProductos
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (image) => {
        axios.post("/upload/delete", {
            filename: image
        })
            .then(res => {
                console.log("imagen removida con exito", res);
            })
            .catch(res => {
                console.log("imagen no se puedo remover", res);
            })
    };

    /**
     *
     * @memberof AgregarProductos
     *
     * @method normFile
     * @description Declaramos las imagenes en el row de la tabla
     *
     * @param images (string)
     * Recibe el nombre de la imagen.
     */
    normFile = (e) => {
        const { file, fileList } = e;
        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].filename = fileList[x].response.filename
                fileList[x].name = fileList[x].response.name
            }

        }
        if (file.status === "removed")
            this.removeFile((file?.response?.filename) ? file.response.filename : file.filename);
        return e.fileList;
    };


    /**
     *
     * @memberof AgregarProductos
     *
     * @method saveModalAgregar
     * @description Guardamos al agregar.
     *
     */
    saveModalAgregar = () => {

        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            console.log('M N S H ', {
                orden_id: this.props.orden_id,
                dataSource: this.state.dataSource.map(e => {
                    e.proveedor_id = (e.proveedor_id?._id) ? e.proveedor_id._id : e.proveedor_id
                    return e
                })
            })
    
            axios.post('/productos/orden/inexistentes', {
                orden_id: this.props.orden_id,
                dataSource: this.state.dataSource.map(e => {
                    e.proveedor_id = (e.proveedor_id?._id) ? e.proveedor_id._id : e.proveedor_id
                    return e
                })
            })
                .then(({ data }) => {
                    message.success('Productos Guardados Exitosamente.')
    
                    console.log('data.data.redirect', data)
                    this.props.onCancel({ redirect: data.redirect })
                })
                .catch(error => {
    
                    console.log('error', error)
                    if (Array.isArray(error?.response?.data?.errors))
                        Modal.error({
                            title: "No pudimos guardar todos los productos.",
                            content: <List
                                itemLayout="horizontal"
                                dataSource={error?.response?.data?.errors}
                                renderItem={item => (
                                    <List.Item>
                                        <List.Item.Meta
                                            description={item}
                                        />
                                    </List.Item>
                                )}
                            />,
                        })
                    this.getOrdenDetallesSinProductos()
                })
                .finally(() => {
                    this.setState({loading: false})
                })
        })
        

    }

    /**
     *
     * @memberof AgregarProductos
     *
     * @description Columans de la productos
     */
    columns = [
        {
            title: 'N/P Nexus',
            dataIndex: 'np_nexus',
            width: 150,
            formItemProps: {
                rules: [
                    { required: true, message: "Ingrese el numero de parte del Proveedor" }
                ]
            },
            renderFormItem: (_, { type, defaultRender, ...rest }, form) => <Input {...rest} placeholder="NX-11521-XT" />,
        },
        {
            title: 'N/P Fabricante',
            dataIndex: 'np_fabricante',
            formItemProps: {
                placeholder: "11521-XT",
                rules: [
                    { required: true, message: "Por favor, ingrese el numero de parte del fabricante" }
                ],
            },
            width: 150,
            renderFormItem: (_, { type, defaultRender, ...rest }, form) => <Input {...rest} placeholder="11521-XT" />,
        },
        {
            title: 'Marca',
            dataIndex: 'marca',
            width: 150,
            formItemProps: {
                rules: [
                    {
                        required: true,
                        whitespace: true,
                        message: 'Es requerido',
                    },
                ],
            },
            renderFormItem: (_, { type, defaultRender, ...rest }, form) => <Input {...rest} placeholder="Grainger" />,
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            width: 200,
            formItemProps: {
                rules: [
                    { required: true, message: "Ingrese el SKU" }
                ],
            },
            renderFormItem: (_, { type, defaultRender, ...rest }, form) => <AutoComplete
                options={this.state.productos.data.map(producto => ({
                    value: producto.sku,
                    producto,
                    label: <List.Item.Meta
                        avatar={<Avatar
                            shape="square"
                            size="large"
                            src={(Array.isArray(producto.imagenes) && producto.imagenes.length > 0) ? (axios.defaults.baseURL + '/upload/' + producto.imagenes[0].filename) : undefined}
                        />}
                        title={<>
                            <Text strong>{producto.marca}</Text>
                            &nbsp;
                            <Text>{producto.np_nexus}</Text><br />
                        </>}
                        description={<>
                            {producto.sku} <br />
                            {producto.descripcion}
                        </>}
                    />
                }))}
                onSearch={search => this.getProductos({ search })}

                // onSelect={(value, { producto }) => this.setProducto(producto)}
                className="width-100">
                <Input allowClear placeholder="Producto" enterButton={false} />
            </AutoComplete>,

            // <Input {...rest} placeholder="SKU" />,
        },
        {
            title: 'Proveedor',
            dataIndex: 'proveedor_id',
            width: 200,
            formItemProps: {
                rules: [
                    { required: true, message: "Ingrese el proveedor del producto" }
                ],
            },
            renderFormItem: (_, { type, defaultRender, ...rest }, form) => <Select
                {...rest}
                labelInValue
                showSearch
                placeholder="Buscar proveedor"
                optionFilterProp="title"
                onSearch={search => this.getProveedores({ page_: 1, search })}
                onPopupScroll={this.onPopupScrollProveedores}
                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Sin Proveedores" />}
            >
                {this.state.proveedores.data.map(({ _id, nombre, nombre_comercial, rfc }) => <Option key={_id} value={_id} title={nombre}>
                    {nombre}
                    <br />
                    <small>{nombre_comercial}</small><br />
                    <small>{rfc}</small>
                </Option>)}
            </Select>
        },
        {
            title: 'Costo',
            dataIndex: 'costo',
            formItemProps: {
                rules: [
                    { required: true, message: "Ingrese el Costo" }
                ],
                // columnEmptyText: "MIN",
                // emptyText: "MIN",
                // locale: {emptyText: 'No Data'}
            },
            width: 120,
            renderFormItem: (_, { type, defaultRender, ...rest }, form) => <InputNumber className="width-100" {...rest} formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} parser={value => value.replace(/\$\s?|(,*)/g, '')} />
        },
        {
            title: 'Imagenes',
            dataIndex: 'imagenes',

            formItemProps: {
                // label="Imagenes del Producto"
                // name="imagenes"
                getValueFromEvent: this.normFile,
                valuePropName: "fileList"
            },
            width: 200,
            renderFormItem: (_, { type, defaultRender, ...rest }, form) => <Upload
                {...rest}
                action={axios.defaults.baseURL + "/upload/add"}
                multiple={true}
            >
                <Button icon={<UploadOutlined />} size="small" />
            </Upload>
        },
    ]

    render() {
        const { loading, dataSource, editableKeys } = this.state;

        console.log('dataSource', dataSource)
        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Productos Faltantes</Title>
                <Paragraph className="text-center" strong>Los siguientes productos de la orden no se encuentran en registrados.</Paragraph>
                <Paragraph className="text-center" strong>Registralos para que puedan ser utilizados en otras ordenes.</Paragraph>
                <EditableProTable
                    // form={this.form}
                    formProps={{
                        ref: ref => window.ref = ref
                    }}
                    locale={{
                        emptyText: 'No hay Registros',
                    }}
                    scroll={{ x: 1050 }}
                    rowKey="id"
                    columns={this.columns}
                    value={dataSource}
                    onChange={dataSource => this.setState({ dataSource })}
                    recordCreatorProps={{
                        newRecordType: 'dataSource',
                        record: () => ({
                            id: Date.now(),
                        }),
                        style: { display: 'none' }
                    }}
                    // onValuesChange={}
                    editable={{
                        type: 'multiple',
                        editableKeys,
                        actionRender: (row, config, defaultDoms) => [defaultDoms.delete],
                        onChange: (editableKeys) => this.setState({ editableKeys }),
                        onValuesChange: (record, values) => {
                            // console.log('values',values)
                            if (values.every(({ costo, proveedor_id, sku, marca, np_fabricante, np_nexus }) => (costo && proveedor_id && sku && marca && np_fabricante && np_nexus)))
                                this.setState({ available: true, dataSource: values })
                            else
                                this.setState({ available: false, dataSource: values })
                        }
                    }}
                />
                <Space>
                    <Button disabled={!this.state.available} loading={this.state.loading} onClick={this.saveModalAgregar}>Guardar</Button>
                </Space>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onCancel } = props

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
            width={900}
        >
            <AgregarProductos {...props} />
        </Modal>
    )
}
