import React, { Component } from 'react';
import { Button, Popconfirm, Space, Spin, Table, PageHeader, Layout, message, Typography, Tooltip } from 'antd'
import axios from 'axios';
//Componentes
import { IconDelete, IconEdit, IconBankError } from '../../Widgets/Iconos';

import { BankOutlined } from '@ant-design/icons'
import CustomAvatar from "../../Widgets/Avatar/Avatar";
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";
import ModalCuentas from './ModalCuentas.js'

import ModalConfiguraciónSyncfy from '../Syncfy/ModalConfiguraciónSyncfy'

const { Content } = Layout;
const { Text } = Typography;
/**
 *
 *
 * @export
 * @class Cuentas
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class Cuentas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            modalVisible: false,
            cuenta_id: undefined,


            page: 1,
            total: 0,
            filtroSearch:'',
            searching: true,
        }
    }

    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        this.props.updateFilterSearch(true);

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getCuentas();
    }

     /**
     *
     *
     * @memberof Cuentas
     * 
     * @method componentDidUpdate
     * @description Actualiza la vista si se buscan datos
     */
      componentDidUpdate() {

        const filtro = this.state.filtroSearch;
        const propFiltro = this.props.search;

        if (filtro !== propFiltro && this.state.searching == true) {
            this.getCuentas();
            this.state.searching = false;
        }
    }

    /**
    * @memberof Cuentas
    * @method getCuentas
    * @description Trae las cuentas 
    * 
    */
    getCuentas = (page = this.state.page) => {

        let search = this.props.search;
        this.setState({loading: true})
        axios.get('/cuentas/list', {
            params: {
                page: page,
                search:search
            }
        }).then(response => {
            this.setState({
                cuentas: response.data.data.itemsList,
                page: response.data.data.paginator.currentPage,
                total: response.data.data.paginator.itemCount,
                searching: true,
                filtroSearch: search
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al traer la información')
        }).finally(()=>this.setState({loading: false}))
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        title="Cuentas"
                    />

                    <Content className="admin-content content-bg pd-1">

                        <Table
                            loading={this.state.loading}
                            scroll={{ x: 1000, y: 1000 }}
                            columns={[
                                {
                                    title: 'Logo',
                                    key: 'avatar',
                                    width: '80px',
                                    render: (text, { nombre, apellido, logo, color }) => <CustomAvatar name={[nombre, apellido]} image={logo} color={color} />,

                                },
                                {
                                    title: 'Nombre',
                                    dataIndex: 'nombre',
                                    render: (text, record) => (
                                        <h3>{record.nombre}</h3>
                                    ),
                                },
                                {
                                    title: 'Saldo',
                                    dataIndex: 'saldo',
                                    render: (text, record) => (
                                        <Text> {text.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</Text>
                                    )
                                },
                                {
                                    title: 'Titular',
                                    dataIndex: 'titular',
                                    key: 'titular',
                                },
                                {
                                    title: 'Número Cuenta',
                                    dataIndex: 'cuenta',
                                    key: 'numerocuenta',
                                },
                                {
                                    title: 'Acciones',
                                    key: 'actions',
                                    fixed: 'right',
                                    width: '210px',
                                    render: (text, record) => (
                                        <Space>

                                            <Tooltip title={(record.syncfy_usuario_id && record.syncfy_credenciales_id) ? "Cuenta con enlace bancario" : "Cuenta sin enlace bancario"}>
                                                <Button 
                                                    onClick={() => this.setState({ syncfy_id: record._id, modalSyncfy: true })}
                                                    danger = {!(record.syncfy_usuario_id && record.syncfy_credenciales_id)}
                                                    type="primary"
                                                    icon={
                                                        (record.syncfy_usuario_id && record.syncfy_credenciales_id)
                                                            ?
                                                            <BankOutlined style={{ fontSize: 22, color: "white", position: "relative", bottom: 2 }} />
                                                            :
                                                            <IconBankError color="white" style={{ position: 'relative', bottom: 2 }} />
                                                    }
                                                    title="Eliminar"
                                                    style={{ backgroundColor: (record.syncfy_usuario_id && record.syncfy_credenciales_id) ? "#1890ff" : undefined, border: (record.syncfy_usuario_id && record.syncfy_credenciales_id) ? "none" :undefined }}
                                                />
                                            </Tooltip>
                                            <Button
                                                type="primary"
                                                icon={<IconEdit />}
                                                title="Editar"
                                                onClick={() => { this.setState({ modalVisible: true, cuenta_id: record._id }) }}
                                            />
                                            <Popconfirm
                                                placement="topRight"
                                                title="¿Deseas eliminar esta Cuenta?"
                                                onConfirm={() => axios.post('/cuentas/delete',
                                                    { id: record._id }).then(() => {
                                                        this.getCuentas()
                                                        message.success('Cuenta eliminada')
                                                    }).catch(error => console.log(error))}
                                                okText="Si"
                                                cancelText="No"
                                            >
                                                <Button
                                                    danger
                                                    type="primary"
                                                    icon={<IconDelete />}
                                                    title="Eliminar"
                                                />
                                            </Popconfirm>


                                        </Space>
                                    ),
                                },
                            ]}
                            dataSource={this.state.cuentas}
                            pagination={{
                                onChange: this.getCuentas,
                                current: this.state.page,
                                pageSize: 10,
                                total: this.state.total,
                                position: ['bottomLeft'],
                            }}
                            locale={{ emptyText: 'Sin Cuentas Disponibles' }}
                             />

                    </Content>

                    <FloatingButton title="Nuevo registro" onClick={() => this.setState({ modalVisible: true })} />

                    <ModalCuentas
                        visible={this.state.modalVisible}
                        onClose={() => {
                            this.setState({ modalVisible: false, cuenta_id: undefined })
                            this.getCuentas()
                        }}
                        cuenta_id={this.state.cuenta_id}
                    />

                </Spin>

                 <ModalConfiguraciónSyncfy
                    visible={this.state.modalSyncfy}
                    id={this.state.syncfy_id}
                    onCancel={() => {
                        this.setState({ modalSyncfy: false, id: null, cuenta_id: undefined })
                        this.getCuentas();
                    }}
                    tipo={1}
                    // id_site_organization_type={"56cf4f5b784806cf028b4568"}
                /> 
            </>
        )
 
    }
}