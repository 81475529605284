import React from 'react'
import { Route, Switch } from "react-router-dom";
import InventariosVendedor from '../components/Admin/ManagerInventarios/InventariosVendedor';

// componentes
import ManagerInventarios from '../components/Admin/ManagerInventarios/ManagerInventarios'

/**
 *
 *
 * @export
 * @function PublicRoutes
 * @extends {Component}
 */
function RouterManagerInventarios(props) {
    return (
        <Switch>
            <Route exact path="/admin/manager-inventarios" render={(props_route) => <ManagerInventarios {...props} {...props_route} />} />
            <Route exact path="/admin/manager-inventarios/vendedor/:cliente_id" render={(props_route) => <InventariosVendedor {...props} {...props_route} />} />
        </Switch>
    )
}

export default RouterManagerInventarios;
