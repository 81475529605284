import React, { Component } from "react";

import { Layout, Row, Col, PageHeader, Space, Button, Dropdown, Form, List, Tag, Collapse, Typography, Card, message, Spin, Popconfirm } from "antd";


import { PlusOutlined } from "@ant-design/icons";
import { IconEye, IconEdit, IconArrowBack, IconDelete, IconLink, } from '../../Widgets/Iconos';
import FloatingButton from "../../Widgets/Floating Button/FloatingButton";

import FilterSelect from "../../Widgets/Filtros/FilterSelect";
import { CardRazonSocial, MontosFaltantes, CardEstatusContabilidad } from "../../Widgets/Cards";

import './../../../Styles/Modules/Contabilidad/conta.css'

import ModalTransaccionesDetalle from "../Finanzas/Transacciones/ModalTransaccionesDetalle";
import ModalTransacciones from "../Finanzas/Transacciones/ModalTransacciones";
import ModalFacturas from "../Finanzas/Facturas/ModalFacturas";
import ModalAsociacionFacturasTransacciones from "./ModalAsociacionFacturasTransacciones";

const { Content } = Layout;
const { Title, Text } = Typography;
const axios = require('axios')
const moment = require('moment')
const { Panel } = Collapse;

const tipo = { "0": 'Sin Cambios', "1": ' Ingreso', "-1": 'Egreso' }
const estatus_facturas = ['Factura Cancelada', 'Pendiente', 'Liquidad']

/**
 *
 *
 * @export
 * @class RazonSocial
 * @extends {Component}
 * @description Vista del listado de RazonSocial
 */
export default class RazonSocial extends Component {

    constructor(props) {
        super(props)
        this.state = {
            razon_social: {},
            loading: false,
            active_panel: 1,
            razon_social: {},
            facturas: {
                data: [],
                loading: false,
                page: 1,
                limit: 10,
                total: 0,
                filtroSearch: '',
                searching: true,

            },
            transacciones: {
                data: [],
                loading: false,
                page: 1,
                limit: 10,
                total: 0,
                filtroSearch: '',
                searching: true,

            },
            transacciones_faltantes: [],
            facturas_faltantes: [],

            filtroSearch: '',
            searching: true,
            showFiltro: {
            },

            modal_trans_editar: false,
            modal_trans_detalle: false,

            modal_fact_detalle: false,
            modal_factura_editar: false,
            modal_factura: false,


            transaccion_id: undefined,
            factura_id: undefined,
        }
    }

    filterForms = React.createRef();


    componentDidMount() {
        //Mostrar input superior de busqueda 
        //Para acceder al valor del input = this.props.search
        // this.props.updateFilterSearch(true);
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getRazonSocial();

        this.getFacturas();
        this.getTransacciones();
        this.getMontosFaltantes();
    }



    getRazonSocial = () => {
        axios.get('/razones-sociales/get', {
            params: {
                id: this.props.match.params.razon_social_id,
                amounts: true
            }
        }).then(response => {
            console.log('response razon social', response)

            this.setState({
                razon_social:response.data.data,
                transacciones_montos: response.data.transacciones,
                facturas_montos: response.data.facturas
            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer Montos Faltantes')
        })
    }


    getMontosFaltantes = () => {
        axios.get('/razones-sociales/get/transacciones/facturas/montos', {
            params: {
                id: this.props.match.params.razon_social_id,
            }
        }).then(response => {
            this.setState({
                transacciones_faltantes: response.data.transacciones,
                facturas_faltantes: response.data.facturas
            });
        }).catch(error => {
            console.log(error)
            message.error('Error al traer los Razon Socials')
        })
    }

    getFacturas = (
        {
            page = this.state.facturas.page,
            limit = this.state.facturas.limit,
            search = this.state.facturas.search,

        } = this.state.facturas,
        { facturas } = this.state,
        filters = this.filterForms.getFieldsValue()
    ) => {
        facturas.loading = true;
        facturas.page = page;
        facturas.limit = limit;
        facturas.search = search;
        this.setState({ facturas })

        axios.get('/facturas/list', {
            params: {
                page,
                limit,
                filters,
                search,
                razon_social_id: this.props.match.params.razon_social_id
            }
        })
            .then(response => {
                let facturas = {
                    data: response.data.data.itemsList,
                    page: response.data.data.paginator.currentPage,
                    total: response.data.data.paginator.itemCount,
                    filtroSearch: search,
                    searching: true,
                    loading: false
                }
                this.setState({
                    facturas
                })
            })
            .catch(error => {
                facturas.loading = false;
                this.setState({facturas})
                message.error('Error al Cargar las facturas')
              
            })

    }


    /**
       *
       *
       * @memberof Transacciones
       * @description Obtiene todas todas las transacciones
       * @param values pagina
       */
    getTransacciones = (
        {
            page = this.state.transacciones.page,
            limit = this.state.transacciones.limit,
            search = this.state.transacciones.search,

        } = this.state.transacciones,
        { transacciones } = this.state,
        filters = this.filterForms.getFieldsValue()
    ) => {
        transacciones.loading = true;
        transacciones.page = page;
        transacciones.limit = limit;
        transacciones.search = search;
        this.setState({ transacciones })
        axios.post('/transacciones/list', {
            page,
            limit,
            filters,
            search,
            razon_social_id: this.props.match.params.razon_social_id
        })
            .then(res => {
                let transacciones = {
                    data: res.data.data.itemsList,
                    page: res.data.data.currentPage,
                    total: res.data.data.itemCount,
                    perPage: res.data.data.perPage,
                    pages: res.data.data.pageCount,
                    filtroSearch: search,
                    searching: true,
                    loading: false
                }
                this.setState({
                    transacciones
                })
            })
            .catch(res => {
                console.log("error", res);
                message.error('No se pudieron cargar las transacciones')
            })
    }


    toogleFilters = (value) => {
        // console.log('value recibido', value);
        this.setState({ showFiltro: value });

        this.renderFiltros()
    }

    renderFiltros = () => {
        let tags = []
        let { showFiltro } = this.state;
        for (const filtro in showFiltro) {
            let array_filtro = showFiltro[filtro];
            for (const key in array_filtro) {
                tags.push(<Tag closable className="tag-filtro" closeIcon={<div className="point"></div>}>{array_filtro[key]}</Tag>)
            }

        }
        return tags;
    }

    onChangePanel = (key) => {
        this.setState({ active_panel: key })
    }
    back = () => {
        this.props.history.goBack();
    }

    render() {
        return (
            <>
                <Spin spinning={false}>
                    <PageHeader
                        className="site-page-header custom-page-header"
                        backIcon={<IconArrowBack />}
                        onBack={this.back}
                        title={
                            <Row className="width-100" justify="space-between" align="middle">
                                <Col xxl={{ span: 24 }} xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}  >
                                    <span className="ant-page-header-heading-title" >
                                        Razón Social
                                    </span>
                                </Col>
                                <Col span={24}>
                                    {this.renderFiltros()}
                                </Col>
                            </Row>
                        }
                        extra={
                            <Form
                                className="form-filters"
                                ref={ref => this.filterForms = ref}
                                // this.getTransacciones({ page: 1, filters: allValues })
                                onValuesChange={(changedValues, allValues) => this.toogleFilters(allValues)}
                            >
                                <Dropdown overlay={<FilterSelect />} trigger="hover">
                                    <Button className="button-plus" icon={<PlusOutlined style={{ color: '#000', fontWeight: 600 }} />} />
                                </Dropdown>
                            </Form>
                        }
                    />
                    <Content className="admin-content content-bg pd-1 ">
                        <Row>
                            <Col span={24}><Title className="subtitle-dashboard" level={2}>Conciliación</Title></Col>
                        </Row>
                        <Row gutter={[18, 18]} className="mb-1">
                            <Col sm={24} lg={12} xl={8} xxl={6}>
                                <CardRazonSocial razon_social={this.state.razon_social} transacciones={this.state.transacciones_montos} facturas={this.state.facturas_montos} />
                            </Col>
                            <Col sm={24} lg={12} xl={8} xxl={6}>
                                <MontosFaltantes transacciones={this.state.transacciones_faltantes} facturas={this.state.facturas_faltantes} />
                            </Col>
                            <Col sm={24} lg={12} xl={8} xxl={6}>
                                <CardEstatusContabilidad />
                            </Col>
                        </Row>
                        <Row className="width-100" justify="space-between" align="middle">
                            <Col span={24}>
                                <Collapse activeKey={this.state.active_panel} onChange={this.onChangePanel} ghost accordion>
                                    <Panel header={<Title level={3}>Facturas</Title>} key="1">
                                        <List
                                            loading={this.state.facturas.loading}
                                            className="component-list "
                                            size="large"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: 'Sin Facturas' }}
                                            dataSource={this.state.facturas.data}
                                            renderItem={item => (
                                                <List.Item className="component-list-item">
                                                    <Card className="card-list" bordered={false}>
                                                        <Row className="width-100">
                                                            <Col xs={24} md={6} lg={2} xl={3} className="center item-info">
                                                                <Text ellipsis strong>{moment(item?.fecha).format('DD/MM/YYYY')}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={4} xl={6} className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={3} xl={4} className="center item-info">
                                                                <span className="spn-estatus">
                                                                    <Text ellipsis className="text-gray-dark">{estatus_facturas[item?.estatus]}</Text>
                                                                </span>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={3} xl={4} className="center item-info">
                                                                <span className="spn-tipo">
                                                                    <Text ellipsis className="text-gray-dark">{tipo[item?.tipo]}</Text>
                                                                </span>
                                                            </Col>

                                                            <Col xs={24} md={6} lg={3} xl={3} className="center item-info">
                                                                <Text ellipsis className="text-back text-monto"> $ {item?.monto}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={3} xl={4} className="center item-info">
                                                                <Space direction="horizontal">
                                                                    <Button
                                                                        className="btn-eye"
                                                                        icon={<IconEye />}
                                                                        type="primary"
                                                                        title="Ver Detalle"
                                                                        disabled={item.factura_xml}
                                                                    >
                                                                    </Button>

                                                                    <Button
                                                                        className="btn-link"
                                                                        icon={<IconLink />}
                                                                        type="primary"
                                                                        title="Link"
                                                                        onClick={() => this.setState({ modal_link: true, factura_id: item._id })}
                                                                    >
                                                                    </Button>

                                                                    <Button
                                                                        className="btn-editar"
                                                                        icon={<IconEdit />}
                                                                        type="primary"
                                                                        title="Editar"
                                                                        onClick={() => { this.setState({ modal_factura_editar: true, factura_id: item._id }) }}

                                                                    >
                                                                    </Button>
                                                                    <Popconfirm
                                                                        placement="topRight"
                                                                        title="¿Deseas eliminar esta factura?"
                                                                        onConfirm={() => { }}
                                                                        okText="Si"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button
                                                                            type="primary"
                                                                            danger
                                                                            icon={<IconDelete />} title="Eliminar" />
                                                                    </Popconfirm>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                            pagination={{
                                                onChange: (page, limit) => {
                                                    this.getFacturas({ page, limit })
                                                },
                                                hideOnSinglePage: true,
                                                total: this.state.facturas.total
                                            }}
                                        />
                                    </Panel>
                                    <Panel header={<Title level={3}>Transacciones</Title>} key="2">
                                        <List
                                            loading={this.state.transacciones.loading}
                                            className="component-list "
                                            size="large"
                                            itemLayout="horizontal"
                                            locale={{ emptyText: 'Sin Transacciones' }}
                                            dataSource={this.state.transacciones.data}
                                            renderItem={item => (

                                                <List.Item className="component-list-item ">
                                                    <Card className="card-list" bordered={false}>
                                                        <Row className="width-100">
                                                            <Col xs={24} md={6} lg={2} xl={2} className="center item-info">
                                                                <Text ellipsis strong>{moment(item?.fecha).format('DD/MM/YYYY')}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={4} xl={4} className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{item?.concepto}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={3} xl={3} className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{(item?.area !== undefined) ? item?.area : 'Sin Área'}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={3} xl={3} className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{(item?.rubro !== undefined) ? item?.rubro : 'Sin Rubro'}</Text>
                                                            </Col>

                                                            <Col xs={24} md={6} lg={4} xl={4} className="center item-info">
                                                                <Text ellipsis className="text-gray-dark">{item?.cuenta_id?.nombre}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={4} xl={4} className="center item-info">
                                                                <Text ellipsis className="text-back text-monto"> $ {item?.monto}</Text>
                                                            </Col>
                                                            <Col xs={24} md={6} lg={4} xl={4} className="center item-info">
                                                                <Space direction="horizontal">
                                                                    <Button
                                                                        className="btn-eye"
                                                                        icon={<IconEye />}
                                                                        type="primary"
                                                                        title="Ver Detalle"
                                                                        onClick={() => this.setState({ modal_trans_detalle: true, transaccion_id: item._id })}

                                                                    >
                                                                    </Button>

                                                                    <Button
                                                                        className="btn-link"
                                                                        icon={<IconLink />}
                                                                        type="primary"
                                                                        title="Link"
                                                                        onClick={() => this.setState({ modal_link: true, transaccion_id: item._id })}

                                                                    >
                                                                    </Button>

                                                                    <Button
                                                                        className="btn-editar"

                                                                        icon={<IconEdit />}
                                                                        type="primary"
                                                                        title="Editar"
                                                                        onClick={() => this.setState({ modal_trans_editar: true, transaccion_id: item._id })}

                                                                    >
                                                                    </Button>
                                                                    <Popconfirm
                                                                        placement="topRight"
                                                                        title="¿Deseas eliminar esta Transacción?"
                                                                        onConfirm={() => axios.post('/transacciones/delete', { id: item._id }).then((response) => {
                                                                            message.success('Transaccion Eliminada')
                                                                            this.getFacturas()
                                                                        }).catch((error) => {
                                                                            message.error('Transacción NO Eliminada')
                                                                            this.getFacturas();
                                                                        })
                                                                        }
                                                                        okText="Si"
                                                                        cancelText="No"
                                                                    >
                                                                        <Button
                                                                            type="primary"
                                                                            danger
                                                                            icon={<IconDelete />} title="Eliminar" />
                                                                    </Popconfirm>
                                                                </Space>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </List.Item>
                                            )}
                                            pagination={{
                                                onChange: (page, limit) => {
                                                    this.getTransacciones({ page, limit })
                                                },
                                                hideOnSinglePage: true,
                                                total: this.state.transacciones.total,
                                            }}
                                        />
                                    </Panel>
                                </Collapse>

                            </Col>
                        </Row>
                        <ModalTransacciones
                            visible={this.state.modal_trans_editar}
                            transaccion_id={this.state.transaccion_id}
                            onClose={() => {
                                this.setState({ modal_trans_editar: false, transaccion_id: undefined })
                                this.getFacturas()
                            }}
                        />
                        <ModalFacturas
                            visible={this.state.modal_factura_editar}
                            onClose={() => {
                                this.setState({ modal_factura_editar: false, factura_id: undefined })
                                this.getFacturas()
                            }}
                            factura_id={this.state.factura_id}
                        />
                        <ModalTransaccionesDetalle
                            visible={this.state.modal_trans_detalle}
                            onClose={() => this.setState({
                                modal_trans_detalle: false,
                                transaccion_id: undefined
                            })}
                            id={this.state.transaccion_id}
                        />

                        <ModalAsociacionFacturasTransacciones
                            visible={this.state.modal_link}
                            onClose={() => this.setState({
                                modal_link: false,
                                transaccion_id: undefined,
                                factura_id: undefined
                            })}
                            factura_id={this.state.factura_id}
                            transaccion_id={this.state.transaccion_id}
                            razon_social_id={this.props.match.params.razon_social_id}

                        />


                        {/* <FloatingButton title="Nuevo registro" /> */}
                    </Content>
                </Spin>


            </>
        )
    }
}