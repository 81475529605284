

import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Select, Spin, Typography, Upload } from 'antd';

import { DeleteOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import PropTypes from "prop-types";
import Logged from '../../../Hooks/Logged';

const axios = require('axios').default;
const { Option } = Select;
const { Title } = Typography;


/**
 *
 *
 * @class FormUsuariosClientes
 * @extends {React.Component}
 * @description Formulario de usuarios
 */
class FormUsuariosClientes extends Component {

    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };

    static defaultProps = {
        visible: false,
        hideModal: () => { },
        accept: () => { }
    };

    static contextType = Logged;
    formModaluser = React.createRef();

    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            usuarios: [],
            form: [],
        }
    }


    /**:4
     * @methodOf ModalUsuarios
     *
     * @function componentDidUpdate
     * @description Se ejecuta cuando se inicia el component, declara en el header el session storage
     *
     * */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getUsuarioClientes(this.props.id)

    }

    /**
     *
     * @methodOf ModalUsuarios
     *
     * @method getUsuarioClientes
     * @description Obtiene la lista de posibles usuarios del cliente
     * @param id ObjectId
     *
     * */
    getUsuarioClientes = (id) => {
        axios.get("/usuarios/clientes",
            { params: { cliente_id: id } })
            .then(res => {
                console.log('res modal usuariocliente', res);
                let usuarios = res.data.data.data;

                let usuarios_array = []
                for (const usuario of usuarios) {
                    if (usuario.cliente_id != null) {
                        usuarios_array.push({ key: usuario._id, value: usuario._id, label: `${usuario.nombre} ${usuario.apellido}` })
                    }
                }
                this.setState({
                    usuarios: res.data.data.data,
                    usuarios_array
                })
                this.formModaluser.current.setFieldsValue({
                    usuarios: usuarios_array,
                })
            })
            .catch(res => {
                message.error('No se encontro el cliente');
                console.log("Usuario no obtenido", res);
            })
    }

    /**
     * @methodOf  Usuarios
     * @method saveUsuario
     *
     * @description Actualiza los usuarios para asignarlos al cliente.
     *
     * */
    saveUsuario = values => {
        this.setState({
            loading: true
        })


        axios.post('/usuarios/clientes/update',
            {
                ...values,
                cliente_id: this.props.id
            })
            .then(() => {
                message.success("¡Se han guardado los usuarios correctamente!")
                this.props.accept();
            })
            .catch((e) => {
                message.error('No se pudo actualizar el usuario');
                console.log('e', e)
            })
            .finally(() => this.setState({
                loading: false,
            }))

    }

    render() {
        const { formModaluser, saveUsuario, } = this;
        const { form, loading, id, } = this.state;
        console.log('prev usuarios', this.state)
        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">{id ? "Editar Usuario al Cliente" : "Nuevo Usuario al Cliente"}</Title>
                <Form layout="vertical" ref={formModaluser} onFinish={saveUsuario}
                    initialValues={form}
                >
                    <Form.Item name="cliente_id" noStyle >
                        <Input type="hidden" />
                    </Form.Item>
                    <Row>
                        <Col xs={24}>
                            <Form.Item label="Usuarios" name="usuarios"  >
                                <Select mode="multiple" placeholder="Usuario" labelInValue>
                                    {this.state.usuarios.map((option, index) => (
                                        <Option key={index} value={option._id}>
                                            {option.nombre} {option.apellido}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center" align="middle" >
                        <Col span={24} className="flex-column">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}


/**
 *@function ModalUsuariosCliente
 *@description 
 */
export default function (props) {
    return (
        <Modal
            visible={props.visible}
            onCancel={props.hideModal}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormUsuariosClientes {...props} />
        </Modal>
    )
}