
import React, { Component } from "react";
import { Layout, PageHeader, Button, Col, Row, Typography, message, Tabs } from "antd";
import { CardAccountPurchases, CardEntrega, CardLegalInfo, CardProjectInfo, CardUtilidad, } from "../../Widgets/Cards";
import { IconArrowBack } from '../../Widgets/Iconos';
import StepTracker from "../ProjectManager/Secciones/StepTracker";

import ModalFacturas from "./AccountPurchase/ModalFacturas";
import ModalFacturasDetalle from "./AccountPurchase/ModalFacturasDetalle";

import { User } from '../../../Hooks/Logged';
import ModalAlmacen from "./AccountPurchase/ModalAlmacen";

//componentes
import EgresosList from './AccountPurchase/EgresosList'
import IngresosList from './AccountPurchase/IngresosList'
import FacturasList from './AccountPurchase/FacturasList'
import PurchasesList from './AccountPurchase/PurchasesList'
import NotasCreditoList from './AccountPurchase/NotasCreditoList'

import '../../../Styles/Modules/ProjectManager/ProjectManager.scss';

const { Content } = Layout;
const { Title } = Typography
const { TabPane } = Tabs

const axios = require('axios')
/**
 *
 *
 * @export
 * @class AccountPurchases
 * @extends {Component}
 */
class AccountPurchases extends Component {

    back = () => {
        this.props.history.goBack();
    }

    constructor(props) {
        super(props)
        this.state = {
            orden_id: this.props.match.params.orden_id,
            orden_compra_id: this.props.match.params.orden_compra_id,
            user: undefined,

            loading: false,
            orden: undefined,
            cliente: undefined,
            user: undefined,

            responsable: false,
            owner: false,

            tipo: 1,
            currentPage: 1,
            total: 10,

            transaccion_id: undefined,
            factura_id: undefined,
            modalFacturaVisible: false,
            modalFacturaDetalleVisible: false,
            modalTransacVisible: false,
            modalTransacDetalleVisible: false,
            productos: [],
            items: [],
            ordenes_compra: [],



            monto_venta: 0,
            costos_proyectado: 0,
            costos_real: 0,
            ingreso_proyectado: 0,
            ingreso_real: 0,
            utilidad_proyectado: 0,
            utilidad_real: 0,
            facturas_emitidas: 0,
            facturas_recibidas: 0,
            impuestos: 0,

            tituloVista: "Orden"
        }
    }

    stepTracker = React.createRef();

    /**
   *
   *
   * @memberof AccountPurchases
   * @method componentDidMount
   * @description Carga las transacciones y ordenes de la vista
   */
    componentDidMount = () => {

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getTransacciones();
        this.getOrden();
        this.getOrdenInfo();

        if (this.props.history.location.search) {
            const urlParams = new URLSearchParams(window.location.search);
            switch (urlParams.get('tab')) {
                case 'compras':
                    this.onTabChange(null, { tipo: 4 })
                    break;

                case 'ingresos':
                    this.onTabChange(null, { tipo: 2 })
                    break;


            }
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.orden && this.props.user?._id !== prevProps.user?._id) {
            let responsable = this.state.orden.agente_compras_id?._id === this?.props.user._id
            let owner = this.props.user?.tipo === 1
            this.setState({
                responsable,
                owner
            })
        }

        if(this.props.match.params.orden_id && this.props.match.params.orden_id !== this.state.orden_id){
            this.setState({orden_id: this.props.match.params.orden_id},()=>{
                this.getTransacciones();
                this.getOrden();
                this.getOrdenInfo();
            })
        }
    }


    getAll = () => {
        
        this.getTransacciones();
        this.getOrden();
        this.getOrdenInfo();
    }


    /**
     *
     *
     * @memberof AccountPurchases
     * @method getOrden
     * @description Desconozco para que hay dos metodos
     */


    getOrden = () => {
        this.setState({ loading: true })
        axios.get('/project-manager/orden', {
            params: {
                id: this.props.match.params.orden_id
            }
        }).then(response => {

            let orden = response.data.data

            let ingresos = orden.margen?.ingresos ? orden.margen.ingresos : 0
            let costos = orden.margen?.costos ? orden.margen?.costos : 0

            let margen = (ingresos - costos) / ingresos * 100
            let progress = orden.percentage ? orden.percentage : 0

            this.setState({
                orden,
                percentage: progress,
                productos: orden.orden_detalles,
                margen: margen.toMoney(),
            })
        })
            .catch(error => {
                console.log(error)
                message.error('Error al cargar la informacion')
            })
            .finally(() => this.setState({ loading: false }))
    }


    /**
    *
    *
    * @memberof AccountPurchases
    * @method onTabChange
    * @description Al seleccionar un filtro de ordenes se cargan los datos correspondientes
    */
    onTabChange = (_, values) => {

        this.setState({
            tipo: values.tipo,
            currentPage: 1
        }, () => {


            switch (this.state.tipo) {
                case 1:
                case 2:
                    this.getTransacciones();
                    break;
                case 3:
                    this.getFacturas()
                    break;

                case 4:
                    this.getOrdenesCompra()
                    break;
            }
        })
    }

    /**
   *
   *
   * @memberof AccountPurchases
   * @method onPageChange
   * @description Al seleccionar un filtro de ordenes se cargan los datos correspondientes
   */
    onPageChange = (current, size) => {
        this.setState({
            currentPage: current
        }, () => {


            switch (this.state.tipo) {
                case 1:
                case 2:
                    this.getTransacciones();
                    break;
                case 3:
                    this.getFacturas()
                    break;

                // case 4:
                //     this.getOrdenesCompra()
                //     break;
            }
        })
    }


    /**
     *
     *
     * @memberof AccountPurchases
     * @method getTransacciones
     * @description Obtiene las transacciones de la orden correspondiente
     */
    getTransacciones = () => {

        const orden_id = this.props.match.params.orden_id;
        const { tipo, currentPage } = this.state;

        if (!orden_id) return;

        axios.post('/transacciones/list', {
            id: orden_id,
            tipo,
            page: currentPage
        }, {
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(({ data }) => {

                this.setState({
                    items: data.data.itemsList,
                    total: data.data.itemCount
                })
            })
            .catch(err => console.log(err))
    }

    /**
    *
    *
    * @memberof AccountPurchases
    * @method getFacturas
    * @description Obtiene las facturas de la orden acual
    */
    getFacturas = () => {
        const orden_id = this.props.match.params.orden_id;
        const { tipo, currentPage } = this.state;

        if (!orden_id) return;

        axios.get('/facturas/list', {
            params: {
                id: orden_id,
                tipo,
                page: currentPage
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(({ data }) => {
                this.setState({
                    items: data.data.itemsList,
                    total: data.data.paginator.itemCount
                })
            })
            .catch(err => console.log(err))
    }

    /**
     *
     *
     * @memberof AccountPurchases
     * @method getOrdenesCompra
     * @description Obtiene las ordenes de compra de la orden correspondiente
     */
    getOrdenesCompra = () => {
        const orden_id = this.props.match.params.orden_id;
        const tipo = this.state.tipo;


        if (!orden_id) return;
        const { currentPage } = this.state;

        axios.get('/ordenesCompra/getListaCompras', {
            params: {
                orden_id: orden_id,
                id: orden_id,
                page: currentPage
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(({ data }) => {

                this.setState({
                    items: data.ordenes_compra.itemsList,
                    total: data.ordenes_compra.paginator.itemCount
                })
            })
            .catch(err => {
                console.log(err)
            })
    }


    /**
     *
     *
     * @memberof AccountPurchases
     * @method deleteItem
     * @description Elimina una factura, transaccion de la orden
     */
    deleteItem = (id) => {

        let url = ""
        switch (this.state.tipo) {
            case 1:
            case 2:
                url = "/transacciones/delete"
                break;
            case 3:
                url = "/facturas/delete"
                break;

            case 4:
                url = "/ordenesCompra/delete"
                break;

        }

        axios.post(url, { id }, {
            headers: { Authorization: sessionStorage.getItem('token') }
        })
            .then(response => {

                this.getOrdenInfo();

                let items = []
                this.state.items.forEach(item => {
                    if (item._id !== id) {
                        items.push(item)
                    }
                })
                this.setState({
                    items
                });

            })
            .catch(err => {
                console.log(err)
            }
            )
    }



    /**
     *
     *
     * @memberof AccountPurchases
     * @method aceptarOrden
     * @description Actualiza una orden de compra
     */
    aceptarOrden = (id) => {
        axios.post("/ordenesCompra/update",
            {
                id,
                estatus: 1
            },
            {
                headers: { Authorization: sessionStorage.getItem('token') }
            })
            .then(response => {
                if (response.data.success) {
                    message.success('Se ha actualizado el estatus de orden de compra.');
                    this.getOrdenesCompra();
                    this.getOrdenInfo();

                }
                else {
                    message.error('Error al actualizar estatus de orden de compra.');
                }
            })
            .catch(err => {
                console.log(err)
                message.error('Error al actualizar estatus de orden de compra.');

            })
    }



    /**
     *
     *
     * @memberof AccountPurchases
     * @method getOrdenInfo
     * @description Al seleccionar un filtro de ordenes se cargan los datos correspondientes
     */
    getOrdenInfo = () => {
        return axios.get('/ordenes/getInfo', {
            params: {
                id: this.props.match.params.orden_id
            },
            headers: {
                Authorization: sessionStorage.getItem('token')
            }
        })
            .then(({ data }) => {

                if (data.success) {


                    let monto_venta         = data.info.monto_venta         === null ? 0 : data.info.monto_venta;
                    let costos_proyectado   = data.info.costos_proyectado   === null ? 0 : data.info.costos_proyectado;
                    let costos_real         = data.info.costos_real         === null ? 0 : data.info.costos_real;
                    let ingreso_proyectado  = data.info.ingreso_proyectado  === null ? 0 : data.info.ingreso_proyectado;
                    let ingreso_real        = data.info.ingreso_real        === null ? 0 : data.info.ingreso_real - data.info.iva;
                    let utilidad_proyectado = data.info.utilidad_proyectado === null ? 0 : data.info.utilidad_proyectado;
                    let utilidad_real       = data.info.utilidad_real       === null ? 0 : data.info.utilidad_real;
                    let facturas_emitidas   = data.info.facturas_emitidas   === null ? 0 : data.info.facturas_emitidas;
                    let facturas_recibidas  = data.info.facturas_recibidas  === null ? 0 : data.info.facturas_recibidas;
                    let impuestos           = data.info.iva                 === null ? 0 : data.info.iva ;

                    ingreso_real    = ingreso_real  - impuestos;
                    utilidad_real   = utilidad_real - impuestos;

                        this.setState({
                            order: data.orden,
                            cliente: data.orden.cliente_id,
                            user: data.orden.agente_compras_id,

                            monto_venta,
                            costos_proyectado,
                            costos_real,
                            ingreso_proyectado,
                            ingreso_real,
                            utilidad_proyectado,
                            utilidad_real,
                            facturas_emitidas,
                            facturas_recibidas,
                            impuestos,

                            tituloVista: "Orden " + data.orden.folio
                        });

                }
            }

            )
            .catch(err => console.log(err))
    }

    /**
     *
     *
     * @memberof AccountPurchases
     * @method cancelOrden
     * @description Cambia el estatus de la orden a cancelado y te regresa al PM
     */
    cancelOrden = () => {
        if(this.state.loading) return
        this.setState({loading: true}, () => {
            axios.post("/ordenes/update",{
                id: this.state.orden._id,
                estatus: 2
            },{
                headers: { Authorization: sessionStorage.getItem('token') }
            })
            .then(response => {
                if (response.data.success) {
                    message.success('Se ha cancelado la orden');
                    this.props.history.push('/admin/project-manager')
                }
                else {
                    message.error('Error al cancelar la orden');
                }
            })
            .catch(err => {
                console.log(err)
                message.error('Error al cancelar la orden');
            })
            .finally(() => {
                this.setState({loading: false})
            })
        })
    }


    render() {

        const { monto_venta, costos_proyectado, costos_real, ingreso_proyectado, ingreso_real, impuestos,
            utilidad_proyectado, utilidad_real, facturas_emitidas, facturas_recibidas, tituloVista } = this.state;
        return (
            <>
                <PageHeader
                    className="site-page-header custom-page-header"
                    title={
                        <Row className=" width-100 " justify="space-between" align="middle">
                            <Col span={2} xxl={1}>
                                <Button ghost onClick={this.back} className="no-border">
                                    <IconArrowBack />
                                </Button>
                            </Col>

                            <Col span={22} xxl={23}>
                                <span className="ant-page-header-heading-title" >
                                    Cadena de Suministro {tituloVista}
                                </span>
                            </Col>
                        </Row>
                    }
                />

                <Content className="content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={24} lg={8} xl={6}>
                            <StepTracker orden_id={this.state.orden_id} ref={this.stepTracker} />
                        </Col>

                        <Col lg={16} xl={18} >
                            <Row gutter={[16, 16]}>
                                <Col span={8} xl={8} lg={24} md={24} >

                                    <CardProjectInfo
                                        cliente={this.state.orden?.cliente_id}
                                        orden={this.state.orden}
                                        responsable={this.state.orden?.agente_compras_id}
                                        progress={this.state.percentage}
                                    />
                                </Col>
                                <Col span={8} xl={8} lg={24} md={24}>
                                    <Row gutter={[0, 16]}>
                                        <Col span={24}>
                                            <CardUtilidad margen={this.state.margen} />
                                        </Col>
                                        <Col span={24}>
                                            <CardLegalInfo orden_id={this.state.orden?._id} />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8} xl={8} lg={24} md={24}>
                                    <CardEntrega
                                        direccion={this.state.cliente?.direccion}
                                        orden_id={this.state.orden?._id}
                                        fecha_entrega={this.state.orden?.fecha_entrega}
                                        update={this.getOrden}
                                    />
                                </Col>
                            </Row>

                            <Row justify="space-between " className="width-100 mt-1">
                                <Col><Title level={3}>Contabilidad y Compras</Title></Col>
                            </Row>

                            <Row className="width-100 ">
                                <CardAccountPurchases
                                    vmi={this.state.orden?.tipo_orden === 2}
                                    monto_venta={monto_venta}
                                    costos_proyectado={costos_proyectado}
                                    costos_real={costos_real}
                                    ingreso_proyectado={ingreso_proyectado}
                                    ingreso_real={ingreso_real}
                                    utilidad_proyectado={utilidad_proyectado}
                                    utilidad_real={utilidad_real}
                                    facturas_emitidas={facturas_emitidas}
                                    facturas_recibidas={facturas_recibidas}
                                    impuestos={impuestos}
                                />
                            </Row>

                            <Row gutter={[24, 48]} className="mt-1">
                                <Col xs={24}>
                                    <Tabs defaultActiveKey={this.state.orden_compra_id ? "4" : "1"} destroyInactiveTabPane={true} className="tabs-inventario width-100">
                                        <TabPane tab="Ingresos" key="1">
                                            <IngresosList
                                                search={this.props.search}
                                                orden_id={this.props.match.params.orden_id}
                                                orden={this.state.orden}
                                                updateOrden={() => {
                                                    this.stepTracker?.current?.getOrden()
                                                    this.getOrdenInfo()
                                                    this.getOrden()
                                                }}
                                            />
                                        </TabPane>
                                        <TabPane tab="Egresos" key="2">
                                            <EgresosList
                                                search={this.props.search}
                                                orden_id={this.props.match.params.orden_id}
                                                orden={this.state.orden}
                                                updateOrden={() => {
                                                    this.stepTracker?.current?.getOrden()
                                                    this.getOrdenInfo()
                                                }}
                                            />
                                        </TabPane>
                                        <TabPane tab="Notas de Crédito" key="3">
                                            <NotasCreditoList
                                                search={this.props.search}
                                                orden_id={this.props.match.params.orden_id}
                                                orden={this.state.orden}
                                                updateOrden={() => {
                                                    this.stepTracker?.current?.getOrden()
                                                    this.getOrdenInfo()
                                                }}
                                            />
                                        </TabPane>
                                        <TabPane tab="Facturas" key="4">
                                            <FacturasList
                                                search={this.props.search}
                                                orden_id={this.props.match.params.orden_id}
                                                orden={this.state.orden}
                                                updateOrden={() => {
                                                    this.stepTracker?.current?.getOrden()
                                                    this.getAll();
                                                }}
                                            />
                                        </TabPane>

                                        <TabPane tab="Ordenes Compra" key="5">
                                            <PurchasesList
                                                search={this.props.search}
                                                orden_id={this.props.match.params.orden_id}
                                                orden_compra_id={this.state.orden_compra_id}
                                                orden={this.state.orden}
                                                premium={this.state.cliente?.premium}
                                                cliente={this.state.cliente}
                                                updateOrden={() => {
                                                    this.stepTracker?.current?.getOrden()
                                                }}
                                                responsable={this.state.responsable}
                                                owner={this.state.owner}
                                                path={`${process.env.REACT_APP_PUBLIC_URL}${this.props.location?.pathname}`}
                                            />
                                        </TabPane>

                                    </Tabs>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Content>
                <ModalAlmacen
                    visible={this.state.modalAlmacenVisible}
                    cliente_id={this.state.cliente?._id}
                    orden_compra_id={this.state.orden_compra_id}
                    onClose={() => {
                        this.setState({ modalAlmacenVisible: false, orden_compra_id: undefined })
                        this.getOrdenesCompra();
                        this.getOrdenInfo();
                    }}
                />

                <ModalFacturas
                    visible={this.state.modalFacturaVisible}
                    factura_id={this.state.factura_id}
                    onClose={() => {
                        this.setState({ modalFacturaVisible: false, factura_id: undefined });
                        this.getFacturas();
                        this.getOrdenInfo();
                    }}
                    orden={this.state.orden}
                />
                <ModalFacturasDetalle
                    visible={this.state.modalFacturaDetalleVisible}
                    factura_id={this.state.factura_id}
                    onClose={() => {
                        this.setState({ modalFacturaDetalleVisible: false, factura_id: undefined });
                        this.getAll();
                    }}
                    onSuccess={() => {
                        this.getAll();
                    }}
                    orden={this.state.orden}
                />
            </>
        )
    }
}

export default function (props) {
    const user = React.useContext(User);

    return <AccountPurchases user={user} {...props} />
}