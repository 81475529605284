import React, { Component } from "react";

import {
    Layout, Row, Col, PageHeader, Space, Button, List,
    Tag, Typography, Card, message, Spin,
    Tooltip,
    Statistic
} from "antd";

import { EyeOutlined, EditFilled, CloseOutlined, WarningOutlined, CheckCircleOutlined, FilePdfOutlined } from "@ant-design/icons";

import ModalXML from "../../Syncfy/ModalXML";
import ModalFacturas from "../../Finanzas/Facturas/ModalFacturas";
import ModalTimbrado from "../../Finanzas/Facturas/ModalTimbrado";

const { Text } = Typography;

const axios = require('axios')
const moment = require('moment')

/**
 *
 *
 * @export
 * @class Facturas
 * @extends {Component}
 * @description Vista del listado de Facturas
 */
class Facturas extends Component {

    constructor(props) {
        super(props)
        this.state = {
            facturas: {

                data: [],

                limit: 10,
                page: 1,

                total: 0,
                pages: 0,

                //filtros
                cliente_id: this.props.cliente_id
            }
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getTransacciones()
    }

    /**
    *
    *
    * @memberof Facturas
    * @description Obtiene todas todas las ListaCompras
    * @param values pagina
    */
    getTransacciones = (
        {
            page = this.state.facturas.page,
            limit = this.state.facturas.limit,
            cliente_id = this.props.cliente_id
        } = this.state.facturas,
        { facturas } = this.state

    ) => {

        facturas.page = page;
        facturas.limit = limit;
        console.log({ cliente_id })

        this.setState({ loading: true, facturas });
        let search = this.props.search;
        axios.get('/facturas/list', {
            params: {
                search,
                page,
                cliente_id
            }
        })
            .then(response => {


                console.log('response.data.data',)
                facturas.data = response.data.data.itemsList
                facturas.total = response.data.data.paginator.itemCount
                facturas.pages = response.data.data.paginator.pageCount
                this.setState({
                    facturas
                    // facturas: response.data.data.itemsList,
                    // page: response.data.data.paginator.currentPage,
                    // total: response.data.data.paginator.itemCount,

                    // searching: true,
                    // loading: false
                })
            })
            .catch(error => {
                message.error('Error al Cargar las facturas')
                console.log(error)
            })
            .finally(() => this.setState({ loading: false }))
    }




    /**
     *
     *
     * @param {*} item
     * @memberof Facturas
     * 
     * @description renderizamos las vistas de facturas
     */
    renderViews = (item) => {


        return <Space>
            <Button
                type="primary"
                icon={<EditFilled />}
                title="Editar"
                onClick={() => { this.setState({ modalFacturasVisible: true, factura_id: item._id }) }}
            />
            <Button
                type="primary"
                icon={<EyeOutlined style={{ color: "#fff" }} />}
                onClick={(e) => this.setState({ factura_xml_storage: item.factura_xml, ModalXMLVisible: true })}
                title="Ver XML"
                disabled={!item.factura_xml}
            >
            </Button>
            <Button
                type="primary"
                icon={<FilePdfOutlined style={{ color: "currentColor" }} />}
                onClick={() => window.open(`${axios.defaults.baseURL}/facturas/cfdi?factura_id=${item._id}&Authorization=${sessionStorage.getItem("token")}`, '_blank')}
                title="Ver PDF"
                disabled={!(item.tipo_facturacion === 1)}
            />
        </Space>
    }

    /**
     *
     *
     * @param {*} item
     * @memberof FacturasSC
     * 
     * @description Renderizamos el estatus de la factura.
     */
    renderEstatus = (item) => {

        switch (item.estatus) {
            case 0:
                return <Tag color="#87d068" icon={<CloseOutlined style={{ color: "currentcolor", fontSize: "14px" }} />}>Factura Cancelada</Tag>
            case 1:
                // Si la fecha de vencimiento 
                if (this.props.cliente?.premium && moment().isAfter(moment(item.fecha_vigencia)))
                    return <Tag color="#ff4d4f" icon={<CloseOutlined style={{ color: "currentcolor" }} />}>Vencida</Tag>
                else
                    return <Tag color="#faad14" icon={<WarningOutlined style={{ color: "currentcolor" }} />}>Pendiente</Tag>

            case 2:
                return <Tag color="#52c41a" icon={<CheckCircleOutlined style={{ color: "currentcolor" }} />}>Liquidada</Tag>
        }
    }

    /**
     *
     *
     * @param {*} item
     * @memberof Facturas
     * 
     * @description Renderizamos el estatus del tipo de factura.
     */
    renderTipo = (value) => {
        switch (value) {
            case 1:
                return <Tag color="#87d068">Ingreso</Tag>
            case 0:
                return "Sin cambios"
            case -1:
                return <Tag color="#f50">Ingreso</Tag>
        }
    }

    render() {

        // console.log('state', this.state)
        return (
            <>
                <List
                    loading={this.state.loading}
                    className="component-list"
                    itemLayout="horizontal"
                    locale={{ emptyText: "Sin Facturas " }}
                    dataSource={this.state.facturas.data}
                    pagination={{
                        current: this.state.page,
                        pageSize: 10,
                        total: this.state.total,
                        position: 'bottom',
                        className: "flex-left",
                        onChange: (page) => this.getFacturas(page)
                    }}
                    header={<Row className="header-list width-100 pr-1 pl-1" >
                        <Col md={4} span={2} className="center">
                            <Text ellipsis strong>Tipo</Text>
                        </Col>
                        <Col md={6} span={4} className="center">
                            <Text ellipsis strong>Descripción</Text>
                        </Col>
                        <Col md={4} span={4} className="center">
                            <Text ellipsis strong>Fecha</Text>
                        </Col>
                        <Col md={5} span={5} className="center">
                            <Text ellipsis strong>Cantidad</Text>
                        </Col>

                    </Row>}

                    renderItem={item => (
                        <List.Item className="component-list-item">
                            <Card className="card-list">
                                <Row className="width-100 ">
                                    <Col lg={2} className="center">
                                        <Text ellipsis>{this.renderTipo(item.tipo)}</Text>
                                    </Col>
                                    <Col lg={6} span={8} className="center">
                                        <p style={{ width: '100%' }}>
                                            <strong>{item.concepto}</strong><br />
                                            <small>{item?.receptor?.rfc}</small>
                                        </p>
                                    </Col>
                                    <Col lg={4} className="center">
                                        <Text ellipsis strong>{moment(item.fecha).format('DD-MM-YYYY')}</Text>
                                    </Col>
                                    <Col lg={3} className="center">
                                        <Text ellipsis>$ {item.monto ? item.monto.toMoney(true) : '0.00'} <small>{item.moneda}</small></Text>
                                    </Col>
                                    <Col lg={3} className="center">
                                        <Text ellipsis>$ {item.pagado ? item.pagado.toMoney(true) : '0.00'} <small>{item.moneda}</small></Text>
                                    </Col>
                                    <Col lg={3} className="center">
                                        {this.renderEstatus(item)}
                                    </Col>
                                    <Col lg={1} className="center">

                                        {this.renderViews(item)}

                                    </Col>
                                </Row>
                            </Card>
                        </List.Item>
                    )}
                />


                <ModalXML
                    visible={this.state.ModalXMLVisible}
                    onCancel={() => this.setState({ ModalXMLVisible: false, factura_xml_storage: undefined, xml: undefined })}
                    factura_xml_storage={this.state.factura_xml_storage}
                />

                <ModalFacturas
                    visible={this.state.modalFacturasVisible}
                    onClose={() => {
                        this.setState({ modalFacturasVisible: false, factura_id: undefined })
                        this.getFacturas()
                    }}
                    factura_id={this.state.factura_id}
                />

                <ModalTimbrado
                    visible={this.state.modalTimbradoVisible}
                    onClose={() => {
                        this.setState({ modalTimbradoVisible: false, factura_id: undefined })
                        this.getFacturas()
                    }}
                />


            </>
        )
    }
}



/**
 *
 *
 * @export
 * @param {*} props
 * @returns
 */
export default function (props) {


    return (<Facturas {...props} />)
}