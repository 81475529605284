import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography, Form,  message, Spin, Select } from 'antd';

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalAlmacen
 * @extends {Component}
 */
class ModalOrdenCompra extends Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,
            allowAlmacenes: true,
            proveedores: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                filters: {},
                loading: false
            },

            clientes: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                filters: {},
                loading: false
            },

            ordenes: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                filters: {},
                loading: false
            },

            almacenes: {
                data: [],

                page: 1,
                limit: 10,

                total: 0,
                pages: 0,
                filters: {},
                loading: false
            },
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getProveedores()
        this.getClientes()
        this.getOrdenes()
    }

    /**
     *
     *
     * @param {*} [{
     *         page = this.state.proveedores.page, Pagina actual del proveedor
     *         limit = this.state.proveedores.limit, Limite de proveedores
     *         search = this.state.proveedores.search Busqueda
     *     }=this.state.proveedores]
     * @memberof FormProveedor
     * 
     * @description Obtenemos los proveedores
     */
    getProveedores = ({
        page = this.state.proveedores.page,
        limit = this.state.proveedores.limit,
        search = this.state.proveedores.search
    } = this.state.proveedores) => {
        this.setState(state => {
            state.proveedores.loading = true
            state.proveedores.page = page;
            state.proveedores.limit = limit;
            return state;
        })
        axios.get('/proveedores/list', {
            params: {
                page, limit, search
            }
        })
            .then(response => {

                let { data } = this.state.proveedores

                let proveedores = data

                if(page === 1){
                    proveedores = response.data.data.itemsList
                }else{
                    proveedores = [...proveedores ,...response.data.data.itemsList]
                }
                this.setState(state => {
                    state.proveedores.data = proveedores
                    state.proveedores.total = response.data.data.paginator.itemCount
                    state.proveedores.pages = response.data.data.paginator.pageCount
                    state.proveedores.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }

    /**
     * @param {*} [{
     *         page = this.state.proveedores.page, Pagina actual del proveedor
     *         limit = this.state.proveedores.limit, Limite de proveedores
     *         search = this.state.proveedores.search Busqueda
     *     }=this.state.proveedores]
     * @memberof FormProveedor
     * 
     * @description Obtenemos los proveedores al scrollear
     */
    onPopupScrollProveedores = event => {
        const { page, pages, } = this.state.proveedores
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.proveedores.loading
        )
            this.getProveedores({ page: page + 1 })
    }

    /**
     * @memberof ModalOrdenCompra
     * @method getClientes
     * @description Obtenemos el listado de clientes
     */
    getClientes = ({
        page = this.state.clientes.page,
        limit = this.state.clientes.limit,
        search = this.state.clientes.search
    } = this.state.clientes) => {
        this.setState(state => {

            state.clientes.loading = true;
            state.clientes.page = page;
            state.clientes.limit = limit;
            return state;

        })
        axios.get('/clientes/list', {
            params: {
                page, 
                limit, 
                search,
                registrosInventario:true
            }
        })
            .then(response => {

                let { data } = this.state.clientes

                let clientes = data

                if(page === 1){
                    clientes = response.data.data.itemsList
                }else{
                    clientes = [...clientes ,...response.data.data.itemsList]
                }

                this.setState(state => {
                    state.clientes.data = clientes
                    state.clientes.total = response.data.data.paginator.itemCount
                    state.clientes.pages = response.data.data.paginator.pageCount
                    state.clientes.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }


    /**
     * @memberof ModalOrdenCompra
     * @method onPopupScrollClientes
     * @description Al hacer scroll hasta el final de clientes obtiene los registros siguientes
     */
    onPopupScrollClientes = event => {
        const { page, pages, } = this.state.clientes
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.clientes.loading
        )
            this.getClientes({ page: page + 1 })
    }


    /**
     * @memberof ModalOrdenCompra
     * @method getClientes
     * @description Obtenemos el listado de ordenes
     */
    getOrdenes = ({
        page = this.state.ordenes.page,
        limit = this.state.ordenes.limit,
        search = this.state.ordenes.search,

        cliente_id = this.state.ordenes.cliente_id
    } = this.state.ordenes) => {
        this.setState(state => {

            state.ordenes.loading = true;
            state.ordenes.page = page;
            state.ordenes.limit = limit;
            return state;

        })
        axios.get('/ordenes', {
            params: {
                page, limit, search, cliente_id
            }
        })
            .then(response => {

                let { data } = this.state.ordenes

                let ordenes = data

                if(page === 1){
                    ordenes = response.data.data.itemsList
                }else{
                    ordenes = [...ordenes ,...response.data.data.itemsList]
                }

                this.setState(state => {
                    state.ordenes.data = ordenes
                    state.ordenes.total = response.data.data.paginator.itemCount
                    state.ordenes.pages = response.data.data.paginator.pageCount
                    state.ordenes.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }

     /**
     * @memberof ModalOrdenCompra
     * @method onPopupScrollClientes
     * @description Al hacer scroll hasta el final de ordenes obtiene los registros siguientes
     */
    onPopupScrollOrdenes = event => {
        const { page, pages, } = this.state.ordenes
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        )
            this.getOrdenes({ page: page + 1 })
    }

    getAlmacenes = ({
        page = this.state.almacenes.page,
        limit = this.state.almacenes.limit,
        search = this.state.almacenes.search,
        cliente_id = this.state.almacenes.cliente_id

    } = this.state.almacenes) => {


        this.setState(state => {

            state.almacenes.loading = true;
            state.almacenes.page = page;
            state.almacenes.limit = limit;
            state.almacenes.cliente_id = cliente_id
            return state;

        })
        axios.get('/almacenes/list', {
            params: {
                page, limit, search, 
                cliente_id
            }
        })
            .then(response => {

                this.setState(state => {
                    if (page === 1)
                        state.almacenes.data = response.data.data.itemsList
                    else
                        state.almacenes.data = [...state.almacenes.data, ...response.data.data.itemsList]

                    state.almacenes.total = response.data.data.paginator.itemCount
                    state.almacenes.pages = response.data.data.paginator.pageCount
                    state.almacenes.loading = false
                    return state;
                })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }

    onPopupScrollAlmacenes = event => {
        const { page, pages, } = this.state.almacenes
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        )
            this.getAlmacenes({ page: page + 1 })
    }

    onSave = (values) => {

        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {

            values.almacen_id = (values.almacen_id?.value) ? values.almacen_id?.value : values.almacen_id
            values.cliente_id = (values.cliente_id?.value) ? values.cliente_id?.value : values.cliente_id
            values.orden_id = (values.orden_id?.value) ? values.orden_id?.value : values.orden_id
            values.proveedor_id = (values.proveedor_id?.value) ? values.proveedor_id?.value : values.proveedor_id
    

            axios.post('/ordenesCompra/add', values)
                .then(({ data }) => {
                    Modal.success({
                        title: "Orden de Compra creadada exitosamente.",
                        content: "Agregue los productos que requerirá en esta orden de compra."
                    })
                    this.props.openDrawer(data.id)
                })
                .catch(({ response }) => {
                    console.log('response', response)
                }).finally(() => {
                    this.setState({loading: false})
                })
        })

    }

    render() {

        return (
            <Spin spinning={this.state.loading}>
                <Form layout="vertical" ref={this.formModalNegocios} onFinish={this.onSave} >
                    <Row gutter={[16, 16]}>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Proveedor"
                                name="proveedor_id"
                                rules={[
                                    { required: true, message: "Ingrese el Proveedor" }
                                ]} >
                                <Select
                                    labelInValue
                                    showSearch
                                    placeholder="Buscar"
                                    optionFilterProp="children"
                                    loading={this.state.proveedores.loading}
                                    onSearch={search => this.getProveedores({ page: 1, search })}
                                    onPopupScroll={this.onPopupScrollProveedores}
                                >
                                    {this.state.proveedores.data.map(({ _id, nombre, nombre_comercial, rfc }) => <Option key={_id} value={_id} title={nombre}>
                                        {nombre}
                                        <br />
                                        <small>{nombre_comercial}</small><br />
                                        <small>{rfc}</small>
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Clientes"
                                name="cliente_id"
                            >
                                <Select
                                    labelInValue
                                    showSearch
                                    placeholder="Buscar"
                                    optionFilterProp="children"
                                    loading={this.state.clientes.loading}
                                    onSearch={search => this.getClientes({ page: 1, search })}
                                    onPopupScroll={this.onPopupScrollClientes}
                                    allowClear={true}
                                    onSelect={(option, element) => {
                                        this.setState({ allowAlmacenes: !element.cliente.premium })
                                        this.getOrdenes({ page: 1, cliente_id: (element.cliente._id) })
                                        if (element.cliente.premium == true)
                                            this.getAlmacenes({ cliente_id: element.value })

                                    }}
                                >
                                    {this.state.clientes.data.map(({ _id, nombre, apellido , nombre_comercial, rfc, ...cliente }) => <Option cliente={cliente} key={_id} value={_id} title={nombre}>{nombre} {apellido}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Ordenes"
                                name="orden_id">
                                <Select
                                    labelInValue
                                    showSearch
                                    placeholder="Buscar"
                                    optionFilterProp="children"
                                    loading={this.state.ordenes.loading}
                                    onSearch={search => this.getOrdenes({ page_: 1, search })}
                                    onPopupScroll={this.onPopupScrollOrdenes}>
                                    {this.state.ordenes.data.map(({ _id, folio }) => <Option key={_id} value={_id} title={folio}>{folio}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Almacen"
                                name="almacen_id">
                                <Select
                                    disabled={this.state.allowAlmacenes}
                                    labelInValue
                                    showSearch
                                    placeholder="Buscar"
                                    optionFilterProp="children"
                                    loading={this.state.ordenes.loading}
                                    onSearch={search => this.getAlmacenes({ page: 1, search })}
                                    onPopupScroll={this.onPopupScrollAlmacenes}
                                >{this.state.almacenes.data.map(({ _id, direccion }) => <Option key={_id} value={_id} title={direccion}>{direccion}</Option>)}</Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}>
        <div className="center">
            <Title level={3}>{props.orden_compra_id ? "Editar" : "Nueva"} Orden de Compra </Title>
        </div>
        <ModalOrdenCompra {...props} />
    </Modal>

}