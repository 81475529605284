import React, { Component } from "react";
import { Layout, Button, Col, Row, Typography, List, message, Card, Statistic} from "antd";

import { IconEye } from '../../../Widgets/Iconos';
import '../../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'
import ModalFacturaDetalle from './ModalFacturaDetalle'

const { Content } = Layout;
const {  Text } = Typography
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class FacturasList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class FacturasList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            facturas: [],

            page: 1,
            limit: 10,
            total: 0,
        }
    }


    componentDidMount(){
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        console.log(this.props)
        this.getFacturas()
    }

    /**
     * @memberof FacturasList
     * @method componentDidUpdate 
     * @description Cargamos la informacion inicial
     */
    componentDidUpdate(prevProps) {
        if(this.props.orden_id && this.props.orden_id !== prevProps.orden_id){
            this.getFacturas(1)
        }
    }


    /**
     * @memberof Cotizaciones
     * @method getFacturas 
     * @description Obtenemos la informacion de la orden 
     */
    getFacturas = (page = this.state.page) => {
        this.setState({loading: true})
        axios.get('/customer/facturas/list',{
            params:{
                orden_id: this.props.orden_id,
                page: page
            }
        }).then(response => {
            this.setState({
                facturas: response.data.data.itemsList,
                page: response.data.data.currentPage,
                total: response.data.data.itemCount,
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obtener la información')
        }).finally(()=>this.setState({loading: false}))
    }


    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
    renderMonto = (tipo, monto) => {
        let colors = ['#000000','#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.facturas}
                                loading={this.state.loading}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: this.state.limit,
                                    total: this.state.total,
                                    hideOnSinglePage: false,
                                    position: 'bottom',
                                    className: "flex-left",
                                    onChange: this.getFacturas,
                                }}
                                renderItem={ item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <div className={`badge-card tipo-${item.tipo}`}>
                                            </div>
                                            <Row className="width-100">
                                            	<Col span={6} className="center">
                                                    <Text strong>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col span={7} className="center">
                                                    <Text strong>{item.concepto}</Text>
                                                </Col>
                                                <Col span={7} className="center">
                                                    <Text>$ {item.monto?.toMoney(true)} MXN</Text>
                                                </Col>
                                                <Col span={4} className="center">
                                                    <Button
                                                        icon={<IconEye />}
                                                        type="primary"
                                                        title="Ver"
                                                        onClick={()=>this.setState({modalVisible: true, factura_id: item._id})}
                                                    >
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Card>
                                </List.Item>
                                )}
                            />
                           
                        </Col>
                    </Row>
                </Content>
                <ModalFacturaDetalle 
                    visible={this.state.modalVisible}
                    onClose={()=>this.setState({factura_id: undefined, modalVisible: false})}
                    factura_id={this.state.factura_id}
                />
            </>
        )
    }
}