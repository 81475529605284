import React, { Component, } from "react";
import { Row, Col, Button, Modal, Typography, Form, message, Spin, InputNumber, Select } from 'antd';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;


const axios = require('axios').default;


/**
 *
 *
 * @class ModalTraspasoProducto
 * @extends {Component}
 */
class ModalTraspasoProducto extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,

            almacenes_origen: {
                data: [],

                page: 1,
                limit: 15,

                total: 0,
                pages: 0,

                search: null
            },

            almacenes_destino: {
                data: [],

                page: 1,
                limit: 15,

                total: 0,
                pages: 0,

                search: null
            },


            //origen
            almacen_producto_id: null,

            //destino
            destino_almacen_producto_id: null

        }
    }

    ModalTraspasoProducto = React.createRef();



    componentDidMount() {
        this.getAlmacenProducto()
        this.getAlmacenesOrigen()
        this.getAlmacenesDestino()
        console.log("this.props", this.props)
    }

    getAlmacenProducto = async (id = this.props.almacen_producto_id) => {
        axios.get("/almacenes/productos/get", {
            params: { id }
        })
            .then(({ data }) => {
                data = data.data
                this.setState({ almacen_producto_id: data })
                this.ModalTraspasoProducto.current.setFieldsValue({
                    almacen_origen: {
                        label: data?.almacen_id?.direccion,
                        key: data?._id,
                        value: data?._id
                    }
                })
                console.log("UWU", data)
                this.getAlmacenesDestino({ ne_almacen_id: data?.almacen_id?._id, })
            })
            .catch(({ data }) => {
                // console.log("data", data)
            })
    }


    getAlmacenProductoDestino = async (
        almacen_id,
        producto_id = this.state.almacen_producto_id?.inventario_producto_id?.producto_id?._id,
    ) => {
        axios.get("/almacenes/productos/get", {
            params: {
                producto_id,
                almacen_id
            }
        })
            .then(({ data }) => {
                data = data.data
                this.setState({ destino_almacen_producto_id: data })
            })
            .catch(({ data }) => {
                // console.log("data", data)
            })
    }


    getAlmacenesOrigen = async (
        {
            page = this.state.almacenes_origen.page,
            limit = this.state.almacenes_origen.limit,
            search = this.state.almacenes_origen.search,

            // total = this.state.almacenes_origen.total,
            // pages = this.state.almacenes_origen.pages,
        } = this.state.almacenes_origen
    ) => {
        this.setState({ almacenes_origen: { ...this.state.almacenes_origen, page, limit, search } })
        axios.get("/almacenes/productos/list", {
            params: {
                page, limit, search,
                producto_id: this.props.producto_id,
                almacen: true
            }
        })
            .then(({ data }) => {
                this.setState({ almacenes_origen: { ...this.state.almacenes_origen, data: data.data.data, total: data.data.total, pages: data.data.pages } })
            })
            .catch(({ data }) => {

                console.log("data", data)

            })
    }

    getAlmacenesDestino = async (
        {
            page = this.state.almacenes_destino.page,
            limit = this.state.almacenes_destino.limit,
            search = this.state.almacenes_destino.search,
            ne_almacen_id = this.state.ne_almacen_id
        } = this.state.almacenes_destino
    ) => {
        this.setState({ 
            ne_almacen_id,
            almacenes_destino: { ...this.state.almacenes_destino, page, limit, search } })



        axios.get("/almacenes/list", {
            params: {
                page, limit, search,

                ne_almacen_id
                // producto_id: this.props.producto_id
                // almacen_producto_id: this.props.almacen_producto_id.
            }
        })
            .then(({ data }) => {
                // this.setState({ almacenes_destino: { ...this.state.almacenes_destino, data: data.data.data, total: data.data.total, pages: data.data.pages} })
                // console.log("data", data.data)
                this.setState({ almacenes_destino: { ...this.state.almacenes_destino, data: data.data.itemsList, total: data.data.paginator.itemCount } })
            })
            .catch(({ data }) => {

                console.log("data", data)

            })
    }


    onFinish = (values) => {

        values.almacen_destino = (values.almacen_destino?.value) ? values.almacen_destino?.value : values.almacen_destino
        values.almacen_origen = (values.almacen_origen?.value) ? values.almacen_origen?.value : values.almacen_origen

        this.setState({ loading: true })
        axios.post("/almacenes/productos/traspaso", {
            ...values,
            producto_id: this.props.producto_id
        })
            .then(({ data }) => {
                this.props.onClose()
                message.error(data.message)
            })
            .catch(error => {
                console.log("error", error)
                message.error(error.response.data?.message)
            })
            .finally(() =>         this.setState({ loading: false }))
    }


    render() {

        const { almacen_producto_id, destino_almacen_producto_id } = this.state

        console.log("almacenes_producto_origen", almacen_producto_id)

        return (
            <Spin spinning={this.state.loading}>
                <Form
                    layout="vertical"
                    onFinish={this.onFinish}
                    ref={this.ModalTraspasoProducto}
                >

                    <Row className="ant-row-center mt-2  text-center">
                        <Col xs={24} md={24} style={{}}>
                            <Paragraph strong>{almacen_producto_id?.inventario_producto_id?.producto_id?.marca}</Paragraph>
                            <Paragraph>{almacen_producto_id?.inventario_producto_id?.producto_id?.np_fabricante}</Paragraph>
                            <Paragraph><small>{almacen_producto_id?.inventario_producto_id?.producto_id?.proveedor_id?.nombre}</small></Paragraph>
                        </Col>
                        <Col xs={24} md={24} style={{ textAlign: "start", marginTop: "0.5em" }}>
                            <Row className="w-100">
                                <Col xs={24} md={18}>
                                    <Title level={5}>Almacen Orígen</Title>
                                </Col>
                                <Col xs={24} md={6} style={{ textAlign: "end" }}>
                                    <Title level={5}>Cantidad</Title>
                                </Col>
                            </Row>

                            <Row className="w-100">
                                <Col xs={24} md={18}>
                                    <Paragraph>{almacen_producto_id?.almacen_id?.direccion}</Paragraph>
                                </Col>
                                <Col xs={24} md={6} style={{ textAlign: "end" }}>
                                    <Paragraph>{almacen_producto_id?.cantidad} {almacen_producto_id?.unidad_nombre}</Paragraph>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={24} style={{ textAlign: "start", marginTop: "0.5em" }}>
                        <Row className="w-100">
                                <Col xs={24} md={18}>
                                    <Title level={5}>Almacen Destino</Title>
                                </Col>
                                <Col xs={24} md={6} style={{ textAlign: "end" }}>
                                    <Title level={5}>Cantidad</Title>
                                </Col>
                            </Row>
                            {/* <Title level={5}>Almacen </Title> */}
                            <Row className="w-100">
                                <Col xs={24} md={18}>
                                    <Paragraph>{destino_almacen_producto_id?.almacen_id?.direccion ? destino_almacen_producto_id?.almacen_id?.direccion : " El almacén no tiene producto. "}</Paragraph>
                                </Col>
                                <Col xs={24} md={6} style={{ textAlign: "end" }}>
                                    <Paragraph>{destino_almacen_producto_id?.cantidad}  {almacen_producto_id?.unidad_nombre}</Paragraph>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col xs={24}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"

                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, seleccione el Almacén Destino"
                                    },
                                    () => ({
                                        validator(_, value) {

                                            value = parseInt(value)

                                            if (!isNaN(value) && almacen_producto_id.cantidad >= parseFloat(value))
                                                return Promise.resolve();

                                            return Promise.reject(new Error('La cantidad debe ser menor o igual a la cantidad disponible en el almacén origen'));
                                        },
                                    }),
                                ]}
                            >
                                <InputNumber placeholder="Cantidad" className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Almacén de Origen"
                                name="almacen_origen"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, seleccione el Almacén Destino"
                                    },
                                ]}
                            >
                                <Select
                                    onSearch={(value) => this.getAlmacenesOrigen({ search: value })}
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                    labelInValue
                                    placeholder="Selecciona un Almacén"
                                    onSelect={(value) => this.getAlmacenProducto(value?.value ? value?.value : value)}
                                    onClear={() => this.setState({ almacen_producto_id: null })}
                                >
                                    {
                                        this.state.almacenes_origen.data?.map((item) => {
                                            

                                            return <Option
                                                almacen_origen={item}
                                                value={item?._id}
                                                title={item?.almacen?.direccion}
                                            >
                                                <strong>{item?.almacen?.direccion}</strong><br />
                                                {item?.inventario_id?.cliente_id.nombre} {item?.inventario_id?.cliente_id?.apellido || <small style={{ fontWeight: 300 }}>{item?.inventario_id?.cliente_id?.razon_social}</small>}
                                            </Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item
                                label="Almacén de Destino"
                                name="almacen_destino"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, seleccione el Almacén Destino"
                                    }
                                ]}
                            >
                                <Select
                                    onSearch={(value) => this.getAlmacenesDestino({ search: value })}
                                    showSearch
                                    allowClear
                                    filterOption={false}
                                    labelInValue
                                    placeholder="Selecciona un Almacén"
                                    onSelect={(value) => this.getAlmacenProductoDestino(value?.value ? value?.value : value)}
                                    onClear={(value) => this.setState({ destino_almacen_producto_id: null })}

                                >
                                    {
                                        this.state.almacenes_destino.data?.map((item) => {
                                            return <Option almacen_origen={item} value={item?._id} title={item?.direccion}>
                                                {console.log("item", item)}
                                                <strong>{item?.direccion}</strong><br />
                                                {item?.inventario_id?.cliente_id.nombre} {item?.inventario_id?.cliente_id?.apellido || <small style={{ fontWeight: 300 }}>{item?.inventario_id?.cliente_id?.razon_social}</small>}
                                            </Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item >
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Traspaso de Productos </Title>
        </div>
        <ModalTraspasoProducto {...props} />
    </Modal>

}