import React, { Component } from "react";
import { Row, Col, Button, Modal, Typography,  Form, Input, message, Spin, Select } from 'antd';

const { Title } = Typography;
const { Option } = Select;
const axios = require('axios').default;


/**
 *
 *
 * @class ModalAlmacen
 * @extends {Component}
 */
class ModalAlmacen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            clientes: [],
            inventario: {},
            almacenes: []
        }
    }

    modalAlmacen = React.createRef();

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getInventario()
    }



    getInventario = () => {
        axios.get('/inventarios/get', {
            params: {
                cliente_id: this.props.cliente_id
            }
        })
            .then(({data}) => {
                this.setState({
                    inventario: data.data
                }, () => {
                    this.getAlmacenes()
                })
            })
            .catch(error => {
                message.error('Error al obtener inventario')
                console.log(error)
            })
    }

    /**
     * @memberof ModalAlmacen
     * @method getAlmacen
     * @description Obtiene un cliente de la DB
     */
    getAlmacenes = () => {
        axios.get('/almacenes/list', {
            params: {
                inventario_id: this.state.inventario?._id
            }
        }).then(({data}) => {
            console.log('this.props.almacen_id',this.props.almacen_id)
            this.setState({
                almacenes: data.data.itemsList
            })

            this.modalAlmacen.current?.setFieldsValue({
                almacen_id: this.props.almacen_id
            })

        }).catch(error => {
            message.error('Error al traer almacenes')
            console.log(error)
        })
    }


    /**
     * @memberof ModalAlmacen
     * @method onFinish
     * @description Se ejecuta al dar enter al formulario
     */
    onFinish = (values) => {
        axios.post('/ordenesCompra/update', {
            id: this.props.orden_compra_id,
            ...values
        })
            .then(success => {
                message.success("Orden de Compra actualizada")
            })
            .catch(error => {
                message.error('Error al actualizar Orden de Compra')
                console.log(error)
            })
            .finally(() => {
                this.props.onClose()
            })
    }

    render() {


        return (
            <Form layout="vertical" ref={this.modalAlmacen} onFinish={this.onFinish} >
                <Spin spinning={this.state.loading}>

                    <Row className="ant-row-center">
                        <Col xs={24} lg={20} >
                            <Form.Item
                                label="Almacén"
                                name="almacen_id"
                            >
                                <Select>
                                    {
                                        this.state.almacenes?.map(almacen => (
                                            <Option value={almacen._id}>{almacen.direccion}</Option>
                                        ))
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                    </Row>
                    <Row >
                        <Col span={24} className="text-center">
                            <Form.Item >
                                <Button htmlType="submit" type="primary">
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { } } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >
        <div className="center">
            <Title level={3}>  Asignar Almacen </Title>
        </div>
        <ModalAlmacen {...props} />
    </Modal>

}