
import React from 'react';
import { Drawer, Form, Select, message, } from 'antd';
import axios from 'axios';


const { Option } = Select

/**
 *
 *
 * @export
 * @class FiltrosDrawer
 * @extends {React.Component}
 * 
 * @description Permite filtrar según diversa información
 */
class FiltrosDrawer extends React.Component {

    /**
     *
     *
     * @export
     * @class FiltrosDrawer
     * @extends {React.Component}
     * @state   inventarios  Objeto para paginar y buscar en el select de inventarios 
     * 
     * @state paginator.data Lista de elementos para paginar
     * @state paginator.dictionary Ya que los selects funcionan solo con ID´s, utilizamos el diccionario para obtener el objeto al que el ID hace referencia
     * @state paginator.page Pagina actual
     * @state paginator.limit Limit de elementos por página
     * @state paginator.pages Total de paginas
     * @state paginator.total Total de Elementos en la lista
     * @state paginator.search Para buscar
     * @state paginator.search Loading, para declarar el actualizado.
     */
    state = {

        clientes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            loading: false,
        },

        almacenes: {
            data: [],
            dictionary: {},

            page: 1,
            limit: 15,

            pages: 0,
            total: 0,

            search: null,
            cliente_id: null,
            loading: false,
            inventario_id: []

        },


    }


    /**
     *
     *
     * @memberof FiltrosDrawer
     * @methodof componentDidMount
     * @description Obtenemos todas las listas
     */
    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getClientes();
        this.getAlmacenes()
    }




    /**
     *
     *
     * @memberof DrawerFiltrosReportes
     * @description Obtiene los clientes que tienen inventario
     * @param values pagina
     */
    getClientes = (
        { page, limit, search, inventario_id } = this.state.clientes,
        { clientes } = this.state) => {
        clientes.loading = true;
        clientes.page = page;
        clientes.limit = limit;

        this.setState({ clientes })

        axios.get('/inventarios/list', {
            params: {
                page, limit, search, inventario_id
            }
        })
            .then(response => {
                console.log('response clientes', response)
                clientes.data = (page === 1) ? response.data.data.itemsList : [...clientes.data, ...response.data.data.itemsList];

                clientes.dictionary = {}
                clientes.data.map(d => clientes.dictionary[d.cliente._id] = d)

                clientes.total = response.data.data.paginator.itemCount
                clientes.pages = response.data.data.paginator.pageCount
                clientes.loading = false;

                this.setState({ clientes })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
            .finally(() => {
                this.setState(state => {
                    state.clientes.loading = false
                    return state
                })
            })
    }

    /**
    *
    *
    * @memberof DrawerFiltrosReportes
    */
    getAlmacenes = (
        { page, limit, search, inventario_id } = this.state.almacenes,
        { almacenes } = this.state,


    ) => {
        almacenes.loading = true;
        almacenes.page = page;
        almacenes.limit = limit;

        this.setState({ almacenes })


        axios.get('/almacenes/list', {
            params: {
                page, limit, search, inventario_id
            }
        })
            .then(response => {
                console.log('response almacenes', response)
                almacenes.data = (page === 1) ? response.data.data.itemsList : [...almacenes.data, ...response.data.data.itemsList];

                almacenes.dictionary = {}
                almacenes.data.map(d => almacenes.dictionary[d._id] = d)

                almacenes.total = response.data.data.paginator.itemCount
                almacenes.pages = response.data.data.paginator.pageCount
                almacenes.loading = false;

                this.setState({ almacenes })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
            .finally(() => {
                this.setState(state => {
                    state.almacenes.loading = false
                    return state
                })
            })
    }




    /**
     *
     *
     * @param {*} [{ page, limit, search }=this.state.cuentas]
     * @param {*} [{ cuentas }=this.state]
     * @memberof FiltrosDrawer
     * @method submit
     * 
     * @description Permite obtener el arreglo para poder mostrarlo en la lista de guardados. 
     */
    submit = ({ clientes, almacen }) => {
        console.log('almacenes submit', almacen);
        console.log('clientes submimt', clientes)
        let filtros = []
        let addToFilter = (name, objectName, array) => array.map(element => filtros.push({
            _id: element,
            name: name,
            objectName,
            filter: this.state[objectName].dictionary[element]
        }))

        if (clientes) addToFilter("cliente._id", "clientes", clientes)
        if (almacen) addToFilter("almacen._id", "almacen", almacen)


        if (this.props.updateFilters)
            this.props.updateFilters(filtros)
    }

    /**
     *
     *
     * @memberof ModalReporteOC
     */
    onSelectCliente = (value) => {
        console.log('value cliente', value)
        let almacenes = this.state.almacenes;
        almacenes.inventario_id.push(value);

        this.setState({ almacenes });
        this.getAlmacenes()


    }


    /**
    *
    *
    * @memberof ModalReporteOC
    */
    onSelectAlmacen = (value) => {
        console.log('value almacen', value)
        let clientes = this.state.clientes;
        clientes.inventario_id = value;

        this.setState({ clientes });
        this.getClientes()


    }



    render() {

        const { onClose, visible } = this.props
        const { clientes, almacenes } = this.state


        return (
            <Form
                ref={ref => this.formFiltros = ref}
                layout='vertical'
                onValuesChange={(x, values) => this.submit(values)}
                onFinish={this.submit}
            >

                <Form.Item label="Clientes" name="clientes">
                    <Select
                        allowClear
                        showSearch
                        filterOption={false}
                        mode="multiple"
                        onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !clientes?.loading && clientes.page < clientes.pages) ? this.getClientes({ page: clientes.page + 1 }) : null}
                        onSearch={search => this.getClientes({ search, page: 1 })}
                        onSelect={(option, element) => {
                            this.onSelectCliente(element.id)
                        }}
                    >
                        {clientes.data.map(({ _id, cliente }) => <Option key={cliente?._id} value={cliente?._id} id={_id} >{cliente?.nombre}</Option>)}

                    </Select>
                </Form.Item>

                <Form.Item label="Almacenes" name="almacen">
                    <Select
                        allowClear
                        showSearch
                        mode="multiple"
                        filterOption={false}
                        onPopupScroll={event => (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight && !almacenes?.loading && almacenes.page < almacenes.pages) ? this.getAlmacenes({ page: almacenes.page + 1 }) : null}
                        onSearch={search => this.getAlmacenes({ search, page: 1 })}
                        onSelect={(option, element) => {
                            this.onSelectAlmacen(element.id)
                        }}

                    >
                        {almacenes.data.map(almacen => <Option key={almacen._id} value={almacen._id} id={almacen.inventario_id}>{almacen.direccion}</Option>)}
                    </Select>
                </Form.Item>

            </Form>)
    }
};


export default function (props) {
    const { onClose, visible } = props

    return <Drawer title="Filtrar Reportes" placement="right" onClose={onClose} visible={visible}>
        <FiltrosDrawer {...props} />
    </Drawer>
}