import React, { Component } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Spin, Typography, Select, Avatar, InputNumber } from 'antd';
import PropTypes from "prop-types";
const axios = require('axios').default;


const { Title, Text } = Typography;
const { Option } = Select;





/**
 *
 *
 * @export
 * @class FormAlmacenProducto
 * @extends {Component}
 */
class FormAlmacenProducto extends Component {


    /**
     *
     *
     * @static
     * @memberof FormAlmacenProducto
     * 
     * @var propTypes Son los valores por defecto
     */
    static propTypes = {
        visible: PropTypes.bool,
        hideModal: PropTypes.func,
        accept: PropTypes.func
    };


    constructor(props) {
        super(props);
        this.state = {
            id: undefined,
            loading: false,
            inventario_producto_edit: undefined,
            productos: {
                data: [],

                page: 1,
                limit: 50,

                total: 0,
                pages: 0,
            },
            nuevo: false,

        }
    }

    formAlmacenProducto = React.createRef();


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');

        this.getProductos();
    }

    /**
     * @memberof AlmacenProductos
     * @method getProductos
     * @description obtiene los productos del inventario de un cliente
     */
    getProductos = (
        {
            page = this.state.productos.page,
            limit = this.state.productos.limit,
            search = this.state.productos.search,

        } = this.state.productos
    ) => {
        console.log({ page, limit, search })
        this.setState({ productos: {...this.state.productos, page, limit, search} })
        axios.get('/productos', {
            params: {
                page, limit, search,
            }
        })
            .then(({ data }) => {
                console.log("D", { productos: {...this.state.productos, ...data.data } })
                this.setState({ productos: {...this.state.productos, ...data.data } })
            })
            .catch(error => {
                message.error('Error al traer la información')
            })
    }


    onPopupScrollProductos = event => {
        const { page, pages, } = this.state.productos
        if (
            (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) &&
            page < pages &&
            !this.state.loading
        ) {
            console.log('true')
            this.getProductos({ page: page + 1 })
        }
    }



    /**
     *
     *
     * @memberof FormAlmacenProducto
     * 
     * @method save
     * @description Guardamos el producto
     */
    save = (values) => {
        
        if(this.state.loading) return
        
        this.setState({ loading: true }, () => {
            axios.post('/almacenes/productos/add', {
                ...values,
                almacen_id: this.props.almacen_id,
                inventario_id: this.props.inventario_id
            })
                .then(() => {
                    message.success("¡Se ha guardado correctamente el producto!")
                    this.props.onSuccess();
                    this.props.onClose();
                })
                .catch((e) => {
                    message.error(e?.response?.data?.message ? e?.response?.data?.message : 'No se pudo guardar el producto')
                    console.log('e', e.response)
                })
                .finally(() => this.setState({
                    loading: false,
                    loadingImage: false,
                    image: undefined
                }))
        })
        
    }



    ifProductoInventarioExists = producto_id => axios.get("/inventarios/productos/get",{
        params: {
            producto_id: this.props.producto_id ? this.props.producto_id : producto_id,
            inventario_id: this.props.inventario_id
        }
    }) 
        .then(e => this.setState({ nuevo: false }))
        .catch(e => this.setState({ nuevo: true }))

    selectProducto = React.createRef()

    render() {
        const { formAlmacenProducto, } = this;
        const { form, loading, nuevo } = this.state;

        return (
            <Spin spinning={loading}>
                <Title level={3} className="text-center">Nuevo Producto</Title>
                <Form
                    layout="vertical"
                    ref={formAlmacenProducto}
                    initialValues={form}
                    onFinish={this.save} >
                    <Row gutter={[16, 16]}>
                        <Col xs={24}>
                            <Form.Item
                                label="Productos"
                                name="producto_id"
                                rules={[
                                    { required: true, message: "Seleccione un producto" }
                                ]} >
                                <Select
                                    showSearch
                                    placeholder="Busque el produco que desea seleccionar"
                                    optionFilterProp="children"
                                    filterOption={false}
                                    loading={loading}
                                    onSearch={search => {
                                        this.getProductos({ page: 1, search })
                                    }}
                                    onPopupScroll={this.onPopupScrollProductos}

                                    onSelect={value => this.ifProductoInventarioExists(value)}
                                >
                                    {this.state.productos.data.map((item) => <Option key={item._id} value={item._id} title={item.np_fabricante}>
                                        <div>
                                            <Avatar
                                                style={{ display: "inline-block", marginRight: 4 }}
                                                shape="square" src={(Array.isArray(item.imagenes) && item.imagenes?.length > 0) ? (axios.defaults.baseURL + '/upload/' + item?.imagenes[0].filename) : undefined} />
                                            <Text strong>{item?.marca}</Text> &nbsp; <Text>{item?.np_nexus}</Text><br />
                                            <Text>{item?.descripcion}</Text>
                                        </div>
                                    </Option>)}
                                </Select>
                            </Form.Item>
                        </Col>

                        {nuevo ? <>
                            <Col xs={24} lg={24}>
                            <Form.Item
                                label="Número de Parte del Cliente"
                                name="np_cliente"
                                rules={[
                                    { required: true, message: "Ingrese el número de parte NEXUS" }
                                ]} >
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Inventario Mínimo"
                                name="inv_minimo"
                                rules={[
                                    { required: true, message: "Ingrese la cantidad mínima" }
                                ]} >
                                <InputNumber placeholder="0" className="width-100"
                                    min={0} defaultValue={0}
                                     />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Inventario Máximo"
                                name="inv_maximo"
                                min={1}
                                rules={[
                                    { required: true, message: "Ingrese la cantidad mínima" }
                                ]} >
                                <InputNumber placeholder="1" className="width-100"
                                    min={0} defaultValue={0}
                                    />
                            </Form.Item>
                        </Col>

                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Precio del Cliente"
                                name="precio"
                                rules={[
                                    { required: true, message: "Ingrese el precio del cliente." }
                                ]} >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                />
                            </Form.Item>
                        </Col></>: null}

                        <Col xs={24} lg={12}>
                            <Form.Item
                                label="Cantidad"
                                name="cantidad"
                                rules={[
                                    { required: true, message: "Ingrese la cantidad" }
                                ]} >
                                <InputNumber placeholder="1" className="width-100" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} lg={24}>
                            <Form.Item
                                label="Ubicación"
                                name="ubicacion">
                                <Input placeholder="" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24} className="text-center">
                            <Form.Item>
                                <Button htmlType="submit" type="primary" loading={this.state.loading}>
                                    Guardar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>

        )
    }
}

/**
 * @description Según el modal
 */
export default function (props) {

    const { visible, onClose } = props

    return (
        <Modal
            visible={visible}
            onCancel={onClose}
            title={null}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            zIndex={1000}
        >
            <FormAlmacenProducto {...props} />
        </Modal>
    )
}
