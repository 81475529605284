import React, { Component } from "react";
import { Layout, Col, Row, Typography, List, message, Card, Statistic } from "antd";

//css
import '../../../../Styles/Modules/ManagerInventarios/ManagerInventarios.css'

const { Content } = Layout;
const { Text } = Typography
const axios = require('axios')
const moment = require('moment')

/**
 * @export
 * @class ComprasList
 * @extends {Component}
 * @description Vista de Project Manager
 */
export default class ComprasList extends Component {


    constructor(props) {
        super(props)
        this.state = {
            compras: [],
            pagos: [],
            page: 1,
            limit: 10,
            total: 0,
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token');
        this.getPagos()
    }

    /**
     * @memberof FacturasList
     * @method componentDidUpdate 
     * @description Cargamos la informacion inicial
     */
    componentDidUpdate(prevProps) {
        if(this.props.orden_id && this.props.orden_id !== prevProps.orden_id){
            this.getPagos(1)
        }
    }

    /**
    * @memberof IngresosList
    * @method   getPagos
    * @description  Retorna los pagos realizados a la orden
    **/
    getPagos = (page = 1) => {
        this.setState({ loading: true })
        axios.get('/customer/pagos/get', {
            params: {
                orden_id: this.props.orden_id,
                page: page
            }
        }).then(response => {
            this.setState({
                pagos: response.data.data.itemsList,
                total: response.data.data.itemCount,
                page: response.data.data.currentPage
            })
        }).catch(error => {
            console.log(error)
            message.error('Error al obetner los pagos')
        }).finally(() => this.setState({ loading: false }))
    }



    /**
    * @memberof IngresosList
    * @method   renderMonto
    * @description  Retorna el monto con el color correspondinte dependiendo del tipo de transaccion
    **/
    renderMonto = (tipo, monto) => {
        let colors = ['#000000', '#00FF19', '#FF0000'];
        return <Statistic value={monto} valueStyle={{ fontSize: '16px', fontWeight: 'bold', color: colors[tipo] }} prefix={'$'} />
    }

    render() {

        return (
            <>
                <Content>
                    <Row>
                        <Col span={24}>
                            <List
                                className="component-list"
                                itemLayout="horizontal"
                                dataSource={this.state.pagos}
                                pagination={{
                                    current: this.state.page,
                                    pageSize: this.state.limit,
                                    total: this.state.total,
                                    hideOnSinglePage: false,
                                    position: 'bottom',
                                    className: "flex-left",
                                    onChange: this.getPagos,
                                }}
                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <div className={`badge-card tipo-1`}></div>
                                            <Row className="width-100">
                                                <Col span={15} className="pl-1">
                                                    <Text strong>{moment(item.createdAt).format('DD/MM/YYYY')}</Text>
                                                </Col>
                                                <Col span={7} className="flex-right">
                                                    <Text>$ {item.cantidad?.toMoney(true)} MXN</Text>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />

                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}