import React, { Component } from "react";
import { Button, Modal, Form, Upload, Spin, Typography, message } from 'antd';
import { UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;
const axios = require('axios').default;

/**
 *
 *
 * @class FormComprobantes
 * @extends {Component}
 * @description Modal para subir comprobantes del proof of delivery
 */
class FormComprobantes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            disabled: false,
            image: undefined

        }
    }

    FormComprobantes = React.createRef();

    componentDidMount = () => {
        this.getEnvio()
    }

    /**
     *
     * @memberof ModalOrdenEmbarque
     *
     * @method removeFile
     * @description Elimina un archivo del WebService.
     *
     * @param filename (string)
     * Recibe el nombre de la imagen.
     */
    removeFile = (filename) => {
        axios.post("/voucher/delete", { filename })
            .then(res => {
                console.log("archivo removido con exito", res);
            })
            .catch(res => {
                console.log("El archivo no se puedo remover", res);
            })
    };
    

    /**
   *
   * @memberof ModalTransaccion
   *
   * @method normFile
   * @description Segun la lista de transacciones, declara el boton de aceptar como disponible segun si se está enviando un archivo.
   *
   * @param  (string)
   * Recibe el nombre de la imagen.
   */
    normFile = (e) => {
        const { file, fileList } = e;


        console.log('file', file);
        console.log('fileList', fileList);

        let upload = false;

        for (let x = 0; x < fileList.length; x++) {
            if (fileList[x].response) {
                fileList[x].name = fileList[x].response.filename

            }

            if (fileList[x].status !== "done" && fileList[x].response && !this.state.loading) {
                this.state.loading = true;
                this.setState({ update: !this.state.update })
                break;
            }
        }

        /**
         * Cuando se sube un archivo, se debe actualizar la lista de imagenes, cuando se selecciona eliminar, se debe actualizar la lista una vez que se elimina
         */
        if (file.status == "done" && this.state.image) {
            this.removeFile(this.state.image);
        }
        if (file.status == "removed")
            this.removeFile((file.response.filename != undefined) ? file.response.filename : file.name);
        return e && e.fileList;
    };

    getEnvio = () => {
        axios.get('/envios/get', {
            params: { id: this.props.envioId }
        })
            .then(({data}) => {
                const firma = data.data.firma
                const fileList = [{
                    uid: -1,
                    name: firma,
                    status: "done",
                    url: axios.defaults.baseURL + "/upload/" + firma,
                    reponse: {filename: firma, name: firma}
                }]
                this.setState({
                    image: firma
                })
                this.FormComprobantes.current?.setFieldsValue({
                    firma: fileList
                })
            })
            .catch(error => {
                message.error('Error al obtener el envio!')
                console.log(error)
            })
    }

    /**
     *
     *
     * @memberof FormComprobantes
     */
    onFinish = (values) => {
        console.log('values', values)
        let v = {...values}

        v.firma = (Array.isArray(v.firma)) ? v.firma[0].name : undefined
        v.fecha = Date.now()
        
        axios.post('/envios/update', {...v, id: this.props.envioId})
            .then(success => {
                message.success('Envio Actualizado!')
                this.props.onSuccess()
            })
            .catch(error => {
                message.error('Error al actualizar envio!')
                console.log(error)
            })
    }

    render() {
        const { disabled } = this.state;
        return (
            <>
                <Form
                    layout="vertical"
                    className="text-center"
                    ref={this.FormComprobantes}
                    onFinish={this.onFinish}

                >
                    <Title level={3} className="text-center">Proof of Delivery</Title>
                    <Spin spinning={this.state.loading}>
                        <Form.Item
                            label="Comprobante"
                            name="firma"
                            getValueFromEvent={this.normFile}
                            valuePropName="fileList"
                            className="content-uploader center"
                        >
                            <Upload
                                action={axios.defaults.baseURL + "/voucher/add"}
                                multiple={false}
                                maxCount={1}

                            >
                                <Button icon={<UploadOutlined />}>
                                    Subir Firma
                                </Button>
                            </Upload>
                        </Form.Item>
                        <Form.Item className="center text-center" wrapperCol={{ lg: { span: 6} }}>
                            <Button htmlType="submit" type="primary" className="text-center center">
                                Enviar
                            </Button>
                        </Form.Item>
                    </Spin>
                </Form>
            </>
        )
    }
}



export default function (props) {
    const { visible = false, onClose = () => { }, id } = props

    return <Modal
        visible={visible}
        onCancel={onClose}
        title={null}
        footer={null}
        closable={true}
        destroyOnClose={true}
        zIndex={1000}
    >

        <FormComprobantes {...props} />
    </Modal>

}