
import React, { Component, createRef } from 'react';
import { Button, Space, Image, Typography, InputNumber, Table, Form, message, Modal } from 'antd';
import axios from 'axios';


import { DeleteOutlined } from '@ant-design/icons'
import ModalProveedor from '../ProjectManager/ProductsForQuotation/ModalProveedor';



const { Text } = Typography

/**
 *
 *
 * @export
 * @class TableOrdenCompra
 * @extends {Component}
 * 
 * @description Permite gestionar la tabla de productos de la orden de Compra
 */
export default class TableOrdenCompra extends Component {

  /**
   *
   *
   * @memberof TableOrdenCompra
   * @state orden Almacen la orden Actual
   * @state dataSource Lista de elementos de la tabla
   * @state visibleModalAgregarProductos Permite agregar y eliminar productos. 
   */
  state = {
    orden: {},
    dataSource: [],
    visibleModalAgregarProductos: false
  }

  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Obtenemos la orden de compra
   */
  componentDidMount() {
    this.getOrdenCompra()
  }

  /**
 *
 *
 * @memberof TableOrdenCompra
 * @description Formatea el arreglo a un objeto de indices para que pueda ser interprestado por el formulario.
 */
  setOrdenesDetalles = (arreglo = []) => {
    let values = {}
    for (let index = 0; index < arreglo.length; index++) {
      const data_orden_detalle = arreglo[index]
      values[index] = data_orden_detalle
    }
    return values
  }

  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Obtenemos la orden de compra
   */
  getOrdenCompra = ({ orden_compra_id = this.props.orden_compra_id } = this.props) => {

    axios.get('/ordenesCompra/get', {
      params: {
        id: orden_compra_id
      }
    })
      .then(({ data }) => {

        this.setState({
          dataSource: data.orden_detalles.map(orden_detalle => {
            return orden_detalle
          }),
          orden: data.data
        }, () => {

          this.formTableOrdenCompra.current.setFieldsValue(this.setOrdenesDetalles(data.orden_detalles))
        })
          this.props.setFolio(data?.data?.folio)
      })
      .catch(e => {
        console.log('datadatadata', e)
      })
  }


  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Eliminamos el producto.
   */
  deleteProduct = (id) => {
    Modal.confirm({
      title: "¿Desea eliminar este producto de la orden de compra?",
      onOk: () => axios.delete('/ordenesCompra/producto/delete', {
        params: {
          id
        }
      })
        .then(({ data }) => {
          message.success("Producto eliminado")
          this.getOrdenCompra()
        })
        .catch(e => {
          this.getOrdenCompra()
        })

    })
  }

  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Agregamos un producto.
   */
  addProduct = values => {
    axios.post('/ordenesCompra/producto/add', {
      ...values,
      orden_compra_id: this.props.orden_compra_id,
      proveedor_id: this.state.orden?.proveedor_id?._id
    })
      .then(({ data }) => {
        this.getOrdenCompra()
        this.setState({ visibleModalAgregarProductos: false })
      })
      .catch(response => {
        console.log('response', response)
      })
  }


  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Este metodo se encarga de calcular las ordenes de compra.
   */
  handleSave = (row) => {
    const { orden } = this.state
    row.info_matriz.costo = parseFloat(row?.info_matriz?.costo ? row?.info_matriz?.costo : 0);
    row.info_matriz.importacion = parseFloat(row?.info_matriz.importacion ? row?.info_matriz.importacion : 0);
    row.info_matriz.fletes = parseFloat(row?.info_matriz?.fletes ? row?.info_matriz?.fletes : 0);
    row.info_matriz.otros = parseFloat(row?.info_matriz?.otros ? row?.info_matriz?.otros : 0);

    //calculamos los costos
    let suma = (row.info_matriz.costo * row.cantidad + row.info_matriz.importacion + row.info_matriz.fletes + row.info_matriz.otros)
    let costo_unitario = (suma / row.cantidad).toMoney();


    row.info_matriz.costo_unitario = costo_unitario
    row.info_matriz.costo_total = suma

    row.total_venta = orden.tipo_orden === 2 ? row.precio_venta_unitario * row.cantidad_faltante : (row.precio_venta_unitario * row.cantidad);
    return row;
  }

  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Guarda la Orden de Compra.
   */
  onSave = (values) => {

    axios.put('/ordenesCompra/update-multiple', {
      orden_compra_id: this.props.orden_compra_id,
      orden_detalles: this.state.dataSource
    })
      .then(({ data }) => {
        message.success("La orden de compra" + this.props.folio + " ha sido guardada exitosamente.")
        this.props.onSave()
      })
      .catch(e => {
        console.log('datadatadata', e)
      })
  }


  /**
   *
   *
   * @memberof TableOrdenCompra
   * @description Permite mostarar y actualizar los valores de la tabla
   */
  columns = [
    {
      title: 'Producto',
      dataIndex: ['producto_id', 'nombre'],
      key: 'name',
      width: "25%",
      render: (value, item) => {
        let producto = item?.producto_id;
        return (
          <Space>
            <Image
              width={80}
              src={
                producto?.imagenes?.length > 0
                  ? `${axios.defaults.baseURL}/upload/${producto?.imagenes[0]?.filename}`
                  : `/images/nofile.png`
              }
              className="product-image"
              preview={true}
            />
            <Space direction="vertical" size={0}>
              <Text className="product-text-name">
                {producto
                  ? producto.modelo
                  : ""}
              </Text>

              <Text className="product-text-name">
                {/* Cant. {(props.orden?.tipo_orden && props.orden?.tipo_orden == 2) ? orden?.cantidad_faltante : cantidad } */}
              </Text>
              <Text className="product-text">
                {producto
                  ?
                  producto.marca
                  :
                  ""}
              </Text>
              <Text className="product-text">
                {
                  producto?.descripcion?.length > 82 ?
                    producto?.descripcion?.slice(0, 78) + "..." :
                    producto?.descripcion
                }
              </Text>
            </Space>
          </Space>
        );
      },
    },
    {
      title: "Cantidad",
      dataIndex: "cantidad",
      key: "cantidad",
      render: (value, record, index) => (
        <Form.Item
          className="form-item-orden-compra"
          name={(this.state.orden?.tipo_orden === 2) ? [index, "cantidad_faltante"] : [index, "cantidad"]}
          hasFeedback
          tooltip
          rules={[
            {
              required: true,
              message: "Ingrese la cantidad de producto",
            },
          ]}
        >
          <InputNumber
            controls={false}
            className="width-100"
          />
        </Form.Item>
      ),
    },
    {
      title: "Costo Uni. Proveedor",
      key: "otros",
      render: (value, record, index) => (
        <Form.Item
          name={[index, "info_matriz", "costo"]}
          className="form-item-orden-compra"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Ingrese el Costo Unitario",
            },
            {
              validator: (_, value) => {
                if (value > 0)
                  return Promise.resolve()
                return Promise.reject(new Error('El precio no es valido.'))
              }
            },
          ]}
          tooltip={{
            title: "Nn"
          }}
        >
          <InputNumber
            controls={false}
            formatter={(value) =>
              `$ ${value.replace("$", "").replace(" ", "")}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            className="width-100"
          />
        </Form.Item>
      ),
    },
    {
      title: "Importacion",
      dataIndex: ["info_matriz", "importacion"],
      key: "importacion",
      render: (value, record, index) => (
        <Form.Item
          name={[index, "info_matriz", "importacion"]}
          className="form-item-orden-compra"
          hasFeedback
          rules={[
            {
              required: true,
              message: "Ingrese la Importacion",
            },
          ]}
        >
          <InputNumber
            controls={false}
            formatter={(value) =>
              `$ ${value.replace("$", "").replace(" ", "")}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            className="width-100"
          />
        </Form.Item>
      ),
    },
    {
      title: "Fletes",
      dataIndex: ["info_matriz", "fletes"],
      key: "fletes",
      render: (value, record, index) => (
        <Form.Item
          className="form-item-orden-compra"
          name={[index, "info_matriz", "fletes"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Ingrese el costo del flete",
            },
          ]}
        >
          <InputNumber

            controls={false}
            formatter={(value) =>
              `$ ${value.replace("$", "").replace(" ", "")}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            className="width-100"
          />
        </Form.Item>
      ),
    },
    {
      title: "Otros",
      dataIndex: ["info_matriz", "otros"],
      key: "otros",
      render: (value, record, index) => (
        <Form.Item
          className="form-item-orden-compra"
          hasFeedback
          name={[index, "info_matriz", "otros"]}
          rules={[
            {
              required: true,
              message: "Ingrese otras cantidades de gastos",
            },
          ]}
        >
          <InputNumber
            controls={false}
            formatter={(value) =>
              `$ ${value.replace("$", "").replace(" ", "")}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            className="width-100"
          />
        </Form.Item>
      ),
    },
    {
      title: "Costo Unitario",
      key: "costo_unitario",
      shouldCellUpdate: () => true,
      render: (value, record, index) => {
        return (
          <span>
            $ {(this.state.dataSource[index]?.info_matriz?.costo_unitario) ? this.state.dataSource[index]?.info_matriz?.costo_unitario.toMoney(true) : null}
          </span>
        );
      },
    },
    {
      title: "Costo total",
      key: "costo_total",
      shouldCellUpdate: () => true,
      render: (value, record, index) => {
        return (
          <span>
            $ {(this.state.dataSource[index]?.info_matriz?.costo_total) ? this.state.dataSource[index]?.info_matriz?.costo_total.toMoney(true) : null}
            {/* $ {record && record?.info_matriz ? record.info_matriz.costo_total?.toMoney(true) : 0.0} */}
          </span>
        );
      },
    },
    {
      key: "acciones",
      render: (value, record, index) => {
        return (
          <Space>
            <Button onClick={() => this.deleteProduct(record._id)} disabled={record.editable !== true} danger type="primary" size='small' icon={<DeleteOutlined style={{ color: "white" }} />} />
          </Space>
        );
      },
    },
  ];

  formTableOrdenCompra = createRef()

  refTableOrdenCompra = createRef()

  render() {

    const { dataSource, orden, visibleModalAgregarProductos } = this.state
    const { columns, formTableOrdenCompra, refTableOrdenCompra } = this

    return (
      <>
        <Form
          ref={formTableOrdenCompra}
          rowKey={record => record._id}
          onFinish={this.onSave}
          onValuesChange={(values, record) => {
            let index = Object.entries(values)[0][0]
            let recordTemp = this.formTableOrdenCompra.current.getFieldValue(index)
            this.setState(state => {
              state.dataSource[index].cantidad = recordTemp.cantidad;
              state.dataSource[index].info_matriz = { ...state.dataSource[index].info_matriz, ...recordTemp.info_matriz }
              state.dataSource[index] = this.handleSave(state.dataSource[index])
              state.dataSource = [...state.dataSource]
              return state;
            })
          }}>
          <Table
            className="table-orden-compra"
            dataSource={dataSource}
            columns={columns}
            pagination={false}
            footer={() => <Button block onClick={() => this.setState({ visibleModalAgregarProductos: true })}>Agregar un nuevo Registro +</Button>}
          />
        </Form>
        <ModalProveedor
          visible={visibleModalAgregarProductos}
          onCancel={() => this.setState({ visibleModalAgregarProductos: false })}
          cantidad={true}
          proveedor_id={orden?.proveedor_id?._id}
          hide_proveedor={(orden?.proveedor_id?._id) !== null && orden?.proveedor_id?._id !== undefined}
          onSave={this.addProduct}
        />
      </>
    )
  }
}